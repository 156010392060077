import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { ARAdvanceAllocation } from './ARAdvanceAllocation/ARAdvanceAllocation'
import { ARAdvanceDetail } from './ARAdvanceDetail/ARAdvanceDetail'
import { ARAdvanceForm } from './ARAdvanceForm/ARAdvanceForm'
import { ARMain } from '../ARMain'
import { ARDetail } from '../ARDetail/ARDetail'

const advancesRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/advance/filter',
    },
    component: (
      <AdvancedFilter
        mode="advance"
        app="account-receivable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/advance',
    },
    component: (
      <ARMain
        arSubmenu="advance"
        routeSegments="Advance"
        accountType="account-receivable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/advance/:DocumentID/detail',
    },
    component: (
      <ARDetail
        arSubmenu="advance"
        routeSegments="Advance"
        accountType="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/advance/:DocumentID/detail`,
    },
    component: (
      <ARDetail
        arSubmenu="advance"
        routeSegments="Advance"
        accountType="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/advance/:AdvanceID/detail/workdesk',
    },
    component: <ARAdvanceDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/advance/:AdvanceID/detail/workdesk-history',
    },
    component: <ARAdvanceDetail mode="workdesk-history" />,
  },

  /* -------------------------------------------- */
  /*                ALLOCATION                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/advance/:AdvanceID/allocation',
    },
    component: <ARAdvanceAllocation />,
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/advance/:AdvanceID/allocation`,
    },
    component: <ARAdvanceAllocation />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/advance/add',
    },
    component: <ARAdvanceForm formMode="add" type="ADVANCE_AP" />,
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/advance/:AdvanceID/edit',
    },
    component: <ARAdvanceForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/advance/:AdvanceID/approve-reject',
    },
    component: <ARAdvanceForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/advance/:AdvanceID/resubmit',
    },
    component: <ARAdvanceForm formMode="resubmit" />,
  },
]

export default advancesRoutes
