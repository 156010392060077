import { CompanyListing } from 'containers/CompanyModule/CompanyListing'
import React, { lazy } from 'react'

const HomeMenu = lazy(() =>
  import('./HomeMenu').then(module => ({
    default: module.HomeMenu,
  }))
)

const AccessSecuritySubmenu = lazy(() =>
  import('../AccessSecurityModule/AccessSecuritySubmenu').then(module => ({
    default: module.AccessSecuritySubmenu,
  }))
)

const homeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <HomeMenu />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <HomeMenu />,
  },
  {
    props: { exact: true, path: '/access-security' },
    component: <AccessSecuritySubmenu />,
  },
  {
    props: { exact: true, path: '/company-setup' },
    component: <CompanyListing mode="company-setup" createAccess={true} />,
  },
]

export default homeRoutes
