import React, { lazy } from 'react'
import { AccCodeStructureDetail } from './AccCodeStructureDetail'
import { AccCodeStructureForm } from './AccCodeStructureForm'
import { AssignEntityCOAForm } from './AssignEntityCOAForm'
import { MasterCOAExcelValidation } from './MasterCOAExcelValidation'

import { MasterCOAForm } from './MasterCOAForm'

const MasterCOAListing = lazy(() =>
  import('./MasterCOAListing').then(module => ({
    default: module.MasterCOAListing,
  }))
)

const MasterCOAStructure = lazy(() =>
  import('./MasterCOAStructure').then(module => ({
    default: module.MasterCOAStructure,
  }))
)

const MasterCOAStructureV2 = lazy(() =>
  import('./MasterCOAStructureV2').then(module => ({
    default: module.MasterCOAStructureV2,
  }))
)

const masterCOARoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/acc-code-structure',
    },
    component: <AccCodeStructureDetail />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/acc-code-structure/add',
    },
    component: <AccCodeStructureForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/acc-code-structure/edit',
    },
    component: <AccCodeStructureForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/master-coa/:accType',
    },
    component: <MasterCOAListing />,
  },

  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/master-coa/:accType/add',
    },
    component: <MasterCOAForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/general-setting/master-coa/:accType/:MasterCOAID/edit',
    },
    component: <MasterCOAForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-ledger/general-setting/master-coa/:accType/:MasterCOAID/entity-coa',
    },
    component: <AssignEntityCOAForm mode="add" />,
  },

  // {
  //   props: { exact: true, path: '/general-setting/master-coa/edit' },
  //   component: <MasterCOAForm mode={'edit'} />,
  // },
  // {
  //   props: { exact: true, path: '/general-setting/master-coa/:ID/add' },
  //   component: <MasterCOAStructure mode="add" />,
  // },
  {
    props: {
      exact: true,
      path: '/general-settings/master-coa/:ID',
    },
    component: <MasterCOAStructureV2 mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/master-coa/:accType/validation',
    },
    component: <MasterCOAExcelValidation />,
  },
]

export default masterCOARoutes
