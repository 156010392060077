import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import React from 'react';

export interface AttachmentDialogProps {
  AttachmentDetailView: any;
  openDoc: boolean;
  setOpenDoc: any;
  menu?: any;
  title?: string;
  DocumentListing?: any;
}

export const AttachmentDialog = (props: AttachmentDialogProps) => {
  const {
    menu,
    openDoc,
    setOpenDoc,
    AttachmentDetailView,
    DocumentListing,
    title,
  } = props;

  return (
    <>
      <CommonDialog
        fullWidth={true}
        open={openDoc}
        onClose={() => setOpenDoc(false)}
        sections={{
          header: {
            title: title,
            rightText: menu?.obj?.[0]?.RefNo,
            infoLine: 'Attachment(s)',
          },
          body: () => (
            <>
              <div className="content-container">
                {DocumentListing.length > 0 ? (
                  AttachmentDetailView(
                    DocumentListing?.filter(x => x?.AttachmentID),
                  )
                ) : (
                  <span className="mdDesc">No Attachment</span>
                )}
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setOpenDoc(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
