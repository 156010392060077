import theme from '@ifca-root/react-component/src/assets/theme'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  StyledMenu,
  StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { dropdownOption } from 'containers/DigitalReportModule/function/DigitalReportDropdown'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_PACKAGE_BY_ACCOUNT } from 'containers/AccountModule/GraphQL/AccountQuery'
import { SubscriptionClient } from 'SubscriptionClient'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'

export const ARDigitalReportSubmenu = (props: any) => {
  let history = useHistory()
  const location = useLocation()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { handlePermHidden } = usePermissionChecker()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [labelState, setLabelState] = useState<string>(
    'Account Receivable Reports'
  )

  // ACCOUNTX API CALLS
  const [
    loadPackage,
    {
      loading: packageLoading,
      error: packageError,
      data: { getPackageByAccount } = { getPackageByAccount: [] },
    },
  ] = useLazyQuery(GET_PACKAGE_BY_ACCOUNT, {
    client: SubscriptionClient,
  })

  const submenuList = [
    {
      path: `/digital-reports/account-receivable/ar-debtor-ledger-detail-by-doc-date/parameters`,
      title: `Debtor Ledger Detail By Doc Date`,
    },
    {
      path: `/digital-reports/account-receivable/ar-statement-of-account/parameters`,
      title: `Statement Of Account`,
    },
    {
      path: `/digital-reports/account-receivable/ar-debtor-aging-summary-trx-date/parameters`,
      title: `Debtor Aging Summary by Trx Date`,
    },
    {
      path: `/digital-reports/account-receivable/ar-debtor-aging-summary-doc-date/parameters`,
      title: `Debtor Aging Summary by Document Date`,
    },
    {
      path: `/digital-reports/account-receivable/ar-debtor-aging-detail-trx-date/parameters`,
      title: `Debtor Aging Detail by Trx Date`,
    },
    {
      path: `/digital-reports/account-receivable/ar-debtor-control-summary/parameters`,
      title: `Debtor Control Summary`,
    },
    {
      path: `/digital-reports/account-receivable/ar-debtor-control-detail/parameters`,
      title: `Debtor Control Detail`,
    },
  ]

  const handleChange = value => {
    history.push({
      pathname: value,
    })
  }

  let package_permission
  if (!packageLoading) {
    package_permission = dropdownOption.filter((v, i) =>
      getPackageByAccount?.includes(v?.package_name)
    )
  }

  useEffect(() => {
    if (user?.accountID) {
      loadPackage({
        variables: {
          softwareCode: 'ACCOUNT',
          accountID: user?.accountID,
        },
      })
    }
  }, [user?.accountID])

  return (
    <>
      {packageLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(``)}
        smTitle={'Account Receivable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reports', current: true },
        ]}
      />
      <DynamicSubHeader
        title={labelState}
        handleClick={e => {
          handleClick(e)
        }}
        dropDownOptions={
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {package_permission?.map(x => {
              if (
                handlePermHidden({
                  permEnum: x?.permission,
                })
              )
                return (
                  <StyledMenuItem
                    onClick={e => {
                      // setLabelState(x?.label)
                      handleChange(x?.path)
                      handleClose()
                    }}
                    selected={location.pathname.includes(x.path)}
                  >
                    <ListItemText primary={x.label} />
                  </StyledMenuItem>
                )
            })}
          </StyledMenu>
        }
      />
      <ContentWrapper
        float
        style={{
          marginTop: isDesktop ? '100px' : '40px',
          top: isDesktop ? '47px' : null,
        }}
      >
        <List className="core-list">
          {submenuList.map((el, index) => {
            return (
              <ListItem key={index} onClick={() => history.push(el?.path)}>
                <ListItemText
                  style={{
                    textAlign: 'right',
                    marginLeft: '5px',
                  }}
                  primary={<span className="xsTitle">{el?.title}</span>}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <KeyboardArrowRight
                      onClick={() =>
                        history.push({
                          pathname: el.path,
                        })
                      }
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
