import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from '@material-ui/core'
import React from 'react'

interface ICardAddOnProps {
  children: any
  style?: Record<string, string>
  contentStyle?: Record<string, string>
  IsBold?: boolean
  IsDifferentColour?: boolean
  headerDifferentColour?: boolean
  section?: {
    header?: {
      title?: any
      rightTitle?: any
      docAmt?: number
      totalAmt?: number
      error?: any
      errorMsg?: any
      onClickAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
      icon?: any
      disabled?: boolean
      dollarIcon?: any
      style?: Record<string, string>
    }
    footer?: {
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
      label?: string
    }
  }
}

export function CardContentsWithInput(props: ICardAddOnProps) {
  const {
    children,
    section,
    style,
    contentStyle,
    IsDifferentColour,
    IsBold,
  } = props

  return (
    <Box
      className={`${(IsDifferentColour && 'card-form-darkblue') ||
        (!IsDifferentColour && 'card-form')}`}
      // className={
      //   IsDifferentColour === true ? 'card-form-darkblue' : 'card-form'
      // }
      style={style}
    >
      <Card>
        {section?.header && (
          <CardHeader
            style={section?.header?.style}
            disableTypography
            title={
              <>
                <div className="infoline-content">
                  <span
                    className="smTitle flex-space"
                    style={{ fontWeight: IsBold ? 500 : null }}
                  >
                    {section?.header?.title}
                  </span>
                  <span className="smTitle ">
                    {section?.header?.dollarIcon}
                  </span>
                  <span
                    className="smTitle "
                    style={{ fontWeight: IsBold ? 500 : null }}
                  >
                    {section?.header?.rightTitle}
                  </span>
                </div>
                {/* <div className="infoline-content" style={{ margin: '0' }}>
                  <>
                    {section?.header?.docAmt && (
                      <span
                        className="xxTitle flex-space"
                        style={{
                          fontWeight: 500,
                          color: 'black',
                          fontSize: '10.5px',
                          height: '12px',
                        }}
                      >
                        Balance:
                        <img
                          src={BlackDollarIcon}
                          style={{
                            width: '12px',
                            height: '12px',
                            marginBottom: '-2px',
                            marginRight: '3px',
                            marginLeft: '3px',
                          }}
                        />
                        {amtStr(
                          Number(section?.header?.docAmt ?? 0) -
                            Number(section?.header?.totalAmt ?? 0)
                        )}
                      </span>
                    )}
                    {section?.header?.docAmt && (
                      <span
                        style={{
                          fontWeight: 500,
                          height: '12px',
                          color: 'black',
                          float: 'right',
                          fontSize: '10.5px',
                        }}
                      >
                        Allocated:
                        <img
                          src={BlackDollarIcon}
                          style={{
                            width: '12px',
                            height: '12px',
                            marginBottom: '-2px',
                            marginRight: '3px',
                            marginLeft: '3px',
                          }}
                        />
                        {amtStr(Number(section?.header?.totalAmt ?? 0))}
                      </span>
                    )}
                  </>
                </div> */}
              </>
            }
            subheader={
              <>
                {section.header?.error && (
                  <div
                    className="desc"
                    style={{ textAlign: 'right', color: '#e53935' }}
                  >
                    {section?.header?.errorMsg}
                  </div>
                )}
              </>
            }
            action={
              <IconButton
                disabled={section?.header?.disabled}
                className="card-header-btn"
                aria-label="edit"
                onClick={section?.header?.onClickAction}
              >
                {section?.header?.icon}
              </IconButton>
            }
          />
        )}

        <CardContent style={contentStyle}>{children}</CardContent>
      </Card>
      {section?.footer && (
        <Box className="btn-container">
          <Button
            color="primary"
            variant="contained"
            onClick={section?.footer?.onClick}
          >
            {section?.footer?.label}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CardContentsWithInput
