import { Divider } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import CreateIcon from '@material-ui/icons/Create'
import AppContext from 'containers/App/Store/AppContext'
import React, { useContext } from 'react'

interface subHeaderProps {
  title?: any
  rightText?: any
  infoLine?: any
  titleStyle?: any
  rightInfoLine?: any
  dropDownOptions?: any
  infoLineDropDownOptions?: any
  handleClick?: any
  infoLineHandleClick?: any
  // fixResponsive?: boolean //new changes 5/6/2021 by syafiq ali
  isPicture?: any //new changes 5/6/2021 by syafiq ali
  style?: any
  infoLineStyle?: any
  rightInfoLineStyle?: any
}
const DynamicSubHeader = ({
  infoLine,
  infoLineStyle,
  rightInfoLineStyle,
  rightText,
  title,
  titleStyle,
  dropDownOptions,
  infoLineDropDownOptions,
  handleClick,
  infoLineHandleClick,
  rightInfoLine,
  style,
  // fixResponsive, //new changes 5/6/2021 by syafiq ali
  isPicture,
}: subHeaderProps) => {
  const { globalState }: any = useContext(AppContext as any)

  return (
    //new changes 5/6/2021 by syafiq ali
    <div
      className={`${
        globalState.drawerOpen ? 'fix-subheader' : 'fix-subheader-fw'
      }`}
      style={style}
    >
      {/* //new changes 5/6/2021 by syafiq ali */}
      <div
        className={`dynamic-subheader fix-subheader-content 
         ${infoLine ? 'multiple' : 'single'} `}
      >
        {isPicture} {/* //new changes 5/6/2021 by syafiq ali */}
        <div className={`${isPicture && 'wraps-dynamic-content'}`}>
          <div className="dynamic-content" onClick={handleClick}>
            <div className="session flex">
              <div
                className="xsTitle flex-space c-orange"
                style={{
                  overflow: 'hidden',
                  fontWeight: '600',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  ...titleStyle,
                }}
              >
                {title}
              </div>
              {rightText && (
                <div className="xsTitle rightText">{rightText}</div>
              )}
              {dropDownOptions ? (
                <IconButton className="right-btn" style={{ padding: 0 }}>
                  <ArrowDropDownOutlinedIcon />
                </IconButton>
              ) : !!handleClick ? (
                <IconButton className="right-btn" style={{ padding: 0 }}>
                  <CreateIcon />
                </IconButton>
              ) : null}
            </div>
          </div>
          <Divider style={{ backgroundColor: '#fff' }} />
          <div className="infoline-content" onClick={infoLineHandleClick}>
            <div className="infoline flex-space" style={infoLineStyle}>
              {infoLine}
            </div>
            {infoLineDropDownOptions ? (
              <IconButton className="right-btn" style={{ padding: 0 }}>
                <ArrowDropDownOutlinedIcon />
              </IconButton>
            ) : null}
            <div
              className="infoline rightText"
              style={
                !!dropDownOptions && !!rightInfoLine
                  ? { paddingRight: '25px', ...rightInfoLineStyle }
                  : rightInfoLineStyle
              }
            >
              {rightInfoLine}
            </div>
          </div>
        </div>
        {dropDownOptions}
        {infoLineDropDownOptions}
      </div>
    </div>
  )
}

export default DynamicSubHeader
