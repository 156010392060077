import { GLDigitalReportSubmenu } from './GLDigitalReportSubmenu'
import { CashLedgerListingReport } from './GeneratedReport/CashLedgerListingReport'
import { GLJournalByCostCentreReport } from './GeneratedReport/GLJournalByCostCentreReport'
import { GLJournalByEntityReport } from './GeneratedReport/GLJournalByEntityReport'
import { MasterCOAListingReport } from './GeneratedReport/MasterCOAListingReport'
import { TrialBalanceByCostCentreReport } from './GeneratedReport/TrialBalanceByCostCentreReport'
import { TrialBalanceByEntityReport } from './GeneratedReport/TrialBalanceByEntityReport'
import { GLJournalByCostCentreParamsForm } from './Parameters/GLJournalByCostCentreParamsForm'
import { GLJournalByEntityParamsForm } from './Parameters/GLJournalByEntityParamsForm'
import { MasterCOAListingParamsForm } from './Parameters/MasterCOAListingParamsFrom'
import { TrailBalanceByCostCentreParamsForm } from './TrailBalanceByCostCentreParamsForm'
import { TrailBalanceByEntityParamsForm } from './Parameters/TralBalanceByEntityParamsForm'
import React from 'react'
import { EntityCOAByDepartmentListingReport } from './GeneratedReport/EntityCOAByDepartmentListingReport'
import { EntityCOAByDepartmentListingParamsForm } from './Parameters/EntityCOAByDepartmentListingParamsForm'

const glDigitalReportRoutes = [
  {
    props: {
      exact: true,
      path: '/digital-reports/general-ledger',
    },
    component: <GLDigitalReportSubmenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/gl-journal-entity-report/generated',
    },
    component: <GLJournalByEntityReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/gl-journal-costcentre-report/generated',
    },
    component: <GLJournalByCostCentreReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/trial-balance-costcentre-report/generated',
    },
    component: <TrialBalanceByCostCentreReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/trial-balance-entity-report/generated',
    },
    component: <TrialBalanceByEntityReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/mastercoa-listing-report/generated',
    },
    component: <MasterCOAListingReport />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/general-ledger/cash-ledger-listing-report',
    },
    component: <CashLedgerListingReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/entitycoa-department-listing-report/generated',
    },
    component: <EntityCOAByDepartmentListingReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/entitycoa-department-listing-report/parameters',
    },
    component: <EntityCOAByDepartmentListingParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/gl-journal-entity-report/parameters',
    },
    component: <GLJournalByEntityParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/gl-journal-costcentre-report/parameters',
    },
    component: <GLJournalByCostCentreParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/trial-balance-costcentre-report/parameters',
    },
    component: <TrailBalanceByCostCentreParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/trial-balance-entity-report/parameters',
    },
    component: <TrailBalanceByEntityParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-ledger/mastercoa-listing-report/parameters',
    },
    component: <MasterCOAListingParamsForm />,
  },
]

export default glDigitalReportRoutes
