import { ApprovalStatus } from 'generated/graphql'

export const statusInput = mode => {
  let temp
  switch (mode) {
    case 'submit':
      temp = ApprovalStatus.Submit
      break
    case 'draft':
      temp = ApprovalStatus.Active
      break
    case 'approve':
      temp = ApprovalStatus.Approved
      break
    case 'reject':
      temp = ApprovalStatus.Rejected
      break
  }
  return temp
}

export const getFiles = file => {
  let myArray = []
  let temp = {}
  for (let i = 0; i < file.length; i++) {
    temp = {
      lastMod: file[i].lastModified,
      lastModDate: file[i].lastModifiedDate,
      name: file[i].name,
      size: file[i].size,
      type: file[i].type,
    }
    //add the file obj to your array
    myArray.push(temp)
  }
  return myArray
}

export const findDuplicates = (
  existingDocNoArr: any[],
  inputStr: string,
  formMode: string,
  editDataDocNo: string
) => {
  if (
    (formMode === 'add' && existingDocNoArr?.includes(inputStr)) ||
    (formMode === 'edit' &&
      existingDocNoArr?.filter(x => x !== editDataDocNo)?.includes(inputStr))
  ) {
    return true
  } else {
    return false
  }
}
