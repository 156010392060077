import React, { lazy } from 'react'
import roleRoutes from './Role/RoleRoutes'
import userRoutes from './User/UserRoutes'

const AccessSecuritySubmenu = lazy(() =>
  import('./AccessSecuritySubmenu').then(module => ({
    default: module.AccessSecuritySubmenu,
  }))
)

const accessSecurityRoutes = [
  {
    props: { exact: true, path: '/general-ledger/access-security' },
    component: <AccessSecuritySubmenu />,
  },
  ...userRoutes,
  ...roleRoutes,
]

export default accessSecurityRoutes
