import React from 'react'
import { ApprovalPolicyAsgmtAmountForm } from './ApprovalPolicyAsgmtAmountForm'
import { ApprovalPolicyAsgmtRoleForm } from './ApprovalPolicyAsgmtRoleForm'
import { ApprovalPolicyAsgmtSubmenu } from './ApprovalPolicyAsgmtSubmenu'

const approvalPolicyAssignmentRoutes = [
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/approval-policy-asgmt-gl',
    },
    component: <ApprovalPolicyAsgmtSubmenu module="general-ledger" />,
  },
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/company-setting/approval-policy-asgmt-cb',
    },
    component: <ApprovalPolicyAsgmtSubmenu module="cash-book" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/approval-policy-asgmt-ap',
    },
    component: <ApprovalPolicyAsgmtSubmenu module="account-payable" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/company-setting/approval-policy-asgmt-ar',
    },
    component: <ApprovalPolicyAsgmtSubmenu module="account-receivable" />,
  },
  //GENERAL LEDGER

  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/general-ledger/:CompanyID/company-setting/approval-policy-asgmt-gl',
  //   },
  //   component: <ApprovalPolicyAsgmtSubmenuGL module="general-ledger" />,
  // },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/approval-policy-asgmt-gl/:docType',
    },
    component: <ApprovalPolicyAsgmtRoleForm module="general-ledger" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/approval-policy-asgmt-gl/:docType/original',
    },
    component: (
      <ApprovalPolicyAsgmtRoleForm
        module="general-ledger"
        budgetType="original"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/approval-policy-asgmt-gl/:docType/revision',
    },
    component: (
      <ApprovalPolicyAsgmtRoleForm
        module="general-ledger"
        budgetType="revision"
      />
    ),
  },

  //CASH BOOK

  // {
  //   props: {
  //     exact: true,
  //     path: '/cash-book/:CompanyID/company-setting/approval-policy-asgmt-cb',
  //   },
  //   component: <ApprovalPolicyAsgmtSubmenuCB module="cash-book" />,
  // },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/company-setting/approval-policy-asgmt-cb/:docType',
    },
    component: <ApprovalPolicyAsgmtRoleForm module="cash-book" />,
  },

  // ACC PAYABLE
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/approval-policy-asgmt-ap/:docType',
    },
    component: <ApprovalPolicyAsgmtRoleForm module="account-payable" />,
  },
  // ACC RECEIVABLE
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/company-setting/approval-policy-asgmt-ar/:docType',
    },
    component: <ApprovalPolicyAsgmtRoleForm module="account-receivable" />,
  },
]

export default approvalPolicyAssignmentRoutes
