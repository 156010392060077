import React, { lazy } from 'react'
import { CostCentreDefinitionDetail } from './CostCentreDefinitionDetail'
import { CostCentreDefinitionFormv2 } from './CostCentreDefinitionFormv2'

const CostCentreDefinitionListing = lazy(() =>
  import('./CostCentreDefinitionListing').then(module => ({
    default: module.CostCentreDefinitionListing,
  }))
)

const CostCentreDefinitionForm = lazy(() =>
  import('./CostCentreDefinitionForm').then(module => ({
    default: module.CostCentreDefinitionForm,
  }))
)

const costCentreDefinitionRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/cost-centre-definition',
    },
    component: <CostCentreDefinitionListing />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/cost-centre-definition/add',
    },
    component: <CostCentreDefinitionFormv2 mode="Add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-ledger/general-setting/cost-centre-definition/:CostCentreDefinitionHeaderID/edit',
    },
    component: <CostCentreDefinitionFormv2 mode="Edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-ledger/general-setting/cost-centre-definition/:CostCentreDefinitionHeaderID/detail',
    },
    component: <CostCentreDefinitionDetail />,
  },
]

export default costCentreDefinitionRoutes
