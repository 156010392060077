import { yupResolver } from '@hookform/resolvers'
import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Logo from 'assets/images/Account-x.png'
import Background from 'assets/images/HR-Background.jpg'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { useResetPasswordMutation } from 'generated/graphql'
import { encryptMessage } from 'helpers/Crypto/JSEncrypt'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import JwtDecode from 'jwt-decode'
import { IAction } from 'models/index'
import React, { Reducer, useContext, useReducer } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'

interface ResetProps {
  newPassword: string
  confirmPassword: string
}

interface resetPasswordProps {
  showNewPassword: boolean
  showConfirmPassword: boolean
  message: string
  dialog: boolean
}
export const ResetPasswordSchema = yup.object().shape({
  newPassword: CommonYupValidation.password(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
})
export const ResetPassword = () => {
  let history = useHistory()
  const { token }: any = useParams()
  // console.log(token, 'token');
  const userInfo = JwtDecode(token) as any
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const [ResetPassword, { loading, error }] = useResetPasswordMutation({
    onError: error => {
      console.log('error', error)
      dispatch({
        type: 'dialog',
        payload: true,
      })
      dispatch({
        type: 'message',
        payload: error.graphQLErrors[0].message,
      })
    },
    onCompleted: data => {
      if (data?.resetPassword) {
        setOpenSnackBar(true)
        setSnackBarMsg('Password reset successfully')
        history.push('/login')
      }
    },
  })

  const reducer: Reducer<resetPasswordProps, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }

  const initialState: resetPasswordProps = {
    showNewPassword: false,
    showConfirmPassword: false,
    dialog: false,
    message: '',
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const { handleSubmit, register, errors, control, getValues } = useForm<
    ResetProps
  >({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema),
  })

  const onSubmit = data => {
    console.log(data)
    console.log(token)
    ResetPassword({
      variables: {
        // userID: userInfo.userID,
        password: encryptMessage(data.newPassword),
        token: token,
      },
    })
  }
  return (
    <>
      {loading && <Loading />}
      <AuthLayout className="margin-override" logo={Logo} image={Background}>
        <span className="page-title">Reset Password</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            <Controller
              as={TextField}
              name="newPassword"
              label="New Password"
              autoComplete="off"
              fullWidth
              required
              control={control}
              helperText={errors?.newPassword?.message}
              error={errors?.newPassword ? true : false}
              type={state.showNewPassword ? 'text' : 'password'}
              value={getValues('newPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Password"
                      onClick={() =>
                        dispatch({
                          type: 'showNewPassword',
                          payload: !state.showNewPassword,
                        })
                      }
                    >
                      {state.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.confirmPassword?.message}
              error={errors?.confirmPassword ? true : false}
              autoComplete="off"
              name="confirmPassword"
              label="Confirm Password"
              required
              control={control}
              type={state.showConfirmPassword ? 'text' : 'password'}
              value={getValues('confirmPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Password"
                      onClick={() =>
                        dispatch({
                          type: 'showConfirmPassword',
                          payload: !state.showConfirmPassword,
                        })
                      }
                    >
                      {state.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            className="login-btn"
            type="submit"
          >
            Send
          </Button>
        </form>
        <CommonDialog
          fullWidth={true}
          open={state.dialog}
          onClose={() =>
            dispatch({
              type: 'dialog',
              payload: false,
            })
          }
          sections={{
            header: {
              title: 'Error',
            },
            body: () => <div>{state.message}</div>,
            footer: {
              actions: [
                {
                  displayText: 'Ok',
                  props: {
                    onClick: () =>
                      dispatch({
                        type: 'dialog',
                        payload: false,
                      }),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
            },
          }}
        />
      </AuthLayout>
    </>
  )
}
