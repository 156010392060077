import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, FormControlLabel, ListItem } from '@material-ui/core'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetWarehouseDocument,
  useAssignWarehouseItemMutation,
  useGetCompanyQuery,
  useGetStockCategoryItemAssignmentLazyQuery,
  useGetWarehouseQuery,
  useUnassignWarehouseItemMutation,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
interface AvailableStockItemProps {
  StockItemID: String
}

export const AvailableStockItemForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  const CompanyID: any = location?.state
  const { WarehouseID }: any = useParams()
  const [entity, setEntity] = useState<Set<any>>()
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const { pageMode, user, companyID }: any = props
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [expandArray, setExpandArray] = useState([])

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<AvailableStockItemProps>({
    defaultValues: {},
    mode: 'all',
  })

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
  })
  const {
    loading: WarehouseLoading,
    error: WarehouseError,
    data: { getWarehouse } = { getWarehouse: {} as any },
  } = useGetWarehouseQuery({
    fetchPolicy: 'network-only',
    variables: { WarehouseID: WarehouseID },
  })

  const [
    fetchStockCategory,
    {
      loading: getStockCategoryItemAssignmentLoading,
      data: { getStockCategoryItemAssignment } = {
        getStockCategoryItemAssignment: [],
      },
    },
  ] = useGetStockCategoryItemAssignmentLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getStockCategoryItemAssignment }) => {
      if (getStockCategoryItemAssignment?.length > 0) {
        setOriginalListing(getStockCategoryItemAssignment)
      }
    },
  })

  const [
    assignWarehouseItem,
    {
      loading: assignWarehouseItemLoading,
      error: assignWarehouseItemError,
      data: assignedWarehouseData,
    },
  ] = useAssignWarehouseItemMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        setEntity(new Set(undefined))
        history.push({
          // pathname: `/general-ledger/general-setting/master-coa/${accType}`,
          state: CompanyID,
        })
      }, 500)
    },
  })
  const [
    unassignWarehouseItem,
    {
      loading: unassignWarehouseItemLoading,
      error: unassignWarehouseItemError,
      data: unassignedWarehouseData,
    },
  ] = useUnassignWarehouseItemMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setEntity(new Set(undefined))
      setTimeout(() => {
        history.push({
          // pathname: `/general-ledger/general-setting/master-coa/${accType}`,
          state: CompanyID,
        })
      }, 500)
    },
  })
  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  const handleCreateUpdate =
    pageMode === 'available' ? assignWarehouseItem : unassignWarehouseItem

  useEffect(() => {
    if (getCompany && getCompany?.length > 0) {
      fetchStockCategory({
        variables: {
          WarehouseID: WarehouseID,
          mode: pageMode,
        },
      })
    }
  }, [getCompany, getWarehouse, pageMode])

  // handle checkbox for specific stock item
  const handleCheckBoxChange = (e: any, data: any, index: number) => {
    let entityTemp = entity || new Set()
    if (!entityTemp.has(e.target.value)) {
      data?.StockItem?.find(v => {
        if (v.StockItemID === e.target.value) {
          entityTemp.add(v?.StockItemID)
        }
      })
    } else {
      data?.StockItem?.find(v => {
        if (v.StockItemID === e.target.value) {
          entityTemp.delete(v?.StockItemID)
        }
      })
    }
    setEntity(new Set(entityTemp))
  }

  // handle checkbox for specific stock item
  const handleSelectCategory = (e: any, data: any, index: number) => {
    let entityTemp = entity || new Set()
    if (!e.target.checked) {
      data?.StockItem?.map(x => {
        entityTemp.delete(x?.StockItemID)
      })
    } else {
      data?.StockItem?.map(x => {
        entityTemp.add(x?.StockItemID)
      })
    }
    setEntity(new Set(entityTemp))
  }

  // handle checkbox for all
  const handleSelectAll = (e: any) => {
    let temp = entity || new Set()
    if (!e?.target?.checked) {
      temp.clear()
    } else {
      filteredList?.map(el => {
        el?.StockItem.forEach(v => {
          temp.add(v?.StockItemID)
        })
      })
    }
    setEntity(new Set(temp))
  }
  //to get stockItemID in stock category
  const listOfItemIds = () => {
    let ids = []
    filteredList?.map(x => {
      x?.StockItem?.map(el => {
        ids.push(el?.StockItemID)
      })
    })
    return ids
  }

  let listCheckedEntity: any = Array.from(entity || [])

  const assignOption = {
    name: 'Assign',
    onClick: () => {
      handleSubmit(onSubmit)()
    },
    color: 'primary',
  }
  const unassignOption = {
    name: 'Unassign',
    onClick: () => {
      handleSubmit(onSubmit)()
    },
    color: 'primary',
  }

  const submitOption: any[] =
    pageMode === 'available' ? [assignOption] : [unassignOption]
  const onSubmit = data => {
    handleCreateUpdate({
      variables: {
        input: {
          CompanyID: CompanyID,
          WarehouseID: WarehouseID,
          stockItemID: listCheckedEntity,
        },
      },
      refetchQueries: [
        {
          query: GetWarehouseDocument,
          variables: { WarehouseID: WarehouseID },
        },
      ],
    })
  }

  return (
    <>
      {companyLoading && <Loading />}
      {assignWarehouseItemLoading && <Loading />}
      {unassignWarehouseItemLoading && <Loading />}
      {WarehouseLoading && <Loading />}
      {getStockCategoryItemAssignmentLoading && <Loading />}

      {/* <SnackBarMsg open={msg} message={MESSAGE} /> */}
      <div className="tab-subheader">
        <SearchHeader
          title="Search here.."
          value={`${filteredList?.length || 0}`}
          search
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['Name', 'StockItem.Name'])
          }}
          onCloseAction={() => handleSearch('', [])}
          // option={{
          //   icon: <Tune />,
          //   //onClick: () => setFilter(true),
          // }}
        />
      </div>

      <ContentWrapper float>
        {filteredList?.length === 0 ? (
          <EmptyList
            title={`No ${
              pageMode === 'available' ? 'available' : 'assigned'
            } item`}
          />
        ) : (
          <>
            <ListItem style={{ padding: '0px 16px' }}>
              <FormControlLabel
                className="select-all-font"
                control={
                  <Checkbox
                    color="primary"
                    onChange={e => {
                      handleSelectAll(e)
                    }}
                    checked={
                      // entity?.size ===
                      // filteredList?.reduce(
                      //   (a, b) => a + b?.StockItem?.length,
                      //   0
                      // )
                      listOfItemIds().every(
                        el => !!listCheckedEntity.includes(el)
                      )
                    }
                  />
                }
                label={<span className="xsTitle flex-space">Select All</span>}
                style={{ paddingLeft: '7px' }}
              />
            </ListItem>
            <CardContents>
              <div style={{ width: '100%' }}>
                {filteredList
                  ?.sort((a, b) => {
                    return a.Name.localeCompare(b.Name)
                  })
                  .map((v, index) => {
                    return (
                      <div>
                        <CardExpansion
                          summary={
                            <>
                              <Checkbox
                                color="primary"
                                value={v.StockItemID}
                                onChange={e => {
                                  handleSelectCategory(e, v, index)
                                }}
                                onClick={e => e.stopPropagation()}
                                checked={
                                  !!v?.StockItem.find(
                                    (el: any) =>
                                      !listCheckedEntity.includes(
                                        el?.StockItemID
                                      )
                                  )
                                    ? false
                                    : true
                                }
                              />
                              <span className="xsTitle text-noflow">
                                {v.Name}
                              </span>
                            </>
                          }
                        >
                          <div>
                            {v?.StockItem?.map(y => (
                              <div>
                                <Checkbox
                                  color="primary"
                                  value={y.StockItemID}
                                  onChange={e => {
                                    handleCheckBoxChange(e, v, index)
                                  }}
                                  checked={
                                    !!listCheckedEntity.find(
                                      (el: any) =>
                                        el.StockItemID === y.StockItemID
                                    ) ||
                                    listCheckedEntity.includes(
                                      y?.StockItemID
                                    ) ||
                                    entity?.size === filteredList?.length
                                  }
                                />
                                <span className="xsTitle text-noflow">
                                  {y?.Name}
                                </span>
                              </div>
                            ))}
                          </div>
                        </CardExpansion>
                      </div>
                    )
                  })}
              </div>
            </CardContents>
          </>
        )}
      </ContentWrapper>
      <Footer options={[...submitOption]} />
    </>
  )
}
