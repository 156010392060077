import React, { lazy } from 'react'

const ItemCategoryListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/ItemCategory/ItemCategoryListing'
  ).then(module => ({
    default: module.ItemCategoryListing,
  }))
)

const ItemCategoryAssignForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/ItemCategory/ItemCategoryAssignment'
  ).then(module => ({
    default: module.ItemCategoryAssignment,
  }))
)

const itemCategoryRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/purchase-class',
    },
    component: <ItemCategoryListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/general-setting/purchase-class/:ItemCategoryID/assign',
    },
    component: <ItemCategoryAssignForm />,
  },
]

export default itemCategoryRoutes
