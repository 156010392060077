import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { SwitchDialog } from 'components/Switch/SwitchDialog'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetAssignedCompanyQuery,
  useGetCbExportedGlListingQuery,
  useGetCompanyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const CBGLExportList = () => {
  useEffect(() => {
    console.log('CBGLExportList')
  }, [])

  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const { globalState, dispatch }: any = useContext(AppContext as any)
  const [opnSwitchDialog, setOpenSwitchDialog] = useState(false)
  const [homeCompanyID, setHomeCompanyID] = useState<string>(CompanyID)

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }

  const history = useHistory()
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()

  const createdByName = ID => {
    return getUsersByAccountAndSoftware?.find(x => x.ID === ID)?.name || ''
  }

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const {
    data: { getExportedGLListing } = { getExportedGLListing: [] },
    loading: listLoading,
  } = useGetCbExportedGlListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      prefix: 'CB',
    },
    onError: error => {
      console.log('error')
    },
  })

  const {
    called: getCompanyCalled,
    loading: getCompanyLoading,
    error: getCompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (
      getExportedGLListing &&
      getExportedGLListing?.length > 0 &&
      !listLoading
    ) {
      setOriginalListing(getExportedGLListing)
    }
  }, [getExportedGLListing, listLoading])

  return (
    <>
      {getCompanyLoading && <Loading />}
      {listLoading && <Loading />}
      <MainHeader
        mainBtn="menu"
        onClick={handleDrawer}
        smTitle="Cash Book"
        title={getCompany[0]?.Name}
        onClickAction={() => setOpenSwitchDialog(true)}
        // sideBtn="switch"
        sideBtn="company"
        rightCustomIcon={getCompany[0]?.Logo?.fileURL}
        routeSegments={[{ name: `GL Export`, current: true }]}
      />

      <SearchHeader
        title="GL Journal Listing"
        value={`${filteredList?.length ?? 0}`}
        onChangeAction={e => {
          handleSearch(`'"${e?.target?.value}"`, [])
        }}
        fixed
        // defaultValue={billListingSearch?.value ?? ''}
        // isDefaultValue={!!billListingSearch?.value ?? false}
        search
        onCloseAction={() => handleSearch('', [])}
      />

      <ContentWrapper float search>
        <List className="core-list">
          {getExportedGLListing?.length === 0 ? (
            <EmptyList title="No Record Found" />
          ) : (
            filteredList?.map((x, i) => (
              <>
                <ListItem key={i}>
                  <ListItemText
                    primary={
                      <>
                        <span className="xsTitle">
                          {formatDate(x.StartDate)} - {formatDate(x.EndDate)}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <span className="desc icon-text">
                          <span style={{ color: '#ff9800' }}>
                            {x.transactionCnt}
                          </span>
                          &nbsp;transactions <TextSeparator />
                          {/* <img
                            src={Auditor}
                            alt="ifca"
                            width={12}
                            loading="lazy"
                            style={{ paddingRight: '4px' }}
                          /> */}
                          <span style={{ color: '#ff9800' }}>
                            {formatDate(x.GLDate)} {createdByName(x.createdBy)}
                          </span>
                        </span>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() => {
                          history.push({
                            pathname: `/cash-book/submenu/gl-export`,
                            state: x,
                          })
                        }}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            ))
          )}
        </List>

        <FloatButton
          onClick={() => history.push(`/cash-book/${CompanyID}/gl-export/add`)}
        />
      </ContentWrapper>
      <SwitchDialog
        open={opnSwitchDialog}
        onClose={() => setOpenSwitchDialog(false)}
        data={getAssignedCompanyByUser}
        name={'Company'}
        nameID={'CompanyID'}
        currID={homeCompanyID}
        setCurrID={setHomeCompanyID}
        customPath={`/cash-book/currID/gl-export`}
      />
    </>
  )
}
