import React from 'react'
import { TransactionTypeListing } from './TransactionTypeListing'

export const transactionTypeRoutes = [
  {
    props: {
      exact: true,
      path: `/inventory-control/general-setting/transaction-type`,
    },
    component: <TransactionTypeListing mode="add" />,
  },
]

export default transactionTypeRoutes
