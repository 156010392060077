import { Grid, List, ListItem, ListItemText } from '@material-ui/core'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { default as React, useEffect } from 'react'
import '../Journal.scss'

export const JournalDetailItemContent = (props: any) => {
  const { listItem } = props

  return (
    <>
      <div className="rm-padding table-wrap">
        <List className="core-list content-wrap full " disablePadding>
          {listItem?.map((el, index) => (
            <ListItem
              className="table-listItem "
              key={index}
              divider
              style={{ backgroundColor: 'white' }}
            >
              <ListItemText
                primary={
                  <Grid container justify="flex-start" spacing={1}>
                    <Grid
                      item
                      xs={9}
                      className="fw-medium desc text-noflow"
                      // style={{ overflow: 'visible' }}
                    >
                      {index + 1}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {el.MasterCOA.Code} | {el.MasterCOA.Name}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className="fw-medium desc text-right text-overflow"
                      style={{
                        textAlign: 'end',
                        overflow: 'visible',
                      }}
                    >
                      {el.DocAmt < 0
                        ? `(${amtStr(el.DocAmt * -1)})`
                        : amtStr(el.DocAmt)}
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Grid container justify="flex-start">
                    <Grid
                      item
                      xs={12}
                      className="fw-medium desc "
                      style={{ color: '#858585' }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {el.CostCentre.Name}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="fw-medium desc "
                      style={{ color: '#858585' }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {el.Remark}
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div
        className="footer-label"
        style={{
          background: '#bfd3ff',
        }}
      ></div>
    </>
  )
}
