import React, { lazy } from 'react'

const SupplierCategoryListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/SupplierCategory/SupplierCategoryListing'
  ).then(module => ({
    default: module.SupplierCategoryListing,
  }))
)

const supplierCategoryRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/supplier-category',
    },
    component: <SupplierCategoryListing type="Supplier" />,
  },
]

export default supplierCategoryRoutes
