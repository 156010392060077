import React from 'react'
import HeaderGL from './HeaderGL'
import TableGL from './TableGL'
import GLList from './ItemList/GLList'
import FooterGL from './FooterGL'

export const GLBodyTemplate = (props: any) => {
  const {
    title,
    previewDetail,
    journalDetail,
    docType,
    getCostCentre,
    threshold,
    getUsersByAccountAndSoftware,
    fontStyle,
    listData,
    remapData,
    page,
    pageLength,
    width,
    currencyCode,
  } = props

  return (
    <>
      {page === 0 && (
        <>
          <HeaderGL />

          <div className="row">
            <p style={fontStyle}>
              <b>{title}</b>
            </p>
          </div>
        </>
      )}

      {listData?.length > 0 && (
        <>
          <div
            style={{
              border: width < 769 ? '0.05px solid' : '0.5px solid',
              margin: '10px',
            }}
          >
            <TableGL
              data={previewDetail}
              journalData={journalDetail}
              page={page}
              pageLength={pageLength}
            />
            {remapData && (
              <GLList
                data={remapData[page]}
                docType={docType}
                getCostCentre={getCostCentre}
                threshold={threshold}
                allData={previewDetail}
                page={page}
                pageLength={pageLength}
                currencyCode={currencyCode}
              />
            )}
          </div>

          {page === pageLength - 1 ? (
            <FooterGL
              data={previewDetail}
              docType={docType}
              getUser={getUsersByAccountAndSoftware}
            />
          ) : (
            <div className="page-break"></div>
          )}
        </>
      )}
    </>
  )
}

export default GLBodyTemplate
