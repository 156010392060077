import React, { lazy } from 'react'

import { ReceiptItemForm } from './ReceiptForm/ReceiptItemForm'

import { ReceiptDetail } from './ReceiptDetail/ReceiptDetail'
import PDFPreviewCBV2 from '../Hooks/PDFPreviewCBV2'

const ReceiptListing = lazy(() =>
  import('./ReceiptMain').then(module => ({
    default: module.ReceiptMain,
  }))
)
const ReceiptFormv2 = lazy(() =>
  import('./ReceiptForm/ReceiptForm').then(module => ({
    default: module.ReceiptFormv2,
  }))
)

const AdvancedFilter = lazy(() =>
  import('../../../components/Filter/AdvancedFilter').then(module => ({
    default: module.AdvancedFilter,
  }))
)

const receiptRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/filter',
    },
    component: (
      <AdvancedFilter
        mode="receipt"
        app="cash-book"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/receipt',
    },
    component: <ReceiptListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:ReceiptID/detail',
    },
    component: <ReceiptDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/detail/:ReceiptID/workdesk',
    },
    component: <ReceiptDetail mode="workdesk" />,
  },

  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:formMode',
    },
    component: (
      <ReceiptFormv2
        formMode="add"
        detailMode="add"
        taxAmtChangeableNeeded={true}
        type="RECEIPT"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:ReceiptID/:formMode',
    },
    component: (
      <ReceiptFormv2
        formMode="edit"
        taxAmtChangeableNeeded={true}
        type="RECEIPT"
      />
    ),
  },

  //THIS IS ROUTE FOR EDIT RECEIPT ITEM BEFORE SUBMIT
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:formMode/receipt-item/:detailMode',
    },
    component: <ReceiptItemForm />,
  },

  //THIS IS ROUTE FOR EDIT RECEIPT ITEM AFTER SUBMIT
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:formMode/receipt-item/:ReceiptItemID/:detailMode',
    },
    component: <ReceiptItemForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:ReceiptID/:formMode/receipt-item/:ReceiptItemID/:detailMode',
    },
    component: <ReceiptItemForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:ReceiptID/:formMode/receipt-item/:detailMode',
    },
    component: <ReceiptItemForm />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:ReceiptID/:formMode/receipt-item/:ReceiptItemID/:detailMode',
  //   },
  //   component: <ReceiptItemForm />,
  // },

  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:ReceiptID/:formMode',
    },
    component: <ReceiptItemForm detailMode={'edit'} />,
  },

  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:ReceiptID/:formMode',
    },
    component: <ReceiptFormv2 detailMode={'edit'} />,
  },
  // // payment = edit, paymentitem = has been added
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:ReceiptID/:formMode',
    },
    component: <ReceiptFormv2 detailMode={'add'} />,
  },

  /* -------------------------------------------- */
  /*              PREVIEW DETAIL PDF              */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/receipt/:ReceiptID/detail/preview',
    },
    component: <PDFPreviewCBV2 accountType="cash-book" docType="receipt" />,
  },
]

export default receiptRoutes
