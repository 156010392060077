import React, { lazy } from 'react'

const EntityCOAListing = lazy(() =>
  import('./EntityCOAListing').then(module => ({
    default: module.EntityCOAListing,
  }))
)

const EntityCOAAssignForm = lazy(() =>
  import('./EntityCOAAssignForm').then(module => ({
    default: module.EntityCOAAssignForm,
  }))
)

const entityCOARoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/company-setting/entity-coa/:AccType',
    },
    component: <EntityCOAListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/entity-coa/:AccType/:entityCOAID',
    },
    component: <EntityCOAAssignForm />,
  },
]

export default entityCOARoutes
