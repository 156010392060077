import React, { lazy } from 'react'
import { InventoryPolicyForm } from './InventoryPolicyForm'

const inventoryPolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/inventory-control/general-setting/inventory-policy-settings',
    },
    component: <InventoryPolicyForm />,
  },
]

export default inventoryPolicyRoutes
