//import { BankAccountListing } from 'containers/CompanyModule/BankAccount/BankAccountListing'
import apDigitalReportRoutes from 'containers/APModule/APDigitalReport/APDigitalReportRoutes'
import cbDigitalReportRoutes from 'containers/CashBookModule/CBDigitalReportModule/CBDigitalReportRoutes'
import glDigitalReportRoutes from 'containers/GeneralLedgerModule/GLDigitalReportModule/GLDigitalReportRoutes'
import React, { lazy } from 'react'

// const digitalReportRoutes = [
//   ...glDigitalReportRoutes,
//   ...cbDigitalReportRoutes,
//   ...apDigitalReportRoutes,
// ]

const digitalReportRoutes = [
  // {
  //   props: { exact: true, path: '/digital-reports' },
  //   component: <DigitalReportSubmenu />,
  // },
  ...glDigitalReportRoutes,
  ...cbDigitalReportRoutes,
  ...apDigitalReportRoutes,
]

export default digitalReportRoutes
