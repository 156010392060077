import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter';
import { amtStr4Dec } from '../../../helpers/StringNumberFunction/numFormatter';
import React from 'react';

export const Table2PR = (props: any) => {
  const { data, docType, allData, page, pageLength, companyCountry } = props;

  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) fontSize = 6;
  else fontSize = 9;

  let currencySymbol;

  switch (companyCountry) {
    case 'Malaysia':
      currencySymbol = 'RM';
      break;
    case 'Singapore':
      currencySymbol = '$';
      break;
    case 'Indonesia':
      currencySymbol = 'Rp.';
      break;
    default:
      currencySymbol = 'RM';
  }

  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
    paddingTop: '4px',
    paddingBottom: '4px',
  };

  const thright = {
    width: '10%',
    borderBottom: '0.5px  solid',
    textAlign: 'right' as 'right',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
    paddingRight: '8px',
  };

  const thnumber = {
    width: '5%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  };

  const thleftnarrow = {
    width: '10%',
    borderBottom: '0.5px solid',
    textAlign: 'left' as 'left',
    backgroundColor: '#e7e6e6',
  };

  const thuom = {
    width: '5%',
    borderBottom: '0.5px solid',
    textAlign: 'left' as 'left',
    backgroundColor: '#e7e6e6',
  };

  const thrightmostnarrow = {
    width: '10%',
    borderBottom: '0.5px solid',
    textAlign: 'right' as 'right',
    backgroundColor: '#e7e6e6',
    paddingRight: '5px',
  };

  const thrightnopadding = {
    width: '10%',
    borderBottom: '0.5px solid',
    textAlign: 'right' as 'right',
    backgroundColor: '#e7e6e6',
  };

  const tdright = {
    width: '10%',
    borderTop: '0.5px  solid',
    textAlign: 'right' as 'right',
    paddingRight: '5px',
  };

  const tdrightpo = {
    width: '10%',
    borderTop: '0.5px  solid',
    textAlign: 'right' as 'right',
    paddingRight: '8px',
  };

  const tdleftstretch = {
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
    paddingLeft: '8px',
  };

  // For PO
  const amount = allData?.POItem?.reduce(
    (total, amountValue) => (total = total + amountValue.BaseAmt),
    0,
  );

  const taxAmount = allData?.POItem?.reduce(
    (total, amountValue) => (total = total + amountValue.TaxAmt),
    0,
  );

  const totalAmount = allData?.POItem?.reduce(
    (total, amountValue) =>
      (total = total + (amountValue.BaseAmt + amountValue?.TaxAmt)),
    0,
  );

  // For PR
  let totalQty = allData?.PRItem?.reduce((total, currentValue) => {
    total = total + parseFloat(currentValue?.Quantity);

    return total;
  }, 0);

  return (
    <>
      {docType !== 'purchase-requisition' && (
        <div style={{ marginTop: '5px' }}>
          <table
            style={{
              width: '100%',
              fontSize,
              borderSpacing: '0 1em',
            }}
          >
            <tr>
              <th style={thnumber}>No</th>
              <th style={thleft}>
                Item <br /> Description
              </th>
              <th style={thleftnarrow}>Dept</th>
              <th style={thuom}>UOM</th>
              <th style={thrightmostnarrow}>Qty</th>
              <th style={thright}>Unit Price</th>
              <th style={thright}>
                Base Amt
                <br />
                {`(${currencySymbol})`}
              </th>
              <th style={thright}>
                Tax Amt
                <br />
                {`(${currencySymbol})`}
              </th>
              <th style={thright}>
                Total Amt
                <br />
                {`(${currencySymbol})`}
              </th>
            </tr>

            {data?.POItem?.map((v, i) => (
              <>
                <tr>
                  <td style={{ textAlign: 'center', verticalAlign: 'top' }}>
                    {v?.index}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {v?.Item?.Name}
                    <br />
                    {v?.Remarks}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    {v?.CostCentre?.Code}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    {v?.UOM?.Code}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr4Dec(v?.OrderedQty)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr4Dec(v?.UnitPrice)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr(v?.BaseAmt)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr(v?.TaxAmt)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr(v?.BaseAmt + v?.TaxAmt)}
                  </td>
                </tr>
              </>
            ))}
          </table>

          {page === pageLength - 1 && (
            <div>
              <table
                style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}
              >
                <tr>
                  <td
                    style={tdleftstretch}
                  >{`Total Amount (${currencySymbol}):`}</td>

                  <td style={tdrightpo}>{amtStr(amount)}</td>
                  <td style={tdrightpo}>{amtStr(taxAmount)}</td>
                  <td style={tdrightpo}>{amtStr(totalAmount)}</td>
                </tr>
              </table>
            </div>
          )}
        </div>
      )}

      {/* PR Item Table */}
      {docType === 'purchase-requisition' && (
        <div style={{ marginTop: '20px' }}>
          <table
            style={{
              width: '100%',
              fontSize,
              // borderCollapse: 'collapse',
              borderSpacing: '0 1em',
            }}
          >
            <tr>
              <th style={thnumber}>No</th>
              <th style={thleft}>
                Item <br /> Description
              </th>
              <th style={thleftnarrow}>Dept</th>
              <th style={thuom}>UOM</th>
              <th style={thrightnopadding}>Qty</th>
              <th style={thrightnopadding}>Unit Cost</th>
              <th style={thrightmostnarrow}>Total Unit Cost</th>
            </tr>

            {data?.PRItem?.map((v, i) => (
              <>
                <tr>
                  <td style={{ textAlign: 'center', verticalAlign: 'top' }}>
                    {v?.index}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {v?.Item?.Name}
                    <br />
                    {v?.Remark}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    {v?.CostCentre?.Code}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    {v?.UOM?.Code}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr4Dec(v?.Quantity)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr(v?.UnitCost)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      verticalAlign: 'top',
                      paddingRight: '5px',
                    }}
                  >
                    {amtStr(v?.TotalUnitCost)}
                  </td>
                </tr>
              </>
            ))}
          </table>

          {page === pageLength - 1 && (
            <div>
              <table
                style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}
              >
                <tr>
                  <td style={tdleftstretch}>{`Total Item Requested:`}</td>

                  <td style={tdright}>{amtStr4Dec(totalQty)}</td>
                </tr>
              </table>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Table2PR;
