import AppContext from 'containers/App/Store/AppContext'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import LeaseContract from 'assets/MFRS/Lease.svg'
import ROUAssetsLeaseLiability from 'assets/MFRS/ROU_Assets__Lease_Liability.svg'
import GenerateMFRS16Journal from 'assets/MFRS/Generate_MRFS_16_Journal.svg'
import GLAccountCode from 'assets/MFRS/GL_Account_Code.svg'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import {
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
} from 'generated/graphql'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import { KeyboardArrowRight } from '@material-ui/icons'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'

export const MFRS16Submenu = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const [change, setSwitch] = useState(false)

  useEffect(() => {
    document.title = `AX - MFRS16`
  }, [])

  /*----------------------------------------------------*/
  /*                      QUERY                         */
  /*----------------------------------------------------*/

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID: CompanyID },
    fetchPolicy: 'network-only',
  })

  //------------------------------------------------------//

  const submenuList = [
    {
      path: `/mfrs16/${CompanyID}/gl-account-code-journal-type-mapping`,
      icon: GLAccountCode,
      title: 'GL Account Code & Journal Type Mapping',
      label1: 'Last updated on',
      data1: formatDate(new Date().toISOString()), //will change following the last updated on,
      // permission: AcctPermission.GeneralLedgerExternalJournalView,
    },
    {
      path: `/mfrs16/${CompanyID}/lease-contract`,
      icon: LeaseContract,
      title: `Lease Contract`,
      label1: `Last updated on`,
      data1: formatDate(new Date().toISOString()), //will change following the last updated on,
      // permission: AcctPermission.GeneralLedgerOriginalBudgetView,
    },
    {
      path: `/mfrs16/${CompanyID}/budget-revision`,
      icon: ROUAssetsLeaseLiability,
      title: `ROU Assets & Lease Liability Schedule`,
      label1: `Last updated on`,
      data1: formatDate(new Date().toISOString()), //will change following the last updated on,
      // permission: AcctPermission.GeneralLedgerBudgetRevisionView,
    },
    {
      path: `/mfrs16/${CompanyID}/budget-revision`,
      icon: GenerateMFRS16Journal,
      title: `Generates MFRS 16 Journal`,
      label1: `Last updated on`,
      data1: formatDate(new Date().toISOString()), //will change following the last updated on,
      // permission: AcctPermission.GeneralLedgerBudgetRevisionView,
    },
  ]
  return (
    <>
      <MainHeader
        mainBtn={'back'}
        onClick={() => history.push(`/`)}
        smTitle={'MFRS 16'}
        title={getCompany[0]?.Name}
        onClickAction={() => setSwitch(true)}
        //currency={`MYR`}
        sideBtn="company"
        rightCustomIcon={getCompany[0]?.Logo?.fileURL}
        routeSegments={[{ name: 'Main Menu', current: true }]}
      />
      <ContentWrapper float>
        <List className="core-list">
          {submenuList.map((el, index) => {
            return (
              <ListItem key={index} onClick={() => history.push(el?.path)}>
                <IconItem
                  image={el?.icon}
                  imageType="svg"
                  badgeType="default"
                />
                <ListItemText
                  style={{
                    textAlign: 'right',
                    marginLeft: '5px',
                  }}
                  primary={<span className="xsTitle">{el?.title}</span>}
                  secondary={
                    <>
                      <span className="desc">
                        <span className="desc">{el.label1}</span>
                        &nbsp;
                        <span className="desc c-orange">{el.data1}</span>
                      </span>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <KeyboardArrowRight
                      onClick={() =>
                        history.push({
                          pathname: el.path,
                        })
                      }
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </ContentWrapper>
    </>
  )
}
