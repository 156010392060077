import theme from '@ifca-root/react-component/src/assets/theme'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import Fuse from 'fuse.js'
import {
  AcctPermission,
  GetStockItemDocument,
  RecordStatus,
  useDeleteStockItemMutation,
  useGetStockCategoryDetailQuery,
  useGetStockItemListQuery,
  useUpdateStockItemMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const StockItemListing = (props: any) => {
  const getSearch = localStorage?.getItem('searchFilter')
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption()
  const { StockCategoryID }: any = useParams()

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const [deleteStockItemDialog, setDeleteStockItemDialog] = useState(false)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const {
    loading: StockCategoryLoading,
    error: StockCategoryErrors,
    data: { getStockCategory } = { getStockCategory: [] },
  } = useGetStockCategoryDetailQuery({
    fetchPolicy: 'network-only',
    variables: { StockCategoryID },
  })

  const {
    loading: StockItemLoading,
    error: StockItemError,
    data: { getStockItemPerm } = { getStockItemPerm: [] },
  } = useGetStockItemListQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
      StockCategoryID: StockCategoryID,
    },
  })

  const [
    deleteStockItem,
    { loading: deleteItemLoading, error: deleteItemError },
  ] = useDeleteStockItemMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setDeleteStockItemDialog(false)
    },
  })

  const [
    updateStockItem,
    { loading: updateStockItemLoading, error: updateError },
  ] = useUpdateStockItemMutation({
    // fetchPolicy: 'network-only',
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
    },
  })

  useEffect(() => {
    if (getStockItemPerm && getStockItemPerm?.length > 0) {
      setOriginalListing(getStockItemPerm)
    }
  }, [getStockItemPerm])

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = ['Name', 'Description']
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch)
      const val = result?.map(x => x.item)
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys)
      }
    }
  }, [getSearch, originalList])

  const handleDelete = data => {
    deleteStockItem({
      variables: {
        input: {
          StockItemID: menu?.obj?.StockItemID,
        },
      },
      refetchQueries: [
        {
          query: GetStockItemDocument,
          variables: {
            orderByAsc: 'Name',
            StockCategoryID: StockCategoryID,
          },
        },
      ],
    })
    setDeleteStockItemDialog(false)
  }

  const handleClickDeleteStockItemDialog = () => {
    setDeleteStockItemDialog(true)
    handleClose()
  }

  const displayWarehouse = warehouses => {
    let finalVal
    warehouses?.map((x, index) => {
      if (index === 0) {
        finalVal = ' | ' + x?.Warehouse?.Name
      } else {
        finalVal = finalVal + `, ${x?.Warehouse?.Name}`
      }
    })

    return finalVal
  }

  const handleRecordStatus = (StockItemID, status) => {
    updateStockItem({
      variables: {
        input: {
          StockItemID: StockItemID,
          Name: menu?.obj?.Name,
          RecordStatus: status,
        },
      },
      refetchQueries: [
        {
          query: GetStockItemDocument,
          variables: { orderByAsc: 'Name', StockCategoryID: StockCategoryID },
        },
      ],
    })
  }
  const { handlePermDisabled } = usePermissionChecker()

  return (
    <>
      {StockCategoryLoading && <Loading />}
      {deleteItemLoading && <Loading />}
      {StockItemLoading && <Loading />}
      {updateStockItemLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/inventory-control/general-setting/stock-item`)
          localStorage.removeItem('searchFilter')
        }} //<- back button action
        smTitle={'Inventory Control'}
        title={user?.accountName} //currency={`MYR`}
        routeSegments={[
          { name: 'IC' },
          { name: 'Stock Master File', current: true },
        ]}
        //rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />

      <DynamicSubHeader
        title={getStockCategory[0]?.Name}
        infoLine={
          !!getStockCategory[0]?.StockAccountCodeID
            ? `${getStockCategory[0]?.StockAccountCode?.Code ?? ''} | ${
                getStockCategory[0]?.StockAccountCode?.Name
              }`
            : getStockCategory[0]?.ParentCategoryMCOA?.MasterCOACode
            ? `${getStockCategory[0]?.ParentCategoryMCOA?.MasterCOACode} | ${getStockCategory[0]?.ParentCategoryMCOA?.MasterCOAName}`
            : null
        }
        infoLineStyle={{
          color: !!getStockCategory[0]?.StockAccountCodeID
            ? 'black'
            : '#ff9800',
        }}
      />

      <SearchHeader
        title="Stock Master Listing"
        value={`${advancedFilterList(filteredList)?.length}`}
        fixed
        search
        onChangeAction={e => {
          handleSearch(e?.target?.value, ['Name', 'Description'])
          localStorage.setItem('searchFilter', e.target.value)
        }}
        defaultValue={getSearch ? getSearch : ''}
        isDefaultValue={!!getSearch}
        onCloseAction={() => handleSearch('', [])}
        multiDynamicInfo
      />

      <ContentWrapper
        footer
        style={{ marginTop: isDesktop ? '160px' : '95px' }}
      >
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList
              .sort((a, b) => {
                return (
                  Object.values(RecordStatus).indexOf(a?.RecordStatus) -
                  Object.values(RecordStatus).indexOf(b?.RecordStatus)
                )
              })
              ?.map((el, index) => {
                return (
                  <ListItem selected={el.RecordStatus === 'INACTIVE'}>
                    <ListItemText
                      primary={
                        <>
                          <span className="xsTitle flex-space">{el.Name} </span>
                          <span className="xxTitle">{el?.UOM?.Code}</span>
                        </>
                      }
                      secondary={
                        <span className="desc text-noflow">
                          <span className="fw-medium">Class:</span>
                          &nbsp;
                          <span>
                            <span className="desc ">{el.ABCClass}</span>
                            <span>&nbsp;|</span>
                            &nbsp;
                            <span className="desc ">
                              {`Shelf Life: ` + el.ShelfLife}
                            </span>
                            <span className="desc ">
                              {displayWarehouse(el?.StockItemWarehouse)}
                            </span>
                          </span>
                        </span>
                      }
                    />

                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        aria-controls="menu-list"
                        aria-haspopup="true"
                        onClick={e => handleClick(e, el.StockItemID, index, el)}
                      >
                        <MoreVert />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
          )}
        </List>
        <FloatButton
          disabled={
            !user?.superUser &&
            handlePermDisabled({
              permEnum:
                AcctPermission.InventoryControlGeneralSettingsStockItemCreate,
            })
          }
          onClick={() =>
            history.push(
              `/inventory-control/general-setting/stock-item/${StockCategoryID}/add`
            )
          }
        />
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            history.push({
              pathname: `/inventory-control/general-setting/stock-item/${StockCategoryID}/${menu?.ID}/detail`,
              state: menu.obj,
            })
          }}
        >
          <span className="">View</span>
        </MenuItem>
        <MenuItem
          disabled={
            !user?.superUser &&
            handlePermDisabled({
              permEnum:
                AcctPermission.InventoryControlGeneralSettingsStockItemUpdate,
            })
          }
          onClick={() => {
            history.push({
              pathname: `/inventory-control/general-setting/stock-item/${StockCategoryID}/${menu?.ID}/edit`,
              state: menu.obj,
            })
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        {menu?.obj?.RecordStatus === RecordStatus.Active ? (
          <MenuItem
            disabled={
              !user?.superUser &&
              handlePermDisabled({
                permEnum:
                  AcctPermission.InventoryControlGeneralSettingsStockItemInactive,
              })
            }
            onClick={() =>
              handleRecordStatus(menu.obj.StockItemID, RecordStatus.Inactive)
            }
          >
            Inactive
          </MenuItem>
        ) : (
          <MenuItem
            disabled={
              !user?.superUser &&
              handlePermDisabled({
                permEnum:
                  AcctPermission.InventoryControlGeneralSettingsStockItemInactive,
              })
            }
            onClick={() =>
              handleRecordStatus(menu.obj.StockItemID, RecordStatus.Active)
            }
          >
            Reactive
          </MenuItem>
        )}
        {menu.obj?.IsUse === false ? (
          <MenuItem
            disabled={
              !user?.superUser &&
              handlePermDisabled({
                permEnum:
                  AcctPermission.InventoryControlGeneralSettingsStockItemDelete,
              })
            }
            onClick={handleClickDeleteStockItemDialog}
          >
            <span className="">Delete</span>
          </MenuItem>
        ) : null}
      </Menu>

      <CommonDialog
        fullWidth={true}
        open={deleteStockItemDialog}
        onClose={() => setDeleteStockItemDialog(false)}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <div
                    className="title"
                    style={{
                      color: '#ff9800',
                      marginBottom: '2px',
                      fontSize: '14px',
                    }}
                  >
                    Stock Item
                  </div>
                </div>
                <div>
                  <div className="infoline-content">
                    <>
                      <div className="mdLabel">{menu?.obj?.Name}</div>
                      <div className="flex-space"></div>
                      <div className="xsTitle rightText">Delete </div>
                    </>
                  </div>
                </div>
              </div>
            ),
          },

          body: () => (
            <div className="content-wrap full">
              <span className="mdDesc full">Confirm to delete?</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setDeleteStockItemDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleDelete(menu?.obj?.WarehouseID),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
