import React, { lazy } from 'react'
import { ExternalLedgerDetail } from './ExternalLedgerDetail'
import { ExternalLedgerExcelValidation } from './ExternalLedgerValidation'

const ExternalLedgerListing = lazy(() =>
  import('./ExternalLedgerListing').then(module => ({
    default: module.ExternalLedgerListing,
  }))
)

const AdvancedFilter = lazy(() =>
  import('../../../components/Filter/AdvancedFilter').then(module => ({
    default: module.AdvancedFilter,
  }))
)

const externalLedgerRoutes = [
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/external-ledger/filter',
    },
    component: (
      <AdvancedFilter
        mode="external-ledger"
        app="cash-book"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/external-ledger',
    },
    component: <ExternalLedgerListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/external-ledger/validation',
    },
    component: <ExternalLedgerExcelValidation />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/external-ledger/:LedgerID/detail',
    },
    component: <ExternalLedgerDetail />,
  },
]

export default externalLedgerRoutes
