import { createStyles, makeStyles, Popper } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme =>
  createStyles({
    // this one for half width
    root: {
      zIndex: 9999,
      '& .MuiAutocomplete-paper': {
        width: 'fit-content',
      },
      '& .MuiAutocomplete-listbox': {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '1rem',
        },
      },
    },
    fullWidth: {
      zIndex: 9999,
      '& .MuiAutocomplete-paper': {
        width: 'auto',
      },
      '& .MuiAutocomplete-listbox': {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '1rem',
        },
      },
    },
  })
)

export const AccCodeDropdown = props => {
  const classes = useStyles()
  return <Popper {...props} placement="top" className={classes.root} />
}

export const AccCodeDropdownFullWidth = props => {
  const classes = useStyles()
  return <Popper {...props} placement="top" className={classes.fullWidth} />
}
