//////////////////////////
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Typography } from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import PersonIcon from '@material-ui/icons/Person'
import { useGetSubmitterNameLazyQuery } from 'generated/graphql'
import {
  dateFormat,
  formatTime24Hour,
} from 'helpers/StringNumberFunction/FormatDate'
import React, { useEffect } from 'react'

export interface SubmitterInfoProps {
  data: any
  setOpenDialog: any
  openDialog: boolean
  moduleName: string
}

export const SubmitterDialog = (props: SubmitterInfoProps) => {
  const { data, setOpenDialog, openDialog, moduleName } = props

  const [
    loadSubmitterName,
    {
      data: { getUser } = {
        getUser: {},
      },
    },
  ] = useGetSubmitterNameLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (openDialog && data) {
      loadSubmitterName({
        variables: {
          ID: data?.modBy ?? data?.createdBy,
        },
      })
    }
  }, [openDialog, data])

  return (
    <CommonDialog
      fullWidth={true}
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      sections={{
        header: {
          dynamic: (
            <div className="">
              <div className="dialog-dynamic-content">
                <div className="session">
                  <div className="flex session">
                    <div className="title flex-space">{moduleName}</div>
                  </div>
                </div>
              </div>
              <div className="infoline-content">
                <div className="xsTitle flex-space">
                  {data?.ToBankAccountDetail?.BankName ||
                    data?.DocNo ||
                    data?.RefNo}
                </div>
                <div className=" xsTitle rightText">Submitter Info </div>
              </div>
            </div>
          ),
        },
        body: () => (
          <>
            <Typography
              variant="subtitle1"
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >{`Submitter`}</Typography>
            <PersonIcon style={{ fontSize: 'small', paddingRight: '8px' }} />
            <Typography variant="caption">{getUser?.name}</Typography>
            <br />
            <EventIcon
              style={{
                fontSize: 'small',
                paddingRight: '8px',
                marginBottom: '-3px',
              }}
            />
            <Typography variant="caption">
              {dateFormat(data?.modTs ?? data?.createdTs)}
              &nbsp;&nbsp;
              {formatTime24Hour(data?.modTs ?? data?.createdTs)}
            </Typography>
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () => {
                  setOpenDialog(false)
                },
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
