import React from 'react'
interface ContentWrapperProps {
  children?: any
  footer?: boolean
  page?: string
  float?: boolean
  floatTotalAmt?: boolean
  search?: boolean
  advSearch?: boolean
  multiDynamicInfo?: boolean
  singleDynamicInfo?: boolean
  dynamicInfoSearch?: boolean
  multiDynamicInfoSearch?: boolean
  multiDynamicSubInfoSearch?: boolean
  tabs?: boolean
  bigSubHeaderNSearchBar?: boolean //new changes 5/6/2021 by syafiq ali
  noMargin?: boolean
  onlyContentScrollable?: boolean //new changes 5/6/2021 by syafiq ali
  withDateRange?: boolean
  style?: any
  singleSubSearch?: boolean
  multiSubSearch?: boolean
  singleSubAdvSearch?: boolean
  multiSubAdvSearch?: boolean
  overflow?: boolean
}
export const ContentWrapper = ({
  children,
  footer,
  search,
  advSearch,
  float,
  floatTotalAmt,
  multiDynamicInfo,
  multiSubSearch, // 2 subheader line with NORMAL search
  multiSubAdvSearch, // 2 subheader line with ADV search
  singleSubAdvSearch, // 1 subheader line with NORMAL search
  singleSubSearch, // 1 subheader line with ADV search
  singleDynamicInfo,
  dynamicInfoSearch,
  multiDynamicInfoSearch,
  tabs,
  bigSubHeaderNSearchBar, //new changes 5/6/2021 by syafiq ali
  noMargin,
  onlyContentScrollable, //new changes 5/6/2021 by syafiq ali
  withDateRange,
  style,
  overflow,
}: ContentWrapperProps) => {
  var footing = ''

  if (footer == true && float == true) {
    footing = 'with-footer-n-float'
  } else if (footer == true && float == null) {
    footing = 'with-footer'
  } else if (footer == null && float == true) {
    footing = 'with-float'
  } else if (footer == true && floatTotalAmt == true) {
    footing = 'with-float-amt'
  }
  //  else if (footer == null && search == true) {
  //   footing = 'with-search'
  // }
  return (
    <div
      className={`content-wrapper 'onlyContentScrollable'
      ${footing} 
      ${tabs && 'with-tab'}
      ${search && 'with-search'} 
      ${advSearch && 'with-advsearch'} 
      ${singleSubSearch && 'with-singlesub-search'} 
      ${singleSubAdvSearch && 'with-singlesub-advsearch'} 
      ${multiSubSearch && 'with-multisub-search'} 
      ${multiSubAdvSearch && 'with-multisub-advsearch'} 
      ${multiDynamicInfo && `multiple-dynamicInfo`}
      ${singleDynamicInfo && `single-dynamicInfo`}
      ${dynamicInfoSearch && `dynamicinfo-search`}
      ${bigSubHeaderNSearchBar && `with-bigSubHeaderNSearchBar`}
      ${multiDynamicInfoSearch && `multi-dynamicinfo-search`}
      ${withDateRange && `with-date-range`}
      `}
      style={style ? style : noMargin ? { marginTop: 0 } : null}
    >
      {children}
    </div>
  )
}
