import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, MenuItem, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  RecordStatus,
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
  useGetCostCentreCodeLazyQuery,
  useGetEntityCoaLazyQuery,
  useGetMasterCoaCodeQuery,
} from 'generated/graphql'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import React, { useState } from 'react'
import { CommonYupValidation } from 'helpers/Form/YupValidation'

interface EntityCOAByDepartmentListingParamsProps {
  CompanyID: string
  mastercoacode: string
  mastercoaname: string
  departmentcode: string
  departmentname: string
  entitystatus: string
}

interface CompanyOption {
  Name: string
  CompanyID: string
}

export const EntityCOAByDepartmentListingParamsForm = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('Company is required'),
  })
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const latestCompany = localStorage.getItem('latestCompany')

  const { handleSubmit, register, errors, control, setValue } = useForm<
    EntityCOAByDepartmentListingParamsProps
  >({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadEntityCOA({
          variables: { CompanyID: latestCompany },
        })
        loadCostCentre({
          variables: {
            CompanyID: latestCompany,
            IsLastNode: true,
          },
        })
      }
    },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadEntityCOA({
          variables: { CompanyID: latestCompany },
        })
        loadCostCentre({
          variables: {
            CompanyID: latestCompany,
            IsLastNode: true,
          },
        })
      }
    },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaCodeQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true },
  })

  const [
    loadEntityCOA,
    {
      loading: EntityCOALoading,
      data: { getEntityCOA } = { getEntityCOA: [] },
    },
  ] = useGetEntityCoaLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {},
  })

  const [
    loadCostCentre,
    {
      loading: CostCentreLoading,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreCodeLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {},
  })

  const masterCOAList =
    getEntityCOA?.length > 0
      ? getEntityCOA?.map(coa => coa?.MasterCOA)
      : getMasterCOA?.filter(
          coa => coa?.IsLastNode === true && coa?.IsControl === false
        )

  const [selectedMasterCOA, setSelectedMasterCOA]: any = useState(new Set())

  const handleCheckboxMasterCOAChange = (event: any) => {
    let masterCOA = selectedMasterCOA

    if (event?.target?.checked) {
      masterCOA.add(event?.target?.value)
    } else {
      masterCOA.delete(event?.target?.value)
    }
    setSelectedMasterCOA(new Set(masterCOA))
  }

  const [selectedDepartment, setSelectedDepartment] = useState(new Set())

  const handleCheckboxDepartmentChange = (event: any) => {
    let department = selectedDepartment
    if (event?.target?.checked) {
      department.add(event?.target?.value)
    } else {
      department.delete(event?.target?.value)
    }
    setSelectedDepartment(new Set(department))
  }

  const onSubmit = data => {
    history.push({
      pathname: `/digital-reports/general-ledger/entitycoa-department-listing-report/generated`,
      state: {
        CompanyID: data?.CompanyID,
        mastercoacode: selectedMasterCOA
          ? Array.from(selectedMasterCOA)
          : undefined,
        mastercoaname: data?.mastercoaname,
        departmentcode: selectedDepartment
          ? Array.from(selectedDepartment)
          : undefined,
        departmentname: data?.departmentname,
        entitystatus: data.entitystatus,
      },
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {masterCOALoading && <Loading />}
      {EntityCOALoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/general-ledger`)}
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Entity COA by Department Listing', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {!CompanyAssignedLoading && !CompanyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const options: CompanyOption[] =
                  !!user?.superUser === false
                    ? getAssignedCompanyByUser
                    : getCompany
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]

                return (
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option: CompanyOption) => option.Name}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('CompanyID', newValue?.CompanyID)

                      loadEntityCOA({
                        variables: { CompanyID: newValue?.CompanyID },
                      })
                      loadCostCentre({
                        variables: {
                          CompanyID: newValue?.CompanyID,
                          IsLastNode: true,
                        },
                      })
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.Name}</span>
                    }}
                    defaultValue={defVal}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            required
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              required
              defaultValue={latestCompany}
            />
          )}
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={masterCOAList || []}
                  getOptionLabel={option => `${option?.Name} | ${option?.Code}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.MasterCOAID)
                    })

                    setSelectedMasterCOA(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.MasterCOAID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxMasterCOAChange(event)
                        }}
                      />
                      {`${option?.Name} | ${option?.Code}`}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Account Code"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="mastercoacode"
            label="Account Code"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            as={TextField}
            name="mastercoaname"
            fullWidth
            label="Account Name"
            control={control}
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getCostCentre || []}
                  getOptionLabel={option => `${option?.Code}=>${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.CostCentreID)
                    })

                    setSelectedDepartment(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.CostCentreID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxDepartmentChange(event)
                        }}
                      />
                      {`${option?.Code}=>${option?.Name}`}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Department Code"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="departmentcode"
            label="Department Code"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            as={TextField}
            name="departmentname"
            fullWidth
            label="Department Name"
            control={control}
            ref={register}
          />

          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Status"
                select
                fullWidth
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                  setValue('RecordStatus', e.target.value)
                }}
                style={{ marginTop: 'auto' }}
              >
                <MenuItem key={RecordStatus.Active} value={RecordStatus.Active}>
                  Active
                </MenuItem>
                <MenuItem
                  key={RecordStatus.Inactive}
                  value={RecordStatus.Inactive}
                >
                  Inactive
                </MenuItem>
              </TextField>
            )}
            control={control}
            fullWidth
            ref={register()}
            name="entitystatus"
            autoComplete="off"
            multiline={true}
            margin="dense"
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
