import '@ifca-root/react-component/src/assets/styles/components/totalAmountFooter.scss'
import theme from '@ifca-root/react-component/src/assets/theme'
import { Grid, Tooltip, useMediaQuery, withStyles } from '@material-ui/core'
import AppContext from 'containers/App/Store/AppContext'
import { TransactionType } from 'generated/graphql'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext } from 'react'

export interface TooltipAmountFooterProps {
  data?: any
  trxType?: any
  module?: string
  docAmt?: any
  detail?: boolean
}

export const TooltipAmountFooter = (props: TooltipAmountFooterProps) => {
  const { data, module, detail, trxType } = props
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const AmountTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
    arrow: {
      '&.MuiTooltip-arrow': {
        right: 0,
        color: '#9e9e9e',
        width: '8rem',
      },
    },
  })(Tooltip)

  let itemData = []
  let icData = []
  switch (module) {
    case 'invoice-draft':
      itemData = data
      break
    case 'invoice-submit':
      itemData = data
      break
    case 'invoice-approved':
      itemData = data?.APInvoiceItem
      break
    case 'debit-note-draft':
      itemData = data
      break
    case 'debit-note-submit':
      itemData = data
      break
    case 'debit-note-approved':
      itemData = data?.APDebitNoteItem
      break
    case 'creditor-debit-note-draft':
      itemData = data
      break
    case 'creditor-debit-note-submit':
      itemData = data
      break
    case 'creditor-debit-note-approved':
      itemData = data?.APCreditorDebitNoteItem
      break
    case 'credit-note-draft':
      itemData = data
      break
    case 'credit-note-submit':
      itemData = data
      break
    case 'creditor-credit-note-draft':
      itemData = data
      break
    case 'creditor-credit-note-submit':
      itemData = data
      break
    case 'credit-note-approved':
      itemData = data?.APCreditNoteItem
      break
    case 'creditor-credit-note-approved':
      itemData = data?.APCreditorCreditNoteItem
      break
    case 'debit-note-approved':
      itemData = data?.APDebitNoteItem
      break
    case 'payment-draft':
      itemData = data
      break
    case 'payment-submit':
      itemData = data
      break
    case 'refund-draft':
      itemData = data
      break
    case 'refund-submit':
      itemData = data
      break
    case 'advance-draft':
      itemData = data
      break
    case 'advance-submit':
      itemData = data
      break
    case 'official-receipt-submit':
      itemData = data
      break
    case 'AR-invoice-approved':
      itemData = data?.ARInvoiceItem
      break
    case 'AR-credit-note-approved':
      itemData = data?.ARCreditNoteItem
      break
    case 'AR-debit-note-approved':
      itemData = data?.ARDebitNoteItem
      break
    case 'CB-payment-draft':
      itemData = data
      break
    case 'CB-payment-submit':
      itemData = data
      break
    case 'CB-payment-approved':
      itemData = data?.PaymentItem
      break
    case 'CB-receipt-draft':
      itemData = data
      break
    case 'CB-receipt-submit':
      itemData = data
      break
    case 'CB-receipt-approved':
      itemData = data?.ReceiptItem
      break
    case 'stock-cost-adjustment':
      icData = data
      break
    case 'stock-cost-adjustment-submit':
      icData = data
      break
    case 'stock-transfer':
      icData = data
      break
    case 'stock-receipt':
      icData = data
      break
    case 'stock-receipt-approved':
      icData = data?.StockReceiptItem
      break
    case 'stock-cost-adjustment-approved':
      icData = data?.StockCostAdjustmentItem
      break
    default:
      break
  }

  const totalCost = icData?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.TotalCost,
    0
  )
  const totalAdjCost = icData?.reduce(
    (prevValue, currentValue) =>
      prevValue +
      (trxType?.find(
        x => x?.TransactionTypeID === currentValue?.TransactionTypeID
      )?.Type === TransactionType.Issue
        ? currentValue?.Cost * -1
        : currentValue?.Cost),
    0
  )

  const totalAdjCostPost = icData?.reduce(
    (prevValue, currentValue) =>
      prevValue +
      (currentValue?.TransactionType?.Type === TransactionType.Issue
        ? currentValue?.Cost * -1
        : currentValue?.Cost),
    0
  )

  const totalBaseAmt = itemData?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.Amount,
    0
  )
  const totalTaxAmt = itemData?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.TaxAmt,
    0
  )
  const grandTotalAmt = itemData?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.DocAmt,
    0
  )

  const grandTotalDocAmt = itemData?.reduce(
    (prevValue, currentValue) => prevValue + currentValue,
    0
  )

  return (
    <div
      className={detail ? 'totalAmountFooterDetail' : 'totalAmountFooter'}
      style={{
        width:
          isDesktop && globalState.drawerOpen
            ? 'calc(100% - 288px)'
            : !isDesktop
            ? '100%'
            : 'calc(100% - 48px)',
        marginLeft:
          isDesktop && globalState.drawerOpen
            ? '264px'
            : !isDesktop
            ? '0px'
            : '24px',
      }}
    >
      <AmountTooltip
        onClick={e => e.stopPropagation()}
        title={
          <React.Fragment>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs zeroMinWidth>
                {module === 'stock-receipt' ||
                module === 'stock-receipt-approved' ||
                module === 'stock-transfer' ? (
                  <div style={{ fontSize: '12px' }}>
                    {`Total Cost: ${amtStr(totalCost)}`}
                  </div>
                ) : module === 'stock-cost-adjustment' ? (
                  <div style={{ fontSize: '12px' }}>
                    {`Total Cost: ${amtStr(totalAdjCost)}`}
                  </div>
                ) : module === 'stock-cost-adjustment-submit' ||
                  module === 'stock-cost-adjustment-approved' ? (
                  <div style={{ fontSize: '12px' }}>
                    {`Total Cost: ${amtStr(totalAdjCostPost)}`}
                  </div>
                ) : (
                  <div>
                    <div style={{ fontSize: '12px' }}>
                      {`Grand Total: ${
                        module === 'payment-submit' ||
                        module === 'refund-submit'
                          ? amtStr(grandTotalDocAmt)
                          : amtStr(grandTotalAmt)
                      }`}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                      {`Base Amount: ${amtStr(totalBaseAmt)}`}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                      {`Tax Amount: ${amtStr(totalTaxAmt)}`}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        }
        disableFocusListener
        placement="top-end"
        enterTouchDelay={0}
        arrow
      >
        <div>
          <span
            style={{
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
              fontSize: '10.5px',
              marginRight: '20px',
              textDecoration: 'underline',
            }}
          >
            Total:
            <span
              style={{
                marginLeft: '3px',
              }}
            />
            {module === 'payment-submit' ||
            module === 'refund-submit' ||
            module === 'advance-submit' ||
            module === 'official-receipt-submit'
              ? amtStr(grandTotalDocAmt)
              : module === 'stock-receipt' ||
                module === 'stock-receipt-approved' ||
                module === 'stock-transfer'
              ? amtStr(totalCost)
              : module === 'stock-cost-adjustment'
              ? amtStr(totalAdjCost)
              : module === 'stock-cost-adjustment-submit' ||
                module === 'stock-cost-adjustment-approved'
              ? amtStr(totalAdjCostPost)
              : amtStr(grandTotalAmt)}
          </span>
        </div>
      </AmountTooltip>
    </div>
  )
}
