import doc from 'assets/icons/file.svg';
import pdf from 'assets/icons/pdf.svg';
import video from 'assets/images/video.png';
import React, { useState } from 'react';
import Viewer from 'react-viewer';

// Function name capitalized to bypass Hook/Function treatment
export const AttachmentDetailView = (DocList: any, diffClass?: boolean) => {
  // to download file with correct file name
  const downloadFile = async (filename, fileurl) => {
    let response = await fetch(fileurl);
    let data = await response.blob();
    let file = new File([data], filename);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = `${filename}`;
    link.click();
  };

  const [openViewer, setOpenViewer] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');

  const viewerStyle = {
    opacity: !!openViewer ? '100%' : '0%',
    zIndex: 2000,
  };

  return (
    <>
      <div className="preview-wrapper">
        {DocList?.length > 0 ? (
          DocList?.map(x => (
            <>
              <span
                onClick={() => {
                  if (x?.mediaType.includes('image')) {
                    setOpenViewer(true);
                    setFileUrl(x?.fileURL);
                    setFileName(x?.name ?? x?.fileName);
                  } else {
                    window.open(
                      `https://docs.google.com/gview?embedded=true&url=${x?.fileURL}`,
                    );
                  }
                }}
              >
                {x?.mediaType.includes('image') ? (
                  <>
                    <img
                      src={x?.fileURL}
                      className={'img-preview'}
                      loading="lazy"
                    />

                    <div
                      className="desc primary-click-text"
                      style={{ fontWeight: 500 }}
                    >
                      {(x?.name ?? x?.fileName).length > 10
                        ? (x?.name ?? x?.fileName).substring(0, 10) +
                          '.' +
                          (x?.name ?? x?.fileName)?.split('.').pop()
                        : x?.name ?? x?.fileName}
                    </div>
                  </>
                ) : x?.mediaType.includes('pdf') ? (
                  <>
                    <img src={pdf} className="img-preview" loading="lazy" />
                    <div
                      className="desc primary-click-text"
                      style={{ fontWeight: 500 }}
                    >
                      {(x?.name ?? x?.fileName).length > 10
                        ? (x?.name ?? x?.fileName).substring(0, 10) +
                          '.' +
                          (x?.name ?? x?.fileName)?.split('.').pop()
                        : x?.name ?? x?.fileName}
                    </div>
                  </>
                ) : x?.mediaType.includes('video') ? (
                  <>
                    <img src={video} className="img-preview" loading="lazy" />
                    <div
                      className="desc primary-click-text"
                      style={{ fontWeight: 500 }}
                    >
                      {(x?.name ?? x?.fileName).length > 10
                        ? (x?.name ?? x?.fileName).substring(0, 10) +
                          '.' +
                          (x?.name ?? x?.fileName)?.split('.').pop()
                        : x?.name ?? x?.fileName}
                    </div>
                  </>
                ) : x?.mediaType.includes('audio') ? (
                  <>
                    <audio controls>
                      <source src={x?.fileURL} type="audio/webm" />
                    </audio>
                    <div
                      className="desc primary-click-text"
                      style={{ fontWeight: 500 }}
                    >
                      {x?.name ?? x?.fileName}
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={doc}
                      className="img-preview"
                      loading="lazy"
                      width="50"
                      height="50"
                    />
                    <div
                      className="desc primary-click-text"
                      style={{ fontWeight: 500 }}
                    >
                      {(x?.name ?? x?.fileName).length > 10
                        ? (x?.name ?? x?.fileName).substring(0, 10) +
                          '.' +
                          (x?.name ?? x?.fileName)?.split('.').pop()
                        : x?.name ?? x?.fileName}
                    </div>
                  </>
                )}
              </span>
            </>
          ))
        ) : (
          <div className="desc">N/A</div>
        )}
      </div>

      <div id="viewer-container" style={viewerStyle}>
        <Viewer
          visible={openViewer}
          onClose={() => {
            setOpenViewer(false);
          }}
          images={[{ src: fileUrl, alt: fileName, downloadUrl: fileUrl }]}
          downloadable={true}
          noImgDetails={true}
          changeable={false}
          rotatable={false}
          showTotal={false}
          scalable={false}
          zoomSpeed={0.1}
          zIndex={2000}
        />
      </div>
    </>
  );
};
