import theme from '@ifca-root/react-component/src/assets/theme'
import { Button, useMediaQuery } from '@material-ui/core'
import AppContext from 'containers/App/Store/AppContext'
import React, { useContext } from 'react'
interface IFooterProps {
  options?: any
  chipLabel?: string
  value?: string | number
  single?: Boolean
  isHidden?: boolean
  totalAmt?: boolean
  extraNote?: string
  label?: string
  optionStyle?: any
  copyright?: boolean
}

/* -------------------------------------------- */
/*    Created for the sole purpose of making    */
/*    the width dynamic to the drawer state     */
/* -------------------------------------------- */

export const AccountFooter = (props: IFooterProps) => {
  const {
    chipLabel,
    options,
    value,
    isHidden,
    totalAmt,
    extraNote,
    label,
    optionStyle,
    copyright,
  } = props
  let single: boolean = false

  if (options?.length === 1) {
    single = true
  }

  const { globalState, dispatch }: any = useContext(AppContext as any)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const buttonColorStyle = (name, disabled) => {
    let obj
    if ((name === 'approve' || name === 'pay') && !disabled) {
      obj = { ...obj, backgroundColor: '#00bf0f', color: '#ffffff' }
    }
    return obj
  }

  // added stuff from faiz
  return (
    <div
      className="footer"
      style={{
        ...(copyright ? { bottom: '25px' } : { bottom: '0px !important' }),
        width:
          isDesktop && globalState.drawerOpen
            ? 'calc(100% - 288px)'
            : !isDesktop
            ? '100%'
            : 'calc(100% - 48px)',
        marginLeft:
          isDesktop && globalState.drawerOpen
            ? '264px'
            : !isDesktop
            ? '0px'
            : '24px',
      }}
    >
      {chipLabel && (
        <div className="footer-label">
          <div className="chip-label">{chipLabel}</div>
          <span className="label-value">{value}</span>
        </div>
      )}
      {totalAmt && (
        <div
          className="footer-label"
          style={{
            background: '#faf2e8',
          }}
        >
          {label ? (
            <div className="xsTitle">
              {label}: <span className="color-blue">{value}</span>
            </div>
          ) : (
            <div className="xsTitle">
              Total: <span className="color-blue">{value}</span>
            </div>
          )}
        </div>
      )}
      {extraNote && (
        <div
          className="footer-label"
          style={{
            background: '#faf2e8',
          }}
        >
          <div className="desc text-noflow" style={{ textAlign: 'center' }}>
            <span style={{ color: 'red' }}>{extraNote}</span>
          </div>
        </div>
      )}
      {isHidden ? null : (
        <div className="footer-btn" style={optionStyle}>
          {options.map((el, index) => (
            <Button
              key={index}
              startIcon={el?.startIcon}
              type="submit"
              color={el.color}
              style={{
                minWidth: options.length > 2 ? '50px' : '126px',
                ...buttonColorStyle(
                  el?.name.toLowerCase(),
                  el?.disabled ?? el?.props?.disabled
                ),
              }}
              variant="contained"
              className={single ? 'single' : ''}
              onClick={el.onClick}
              disabled={el.disabled}
              {...el.props}
            >
              {el.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}
