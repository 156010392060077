import React, { lazy } from 'react'

const AccountingPeriodListing = lazy(() =>
  import('./AccountingPeriodListing').then(module => ({
    default: module.AccountingPeriodListing,
  }))
)

const AccountingPeriodForm = lazy(() =>
  import('./AccountingPeriodForm').then(module => ({
    default: module.AccountPeriodForm,
  }))
)

const accountingPeriodRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/company-setting/accounting-period',
    },
    component: <AccountingPeriodListing />,
  },

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/company-setting/accounting-period/add',
    },
    component: <AccountingPeriodForm mode={'add'} />,
  },

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/company-setting/accounting-period/edit',
    },
    component: <AccountingPeriodForm mode={'edit'} />,
  },
]

export default accountingPeriodRoutes
