import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetAssignedUserByCompanyDocument,
  GetUserDocument,
  GetWarehouseDocument,
  useAssignCompanyMutation,
  useAssignWarehouseMutation,
  useGetCompanyQuery,
  useGetUserCompanyAssignedUsersByRoleQuery,
  useGetUserQuery,
  useGetWarehouseQuery,
  useUnassignCompanyMutation,
  useUnassignWarehouseMutation,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'

interface AvailableUserProps {
  userID: String
  warehouseID: String
  companyID: String
}

export const AvailableUserForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  const CompanyID: any = location?.state
  const { WarehouseID }: any = useParams()
  const [entity, setEntity] = useState<Set<any>>()
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const { pageMode, user, companyID }: any = props
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<AvailableUserProps>({
    defaultValues: {},
    mode: 'all',
  })

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
  })
  const {
    loading: WarehouseLoading,
    error: WarehouseError,
    data: { getWarehouse } = { getWarehouse: {} as any },
  } = useGetWarehouseQuery({
    fetchPolicy: 'network-only',
    variables: { WarehouseID: WarehouseID },
  })

  const [
    assignWarehouse,
    {
      loading: assignWarehouseLoading,
      error: assignWarehouseError,
      data: assignedWarehouseData,
    },
  ] = useAssignWarehouseMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        setEntity(new Set(undefined))
        history.push({
          // pathname: `/general-ledger/general-setting/master-coa/${accType}`,
          state: CompanyID,
        })
      }, 500)
    },
  })
  const [
    unassignWarehouse,
    {
      loading: unassignWarehouseLoading,
      error: unassignWarehouseError,
      data: unassignedWarehouseData,
    },
  ] = useUnassignWarehouseMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setEntity(new Set(undefined))
      setTimeout(() => {
        history.push({
          // pathname: `/general-ledger/general-setting/master-coa/${accType}`,
          state: CompanyID,
        })
      }, 500)
    },
  })

  const handleCreateUpdate =
    pageMode === 'available' ? assignWarehouse : unassignWarehouse

  useEffect(() => {
    if (getCompany && getCompany?.length > 0) {
      setOriginalListing(user)
    }
  }, [getCompany, getWarehouse, pageMode])

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  // handle checkbox for all
  const handleSelectAll = (e: any) => {
    let temp = entity || new Set()
    if (!e?.target?.checked) {
      temp.clear()
    } else {
      filteredList.forEach(v => {
        temp.add(v)
      })
    }
    setEntity(new Set(temp))
  }

  // handle checkbox for specific user
  const handleCheckBoxChange = (e: any, index: number) => {
    let entityTemp = entity || new Set()
    if (!e.target.checked) {
      filteredList.find(v => {
        if (v.UserID === e.target.value) {
          entityTemp.delete(v)
        }
      })
    } else {
      filteredList.find(v => {
        if (v.UserID === e.target.value) {
          entityTemp.add(v)
        }
      })
    }
    setEntity(new Set(entityTemp))
  }

  let listCheckedEntity = Array.from(entity || [])

  const userArray = listCheckedEntity?.map(v => {
    return v.UserID
  })

  const assignOption = {
    name: 'Assign',
    onClick: () => {
      handleSubmit(onSubmit)()
    },
    color: 'primary',
  }
  const unassignOption = {
    name: 'Unassign',
    onClick: () => {
      handleSubmit(onSubmit)()
    },
    color: 'primary',
  }

  const submitOption: any[] =
    pageMode === 'available' ? [assignOption] : [unassignOption]
  const onSubmit = data => {
    handleCreateUpdate({
      variables: {
        input: {
          CompanyID: CompanyID,
          WarehouseID: WarehouseID,
          userID: userArray,
        },
      },
      refetchQueries: [
        {
          query: GetWarehouseDocument,
          variables: { WarehouseID: WarehouseID },
        },
      ],
    })
  }

  return (
    <>
      {companyLoading && <Loading />}
      {assignWarehouseLoading && <Loading />}
      {unassignWarehouseLoading && <Loading />}
      {WarehouseLoading && <Loading />}

      {/* <SnackBarMsg open={msg} message={MESSAGE} /> */}
      <div className="tab-subheader">
        <SearchHeader
          title="Search here.."
          value={`${filteredList?.length || 0}`}
          search
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['Name'])
          }}
          onCloseAction={() => handleSearch('', [])}
          // option={{
          //   icon: <Tune />,
          //   //onClick: () => setFilter(true),
          // }}
        />
      </div>

      <ContentWrapper float>
        {filteredList?.length === 0 ? (
          <EmptyList
            title={`No ${
              pageMode === 'available' ? 'available' : 'assigned'
            } users`}
          />
        ) : (
          <>
            <ListItem style={{ padding: '0px 16px' }}>
              <FormControlLabel
                className="select-all-font"
                control={
                  <Checkbox
                    color="primary"
                    onChange={e => {
                      handleSelectAll(e)
                    }}
                    checked={entity?.size === filteredList?.length}
                  />
                }
                label={<span className="xsTitle flex-space">Select All</span>}
                style={{ paddingLeft: '7px' }}
              />
            </ListItem>

            <CardContents>
              <List disablePadding>
                {filteredList
                  ?.sort((a, b) => {
                    return a.Name.localeCompare(b.Name)
                  })
                  .map((v, index) => {
                    return (
                      <ListItem disableGutters style={{ padding: '0px' }}>
                        <Checkbox
                          color="primary"
                          value={v.UserID}
                          onChange={e => {
                            handleCheckBoxChange(e, index)
                          }}
                          checked={
                            !!listCheckedEntity.find(
                              (el: any) => el.UserID === v.UserID
                            ) || entity?.size === filteredList?.length
                          }
                        />
                        <ListItemText
                          primary={
                            <div className="xsTitle text-noflow">{v.Name}</div>
                          }
                          secondary={
                            <>
                              {v.children?.length > 0 && (
                                <span className="desc text-no-flow">
                                  {v?.selected ? v?.selected : 0}
                                  <span style={{ margin: '0px 4px' }}>/</span>
                                  {v.children?.length} Accessible
                                </span>
                              )}
                            </>
                          }
                        />
                      </ListItem>
                    )
                  })}
              </List>
            </CardContents>
          </>
        )}
      </ContentWrapper>
      <Footer options={[...submitOption]} />
    </>
  )
}
