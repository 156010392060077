import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import { useHistory, useLocation, useParams } from 'react-router';
import React from 'react';
import { useGetItemQuery } from '../../../../generated/graphql';
import { amtStr4Dec } from '../../../../helpers/StringNumberFunction/numFormatter';

export const ItemDetail = (props: any) => {
  const { mode }: any = props;
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  let history = useHistory();
  const { ItemID, ItemCategoryID }: any = useParams();
  let location = useLocation();
  const editData = location?.state as any;

  // QUERY //

  const {
    loading: ItemLoading,
    error: ItemError,
    data: { getItem } = {
      getItem: [],
    },
  } = useGetItemQuery({
    fetchPolicy: 'network-only',
    variables: {
      ItemID: ItemID,
    },
  });

  return (
    <>
      {ItemLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(
            `/general-purchase/general-setting/item-category/${ItemCategoryID}`,
          )
        }
        smTitle={smTitle.GENERAL_SETTINGS}
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Item' },
          { name: 'Item', current: true },
        ]}
      />

      <ContentWrapper>
        <CardContents
          section={{
            header: {
              title: 'Item Details',
            },
          }}
        >
          <div className="content-wrap left">
            <div className="desc">Name</div>
            <div className="xsTitle">{getItem[0]?.Name}</div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Item Category</div>
            <div className="xsTitle">{getItem[0]?.ItemCategory?.Name}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Description</div>
            <div className="xsTitle text-noflow">{getItem[0]?.Description}</div>
          </div>
          <div className="content-wrap left">
            <div className="desc">UOM</div>
            <div className="xsTitle">{getItem[0]?.UOM?.Name}</div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Tax Code</div>
            <div className="xsTitle">{getItem[0]?.TaxScheme?.Code}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Variance Percentage</div>
            <div className="xsTitle">{getItem[0]?.VariancePerc}%</div>
          </div>
          <div className="content-wrap left">
            <div className="desc">Market Price</div>
            <div className="xsTitle">
              {amtStr4Dec(getItem[0]?.MarketPrice ?? 0)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Latest Price</div>
            <div className="xsTitle">
              {amtStr4Dec(getItem[0]?.LatestPurchasePrice ?? 0)}
              {getItem[0]?.LatestUOMID ? `/${getItem[0].LatestUOMCode}` : ''}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Remark</div>
            <div className="xsTitle text-noflow">{getItem[0]?.Remark}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Default Supplier</div>
            <div className="xsTitle">
              {getItem[0]?.DefaultSupplier?.CompanyName}
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc">Inventory Item</div>
            <div className="xsTitle">
              {getItem[0]?.IsInventory === true ? 'Yes' : 'No'}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Centralised Purchase Item</div>
            <div className="xsTitle">
              {getItem[0]?.IsCentralised === true ? 'Yes' : 'No'}
            </div>
          </div>
          {/* <div className="content-wrap full">
            <div className="desc">Disallowed Purchase</div>
            <div className="xsTitle">
              {getItem[0]?.IsDisallow === true ? 'Yes' : 'No'}
            </div>
          </div> */}
          <div className="content-wrap left">
            <div className="desc">Category Account Code</div>
            <div className="xsTitle text-noflow">
              {!!getItem[0]?.ItemCategory?.MasterCOAID
                ? ` ${getItem[0]?.ItemCategory?.MasterCOA?.Code} | ${getItem[0]?.ItemCategory?.MasterCOA?.Name}`
                : `${getItem[0]?.ItemCategory?.ParentCategoryMCOA?.MasterCOACode} | ${getItem[0]?.ItemCategory?.ParentCategoryMCOA?.MasterCOAName}`}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Status</div>
            <div
              className="xsTitle"
              style={{ display: 'flex', marginTop: '3px' }}
            >
              {getItem[0]?.RecordStatus === 'ACTIVE' && (
                <div
                  className="blue-status"
                  style={{
                    padding: '5px',
                    margin: '0px',
                    borderRadius: '2px',
                  }}
                >
                  Active
                </div>
              )}
              {getItem[0]?.RecordStatus === 'INACTIVE' && (
                <div
                  className="grey-status"
                  style={{
                    padding: '5px',
                    margin: '0px',
                    borderRadius: '2px',
                  }}
                >
                  Inactive
                </div>
              )}
            </div>
          </div>
        </CardContents>
      </ContentWrapper>
    </>
  );
};
