import { MenuItem, TextField } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'

export const DropdownTextfield = props => {
  const {
    // mount,
    disabled,
    helperText,
    error,
    label,
    handleChangeEnabled,
    handleChange,
    data,
    displayKey,
    control,
    register,
    value,
    name,
    selectProps,
    customDropdown,
    suffix,
  } = props
  // if (mount) {
  return (
    <Controller
      render={({ onChange, value }) => (
        <TextField
          margin="normal"
          disabled={disabled}
          helperText={helperText}
          error={error}
          label={label}
          select
          SelectProps={selectProps}
          required
          value={value}
          onChange={e => {
            onChange(e)
            if (handleChangeEnabled) {
              handleChange(e)
            }
          }}
        >
          {!!customDropdown
            ? customDropdown
            : data?.map((el, index) => (
                <MenuItem
                  id="contract-select"
                  key={index}
                  value={el[`${suffix}ID`]}
                >
                  {el[`${displayKey}`]}
                </MenuItem>
              ))}
        </TextField>
      )}
      name={name}
      control={control}
      ref={register}
      fullWidth
    />
  )
  // } else {
  //   return null
  // }
}
