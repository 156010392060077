import React, { lazy } from 'react'
import { ItemDetail } from '@account-root/procurement-react/src/containers/GeneralSettingModule/Item/styles/ItemDetail'

const ItemCategoryItem = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/Item/ItemCategoryItem'
  ).then(module => ({
    default: module.ItemCategoryItem,
  }))
)

const ItemListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/Item/ItemListing'
  ).then(module => ({
    default: module.ItemListing,
  }))
)

const ItemForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/Item/ItemForm'
  ).then(module => ({
    default: module.ItemForm,
  }))
)

const ItemValidation = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/Item/ItemExcel/ItemExcelValidation'
  ).then(module => ({
    default: module.ItemExcelValidation,
  }))
)

const ItemCompanyDisallowForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/Item/ItemCompanyDisallowForm'
  ).then(module => ({
    default: module.ItemCompanyDisallowForm,
  }))
)

const itemRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/item-category',
    },
    component: <ItemCategoryItem />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/item-category/:ItemCategoryID',
    },
    component: <ItemListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/general-setting/item-category/:ItemCategoryID/item/:ItemID/detail',
    },
    component: <ItemDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/general-setting/item-category/:ItemCategoryID/item/:ItemID/edit',
    },
    component: <ItemForm formMode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/general-setting/item-category/:ItemCategoryID/add',
    },
    component: <ItemForm formMode="Add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/item-category/validation',
    },
    component: <ItemValidation />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/general-setting/item-category/:ItemCategoryID/item/:ItemID/disallow',
    },
    component: <ItemCompanyDisallowForm />,
  },
]

export default itemRoutes
