import Cycle from '@ifca-root/react-component/src/assets/icons/cycle-count.svg'
import Adjustment from '@ifca-root/react-component/src/assets/icons/stock-adjustment.svg'
import Enquiry from '@ifca-root/react-component/src/assets/icons/stock-enquiry.svg'
import Issue from '@ifca-root/react-component/src/assets/icons/stock-issue.svg'
import Receipt from '@ifca-root/react-component/src/assets/icons/stock-receipt.svg'
import Transfer from '@ifca-root/react-component/src/assets/icons/stock-transfer.svg'
import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { SwitchDialog } from 'components/Switch/SwitchDialog'
import AppContext from 'containers/App/Store/AppContext'
import {
  AcctPermission,
  useGetAssignedWarehouseCompanyQuery,
  useGetWarehouseNameQuery,
  useIcSubmenuDetailQuery,
  useStockEnquiryDetailsQuery,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const InventoryControlSubmenu = (props: any) => {
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { handlePermHidden } = usePermissionChecker()
  let history = useHistory()
  const { WarehouseID }: any = useParams()
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const [change, setSwitch] = useState(false)
  const [homeWarehouse, setHomeWarehouse] = useState<string>(WarehouseID)

  localStorage.setItem('latestWarehouse', WarehouseID)
  if (globalState?.mode === '') {
    dispatch({
      type: 'mode',
      payload: 'inventory-control',
    })
  }

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }

  useEffect(() => {
    document.title = `AX - Inventory Control`
  }, [])

  // QUERY //
  const {
    loading: SubmenuLoading,
    error: SubmenuError,
    data: {
      stockRequisitionCount,
      stockIssueCount,
      stockReceiptCount,
      stockTransferRequisitionCount,
      stockTransferCount,
      stockAdjustmentCount,
      stockCostAdjustmentCount,
      cycleCountCount,
    } = {
      stockRequisitionCount: undefined,
      stockIssueCount: undefined,
      stockReceiptCount: undefined,
      stockTransferRequisitionCount: undefined,
      stockTransferCount: undefined,
      stockAdjustmentCount: undefined,
      stockCostAdjustmentCount: undefined,
      cycleCountCount: undefined,
    },
  } = useIcSubmenuDetailQuery({
    variables: { WarehouseID: WarehouseID },
    fetchPolicy: 'network-only',
  })

  const {
    loading: WarehouseNameLoading,
    error: WarehouseNameError,
    data: { getWarehouse: getWarehouseName } = { getWarehouse: [] },
  } = useGetWarehouseNameQuery({
    variables: { WarehouseID: WarehouseID },
    fetchPolicy: 'network-only',
  })

  let userAccDetail = {
    ...user,
    warehouseID: getWarehouseName[0]?.WarehouseID,
    warehouseName: getWarehouseName[0]?.Name,
  }

  localStorage.setItem('loggedInUser', JSON.stringify(userAccDetail))

  // const {
  //   loading: warehouseLoading,
  //   error: warehouseError,
  //   data: { getWarehouse } = {
  //     getWarehouse: [],
  //   },
  // } = useGetWarehouseQuery({
  //   fetchPolicy: 'network-only',
  //   variables: {
  //     orderByAsc: 'Name',
  //   },
  // })

  const {
    loading: warehouseCompanyLoading,
    error: warehouseCompanyError,
    data: { getAssignedWarehouseCompanyByUser } = {
      getAssignedWarehouseCompanyByUser: [],
    },
  } = useGetAssignedWarehouseCompanyQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: StockItemWarehouseLoading,
    error: StockItemWarehouseError,
    data: { getStockItemWarehouse: stockEnquiryDetails } = {
      stockEnquiryDetails: [],
    },
  } = useStockEnquiryDetailsQuery({
    fetchPolicy: 'network-only',

    variables: {
      WarehouseID: WarehouseID, /// later need to change variabled to WarehouseID
    },
  })

  let submenuList = [
    {
      path: `/inventory-control/${WarehouseID}/stock-enquiry`,
      icon: Enquiry,
      title: `Stock Enquiry`,
      label1: ` Stock items`,
      data1: stockEnquiryDetails?.length || 0,
      permission: AcctPermission.InventoryControlStockEnquiryView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-requisition`,
      icon: Adjustment,
      title: `Stock Requisition`,
      draft: stockRequisitionCount?.draft,
      submitted: stockRequisitionCount?.submitted,
      permission: AcctPermission.InventoryControlStockRequisitionView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-issue`,
      icon: Issue,
      title: `Stock Issue`,
      draft: stockIssueCount?.draft,
      submitted: stockIssueCount?.submitted,
      permission: AcctPermission.InventoryControlStockIssueView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-receipt`,
      icon: Receipt,
      title: `Stock Receipt`,
      draft: stockReceiptCount?.draft,
      submitted: stockReceiptCount?.submitted,
      permission: AcctPermission.InventoryControlStockReceiptView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-transfer-requisition`,
      icon: Adjustment,
      title: `Stock Transfer Requisition`,
      draft: stockTransferRequisitionCount?.draft,
      submitted: stockTransferRequisitionCount?.submitted,
      permission: AcctPermission.InventoryControlStockTransferRequisitionView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-transfer`,
      icon: Transfer,
      title: `Stock Transfer`,
      draft: stockTransferCount?.draft,
      submitted: stockTransferCount?.submitted,
      permission: AcctPermission.InventoryControlStockTransferView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-adjustment`,
      icon: Adjustment,
      title: `Stock Qty Adjustment`,
      draft: stockAdjustmentCount?.draft,
      submitted: stockAdjustmentCount?.submitted,
      permission: AcctPermission.InventoryControlStockAdjustmentView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-cost-adjustment`,
      icon: Adjustment,
      title: `Stock Cost Adjustment`,
      draft: stockCostAdjustmentCount?.draft,
      submitted: stockCostAdjustmentCount?.submitted,
      permission: AcctPermission.InventoryControlStockCostAdjustmentView,
    },
    {
      path: `/inventory-control/${WarehouseID}/cycle-count`,
      icon: Cycle,
      title: `Cycle Count`,
      draft: cycleCountCount?.draft,
      submitted: cycleCountCount?.submitted,
      permission: AcctPermission.InventoryControlCycleCountingView,
    },

    // {
    //   path: `/inventory-control/${WarehouseID}/digital-report`,
    //   icon: DigitalReports,
    //   title: `Digital Reports`,
    //   label1: ``,
    //   data1: 2,
    //   label2: ` Reports available`,
    //   data2: ``,
    //   permission: AcctPermission.InventoryControlDigitalReportsView,
    // },
  ]

  return (
    <>
      {SubmenuLoading && <Loading />}
      {WarehouseNameLoading && <Loading />}
      {warehouseCompanyLoading && <Loading />}
      {StockItemWarehouseLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/`)}
        smTitle={'Inventory Control'}
        title={getWarehouseName[0]?.Name}
        onClickAction={() => setSwitch(true)}
        sideBtn="company"
        //rightCustomIcon={getWarehouseName[0]?.Logo?.fileURL}
        routeSegments={[{ name: 'Main Menu', current: true }]}
      />

      <ContentWrapper float>
        <List className="core-list">
          {submenuList.map((el, index) => {
            if (
              handlePermHidden({
                permEnum: el?.permission,
              })
            )
              return (
                <ListItem key={index} onClick={() => history.push(el?.path)}>
                  <IconItem
                    image={el?.icon}
                    imageType="svg"
                    badgeType="default"
                  />
                  <ListItemText
                    style={{
                      textAlign: 'right',
                      marginLeft: '5px',
                    }}
                    primary={
                      <Grid spacing={1} container className="table-content">
                        <Grid
                          item
                          xs={6}
                          style={{
                            textAlign: 'start',
                          }}
                        >
                          <span className="xsTitle">{el?.title}</span>
                        </Grid>
                        {el?.title === 'Stock Enquiry' && (
                          <Grid
                            item
                            xs={6}
                            style={{
                              textAlign: 'end',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '12px',
                                color: 'orange',
                              }}
                            >
                              {amtStr(
                                stockEnquiryDetails?.reduce(
                                  (a, b) =>
                                    a + b?.StockAvgUnitCost * b?.StockQty,
                                  0
                                )
                              )}
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    }
                    secondary={
                      <>
                        {el.label1 ? (
                          <span className="desc">
                            <span className="desc c-orange">{el.data1}</span>
                            &nbsp;
                            <span className="desc">{el.label1}</span>
                          </span>
                        ) : (
                          <>
                            <span className="desc">
                              <span className="text c-orange">
                                {`${el?.draft ?? 0}`}&nbsp;
                              </span>
                              <span className="text">
                                {`Draft`}&nbsp;&nbsp;{' '}
                              </span>
                              <span className="text c-orange">
                                {`${el?.submitted ?? 0}`}&nbsp;
                              </span>
                              <span className="text">{`Submitted`}</span>
                            </span>
                          </>
                        )}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: el.path,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          })}
        </List>
      </ContentWrapper>

      <SwitchDialog
        open={change}
        onClose={() => setSwitch(false)}
        data={getAssignedWarehouseCompanyByUser}
        name={'Warehouse'}
        nameID={'WarehouseID'}
        currID={homeWarehouse}
        setCurrID={setHomeWarehouse}
        path={'/inventory-control'}
      />
    </>
  )
}
