import React, { lazy } from 'react'

const JournalTypeListing = lazy(() =>
  import('./JournalTypeListing').then(module => ({
    default: module.JournalTypeListing,
  }))
)

const journalTypeRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/company-setting/journal-type',
    },
    component: <JournalTypeListing />,
  },
]

export default journalTypeRoutes
