import React, { lazy } from 'react'
import { LeaseContractMain } from './LeaseContractMain'
import { LeaseContractForm } from './LeaseContractForm/LeaseContractForm'

// const UOMExchangeListing = lazy(() =>
//   import(
//     '@ifca-root//UOMExchange/UOMRateListing'
//   ).then(module => ({
//     default: module.UOMRateListing,
//   })),
// );

const leaseContractRoutes = [
  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/lease-contract',
    },
    component: <LeaseContractMain />,
  },
  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/lease-contract/add',
    },
    component: <LeaseContractForm />,
  },
]

export default leaseContractRoutes
