import Fuse from 'fuse.js'
import React, { useEffect } from 'react'

// filter the initital list to not search by ID, objects, createdBy, createdTs and modTs
// if possible, can also gives array of keys to be filtered
// this works even better with specific .graphql
// export const useSearch = () => {
//   const [filteredList, setFiltered] = React.useState([])
//   const handleSearch = (origList, filter: string) => {
//     setFiltered(origList)

//     if (origList) {
//       // filtering ID and object inside object
//       // array of object should consist of same element so define key by taking first element only
//       let objKey = Object.keys(origList[0])
//       let filteredKeys = objKey?.filter(
//         key =>
//           !key.includes('ID') &&
//           // typeof origList[0][key] !== 'object' &&
//           !key.includes('created') &&
//           !key.match('modTs')
//       )

//       let filteredObj = origList?.filter(i => {
//         let j = filteredKeys.reduce((val, entry) => {
//           if (entry.toLowerCase().includes('date') && val[entry] != null) {
//             val[entry] = i[entry]
//             //val[entry] = formatDate(new Date(i[entry]).toISOString())
//           } else {
//             val[entry] = i[entry]
//           }
//           // val[entry] = i[entry];
//           return val
//         }, {})

//         if (
//           JSON.stringify(j)
//             ?.toLowerCase()
//             .includes(filter.toString().toLowerCase())
//         )
//           return i
//       })

//       setFiltered(filteredObj)
//     }
//   }

//   return {
//     filteredList,
//     handleSearch,
//   }
// }

/**
 *
 * @param handleSearch
 * 3 parameters (origList, filter, keys)
 *
 * @param keys
 * keys to be search in the object
 *
 * first layer:
 * - {name: "chia"}
 * - keys = ["name"]
 *
 * nested object:
 * - { person: { name: "chia", size: { height: "180", weight: "120"}}}
 * - keys = ["person.name.size.height", "person.name.size.weight"]
 *
 * @param origList
 * original list to be search
 *
 * @param filter
 * filter string or pattern words being search
 * -- empty will return the original list
 
 * @function reset
 * -- only use if you want to reset the list manually,
 * else the search should be able to auto reset the list to original
 */
export const useFuseTreeSearch = () => {
  const [filteredList, setFiltered] = React.useState([])
  const [originalList, setOriginalList] = React.useState([])
  const [searchText, setSearchText] = React.useState<string>()
  const [searchKey, setSearchKey] = React.useState<string[]>([])
  const setOriginalListing = (origList: any, customDataChanges?: any) => {
    let tempOrig = origList
    if (!!customDataChanges) {
      tempOrig?.map(v => customDataChanges(v))
    }

    setFiltered(tempOrig)
    setOriginalList(tempOrig)
  }

  useEffect(() => {
    if (searchText && searchKey && searchKey.length > 0) {
      filterTreeFn(searchText, searchKey)
    }
  }, [originalList])

  const handleFilterTree = (array, searchText, options) => {
    let matches = []
    if (!Array.isArray(array)) return matches

    array.forEach(function(i) {
      const myFuse = new Fuse([i], options)
      const result = myFuse.search(searchText)
      const val = result?.map(x => x?.item)
      // remove child after match
      let data = (({ child, ...other }) => other)(i)
      if (val?.length > 0) {
        matches.push(
          Object.assign({}, data, {
            child: handleFilterTree(i.child, searchText, options),
          })
        )
      } else {
        let childResults = handleFilterTree(i.child, searchText, options)
        if (childResults.length)
          matches.push(Object.assign({}, i, { child: childResults }))
      }
    })

    return matches
  }

  const filterTreeFn = (searchText: string, keys: string[]) => {
    if (originalList) {
      const options = {
        isCaseSensitive: false,
        shouldSort: true,
        threshold: 0.0,
        ignoreLocation: true,
        keys: keys,
      }
      if (searchText) {
        const data = handleFilterTree(originalList, searchText, options)
        setFiltered(data)
      } else {
        setFiltered(originalList)
      }
    }
  }

  const handleSearch = (filter: string, keys: string[]) => {
    setSearchText(filter)
    setSearchKey(keys)
    filterTreeFn(filter, keys)
  }

  const reset = () => {
    setFiltered(originalList)
  }

  return {
    filteredList,
    handleSearch,
    setOriginalListing,
    reset,
    originalList,
  }
}
