import React, { lazy } from 'react'
import { RoleDetail } from './RoleDetail'

const RoleListing = lazy(() =>
  import('./RoleListing').then(module => ({
    default: module.RoleListing,
  }))
)

const RoleForm = lazy(() =>
  import('./RoleForm').then(module => ({
    default: module.RoleForm,
  }))
)

const roleRoutes = [
  {
    props: { exact: true, path: '/general-ledger/access-security/role' },
    component: <RoleListing />,
  },
  {
    props: { exact: true, path: '/general-ledger/access-security/role/add' },
    component: <RoleForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/access-security/role/:ID/edit',
    },
    component: <RoleForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/access-security/role/detail/:ID',
    },
    component: <RoleDetail />,
  },

  //For homeAccessSecurity
  {
    props: {
      exact: true,
      path: '/access-security/role/detail/:ID',
    },
    component: <RoleDetail />,
  },
  {
    props: { exact: true, path: '/access-security/role' },
    component: <RoleListing />,
  },
  {
    props: { exact: true, path: '/access-security/role/add' },
    component: <RoleForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/access-security/role/:ID/edit',
    },
    component: <RoleForm mode="edit" />,
  },
]

export default roleRoutes
