import React, { lazy } from 'react'
import { BankTransferDetail } from './BankTransferDetail/BankTransferDetail'
import PDFPreviewCBV2 from '../Hooks/PDFPreviewCBV2'

const BankTransferListing = lazy(() =>
  import('./BankTransferMain').then(module => ({
    default: module.BankTransferMain,
  }))
)

const BankTransferForm = lazy(() =>
  import('./BankTransferForm').then(module => ({
    default: module.BankTransferForm,
  }))
)

const AdvancedFilter = lazy(() =>
  import('../../../components/Filter/AdvancedFilter').then(module => ({
    default: module.AdvancedFilter,
  }))
)

const banktransferRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/bank-transfer/filter',
    },
    component: (
      <AdvancedFilter
        mode="bank-transfer"
        app="cash-book"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/bank-transfer',
    },
    component: <BankTransferListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-transfer/:BankTransferID/detail',
    },
    component: <BankTransferDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-transfer/detail/:BankTransferID/workdesk',
    },
    component: <BankTransferDetail mode="workdesk" />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/cash-book/:CompanyID/submenu/:BankAccountID/bank-transfer',
  //   },
  //   component: <BankTransferListing formMode="Draft" />,
  // },
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/bank-transfer/add',
    },
    component: <BankTransferForm formMode="Add" type="BANK_TRANSFER" />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-transfer/:BankTransferID/edit',
    },
    component: <BankTransferForm formMode="Edit" type="BANK_TRANSFER" />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-transfer/approve-reject/:BankTransferID',
    },
    component: <BankTransferForm formMode="Approve-reject" />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-transfer/resubmit/:BankTransferID',
    },
    component: <BankTransferForm formMode="Resubmit" />,
  },

  /* -------------------------------------------- */
  /*              PREVIEW DETAIL PDF              */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-transfer/:BankTransferID/detail/preview',
    },
    component: (
      <PDFPreviewCBV2 accountType="cash-book" docType="bank-transfer" />
    ),
  },
]

export default banktransferRoutes
