import React, { lazy } from 'react'
import bankreconciliationRoutes from './BankReconciliation/BankReconciliationRoutes'
import {
  default as bankTransferPostingRoutes,
  default as banktransferRoutes,
} from './BankTransfer/BankTransferRoutes'
import cbGLExportRoutes from './CBGLExport/CBGLExportRoutes'
import digitalReportsRoutes from './DigitalReports/DigitalReportsRoutes'
import externalLedgerRoutes from './ExternalLedger/ExternalLedgerRoutes'
import forexRevaluationRoutes from './ForexRevaluation/ForexRevaluationRoutes'
import paymentRoutes from './Payment/PaymentRoutes'
import receiptRoutes from './Receipt/ReceiptRoutes'

const CashBookSubmenu = lazy(() =>
  import('./CashBookSubmenu').then(module => ({
    default: module.CashBookSubmenu,
  }))
)

const BankAccListing = lazy(() =>
  import('./BankAccListing').then(module => ({
    default: module.BankAccListing,
  }))
)

const cashBookRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/',
    },
    component: <BankAccListing />,
  },
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/',
    },
    component: <CashBookSubmenu />,
  },

  ...bankreconciliationRoutes,
  ...forexRevaluationRoutes,
  ...banktransferRoutes,
  ...receiptRoutes,
  ...paymentRoutes,
  ...externalLedgerRoutes,
  ...digitalReportsRoutes,
  ...bankTransferPostingRoutes,
  ...cbGLExportRoutes,
]

export default cashBookRoutes
