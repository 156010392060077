import BigNumber from 'bignumber.js'
import {
  isNumber,
  isString,
  isBigNumber,
} from 'helpers/Validation/NumValidators'
export type Timestamp = string
export const throwOverloadValueTypeNotFoundErr = (v: any) => {
  throw new Error(`There's no overload for the value type: ${v}`)
}

export interface IAmtStr {
  (v: BigNumber, prefix?: string, suffix?: string): string
  (
    v: BigNumber,
    prefix?: string,
    suffix?: string,
    format?: BigNumber.Format
  ): string
  (v: string, prefix?: string, suffix?: string): string
  (
    v: string,
    prefix?: string,
    suffix?: string,
    format?: BigNumber.Format
  ): string
  (v: number, prefix?: string, suffix?: string): string
  (
    v: string,
    prefix?: string,
    suffix?: string,
    format?: BigNumber.Format
  ): string
}

export const amtStr: IAmtStr = (
  v: any = '0.00',
  prefix: string = '',
  suffix: string = '',
  format: BigNumber.Format = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    suffix,
  }
) => {
  if (!v) v = 0
  let result: string = ''

  switch (true) {
    case isBigNumber(v):
      result = (<BigNumber>v).toFormat(2, format)
      break
    case isString(v):
      ;(<string>v).replace(',', '')
      result = new BigNumber(<string>v || 0).toFormat(2, format)
      break
    case isNumber(v):
      result = new BigNumber(<number>v || 0).toFormat(2, format)
      break
    default:
      throwOverloadValueTypeNotFoundErr(v)
  }

  return result
}

export const amtStr4Dec: IAmtStr = (
  v: any = '0.0000',
  prefix: string = '',
  suffix: string = '',
  format: BigNumber.Format = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    suffix,
  }
) => {
  if (!v) v = 0
  let result: string = ''

  switch (true) {
    case isBigNumber(v):
      result = (<BigNumber>v).toFormat(4, format)
      break
    case isString(v):
      ;(<string>v).replace(',', '')
      result = new BigNumber(<string>v || 0).toFormat(4, format)
      break
    case isNumber(v):
      result = new BigNumber(<number>v || 0).toFormat(4, format)
      break
    default:
      throwOverloadValueTypeNotFoundErr(v)
  }

  return result
}

export const pcStr = (v: string) => amtStr(v, '', ' %')

export interface IAmtFloat {
  (v: string): number
  (v: number): number
  (v: BigNumber): number
}

export const amtFloat: IAmtFloat = (v: any = 0) => {
  if (!v) v = 0
  let adjV: any

  switch (true) {
    case isString(v):
      adjV = <string>v
      break
    case isNumber(v):
      adjV = <number>v
      break
    case isBigNumber(v):
      adjV = <BigNumber>v.toString()
      break
    default:
      throwOverloadValueTypeNotFoundErr(v)
  }

  return parseFloat(parseFloat(adjV).toFixed(2))
}

export const toDate = (v: Timestamp) => (v ? new Date(parseInt(v)) : new Date())

export interface INumberStr {
  (v: string): string
  (v: number): string
  (v: BigNumber): string
}

/** e.g. 10,000.00 -> 10000.00 */
export const amtNumStr: INumberStr = (v: any = 0) => {
  if (!v) v = 0
  //console.log('doggy', v);
  let adjV: string

  switch (true) {
    case isString(v):
      adjV = (<string>v).replace(/( )|(- )|(--)|(,)/g, '')
      break
    case isNumber(v):
      adjV = (<number>v).toFixed(2)
      //console.log('doogie', adjV);
      break
    case isBigNumber(v):
      adjV = (<BigNumber>v).toFormat(2)
      adjV = (<string>adjV).replace(/( )|(- )|(--)|(,)/g, '')
      break
    default:
      throwOverloadValueTypeNotFoundErr(v)
  }
  //console.log('muti', adjV, typeof adjV);
  return adjV
}
