import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { Avatar, Grid, Tooltip, useMediaQuery } from '@material-ui/core'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import OrangeDollarIcon from 'assets/icons/Money/orange-dollar.svg'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { DetailAllocationTableContent } from 'components/Table/DetailAllocationTableContent'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AccountType,
  ApprovalStatus,
  GetArCreditNotebyStatusDocument,
  useDocumentListingQuery,
  useGetArCreditNoteQuery,
  useGetCostCentreQuery,
  useGetEntityCoaQuery,
  useGetMasterCoaQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostApCreditNoteMutation,
  UserSelection,
} from 'generated/graphql'
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { ARCreditNoteDetailContent } from './ARCreditNoteDetailContent'
import PersonIcon from '@material-ui/icons/Person'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import BlackDollarIcon from 'assets/icons/Money/black-dollar.svg'

export const ARCreditNoteDetail = props => {
  let history = useHistory()
  const { CompanyID, CreditNoteID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { menu, handleClick: handleClick2 }: any = useMenuOption()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  let location = useLocation()
  const editData = location?.state as any
  const [openWorkFlow, setWorkFlow] = useState(false)
  const { handleStatusColor } = useColorStatusList()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()

  const {
    loading: ARCreditNoteLoading,
    error: ARCreditNoteError,
    data: { getARCreditNote } = {
      getARCreditNote: [],
    },
  } = useGetArCreditNoteQuery({
    fetchPolicy: 'network-only',
    variables: {
      CreditNoteID: CreditNoteID,
    },
  })

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: CreditNoteID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const {
    loading: EntityCOALoading,
    error: EntityCOAErros,
    data: { getEntityCOA } = { getEntityCOA: [] },
  } = useGetEntityCoaQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
  })

  const masterCOAList =
    getEntityCOA?.length > 0
      ? getEntityCOA
          ?.map(coa => coa?.MasterCOA)
          ?.filter(coa => coa?.IsLastNode === true && coa?.IsControl === false)
          ?.filter(x => x?.AccountType === AccountType.Revenue)
      : getMasterCOA
          ?.filter(coa => coa?.IsLastNode === true && coa?.IsControl === false)
          ?.filter(x => x?.AccountType === AccountType.Revenue)

  //CostCentre
  const {
    loading: CostCentreLoading,
    error: CostCentreErrors,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      orderByAsc: 'CostCentreID',
      IsLastNode: true,
    },
  })

  const statusText = jStatus => {
    switch (jStatus) {
      case ApprovalStatus.Submit:
        return 'Submitted'
      case ApprovalStatus.Active:
        return 'Draft'
      case ApprovalStatus.Approved:
        return 'Submitted'
      case ApprovalStatus.Completed:
        return 'Approved'
      case ApprovalStatus.Rejected:
        return 'Rejected'
      case ApprovalStatus.Cancelled:
        return 'Cancelled'
      default:
        return ''
    }
  }

  const [
    postARCreditNote,
    {
      loading: postCreditNoteLoading,
      called: postCreditNoteCalled,
      error: postCreditNoteError,
    },
  ] = usePostApCreditNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/credit-note-posting`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const onSubmit = (data, status) => {
    postARCreditNote({
      variables: {
        CreditNoteIDs: CreditNoteID,
        CompanyID,
        RefTable: 'AR_CreditNote',
      },
      refetchQueries: [
        {
          query: GetArCreditNotebyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Completed, ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  const allocationTotal = getARCreditNote[0]?.Allocation?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.AllocationAmt,
    0
  )

  return (
    <>
      {postCreditNoteLoading && <Loading />}
      {ARCreditNoteLoading && <Loading />}

      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="back"
        smTitle={'Account Receivable'}
        title={`${user?.companyName}`}
        routeSegments={[
          { name: 'Account Receivable' },
          { name: 'AR Submenu' },
          { name: 'Credit Note', current: true },
        ]}
        currency={user?.companyCurrencyCode}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getARCreditNote[0]?.DocNo}
            </span>
          </>
        }
        rightText={
          <span
            className="desc"
            style={{ color: 'orange', fontWeight: 'bold' }}
          >
            {formatDate(getARCreditNote[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            {/* <div
              className="text-overflow"
              style={{ fontSize: '11px', width: '270px' }}
            >
              {getARCreditNote[0]?.DebtorAccount?.DebtorName}
            </div> */}
            <div className="icon-text">
              <Tooltip
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Avatar
                          style={{
                            width: '20px',
                            height: '20px',
                          }}
                        >
                          <PersonIcon style={{ fontSize: 'small' }} />
                        </Avatar>
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <div style={{ fontSize: '12px' }}>
                          {getUsersByAccountAndSoftware?.map(user => {
                            if (user.ID === getARCreditNote[0]?.approvedBy) {
                              return user?.name
                            }
                          })}
                        </div>
                        <div style={{ fontSize: '10px' }}>
                          {formatDate(getARCreditNote[0]?.approvedTs)}
                          &nbsp;
                          {formatTime(getARCreditNote[0]?.modTs)}
                        </div>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
                arrow
                enterTouchDelay={0}
              >
                <div>
                  <IconText
                    icon={
                      <img
                        src={SubmitterLog}
                        style={{
                          width: '11.5px',
                          marginRight: '5px',
                        }}
                      />
                    }
                    font="desc"
                  ></IconText>
                </div>
              </Tooltip>
              <span
                className="xsTitle content-wrap full text-overflow desc"
                style={{
                  marginBottom: '0px',
                }}
              >
                {getARCreditNote[0]?.Description}
              </span>
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <img
              src={BlackDollarIcon}
              style={{
                width: '12px',
                marginBottom: '-2px',
                marginRight: '3px',
              }}
            />
            <span className=" desc flex-space">
              {amtStr(getARCreditNote[0]?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          <ARCreditNoteDetailContent
            listEl={getARCreditNote[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Allocation(s)',
              rightTitle: amtStr(allocationTotal),
              customFontSizeClass: 'xsTitle',
              dollarIcon: (
                <img
                  src={OrangeDollarIcon}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginBottom: '-2px',
                    marginRight: '3px',
                    marginLeft: '3px',
                  }}
                />
              ),
            },
          }}
        >
          <DetailAllocationTableContent
            listItem={getARCreditNote[0]?.Allocation}
          />
        </CardContents>
      </ContentWrapper>
      <ApprovalLogDialog
        data={getARCreditNote[0]}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Debit Note'}
      />

      {getARCreditNote[0]?.ApprovalStatus === 'SUBMIT' ? (
        <Footer
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      ) : null}
    </>
  )
}
