import React, { lazy } from 'react'
import dataSourceSettingsRoutes from './DataSourceSettings/DataSourceSettingsRoutes'
import reportSettingsRoutes from './ReportSettings/ReportSettingsRoutes'

const FinancialReportingSubmenu = lazy(() =>
  import('./FinancialReportingSubmenu').then(module => ({
    default: module.FinancialReportingSubmenu,
  }))
)

const PrintReportListing = lazy(() =>
  import('./PrintReport/PrintReportListing').then(module => ({
    default: module.PrintReportListing,
  }))
)

const FinancialReportingToolForm = lazy(() =>
  import('./FinancialReportingTool/FinancialReportingToolForm').then(
    module => ({
      default: module.FinancialReportingToolForm,
    })
  )
)

const financialReportRoutes = [
  //GENERAL LEDGER
  {
    props: {
      exact: true,
      path: '/general-ledger/financial-reporting',
    },
    component: <FinancialReportingSubmenu module="general-ledger" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/financial-reporting/print-report',
    },
    component: <PrintReportListing module="general-ledger" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/financial-reporting/financial-reporting-tool',
    },
    component: <FinancialReportingToolForm module="general-ledger" />,
  },
  ...dataSourceSettingsRoutes,
  ...reportSettingsRoutes,
]

export default financialReportRoutes
