export const DocDateValidationBefore = (MonthsBefore: any) => {
  var dateBefore = new Date()

  return dateBefore.setMonth(dateBefore.getMonth() - MonthsBefore)
}

export const DocDateValidationAfter = (MonthsAfter: any) => {
  var dateAfter = new Date()

  return dateAfter.setMonth(dateAfter.getMonth() + MonthsAfter)
}
