import React, { lazy } from 'react'

const ApprovalPolicySubmenu = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/ApprovalPolicy/ApprovalPolicySubmenu'
  ).then(module => ({
    default: module.ApprovalPolicySubmenu,
  }))
)
const PRForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/ApprovalPolicy/PRForm'
  ).then(module => ({
    default: module.PRForm,
  }))
)
const RFQForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/ApprovalPolicy/RFQForm'
  ).then(module => ({
    default: module.RFQForm,
  }))
)
const POListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/ApprovalPolicy/POListing'
  ).then(module => ({
    default: module.POListing,
  }))
)
const POForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/ApprovalPolicy/POForm1'
  ).then(module => ({
    default: module.POForm,
  }))
)
const GPApprovalPolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/approval-policy',
    },
    component: <ApprovalPolicySubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/approval-policy/pr',
    },
    component: <PRForm />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/approval-policy/rfq',
    },
    component: <RFQForm />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/approval-policy/po',
    },
    component: <POListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/company-settings/approval-policy/po/add',
    },
    component: <POForm modePage="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/company-settings/approval-policy/po/edit/:ApprovalPolicyAssignmentID',
    },
    component: <POForm modePage="edit" />,
  },
]

export default GPApprovalPolicyRoutes
