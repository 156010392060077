import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { InputUpload } from '@ifca-root/react-component/src/components/Input/InputUpload'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React from 'react'

interface ISnackBarProps {
  openSnackBar: boolean
  snackBarMessage: string
  setOpenSnackBar?: React.Dispatch<boolean>
  onClickButton?: any
  label?: string
}
interface IExcelInputProps {
  openUpload: boolean
  setOpenUpload: any
  upload: string
  template: any
  onDownloadTemplate: any
  onSelectFile: any
  setUpload: any
  setTemplate: any
  onUploadTemplate: any
  snackBarProps: ISnackBarProps
  showDupErrors?: boolean
  duplicateItems?: any
  errorDia?: boolean
  setErrorDia?: React.Dispatch<boolean>
  resetFileUpload?: any
  GLBudgetExcelYear?: number
  setGLBudgetExcelYear?: any
  handleSelectYear?: any
}

export const GLBudgetExcelInput = (props: IExcelInputProps) => {
  const {
    openUpload,
    setOpenUpload,
    upload,
    onDownloadTemplate,
    onSelectFile,
    setUpload,
    setTemplate,
    onUploadTemplate,
    snackBarProps,
    duplicateItems,
    showDupErrors,
    errorDia,
    setErrorDia,
    resetFileUpload,
    GLBudgetExcelYear,
    setGLBudgetExcelYear,
    handleSelectYear,
  } = props

  const {
    openSnackBar,
    snackBarMessage,
    setOpenSnackBar,
    onClickButton,
    label,
  } = snackBarProps

  return (
    <div>
      {showDupErrors && errorDialog(errorDia, setErrorDia, duplicateItems)}

      <CommonDialog
        fullWidth={true}
        open={openUpload}
        onClose={() => {
          resetFileUpload()
          setUpload('')
          setTemplate([])
          setOpenUpload(false)
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <span
                    className="title c-orange flex-space"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Excel
                  </span>

                  <span
                    className="title c-orange"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      textAlign: 'right',
                    }}
                  >
                    {'Upload'}
                  </span>
                </div>
              </div>
            ),
            title: 'Import Data Template',
          },
          body: () => (
            <>
              {/* <KeyboardDatePicker
                onChange={(date: Date | null) => {
                  handleSelectYear(date)
                }}
                label="Budget Year"
                format="yyyy"
                views={['year']}
                value={new Date(GLBudgetExcelYear, 0, 1)}
                className="custom-date-reservation"
                fullWidth
                style={{
                  zIndex: 40,
                  width: '100%',
                  boxShadow: 'revert',
                  paddingTop: '0px !important',
                }}
                KeyboardButtonProps={{
                  style: {},
                  'aria-label': 'change date',
                }}
                InputProps={{
                  style: {
                    fontSize: '15px',
                    backgroundColor: 'white',
                    color: 'black',
                  },
                  readOnly: true,
                  disableUnderline: true,
                  fullWidth: true,
                }}
              /> */}
              <InputUpload
                accept={'.xlsx, .xls, .cxv'}
                value={upload}
                label="File"
                required
                multiline={true}
                handleUpload={e => onSelectFile(e)}
                className="p-b-20"
              />
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => [
                    setOpenUpload(false),
                    setUpload(''),
                    setTemplate([]),
                    resetFileUpload(),
                  ],
                  variant: 'contained',
                  color: 'primary',
                },
              },
              // {
              //   displayText: 'Download',
              //   props: {
              //     onClick: () => onDownloadTemplate(),
              //     variant: 'contained',
              //     color: 'primary',
              //   },
              // },
              {
                displayText: 'Upload',
                props: {
                  onClick: () => [onUploadTemplate()],
                  variant: 'contained',
                  color: 'primary',
                  disabled: !!!upload,
                },
              },
            ],
          },
        }}
      />

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        setOpen={setOpenSnackBar}
        label={label}
        onClickButton={onClickButton}
      />
    </div>
  )
}

const errorDialog = (
  openErrDialog: boolean,
  setOpenErrDia: React.Dispatch<boolean>,
  duplicateItems: string[]
) => (
  <CommonDialog
    fullWidth={true}
    open={openErrDialog}
    onClose={() => {
      setOpenErrDia(false)
    }}
    sections={{
      header: {
        title: 'Item(s) exists',
      },
      body: () => (
        <>
          <div>
            <span className="mdDesc text-noflow">
              Unable to upload the following item(s).
              <List>
                {duplicateItems?.map((v, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      style={{ height: '10px' }}
                      primary={
                        <>
                          <span className="mdDesc flex-space">{`- ${v}`}</span>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </span>
            <span className="desc text-noflow">
              Please remove duplicated items and try again.
            </span>
          </div>
        </>
      ),
      footer: {
        actions: [
          {
            displayText: 'Close',
            props: {
              onClick: () => {
                setOpenErrDia(false)
              },
              variant: 'contained',
              color: 'primary',
            },
          },
        ],
      },
    }}
  />
)
