import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import Fuse from 'fuse.js'
import {
  useGetAuditAdjustmentbyStatusLazyQuery,
  useGetAuditAdjustmentbyStatusListingLazyQuery,
  useGetJournalProcessingbyStatusLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AuditAdjustmentEntriesList } from './AuditAdjustmentList/AuditAdjustmentEntryList'
import { AuditAdjustmentPostingList } from './AuditAdjustmentList/AuditAdjustmentPostingList'
// import '../AuditAdjustmentEntry/AuditAdjustment.scss'

export const AuditAdjustmentMain = (props: any) => {
  useEffect(() => {
    document.title = 'GL-Audit Adjustment'
  }, [])

  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [TotalLength, setTotalLength] = useState(0)
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    loadAuditAdjustment,
    {
      loading: AuditAdjustmentbyStatusLoading,
      error: AuditAdjustmentbyStatusError,
      data: { getAuditAdjustmentbyStatusListing } = {
        getAuditAdjustmentbyStatusListing: [],
      },
    },
  ] = useGetAuditAdjustmentbyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    // variables: {
    //   CompanyID: CompanyID,
    //   StatusArr: [listStatus ?? 'ACTIVE'],
    // },
    onCompleted: ({ getAuditAdjustmentbyStatusListing }) => {
      if (getAuditAdjustmentbyStatusListing?.Data?.length > 0) {
        setOriginalListing([
          ...filteredList,
          ...getAuditAdjustmentbyStatusListing?.Data,
        ])
        setTotalLength(getAuditAdjustmentbyStatusListing?.TotalLength)
      }
    },
  })

  useEffect(() => {
    setOriginalListing([])
    loadAuditAdjustment({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name !== '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
      },
    })
  }, [listStatus])



  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()



  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (postIDs?.size === 0) return false
    else return true
  }

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadAuditAdjustment({
          variables: {
            CompanyID: CompanyID,
            StatusArr: [listStatus],
            take: 30,
            skip: 0,
            searchValue: change,
          },
        })
      }, 1000)
    )
  }

  return (
    <>
      {UserLoading && <Loading />}
      {AuditAdjustmentbyStatusLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true && listStatus === 'SUBMIT'
            ? setOpenExitConf(true)
            : history.push(`/general-ledger/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }} //<- back button action
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'Audit Adjustment', current: true },
        ]}
        //rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            // handleSearch(e?.target?.value, [
            //   'DocNo',
            //   'Description',
            //   amtNumStr('JournalProcessingItem.DocAmt'),
            //   'DocDateFormatted',
            // ])
            changeDelay(e?.target?.value)
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            loadAuditAdjustment({
              variables: {
                CompanyID: CompanyID,
                StatusArr: [listStatus],
                take: 30,
                skip: 0,
              },
            })
          }}
          isDefaultValue={!!getSearch?.name}
        />

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        // !AuditAdjustmentbyStatusLoading &&
        <AuditAdjustmentEntriesList
          filteredList={filteredList}
          listLoading={AuditAdjustmentbyStatusLoading}
          listStatus={listStatus}
          userList={getUsersByAccountAndSoftware}
          fetchMore={loadAuditAdjustment}
          setOriginalListing={setOriginalListing}
        />
      )}
      {listStatus === 'SUBMIT' && (
        // !AuditAdjustmentbyStatusLoading &&
        <AuditAdjustmentPostingList
          filteredList={filteredList}
          listLoading={AuditAdjustmentbyStatusLoading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
          fetchMore={loadAuditAdjustment}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/general-ledger/${CompanyID}`)
        }}
      />
    </>
  )
}
