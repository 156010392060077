import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import React from 'react'
import { useHistory, useParams } from 'react-router'

export const CashLedgerListingReport = () => {
  const history = useHistory()
  const { CompanyID, BankAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID

  let reporturl =
    'CashLedgerListingReport?accountid=' +
    accountID +
    '&companyid=' +
    CompanyID +
    '&bankaccountid=' +
    BankAccountID

  return (
    <>
      <MainHeader
        onClick={() => history.push(`/digital-reports/general-ledger`)}
        mainBtn="back"
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reporting' },
          { name: 'Cash Ledger Listing', current: true },
        ]}
      />
      <ContentWrapper>
        {ReportViewer({
          url: reporturl,
          host: accountxReportUrl,
          // host: contractReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
