import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetAssignedCompanyQuery,
  useGetCompanyQuery,
  useGetStockCategoryNameLazyQuery,
  useGetStockItemNameLazyQuery,
  useGetWarehouseLazyQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'

interface StockBalanceByDocDateParamsFormProps {
  DocDate: Date
  CompanyID: string
  WarehouseID: string
  StockItemID: string
  StockCategoryID: string
}

interface CompanyOption {
  Name: string
  CompanyID: string
}

export const StockBalanceByDocDateParamsForm = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  const latestCompany = localStorage.getItem('latestCompany')
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('CompanyID is required'),
    DocDate: CommonYupValidation.requireField('Document Date is required'),
  })

  const [selectedWarehouse, setSelectedWarehouse]: any = useState(new Set())
  const [selectedStockItem, setSelectedStockItem]: any = useState(new Set())
  const [selectedStockCategory, setSelectedStockCategory]: any = useState(
    new Set()
  )

  const { handleSubmit, register, errors, control, watch, setValue } = useForm<
    StockBalanceByDocDateParamsFormProps
  >({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
    onCompleted: () => {
      if (latestCompany) {
        loadWarehouse({
          variables: { CompanyID: latestCompany },
        })
        loadStockItem({ variables: { orderByAsc: 'Name' } })
        loadStockCategory({ variables: { orderByAsc: 'Name' } })
      }
    },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadWarehouse({
          variables: { CompanyID: latestCompany },
        })
        loadStockItem({ variables: { orderByAsc: 'Name' } })
        loadStockCategory({ variables: { orderByAsc: 'Name' } })
      }
    },
  })

  const [
    loadWarehouse,
    {
      loading: WarehouseLoading,
      error: WarehouseError,
      data: { getWarehouse } = { getWarehouse: [] },
    },
  ] = useGetWarehouseLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getWarehouse }) => {},
  })

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/digital-reports/inventory-control/stock-balance-by-document-date/generated`,
      state: {
        DocDate: new Date(data.DocDate),
        CompanyID: data?.CompanyID,
        WarehouseID: selectedWarehouse
          ? Array.from(selectedWarehouse)
          : undefined,
        StockItemID: selectedStockItem
          ? Array.from(selectedStockItem)
          : undefined,
        StockCategoryID: selectedStockCategory
          ? Array.from(selectedStockCategory)
          : undefined,
      },
    })
  }

  const [
    loadStockItem,
    {
      loading: StockItemNameLoading,
      error: StockItemNameError,
      data: { getStockItem } = { getStockItem: [] },
    },
  ] = useGetStockItemNameLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    loadStockCategory,
    {
      loading: StockCategoryNameLoading,
      error: StockCategoryNameError,
      data: { getStockCategory } = { getStockCategory: [] },
    },
  ] = useGetStockCategoryNameLazyQuery({
    fetchPolicy: 'network-only',
  })

  const handleCheckboxWarehouseChange = (event: any) => {
    let Warehouse = selectedWarehouse

    if (event?.target?.checked) {
      Warehouse.add(event?.target?.value)
    } else {
      Warehouse.delete(event?.target?.value)
    }
    setSelectedWarehouse(new Set(Warehouse))
  }

  const handleCheckboxStockItemChange = (event: any) => {
    let StockItem = selectedStockItem

    if (event?.target?.checked) {
      StockItem.add(event?.target?.value)
    } else {
      StockItem.delete(event?.target?.value)
    }
    setSelectedStockItem(new Set(StockItem))
  }

  const handleCheckboxStockCategoryChange = (event: any) => {
    let StockCategory = selectedStockCategory

    if (event?.target?.checked) {
      StockCategory.add(event?.target?.value)
    } else {
      StockCategory.delete(event?.target?.value)
    }
    setSelectedStockCategory(new Set(StockCategory))
  }

  return (
    <>
      {companyLoading && <Loading />}
      {WarehouseLoading && <Loading />}
      {StockItemNameLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}
      {StockCategoryNameLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/inventory-control`)}
        smTitle={'Inventory Control'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Stock Balance By Document Date', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {!CompanyAssignedLoading && !companyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const options: CompanyOption[] =
                  !!user?.superUser === false
                    ? getAssignedCompanyByUser
                    : getCompany
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]

                return (
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option: CompanyOption) => option.Name}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('CompanyID', newValue?.CompanyID)
                      loadWarehouse({
                        variables: { CompanyID: newValue?.CompanyID },
                      })
                      setValue('WarhouseID', undefined)
                      loadStockItem({ variables: { orderByAsc: 'Name' } })
                      loadStockCategory({ variables: { orderByAsc: 'Name' } })
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.Name}</span>
                    }}
                    defaultValue={defVal}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            required
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              required
              defaultValue={latestCompany}
            />
          )}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Document Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('DocDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.DocDate ?? new Date()}
              helperText={errors?.DocDate?.message}
              error={errors?.DocDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getWarehouse || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.WarehouseID)
                    })

                    setSelectedWarehouse(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.WarehouseID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxWarehouseChange(event)
                        }}
                      />
                      {option?.Name}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="Location"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="WarehouseID"
            label="Location"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getStockCategory || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.StockCategoryID)
                    })

                    setSelectedStockCategory(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.StockCategoryID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxStockCategoryChange(event)
                        }}
                      />
                      {option?.Name}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="Stock Category"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="StockCategoryID"
            label="Stock Category"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getStockItem || []}
                  getOptionLabel={(option: any) => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.StockItemID)
                    })

                    setSelectedStockItem(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.StockItemID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxStockItemChange(event)
                        }}
                      />
                      {option?.Name}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="Stock Item"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="StockItemID"
            label="Stock Item"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
