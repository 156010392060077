import {
  AcctPermission,
  useGetApAdvanceLazyQuery,
  useGetApAllocationDocumentLazyQuery,
  useGetApCreditNoteLazyQuery,
  useGetApCreditorCreditNoteLazyQuery,
  useGetApPaymentLazyQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'

export const useAPAllocationData: any = ({
  apSubmenu,
  CompanyID,
  ID,
  append,
}) => {
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const [allocatedObj, setAllocatedObj] = useState({})
  const [DocumentID, setDocumentID] = useState('')
  const [creditorID, setCreditorID] = useState('')
  const [documentAmt, setDocumentAmt] = useState(0)
  const [documentDetail, setDocumentDetail] = useState({})

  //----------AP Queries----------//
  const [
    loadAllocationDocument,
    { loading: allocationDocumentLoading },
  ] = useGetApAllocationDocumentLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: ({ getAllocationDocument }) => {
      let arr = []
      if (getAllocationDocument?.length > 0) {
        getAllocationDocument?.map((alloc, index) => {
          arr.push({
            AllocationID: alloc?.AllocationID,
            CanAllocate: alloc?.CanAllocate,
            CreditOrDebit: alloc?.CreditOrDebit,
            DocDate: alloc?.DocDate,
            DocNo: alloc?.DocNo,
            Description: alloc?.Description,
            // for previous allocated amt on the same doc
            AllocationAmt: Number(
              allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0
            ),
            // balance amount + previous allocated amt on the same doc
            BalanceAmt:
              Number(alloc?.BalanceAmt).toFixed(2) +
              Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0),
            // credit or debit id
            [`${alloc?.CreditOrDebit}ID`]: alloc[`${alloc?.CreditOrDebit}ID`],
            // credit or debit ref table
            [`${alloc?.CreditOrDebit}RefTable`]: alloc[
              `${alloc?.CreditOrDebit}RefTable`
            ],
            // credit or debit doc no
            [`${alloc?.CreditOrDebit}DocNo`]: alloc[
              `${alloc?.CreditOrDebit}DocNo`
            ],
          })

          return alloc
        })
        append(arr)
      }
    },
  })

  const [
    loadApAdvance,
    {
      loading: ApAdvanceLoading,
      called: ApAdvanceCalled,
      data: { getAPAdvance } = { getAPAdvance: [] },
    },
  ] = useGetApAdvanceLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPAdvance }) => {
      if (getAPAdvance?.length > 0) {
        setCreditorID(getAPAdvance[0]?.CreditorAccountID)
        setDocumentID(getAPAdvance[0]?.AdvanceID)
        setDocumentAmt(getAPAdvance[0]?.DocAmt)
        setDocumentDetail({ ...getAPAdvance[0] })
      }
    },
  })

  const [
    loadApCreditNote,
    {
      loading: ApCreditNoteLoading,
      called: ApCreditNoteCalled,
      data: { getAPCreditNote } = { getAPCreditNote: [] },
    },
  ] = useGetApCreditNoteLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPCreditNote }) => {
      if (getAPCreditNote?.length > 0) {
        setCreditorID(getAPCreditNote[0]?.CreditorAccountID)
        setDocumentID(getAPCreditNote[0]?.CreditNoteID)
        setDocumentAmt(getAPCreditNote[0]?.DocAmt)
        setDocumentDetail({ ...getAPCreditNote[0] })
      }
    },
  })

  const [
    loadApCreditorCreditNote,
    {
      loading: ApCreditorCreditNoteLoading,
      called: ApCreditorCreditNoteCalled,
      data: { getAPCreditorCreditNote } = { getAPCreditorCreditNote: [] },
    },
  ] = useGetApCreditorCreditNoteLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPCreditorCreditNote }) => {
      if (getAPCreditorCreditNote?.length > 0) {
        setCreditorID(getAPCreditorCreditNote[0]?.CreditorAccountID)
        setDocumentID(getAPCreditorCreditNote[0]?.CreditorCreditNoteID)
        setDocumentAmt(getAPCreditorCreditNote[0]?.DocAmt)
        setDocumentDetail({ ...getAPCreditorCreditNote[0] })
      }
    },
  })

  const [
    loadAPPayment,
    {
      loading: getAPPaymentLoading,
      called: ApPaymentCalled,
      data: { getAPPayment } = { getAPPayment: [] },
    },
  ] = useGetApPaymentLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPPayment }) => {
      if (getAPPayment?.length > 0) {
        setCreditorID(getAPPayment[0]?.CreditorAccountID)
        setDocumentID(getAPPayment[0]?.PaymentID)
        setDocumentAmt(getAPPayment[0]?.DocAmt)
        setDocumentDetail({ ...getAPPayment[0] })
      }
    },
  })

  let loading,
    allocLoading,
    called,
    docRefTable,
    subMenuName,
    primaryKey,
    allocPermissionCheck

  switch (apSubmenu) {
    case 'advance':
      allocLoading = allocationDocumentLoading
      loading = ApAdvanceLoading
      called = ApAdvanceCalled
      docRefTable = 'AP_Advance'
      subMenuName = 'Advance'
      primaryKey = 'AdvanceID'
      allocPermissionCheck = AcctPermission.AccPayableAdvancesAllocation
      break
    case 'credit-note':
      allocLoading = allocationDocumentLoading
      loading = ApCreditNoteLoading
      called = ApCreditNoteCalled
      docRefTable = 'AP_CreditNote'
      subMenuName = 'CreditNote'
      primaryKey = 'CreditNoteID'
      allocPermissionCheck = AcctPermission.AccPayableCreditNotesUpdate
      break
    case 'creditor-credit-note':
      allocLoading = allocationDocumentLoading
      loading = ApCreditorCreditNoteLoading
      called = ApCreditorCreditNoteCalled
      docRefTable = 'AP_CreditorCreditNote'
      subMenuName = 'CreditorCreditNote'
      primaryKey = 'CreditorCreditNoteID'
      allocPermissionCheck = AcctPermission.AccPayableCreditNotesUpdate
      break
    case 'payment':
      allocLoading = allocationDocumentLoading
      loading = getAPPaymentLoading
      called = ApPaymentCalled
      docRefTable = 'AP_Payment'
      subMenuName = 'Payment'
      primaryKey = 'PaymentID'
      allocPermissionCheck = AcctPermission.AccPayablePaymentAllocation
      break
  }

  const loadData = () => {
    switch (apSubmenu) {
      case 'advance':
        loadApAdvance({
          variables: { CompanyID: CompanyID, AdvanceID: ID },
        })
        break
      case 'credit-note':
        loadApCreditNote({
          variables: { CompanyID: CompanyID, CreditNoteID: ID },
        })
        break
      case 'creditor-credit-note':
        loadApCreditorCreditNote({
          variables: { CompanyID: CompanyID, CreditorCreditNoteID: ID },
        })
        break
      case 'payment':
        loadAPPayment({
          variables: { CompanyID: CompanyID, PaymentID: ID },
        })
        break
    }
  }
  const loadAllocData = () => {
    switch (apSubmenu) {
      case 'advance':
        loadAllocationDocument({
          variables: {
            entityID: [ID],
            companyID: CompanyID,
            refTable: 'AP_Advance',
            creditorDebtorID: creditorID,
          },
        })
        break
      case 'credit-note':
        loadAllocationDocument({
          variables: {
            entityID: [ID],
            companyID: CompanyID,
            refTable: 'AP_CreditNote',
            creditorDebtorID: creditorID,
          },
        })
        break
      case 'creditor-credit-note':
        loadAllocationDocument({
          variables: {
            entityID: [ID],
            companyID: CompanyID,
            refTable: 'AP_CreditorCreditNote',
            creditorDebtorID: creditorID,
          },
        })
        break
      case 'payment':
        loadAllocationDocument({
          variables: {
            entityID: [ID],
            companyID: CompanyID,
            refTable: 'AP_Payment',
            creditorDebtorID: creditorID,
          },
        })
        break
    }
  }

  useEffect(() => {
    loadData()
  }, [apSubmenu])

  useEffect(() => {
    if (!!creditorID) {
      loadAllocData()
    }
  }, [creditorID])

  return {
    loading,
    allocLoading,
    called,
    allocatedObj,
    subMenuName,
    primaryKey,
    allocPermissionCheck,
    creditorID,
    documentAmt,
    documentDetail,
    docRefTable,
    DocumentID,
  }
}
