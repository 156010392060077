import React from 'react'
import { APBatchPaymentDetail } from './APBatchPaymentDetail/APBatchPaymentDetail'
import { APBatchPaymentFormV2 } from './APBatchPaymentForm/APBatchPaymentFormV2'
import { APBatchPaymentPostingList } from './APBatchPaymentList/APBatchPaymentPostingList'
import { APBatchPaymentMain } from './APBatchPaymentMain'
import { PDFPreviewV2 } from 'containers/ARModule/Hooks/PDFPreviewV2'

export const batchPaymentRoutes = [
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/batch-payment`,
    },
    component: <APBatchPaymentMain />,
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/batch-payment/:BatchPaymentID/submit`,
    },
    component: <APBatchPaymentPostingList />,
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/batch-payment/:BatchPaymentID/detail`,
    },
    component: <APBatchPaymentDetail />,
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/:docType/:BatchPaymentID/:PaymentID/preview`,
    },
    component: <PDFPreviewV2 accountType="account-payable" docType="payment" />,
  },

  ///////////////
  //// FORMS ////
  ///////////////

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/batch-payment/add',
    },
    component: <APBatchPaymentFormV2 formMode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/batch-payment/:BatchPaymentID/edit',
    },
    component: <APBatchPaymentFormV2 formMode="edit" />,
  },
]
