import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Tune } from '@material-ui/icons'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetPaymentDocument,
  useDeletePaymentMutation,
  useGetBankAccountQuery,
  useGetPaymentQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const PaymentListingGS = (props: any) => {
  let history = useHistory()
  const { CompanyID, BankAccountID, PaymentID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  // Query for Payment

  const {
    loading: paymentLoading,
    error: paymentError,
    data: { getPayment } = { getPayment: [] },
  } = useGetPaymentQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  // Query for Bank Account

  const {
    loading: bankAccountLoading,
    error: bankAccountError,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: { BankAccountID: BankAccountID },
  })

  // Search Function

  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()

  useEffect(() => {
    if (getPayment && getPayment.length > 0) {
      setOriginalListing(getPayment)
    }
  }, [getPayment])

  // Delete

  const [deletePaymentList, setDeletePaymentList] = useState(false)

  const handleClickDeletePayment = () => {
    setDeletePaymentList(true)
    handleClose()
  }

  const [
    deletePayment,
    { loading: deletePaymentLoading, error: deletePaymentError },
  ] = useDeletePaymentMutation({
    onError: error => {
      if (error.message.includes('FK_')) {
        console.log('error')
      }
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())

      console.log('data', data)
      setTimeout(() => {
        history.push({
          pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment`,
        })
      }, 500)
    },
  })

  const handleDelete = data => {
    deletePayment({
      variables: {
        PaymentID: menu?.obj?.PaymentID,
      },
      refetchQueries: [
        {
          query: GetPaymentDocument,
          variables: { CompanyID: CompanyID },
        },
      ],
    })
    setDeletePaymentList(false)
  }

  return (
    <>
      {paymentLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(
            `/cash-book/general-setting/${CompanyID}/approval-policy-cb`
          )
        } //<- back button action
        smTitle={'Cash Book'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Payment' },
          { name: 'Approval Policy', current: true },
        ]}
        // currency={`MYR`}
      />

      <DynamicSubHeader
        title={'Payment'}
        // infoLine={
        //   <>
        //     <span className="xxTitle">{'Account No.'}&nbsp;:&nbsp;</span>
        //     <span className="xxTitle">{getBankAccount[0]?.AccountNo}</span>
        //   </>
        // }
      />

      <SearchHeader
        title="Payment Listing"
        value={`${0}`}
        search
        fixed
        onChangeAction={e => {
          handleSearch(e?.target?.value, ['name'])
        }}
        onCloseAction={() => handleSearch('', [])}
        dynamicInfo
        option={{
          icon: <Tune />,
          //onClick: () => setFilter(true),
        }}
      />

      {/* <ContentWrapper dynamicInfoSearch>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <>
              {paymentLoading && <Loading />}
              <EmptyList
                title="No Record found"
                subtitle="Add a new record now."
              />
            </>
          ) : (
            filteredList?.map((el, index) => (
              <ListItem selected={el.ReceiptStatus === 'PENDING_CLOSE'}>
                <ListItemText
                  primary={
                    <>
                      <span className="mdDesc date-width">
                        {formatDate(el.DocDate)}
                      </span>
                      <span className="xsTitle flex-space">{el.DocRef}</span>
                      <span className="xxTitle">
                        
                        <span className="color-blue">
                          <IconText
                            icon={
                              <img
                                src={BlueDollarIcon}
                                style={{ width: '12px' }}
                              />
                            }
                            font="xxTitle"
                            children={amtStr(el.BaseAmt)}
                          ></IconText>
                        </span>
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      <span className="desc flex-space">
                        <span>{el.Description}</span>
                      </span>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    aria-controls="menu-list"
                    aria-haspopup="true"
                    onClick={e =>
                      el.ReceiptStatus === 'PENDING_CLOSE'
                        ? ''
                        : handleClick(e, el?.ID, index, el)
                    }
                  >
                    <MoreVert />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          )}
        </List>
        <FloatButton
          onClick={() =>
            history.push(
              `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/add`
            )
          }
          // disabled={
          //   handlePermDisabled({
          //     contractID: '',
          //     permEnum: ContPermission.ContractMgmtCreate,
          //   })
          //   // permission?.hasOwnProperty(ContractPermission.HomeContractCreate) ===
          //   //   false && !user?.superUser
          // }
        />
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem>
            <span
              onClick={() =>
                history.push({
                  pathname: `/cash-book/${CompanyID}/submenu/${menu.obj.BankAccountID}/payment/${menu.obj.PaymentID}/edit`,
                  state: menu.obj,
                })
              }
            >
              Edit
            </span>
          </MenuItem>

          <MenuItem onClick={handleClickDeletePayment}>
            <span className="">Delete</span>
          </MenuItem>
        </Menu>
      </ContentWrapper> */}

      <FloatButton
      // onClick={() =>
      //   history.push(
      //     `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/add`
      //   )
      // }
      // disabled={
      //   handlePermDisabled({
      //     contractID: '',
      //     permEnum: ContPermission.ContractMgmtCreate,
      //   })
      //   // permission?.hasOwnProperty(ContractPermission.HomeContractCreate) ===
      //   //   false && !user?.superUser
      // }
      />

      <CommonDialog
        fullWidth={true}
        open={deletePaymentList}
        onClose={() => setDeletePaymentList(false)}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <div className="session">
                    <div className="flex session">
                      <div
                        className="dialog-title c-orange"
                        style={{ fontSize: '12px', fontWeight: 'bold' }}
                      >
                        {'Payment'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="infoline-content">
                  {' '}
                  <div className="xsTitle">{menu?.obj?.DocRef}</div>
                  <div className="flex-space"></div>
                  <div className="xsTitle rightText">Delete</div>
                </div>
              </div>
            ),
          },
          body: () => (
            <div className="content-wrap full">
              <span className="mdDesc full">Confirm to delete?</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setDeletePaymentList(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleDelete(menu?.obj?.PaymentID),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
