import theme from '@ifca-root/react-component/src/assets/theme'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import Fuse from 'fuse.js'
import {
  useGetJournalQuery,
  useGetRecurringJournalQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const RecurringJournalJournalListing = (props: any) => {
  const getStorage = localStorage?.getItem('journalFilter')
  const getSearch = localStorage?.getItem('searchFilter')
  const { CompanyID, RecurringJournalID }: any = useParams()
  let location = useLocation()
  let history = useHistory()
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { menu, handleClick }: any = useMenuOption()

  const {
    loading: JournalLoading,
    error: JournalError,
    data: { getJournal } = { getJournal: [] },
  } = useGetJournalQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RefRecordID: RecurringJournalID },
  })

  const {
    loading: RecurringJournalLoading,
    error: RecurringJournalError,
    data: { getRecurringJournal } = { getRecurringJournal: [] },
  } = useGetRecurringJournalQuery({
    variables: { RecurringJournalID: RecurringJournalID },
  })

  useEffect(() => {
    if (getJournal && getJournal?.length > 0) {
      setOriginalListing(getJournal, v => {
        v['JournalDateFormatted'] = formatDate(v.JournalDate)
      })
    }
  }, [getJournal])

  useEffect(() => {
    if (getStorage && !!originalList) {
      const keys = [
        'JournalNo',
        'RefNo',
        'Description',
        'JournalDateFormatted',
        'JournalItem.DocAmt',
      ]
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getStorage)
      const val = result?.map(x => x.item)
      if (val.length !== 0) {
        handleSearch(getStorage + '', keys)
      }
    }
  }, [getStorage, originalList])

  return (
    <>
      {RecurringJournalLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          localStorage.removeItem(`journalFilter`)
          history.push({
            pathname: `/general-ledger/${CompanyID}/recurring-journal`,
            state: menu?.obj,
          })
        }}
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Main Menu' },
          { name: 'Recurring Journal', current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          <div
            style={{
              color: 'ff9800',
              // '#001088'
            }}
          >
            {formatDate(getRecurringJournal[0]?.createdTs)}
          </div>
        }
        rightText={
          <>
            <span style={{ paddingRight: '0px', color: '#ff9800' }}>
              {amtStr(
                getRecurringJournal[0]?.RecurringJournalItem?.filter(
                  item => item?.DocAmt > 0
                )?.reduce((x, y) => x + y?.DocAmt, 0)
              )}
            </span>
          </>
        }
        infoLineStyle={{ display: 'flex', flex: '1 1' }}
        infoLine={
          <>
            <span
              className="xxTitle highlight-text flex-space"
              style={{ color: 'black' }}
            >
              {`Recurring Journal ${getRecurringJournal[0]?.NoOfRecurrance}`}
            </span>
            {/* <span className="xxTitle highlight-text">
              <img
                src={ApprovalLog}
                style={{
                  width: '12px',
                  marginBottom: '-3px',
                  marginRight: '3px',
                }}
                onClick={e => {
                  setWorkFlow(true)
                  handleClick2(e, getRecurringJournal[0]?.WorkFlow)
                }}
              />
              <span
                className="desc"
                style={{
                  color: handleStatusColor(
                    getRecurringJournal[0]?.ApprovalStatus
                  ),
                  fontWeight: 'bold',
                }}
              >
                {journalStatusText(getRecurringJournal[0]?.ApprovalStatus)}
              </span>
            </span> */}
          </>
        }
      />

      <SearchHeader
        title={'Recurring Journal Listing'}
        value={`${filteredList?.length}`}
        search
        multiDynamicInfo
        fixed
        onChangeAction={e => {
          handleSearch(e?.target?.value, [
            'DocNo',
            'RefNo',
            'Description',
            'JournalNo',
            'JournalDateFormatted',
            'JournalItem.DocAmt',
          ])
          localStorage.setItem('journalFilter', e?.target?.value)
          localStorage.setItem('searchFilter', getSearch)
        }}
        onCloseAction={() => handleSearch('', [])}
        isDefaultValue={!!getStorage}
        defaultValue={getStorage ? getStorage : ''}
      />

      <ContentWrapper
        float
        footer
        style={{
          paddingTop: '100px',
        }}
      >
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList?.map((el, index) => {
              return (
                <ListItem>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <span
                          className="flex-space mdLabel click-text"
                          onClick={() => {
                            history.push({
                              pathname: `/general-ledger/${CompanyID}/recurring-journal/${RecurringJournalID}/${el?.JournalID}/preview`,
                              state: {
                                ...el,
                                mode: 'detail',
                                JournalNo: el?.JournalNo,
                              },
                            })
                          }}
                        >
                          {el?.JournalNo ?? el?.RefNo}
                        </span>
                        <span className="xxTitle">
                          <span className="mdDesc date-width">
                            {formatDate(el?.JournalDate ?? el?.createdTs)}
                          </span>
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <span className="desc flex-space">
                          {/* <IconItem image={defecticon} imageType="svg" badgeType="default" /> */}

                          <span className="fw-medium">{el?.Description}</span>
                        </span>
                        <span className="xxTitle">
                          <span className="highlight-text">
                            {amtStr(
                              getRecurringJournal[0]?.RecurringJournalItem?.filter(
                                item => item?.DocAmt > 0
                              )?.reduce((x, y) => x + y?.DocAmt, 0)
                            )}
                          </span>
                        </span>
                      </>
                    }
                  />

                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: `/general-ledger/${CompanyID}/recurring-journal/${RecurringJournalID}/${el?.JournalID}/journal-Detail`,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          )}
        </List>
      </ContentWrapper>
    </>
  )
}
