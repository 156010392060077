import React, { lazy } from 'react'

const CurrencyExchangeListing = lazy(() =>
  import('./CurrencyExchangeListing').then(module => ({
    default: module.CurrencyExchangeListing,
  }))
)

const currencyExchangeRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/currency-exchange',
    },
    component: <CurrencyExchangeListing />,
  },
]

export default currencyExchangeRoutes
