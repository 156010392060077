import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import React, { useState } from 'react'

export const GLList = (props: any) => {
  // const { docDate, docNo, description, dueDate, reminderAmt } = props;
  const {
    data,
    docType,
    getCostCentre,
    threshold,
    allData,
    page,
    pageLength,
    currencyCode,
  } = props
  // console.log(docType, 'dataa') not deleting this cause want to check the data 11/8/2023

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  const [thresholdChange, setThresholdChange] = useState(false)

  let localThreshold = threshold

  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 9
  }

  ///// Table header style
  const thleftmostcode = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as const,
    width: '15%',
    backgroundColor: '#e7e6e6',
    paddingLeft: '8px',
    paddingTop: '4px',
    paddingBottom: '4px',
  }

  const thcode = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as const,
    width: '8%',
    backgroundColor: '#e7e6e6',
  }

  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as const,
    backgroundColor: '#e7e6e6',
    width: '50%',
  }

  const thamt = {
    borderBottom: '0.5px  solid',
    textAlign: 'right' as const,
    width: '15%',
    backgroundColor: '#e7e6e6',
    paddingRight: '8px',
  }

  // Table data style
  const tdleftmostcode = {
    textAlign: 'left' as const,
    width: '15%',
    paddingLeft: '8px',
    verticalAlign: 'top',
  }

  const tdcode = {
    textAlign: 'left' as const,
    width: '8%',
    verticalAlign: 'top',
  }

  const tdleft = {
    textAlign: 'left' as const,
    width: '50%',
  }

  const tdamt = {
    textAlign: 'right' as const,
    paddingRight: '8px',
    width: '15%',
    verticalAlign: 'top',
  }

  // Footer style
  const footertitle = {
    textAlign: 'right' as const,
  }

  const footeramt = {
    width: '15%' as const,
    textAlign: 'right' as const,
    paddingRight: '8px',
  }

  let itemName
  let num = -1

  switch (docType) {
    case 'journal-processing':
      itemName = 'JournalProcessingItem'
      break
    case 'recurring-journal':
      itemName = 'RecurringJournalItem'
      break
    case 'audit-adjustment':
      itemName = 'AuditAdjustmentItem'
      break
  }

  // ////// Line Under Amount //////
  let debitAmount = allData?.[itemName]?.reduce((total, currentValue) => {
    if (parseFloat(currentValue?.DocAmt) > 0)
      total = total + parseFloat(currentValue?.DocAmt)
    else total = total + 0

    return total
  }, 0)

  let creditAmount = allData?.[itemName]?.reduce((total, currentValue) => {
    if (parseFloat(currentValue?.DocAmt) < 0)
      total = total + parseFloat(currentValue?.DocAmt)
    else total = total + 0

    return total
  }, 0)

  return (
    <>
      <div>
        <table
          style={{
            marginTop: '5px',
            marginBottom: '20px',
            width: '100%',
            fontSize,
            borderSpacing: width < 769 ? '0 0.25em' : '0 0.5em',
          }}
        >
          <tr>
            <th style={thleftmostcode}>Account Code</th>
            <th style={thcode}>Dept</th>
            <th style={thleft}>Remark</th>
            <th style={thamt}>
              Debit Amt
              <br />
            </th>
            <th style={thamt}>
              Credit Amt
              <br />
            </th>
          </tr>
          {data?.[itemName]?.map((v, i) => {
            if (i > threshold && thresholdChange === false) {
              localThreshold += 5
              setThresholdChange(true)
            }

            return (
              <>
                <tr
                  className={
                    i !== 0 && num % localThreshold === 0
                      ? 'page-break'
                      : 'avoid'
                  }
                >
                  <td style={tdleftmostcode}>{v?.MasterCOA?.Code}</td>
                  <td style={tdcode}>
                    {v?.CostCentre?.Code ??
                      getCostCentre
                        ?.filter(x => x?.CostCentreID === v?.CostCentreID)
                        ?.map(x => {
                          return x?.Code
                        })}
                  </td>
                  <td style={tdleft}>
                    <span>
                      {v?.MasterCOA?.Name}
                      <br />
                      {v?.Remark}
                    </span>
                  </td>
                  <td style={tdamt}>
                    {v?.DocAmt > 0 ? amtStr(v?.DocAmt) : null}
                  </td>
                  <td style={tdamt}>
                    {v?.DocAmt < 0 ? amtStr(v?.DocAmt).substring(1) : null}
                  </td>
                </tr>
              </>
            )
          })}
        </table>
      </div>

      {page === pageLength - 1 && (
        <div>
          <table
            style={{
              marginTop: '20px',
              width: '100%',
              fontSize,
              borderTop: '0.5px solid black',
            }}
          >
            <tr>
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '15%',
                  paddingLeft: '8px',
                }}
              >
                Total Lines :
              </th>
              <th style={{ fontWeight: 'normal', textAlign: 'left' }}>
                {allData?.[itemName]?.length}
              </th>
              <th style={footertitle}>Total : </th>
              <th style={footeramt}>{amtStr(debitAmount)}</th>
              <th style={footeramt}>{amtStr(creditAmount).substring(1)}</th>
            </tr>
          </table>
        </div>
      )}
    </>
  )
}

export default GLList
