import React, { lazy } from 'react'

const GeneralApprovalPolicySubmenu = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/GeneralApprovalPolicySubmenu'
  ).then(module => ({
    default: module.GeneralApprovalPolicySubmenu,
  }))
)

//Comment for future enhancements.
// const ApprovalTypeForm = lazy(() =>
//   import(
//     '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/ApprovalPolicy/ApprovalTypeForm'
//   ).then(module => ({
//     default: module.ApprovalTypeForm,
//   }))
// )

const PRForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/ApprovalPolicy/PR/PRForm'
  ).then(module => ({
    default: module.PRForm,
  }))
)

const RFQForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/ApprovalPolicy/RFQ/RFQForm'
  ).then(module => ({
    default: module.PRForm,
  }))
)

const POListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/ApprovalPolicy/PO/POListing'
  ).then(module => ({
    default: module.POListing,
  }))
)

const ApprovalPolicyPOForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/PolicySettings/GeneralApprovalPolicy/ApprovalPolicy/ApprovalPolicyPOForm'
  ).then(module => ({ default: module.ApprovalPolicyPOForm }))
)

const POForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/ApprovalPolicy/PO/POForm'
  ).then(module => ({
    default: module.POForm,
  }))
)

const generalApprovalRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/approval-policy',
    },
    component: <GeneralApprovalPolicySubmenu />,
  },
  //Comment for future enhancements.
  // {
  //   props: {
  //     exact: true,
  //     path: '/general-purchase/general-setting/approval-policy/approval-type',
  //   },
  //   component: <ApprovalTypeForm />,
  // },
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/approval-policy/pr',
    },
    component: <PRForm />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/general-setting/approval-policy/rfq`,
    },
    component: <RFQForm />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/general-setting/approval-policy/po`,
    },
    component: <ApprovalPolicyPOForm />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/general-setting/approval-policy/po/add`,
    },
    component: <POForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path: `/:mode/general-purchase/general-setting/approval-policy/:ApprovalPolicyID/po-form/edit`,
    },
    component: <POForm mode="edit" />,
  },
]

export default generalApprovalRoutes
