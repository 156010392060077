// STRING CONVERTER FOR CAPITALIZATION
export const toTitleCase = (str: any) => {
  return str?.replace(/\w\S*/g, function(txt: any) {
    return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase()
  })
}

export const toProperCase = (str: any) => {
  str = str?.toLowerCase()?.split(' ')
  for (var i = 0; i < str?.length; i++) {
    str[i] = str[i]?.charAt(0)?.toUpperCase() + str[i]?.slice(1)
  }
  return str?.join(' ')
}

export const toCapitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s?.slice(1)?.slice(1, -1)
}
