import React, { lazy } from 'react'

const DeliveryLocationListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/DeliveryLocation/DeliveryLocationListing'
  ).then(module => ({
    default: module.DeliveryLocationListing,
  }))
)

const GPDeliveryLocationRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/delivery-location',
    },
    component: <DeliveryLocationListing />,
  },
]

export default GPDeliveryLocationRoutes
