import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import { PDFPreviewV2 } from 'containers/ARModule/Hooks/PDFPreviewV2'
import { APAllocation } from '../APAllocation/APAllocation'
import { APDetail } from '../APDetail/APDetail'
import { APMain } from '../APMain'
import { APCreditNoteDetail } from './APCreditNoteDetail/APCreditNoteDetail'
import { CreditNoteFormWithAllocation } from './APCreditNoteForm/APCreditNoteFormWithAllocation'
import React from 'react'

const creditNoteRoute = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/credit-note/filter',
    },
    component: (
      <AdvancedFilter
        mode="credit-note"
        app="account-payable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/credit-note',
    },
    component: (
      <APMain
        apSubmenu="credit-note"
        routeSegments="Credit Note"
        accountType="account-payable"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/creditor-credit-note',
    },
    component: (
      <APMain
        apSubmenu="creditor-credit-note"
        routeSegments="Credit Note from Creditor"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/credit-note/:DocumentID/detail',
    },
    component: (
      <APDetail
        apSubmenu="credit-note"
        routeSegments="Credit Note"
        accountType="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-credit-note/:DocumentID/detail',
    },
    component: (
      <APDetail
        apSubmenu="creditor-credit-note"
        routeSegments="Credit Note from Creditor"
        accountType="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/credit-note/:CreditNoteID/detail/workdesk',
    },
    component: <APCreditNoteDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/credit-note/:CreditNoteID/detail/workdesk-history',
    },
    component: <APCreditNoteDetail mode="workdesk-history" />,
  },

  /* -------------------------------------------- */
  /*                 PREVIEW PDF                  */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/credit-note/:CreditNoteID/preview`,
    },
    component: (
      <PDFPreviewV2 accountType="account-payable" docType="credit-note" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/creditor-credit-note/:ID/preview`,
    },
    component: (
      <PDFPreviewV2
        accountType="account-payable"
        docType="creditor-credit-note"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                ALLOCATION                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/credit-note/:ID/allocation',
    },
    component: (
      <APAllocation
        apSubmenu="credit-note"
        routeSegments="Credit Note"
        accountType="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/creditor-credit-note/:ID/allocation',
    },
    component: (
      <APAllocation
        apSubmenu="creditor-credit-note"
        routeSegments="Credit Note from Creditor"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/credit-note/add',
    },
    component: (
      <CreditNoteFormWithAllocation
        // <APCreditNoteDialogForm
        docType="credit-note" // formMode="add" type="CREDIT_NOTE"
        accountType="account-payable"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/creditor-credit-note/add',
    },
    component: (
      <CreditNoteFormWithAllocation
        // <APCreditNoteDialogForm
        // formMode="add" type="CREDIT_NOTE"
        docType="creditor-credit-note"
        accountType="account-payable"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/credit-note/:ID/edit',
    },
    component: (
      <CreditNoteFormWithAllocation
        docType="credit-note" // <APCreditNoteDialogForm
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/creditor-credit-note/:ID/edit',
    },
    component: (
      <CreditNoteFormWithAllocation
        docType="creditor-credit-note" // <APCreditNoteDialogForm
        // <APCreditNoteDialogForm
        formMode="edit"
      />
    ),
  },
]

export default creditNoteRoute
