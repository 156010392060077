import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Grid, TextField } from '@material-ui/core'
import BlueDollar from 'assets/icons/Money/blue-dollar.svg'
import {
  AccountType,
  RecordStatus,
  useGetBudgetQuery,
  useGetCompanyQuery,
} from 'generated/graphql'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useParams } from 'react-router'

interface BudgetSetupProps {
  AccountID: string
  RecordStatus: string
  BudgetID: string
  CompanyID: string
  Fyear: number
  Version: string
  Description: string
  IsDefault: boolean
  BudgetItem: any
}

export const BudgetSetupPeriodForm = (props: any) => {
  let history = useHistory()
  const { CompanyID, BudgetItemID, BudgetItemCostCentreID }: any = useParams()
  const { mode } = props
  //const editData = location?.state as any
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const budgetItemCC = JSON.parse(localStorage.getItem('budgetCC'))
  const currBudgetItemCC = budgetItemCC?.find(
    v => v.BudgetItemID === BudgetItemID
  )
  const budgetItemPeriod = JSON.parse(localStorage.getItem('budgetPeriod'))
  const currBudgetItemPeriod = budgetItemPeriod?.find(
    v => v.BudgetItemCostCentreID === BudgetItemCostCentreID
  )

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RecordStatus: RecordStatus.Active },
  })

  const {
    loading: BudgetLoading,
    error: BudgetError,
    data: { getBudget: getBudget } = { getBudget: [] },
  } = useGetBudgetQuery({
    fetchPolicy: 'network-only',
    variables: {},
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<BudgetSetupProps>({
    defaultValues: {},
    mode: 'all',
  })

  const E = 12

  return (
    <>
      {CompanyLoading && <Loading />}
      {BudgetLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(
            `/general-ledger/${CompanyID}/company-setting/budget/add/${BudgetItemID}/budgetcostcentre`
          )
        }
        smTitle={'Company Setting'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Company Setting' },
          { name: 'Company' },
          { name: 'Budget' },
          { name: 'Period Budget', current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          <span style={{ color: 'darkblue' }}>{currBudgetItemCC?.Code}</span>
        }
        infoLine={
          // infoline text
          <>
            <div className="desc flex-space">
              <span className="xxTitle">{getBudget[0]?.Fyear}</span>
              <span style={{ paddingLeft: '310px' }}>
                {currBudgetItemPeriod?.Code}
              </span>
            </div>
          </>
        }
        rightText={
          <>
            <span style={{ paddingRight: '0px', color: 'blue' }}>
              <img
                src={BlueDollar}
                style={{
                  width: '12px',
                  marginBottom: '-2px',
                  font: 'xxTitle highlight-text',
                }}
              />
              {amtStr(currBudgetItemPeriod?.BudgetAmt)}
            </span>
          </>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          {[...Array(12)].map((e, index) => (
            <Grid
              spacing={1}
              key={e}
              container
              justify="flex-start"
              className="table-content"
            >
              <Grid item xs={1} style={{ alignSelf: 'center', color: 'white' }}>
                <span className="circle">{index + 1}</span>
              </Grid>

              <Grid container item xs={10}>
                <Grid
                  item
                  xs={5}
                  className="mat-new"
                  style={{ paddingLeft: '10px' }}
                >
                  <Controller
                    render={({ onChange }) => (
                      <NumberFormat
                        key={e}
                        allowNegative={true}
                        customInput={TextField}
                        decimalScale={2}
                        fixedDecimalScale
                        variant="outlined"
                        autoComplete="off"
                        thousandSeparator
                        isNumericString
                        margin="dense"
                        onValueChange={values => {
                          let { floatValue } = values
                        }}
                        defaultValue={0}
                      />
                    )}
                    required
                    ref={register()}
                    name={`BudgetAmt`}
                    control={control}
                    onChangeName="onValueChange"
                    disabled={mode === 'approve-reject'}
                  />
                </Grid>

                <Grid
                  item
                  xs={7}
                  className="mat-new"
                  style={{ paddingLeft: '10px' }}
                >
                  {/* Budget Amount */}
                  <Controller
                    render={({ onChange }) => (
                      <NumberFormat
                        key={e}
                        allowNegative={true}
                        customInput={TextField}
                        decimalScale={2}
                        fixedDecimalScale
                        variant="outlined"
                        autoComplete="off"
                        thousandSeparator
                        isNumericString
                        margin="dense"
                        onValueChange={values => {
                          let { floatValue } = values
                        }}
                        defaultValue={0}
                      />
                    )}
                    required
                    ref={register()}
                    name={`BudgetAmt`}
                    control={control}
                    onChangeName="onValueChange"
                    disabled={mode === 'approve-reject'}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </CardContents>
      </ContentWrapper>

      <Footer
        options={[
          {
            name: 'Save',
            color: 'primary',
            onClick: () => {},
            props: {
              type: 'submit',
            },
          },
        ]}
      />
    </>
  )
}
