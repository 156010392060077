import {
  COUNTRY,
  STATE_BY_COUNTRY,
  CITY_BY_STATE,
  POSTCODE_BY_CITY,
} from 'containers/AccountModule/GraphQL/AccountQuery'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { SubscriptionClient } from 'SubscriptionClient'
// import {
//   useGetCitybyStateLazyQuery,
//   useGetCountryQuery,
//   useGetPostcodebyCityLazyQuery,
//   useGetStatebyCountryLazyQuery,
// } from 'generated/graphql';

export const useCustomCountryQuery = () => {
  const { data: { getCountry } = { getCountry: [] } } = useQuery(COUNTRY, {
    client: SubscriptionClient,
    variables: { orderByAsc: 'name' },
  })
  const [
    handleGetStateByCountry,
    { data: { getStatebyCountry } = { getStatebyCountry: [] } },
  ] = useLazyQuery(STATE_BY_COUNTRY, { client: SubscriptionClient })

  const [
    handleGetCityByState,
    { data: { getCitybyState } = { getCitybyState: [] } },
  ] = useLazyQuery(CITY_BY_STATE, { client: SubscriptionClient })
  const [
    handleGetPostcodeByCity,
    { data: { getPostcodebyCity } = { getPostcodebyCity: [] } },
  ] = useLazyQuery(POSTCODE_BY_CITY, { client: SubscriptionClient })

  return {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleGetStateByCountry, // variables : CountryID
    handleGetCityByState, // variables : StateID
    handleGetPostcodeByCity, // variables : StateID
  }
}
