import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import SpeedDial, { SpeedDialProps } from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import React from 'react'
interface AnimatedFloatButtonProps {
  //   onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  actions?: any
  footer?: boolean
  floatTemplate?: boolean
  floatTotalAmt?: boolean
  isCreate?: boolean
  copyright?: boolean
  noCopyright?: boolean
  saveFooter?: boolean
  style?: any
  disabled?: boolean
  copyRightWithFooter?: boolean
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    exampleWrapper: {
      position: 'relative',
      marginTop: theme.spacing(3),
      height: 380,
    },
    radioGroup: {
      margin: theme.spacing(1, 0),
    },
    speedDial: {
      position: 'fixed',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: '24px',
        right: '30px',
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
  })
)

export default function AnimatedFloatButton(props: AnimatedFloatButtonProps) {
  const {
    // onClick,
    actions,
    footer,
    floatTotalAmt,
    isCreate,
    copyright,
    noCopyright,
    saveFooter,
    style,
    disabled,
    copyRightWithFooter,
    floatTemplate,
    ...rest
  } = props
  const classes = useStyles()
  const [direction, setDirection] = React.useState<SpeedDialProps['direction']>(
    'up'
  )
  const [open, setOpen] = React.useState(false)
  const [hidden, setHidden] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <SpeedDial
      ariaLabel="SpeedDial example"
      className={classes.speedDial}
      hidden={hidden}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      direction={direction}
    >
      {actions.map(action => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.onClick}
        />
      ))}
    </SpeedDial>
  )
}
