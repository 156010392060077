import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'

interface APEInvoiceListingParamsFormProps {
  CompanyID: string
  StartDate: Date
  EndDate: Date
  Submenu: string
  DocNo: string
  RefNo: string
  UIN: string
  DocType: string
  Name: string
  EInvoiceStatus: string
  DocAmt: number
}

interface CompanyOption {
  Name: string
  CompanyID: string
}

export const APEInvoiceListingParamsForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  const { CompanyID }: any = useParams()
  const editData = location?.state as any
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const latestCompany = localStorage.getItem('latestCompany')
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('Company is required'),
    StartDate: CommonYupValidation.requireField('Start Date is required'),
    EndDate: CommonYupValidation.requireField('End Date is required'),
  })

  const [selectedSubmenu, setSelectedSubmenu]: any = useState(new Set())
  const [selectedDocType, setSelectedDocType]: any = useState(new Set())
  const [selectedEInvoiceStatus, setSelectedEInvoiceStatus]: any = useState(
    new Set()
  )

  const { handleSubmit, register, errors, control, watch, setValue } = useForm<
    APEInvoiceListingParamsFormProps
  >({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID },
    fetchPolicy: 'network-only',
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
  })

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/digital-reports/account-payable/e-invoice-listing/generated`,
      state: {
        CompanyID: data?.CompanyID,
        StartDate: new Date(data.StartDate),
        EndDate: new Date(data.EndDate),
        DocNo: data?.DocNo && data?.DocNo !== '' ? data?.DocNo : undefined,
        RefNo: data?.RefNo && data?.RefNo !== '' ? data?.RefNo : undefined,
        UIN: data?.UIN && data?.UIN !== '' ? data?.UIN : undefined,
        DocAmt: data?.DocAmt && data?.DocAmt !== '' ? data?.DocAmt : undefined,
        Submenu: selectedSubmenu ? Array.from(selectedSubmenu) : undefined,
        DocType: selectedDocType ? Array.from(selectedDocType) : undefined,
        EInvoiceStatus: selectedEInvoiceStatus
          ? Array.from(selectedEInvoiceStatus)
          : undefined,
      },
    })
  }

  const handleCheckboxSubmenuChange = (event: any) => {
    let Submenu = selectedSubmenu

    if (event?.target?.checked) {
      Submenu.add(event?.target?.value)
    } else {
      Submenu.delete(event?.target?.value)
    }
    setSelectedSubmenu(new Set(Submenu))
  }

  const handleCheckboxDocTypeChange = (event: any) => {
    let DocType = selectedDocType

    if (event?.target?.checked) {
      DocType.add(event?.target?.value)
    } else {
      DocType.delete(event?.target?.value)
    }
    setSelectedDocType(new Set(DocType))
  }

  const handleCheckboxEInvoiceStatusChange = (event: any) => {
    let EInvoiceStatus = selectedEInvoiceStatus

    if (event?.target?.checked) {
      EInvoiceStatus.add(event?.target?.value)
    } else {
      EInvoiceStatus.delete(event?.target?.value)
    }
    setSelectedEInvoiceStatus(new Set(EInvoiceStatus))
  }

  const SubmenuList = [
    'Invoice',
    'Debit Note',
    'Credit Note',
    'Debit Note From Creditor',
    'Credit Note From Creditor',
  ]
  const DocTypeList = [
    'Self Billed Invoice',
    'Self Billed Debit Note',
    'Self Billed Credit Note',
  ]
  const EInvoiceStatusList = [
    'New',
    'Pending Send to LHDN',
    'Failed to Send',
    'Sent and In Validation by LHDN',
    'Failed Validation',
    'Cancelled Send',
    'Successfully Validated',
    'Request to Reject by Buyer',
    'Approved Rejection Pending Adjustment',
    'Rejected By Buyer with Adjustment',
    'Cancelled Validated Transaction Pending Adjustment',
    'Cancelled with Adjustment',
    'Completed',
  ]

  return (
    <>
      {CompanyLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/account-payable`)}
        smTitle={'Inventory Control'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'e-Invoice Listing', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {!CompanyAssignedLoading && !CompanyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const options: CompanyOption[] =
                  !!user?.superUser === false
                    ? getAssignedCompanyByUser
                    : getCompany
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]

                return (
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option: CompanyOption) => option.Name}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('CompanyID', newValue?.CompanyID)
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.Name}</span>
                    }}
                    defaultValue={defVal}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            required
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              required
              defaultValue={latestCompany}
            />
          )}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="DocNo"
            label="Doc No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.DocNo?.message}
            error={errors?.DocNo ? true : false}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Ref No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={SubmenuList}
                  getOptionLabel={option => `${option}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x)
                    })

                    setSelectedSubmenu(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxSubmenuChange(event)
                        }}
                      />
                      {option}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="Submenu"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="Submenu"
            label="Submenu"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={DocTypeList}
                  getOptionLabel={option => `${option}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x)
                    })

                    setSelectedDocType(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxDocTypeChange(event)
                        }}
                      />
                      {option}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="Doc Type"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="DocType"
            label="Doc Type"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={EInvoiceStatusList}
                  getOptionLabel={option => `${option}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x)
                    })

                    setSelectedEInvoiceStatus(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxEInvoiceStatusChange(event)
                        }}
                      />
                      {option}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="e-Invoice Status"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="EInvoiceStatus"
            label="e-Invoice Status"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            as={
              <NumberFormat
                allowNegative={false}
                customInput={TextField}
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                isNumericString
                onValueChange={e => {
                  setValue('DocAmt', parseFloat(e.value))
                }}
              />
            }
            name="DocAmt"
            label="DocAmt"
            ref={register()}
            control={control}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
