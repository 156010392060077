import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { JournalDetail } from './JournalDetail/JournalDetail'
import { JournalForm } from './JournalForm/JournalForm'
import { JournalItemForm } from './JournalForm/JournalItemForm'
import { JournalMain } from './JournalMain'
import { PDFPreviewGL } from '../Hooks/PDFPreviewGL'
import { PDFPreviewGLV2 } from '../Hooks/PDFPreviewGLV2'

const journalRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/journal-processing/filter',
    },
    component: (
      <AdvancedFilter
        mode="journal-processing"
        app="general-ledger"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/journal-processing',
    },
    component: <JournalMain />,
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:JournalProcessingID/detail',
    },
    component: <JournalDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:JournalProcessingID/detail/workdesk',
    },
    component: <JournalDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:JournalProcessingID/detail/workdesk-history',
    },
    component: <JournalDetail mode="workdesk-history" />,
  },

  /* -------------------------------------------- */
  /*                  PREVIEW PDF                 */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:JournalProcessingID/preview',
    },
    component: (
      <PDFPreviewGLV2
        accountType="general-ledger"
        docType="journal-processing"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/journal-processing/add',
    },
    component: <JournalForm formMode="add" type="JOURNAL_PROCESSING" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:JournalProcessingID/edit',
    },
    component: <JournalForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:JournalProcessingID/approve-reject',
    },
    component: <JournalForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:JournalProcessingID/resubmit',
    },
    component: <JournalForm formMode="resubmit" />,
  },

  /* -------------------------------------------- */
  /*                     ITEM                     */
  /* -------------------------------------------- */
  //payment = add , paymentitem = add
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:formMode/journal-processing-item/:detailMode',
    },
    component: <JournalItemForm />,
  },
  //payment = add , paymentitem = edit & add
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:JournalProcessingID/:formMode/journal-processing-item/:detailMode',
    },
    component: <JournalItemForm />,
  },
  //payment = add, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:formMode/journal-processing-item/:JournalProcessingItemID/:detailMode',
    },
    component: <JournalItemForm />,
  },
  //payment = edit, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/journal-processing/:JournalProcessingID/:formMode/journal-processing-item/:JournalProcessingItemID/:detailMode',
    },
    component: <JournalItemForm />,
  },
]

export default journalRoutes
