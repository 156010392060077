import HeaderGL from 'containers/GeneralLedgerModule/Hooks/PDFTemplate/HeaderGL'
import React from 'react'
import TableCBV from './TableCBV'
import TableBT from './TableBT'
import TableCBPV from './TableCBPV'
import TableCBOR from './TableCBOR'
import TableDetailBT from './TableDetailBT'
import FooterPR from 'containers/ARModule/Hooks/PDFTemplate/FooterPR'
import FooterBT from './FooterBT'

export const CBBodyTemplate = (props: any) => {
  const {
    fontStyle,
    title,
    docType,
    previewDetail,
    getBankAccount,
    getUsersByAccountAndSoftware,
    listData,
    remapData,
    page,
    pageLength,
    width,
    currencyCode,
  } = props

  return (
    <>
      {(page === 0 || docType === 'bank-transfer') && (
        <>
          <HeaderGL />

          <div className="row">
            <p style={fontStyle}>
              <b>{title}</b>
            </p>
          </div>
        </>
      )}

      {listData?.length > 0 && (
        <div
          style={{
            border: width < 769 ? '0.25px solid' : '0.5px solid',
            margin: '10px',
          }}
        >
          {/* Table for Document Info */}
          {docType !== 'bank-transfer' ? (
            <TableCBV
              data={previewDetail}
              page={page}
              pageLength={pageLength}
            />
          ) : (
            <TableBT data={previewDetail} getBankAccount={getBankAccount} />
          )}

          {/* Table for details */}
          {!!remapData && docType === 'payment' && (
            <>
              <TableCBPV
                data={remapData[page]}
                item={remapData[page]?.PaymentItem}
                page={page}
                pageLength={pageLength}
                currencyCode={currencyCode}
              />
            </>
          )}

          {!!remapData && docType === 'receipt' && (
            <>
              <TableCBOR
                data={remapData[page]}
                item={remapData[page]?.ReceiptItem}
                page={page}
                pageLength={pageLength}
                currencyCode={currencyCode}
              />
            </>
          )}

          {docType === 'bank-transfer' && (
            <>
              <TableDetailBT
                data={previewDetail}
                getBankAccount={getBankAccount}
                currencyCode={currencyCode}
              />
            </>
          )}
        </div>
      )}

      {/* Footer */}
      {page === pageLength - 1 && docType !== 'bank-transfer' ? (
        <FooterPR
          data={previewDetail}
          docType={docType}
          getUser={getUsersByAccountAndSoftware}
        />
      ) : previewDetail && docType === 'bank-transfer' ? (
        <FooterBT
          data={previewDetail}
          docType={docType}
          getUser={getUsersByAccountAndSoftware}
        />
      ) : (
        <div className="page-break"></div>
      )}
    </>
  )
}

export default CBBodyTemplate
