import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { Box, TextField, Typography } from '@material-ui/core'
import { TreeView } from '@material-ui/lab'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { useGetCostCentreDefinitionQuery } from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  DotIcon,
  MinusSquare,
  PlusSquare,
  StyledTreeItem,
} from '../MasterCOA/RoleTreeStyle'

export const CostCentreDefinitionDetail = (props: any) => {
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const defaultData: any = location?.state
  const [fields, setFields] = useState<any>([])

  console.log('defaultData: ', defaultData)

  const {
    loading: costCentreLoading,
    error: costCentreError,
    data: { getCostCentreDefinition } = {
      CostCentreDefinitionHeader: [],
      CostCentreDefinitionDetail: [],
    },
  } = useGetCostCentreDefinitionQuery({
    fetchPolicy: 'network-only',
    variables: {
      CostCentreDefinitionHeaderID: defaultData?.CostCentreDefinitionHeaderID,
    },
    onCompleted: ({ getCostCentreDefinition }) => {
      if (
        getCostCentreDefinition?.CostCentreDefinitionDetail === undefined ||
        getCostCentreDefinition?.CostCentreDefinitionDetail?.length === 0
      ) {
        setFields([])
      } else {
        let initialArr = []

        let level = getCostCentreDefinition?.CostCentreDefinitionDetail?.map(
          v => v.Level
        ).sort((a, b) => b - a)[0]
        // parent.map((el, index) => {
        //   return
        initializeLevel(
          1,
          getCostCentreDefinition?.CostCentreDefinitionDetail,
          initialArr,
          level,
          level
        )
        // })

        /// FILTER IF HAVE CHILD////
        const childEmpty = initialArr?.filter(x => x?.child?.length === 0)
        const AllArray = initialArr
          ?.filter(x => x?.child?.length > 0)
          .concat(childEmpty)

        setFields(initialArr)
      }
    },
  })

  console.log('Datass1: ', getCostCentreDefinition)
  console.log('Datass: ', getCostCentreDefinition?.CostCentreDefinitionDetail)

  // useEffect(() => {
  //   if (
  //     getCostCentreDefinition?.CostCentreDefinitionDetail === undefined ||
  //     getCostCentreDefinition?.CostCentreDefinitionDetail?.length === 0
  //   ) {
  //     setFields([])
  //   } else {
  //     let initialArr = []

  //     let level = getCostCentreDefinition?.CostCentreDefinitionDetail?.map(
  //       v => v.Level
  //     ).sort((a, b) => b - a)[0]
  //     // parent.map((el, index) => {
  //     //   return
  //     initializeLevel(
  //       1,
  //       getCostCentreDefinition?.CostCentreDefinitionDetail,
  //       initialArr,
  //       level
  //     )
  //     // })

  //     /// FILTER IF HAVE CHILD////
  //     const childEmpty = initialArr?.filter(x => x?.child?.length === 0)
  //     const AllArray = initialArr
  //       ?.filter(x => x?.child?.length > 0)
  //       .concat(childEmpty)

  //     setFields(AllArray)
  //   }
  // }, [getCostCentreDefinition?.CostCentreDefinitionDetail])

  const initializeLevel = (level, data, arr, maxLevel, prtID) => {
    const dataToPush = data
      ?.filter(v => {
        if (v.ParentCostCentreDefinitionDetailID !== null) {
          return (
            v.Level === level && v.ParentCostCentreDefinitionDetailID === prtID
          )
        } else {
          return v.Level === level
        }
      })
      .map(x => {
        return {
          CostCentreDefinitionDetailID: x?.CostCentreDefinitionDetailID,
          ParentCostCentreDefinitionDetailID:
            x?.ParentCostCentreDefinitionDetailID,
          CostCentreDefinitionHeaderID: x?.CostCentreDefinitionHeaderID,
          name: x?.Name,
          level: x?.Level,
          CostCentre: x?.CostCentre,
          child: [],
        }
      })

    arr.push(...dataToPush)
    console.log('arr', arr)

    if (level < maxLevel) {
      arr.map(k => {
        initializeLevel(
          level + 1,
          data,
          k.child,
          maxLevel,
          k.CostCentreDefinitionDetailID
        )
      })
    }
  }

  console.log('fields', fields)

  const renderTree = nodes => (
    <StyledTreeItem
      key={nodes?.CostCentreDefinitionDetailID}
      nodeId={nodes?.CostCentreDefinitionDetailID}
      label={
        <>
          <Box p={0} pr={0} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body2"
              style={{ fontWeight: 'inherit', flexGrow: 1, padding: '10px' }}
            >
              <span>{`${nodes.name}`}</span>
            </Typography>
          </Box>
        </>
      }
    >
      {Array.isArray(nodes.child)
        ? nodes.child.map((nodes, index) => renderTree(nodes))
        : null}
    </StyledTreeItem>
  )

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/general-ledger/general-setting/cost-centre-definition`)
        }
        smTitle={'General Ledger'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Company' },
          { name: 'Department Definition', current: true },
        ]}
        rightRouteSegments={[{ name: 'View', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <TextField
            name="title"
            label="Title"
            value={defaultData?.Title}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Hierachy',
            },
          }}
        >
          <TreeView
            className="tree-view-root"
            defaultExpanded={['root']}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<DotIcon />}
            multiSelect
            // onNodeToggle={handleToggle}
            // expanded={expanded}
          >
            {fields.map(el => {
              return renderTree(el)
            })}
          </TreeView>
        </CardContents>
      </ContentWrapper>
    </>
  )
}
