import React from 'react'
import { BillItemListing } from './BillItemListing'

export const billItemRoutes = [
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/bill-items`,
    },
    component: <BillItemListing accountType="account-receivable" />,
  },
]

export default billItemRoutes
