import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  StyledMenu,
  StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import AppContext from 'containers/App/Store/AppContext'
import { AcctPermission } from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import theme from '@ifca-root/react-component/src/assets/theme'
import { dropdownOption } from 'containers/DigitalReportModule/function/DigitalReportDropdown'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_PACKAGE_BY_ACCOUNT } from 'containers/AccountModule/GraphQL/AccountQuery'
import { SubscriptionClient } from 'SubscriptionClient'

export const APDigitalReportSubmenu = (props: any) => {
  let history = useHistory()
  const location = useLocation()
  const { handlePermHidden } = usePermissionChecker()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [labelState, setLabelState] = useState<string>(
    'Account Payable Reports'
  )
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  // ACCOUNTX API CALLS
  const [
    loadPackage,
    {
      loading: packageLoading,
      error: packageError,
      data: { getPackageByAccount } = { getPackageByAccount: [] },
    },
  ] = useLazyQuery(GET_PACKAGE_BY_ACCOUNT, {
    client: SubscriptionClient,
  })

  const submenuList = [
    {
      path: `/digital-reports/account-payable/ap-advance-ledger/parameters`,
      title: `Advance Ledger`,
      permission: AcctPermission.AccPayableDigitalReportOverduePayableView,
      deployed: true,
    },
    {
      path: `/digital-reports/account-payable/ap-overdue-payable/parameters`,
      title: `Overdue Payable`,
      permission: AcctPermission.AccPayableDigitalReportOverduePayableView,
      deployed: true,
    },
    {
      path: `/digital-reports/account-payable/ap-creditor-ledger-detail-by-doc-date/parameters`,
      title: `Creditor Ledger Detail By Document Date`,
      permission:
        AcctPermission.AccPayableDigitalReportCreditorLedgerByDocDateView,
      deployed: true,
    },
    {
      path: `/digital-reports/account-payable/ap-creditor-ledger-detail-by-trx-date/parameters`,
      title: `Creditor Ledger Detail By Transaction Date`,
      deployed: true,
    },
    {
      path: `/digital-reports/account-payable/ap-creditor-aging-detail-by-doc-date/parameters`,
      title: `Creditor Aging Detail By Document Date`,
      permission:
        AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
      deployed: true,
    },
    {
      path: `/digital-reports/account-payable/ap-creditor-aging-detail-by-trx-date/parameters`,
      title: `Creditor Aging Detail By Transaction Date`,
      deployed: true,
    },
    {
      path: `/digital-reports/account-payable/ap-creditor-aging-summary-doc-date/parameters`,
      title: `Creditor Aging Summary By Document Date`,
      deployed: true,
    },
    {
      path: `/digital-reports/account-payable/ap-creditor-aging-summary-trx-date/parameters`,
      title: `Creditor Aging Summary By Transaction Date`,
      deployed: true,
    },
    {
      path: `/digital-reports/account-payable/ap-creditor-control-summary/parameters`,
      title: `Creditor Control Summary`,
      deployed: true,
    },
    {
      path: `/digital-reports/account-payable/ap-creditor-control-detail/parameters`,
      title: `Creditor Control Detail`,
      deployed: true,
    },
    {
      path: `/digital-reports/account-payable/e-invoice-listing/parameters`,
      title: `e-Invoice Listing`,
      deployed: true,
    },
  ]

  const handleChange = value => {
    history.push({
      pathname: value,
    })
  }

  let package_permission
  if (!packageLoading) {
    package_permission = dropdownOption.filter((v, i) =>
      getPackageByAccount?.includes(v?.package_name)
    )
  }

  useEffect(() => {
    if (user?.accountID) {
      loadPackage({
        variables: {
          softwareCode: 'ACCOUNT',
          accountID: user?.accountID,
        },
      })
    }
  }, [user?.accountID])

  return (
    <>
      {packageLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(``)}
        smTitle={'Account Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reports', current: true },
        ]}
      />
      <DynamicSubHeader
        title={labelState}
        handleClick={e => {
          handleClick(e)
        }}
        dropDownOptions={
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {package_permission?.map(x => {
              if (
                handlePermHidden({
                  permEnum: x?.permission,
                })
              )
                return (
                  <StyledMenuItem
                    onClick={e => {
                      handleChange(x?.path)
                      handleClose()
                    }}
                    selected={location.pathname.includes(x.path)}
                  >
                    <ListItemText primary={x.label} />
                  </StyledMenuItem>
                )
            })}
          </StyledMenu>
        }
      />
      <ContentWrapper
        float
        style={{
          marginTop: isDesktop ? '100px' : '40px',
          top: isDesktop ? '47px' : null,
        }}
      >
        <List className="core-list">
          {submenuList.map((el, index) => {
            if (
              el?.deployed &&
              handlePermHidden({
                permEnum: el?.permission,
              })
            )
              return (
                <ListItem key={index} onClick={() => history.push(el?.path)}>
                  <ListItemText
                    style={{
                      textAlign: 'right',
                      marginLeft: '5px',
                    }}
                    primary={<span className="xsTitle">{el?.title}</span>}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: el.path,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          })}
        </List>
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
