import React, { lazy } from 'react'

const StockCategoryForm = lazy(() =>
  import('./StockCategoryForm').then(module => ({
    default: module.StockCategory,
  }))
)

const stockCategoryForm = [
  {
    props: {
      exact: true,
      path: '/inventory-control/general-setting/stock-category',
    },
    component: <StockCategoryForm />,
  },
]

export default stockCategoryForm

