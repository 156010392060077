import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs'
import {
  useGetStockItemNameQuery,
  useGetWarehouseQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { AvailableStockItemForm } from './AvailableItemForm'

export const WarehouseItemAccessListing = (props: any) => {
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [value, setValue] = useState(0)
  const { WarehouseID }: any = useParams()
  const [availableItem, setAvailableItem] = useState([])
  const [assignedItem, setAssignedItem] = useState([])
  let location = useLocation()
  const CompanyID: any = location?.state
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  //API SECTION

  const {
    loading: WarehouseLoading,
    error: WarehouseError,
    data: { getWarehouse } = { getWarehouse: {} as any },
  } = useGetWarehouseQuery({
    fetchPolicy: 'network-only',
    variables: { WarehouseID: WarehouseID },
  })

  const {
    loading: StockItemNameLoading,
    error: StockItemNameError,
    data: { getStockItem } = { getStockItem: {} as any },
  } = useGetStockItemNameQuery({
    fetchPolicy: 'network-only',
  })

  // //API SECTION
  useEffect(() => {
    if (getWarehouse) {
      setAssignedItem(getWarehouse[0]?.stockItemAssigned)
    }
    if (getStockItem && getStockItem?.length > 0) {
      const assignedEntity = getWarehouse[0]?.stockItemAssigned?.map(
        y => y?.StockItemID
      )

      const filteredItem = getStockItem?.filter(
        v => !assignedEntity?.includes(v?.StockItemID)
      )

      setAvailableItem(filteredItem)
    }
  }, [getWarehouse, getStockItem])

  return (
    <>
      {WarehouseLoading && <Loading />}
      {StockItemNameLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(`/inventory-control/general-setting/warehouse/`)
        }
        smTitle={'Inventory Control'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Settings' },
          { name: 'User' },
          { name: 'User Access', current: true },
        ]}
        rightRouteSegments={[{ name: 'Assign', current: true }]}
      />

      <StandardTabs
        value={value}
        onChange={handleChange}
        responsive
        fullWidth
        // dynamicInfo
        style={{ boxShadow: 'none' }}
        tabs={[
          {
            label: `Available(${availableItem?.length})`,
            content: (
              <AvailableStockItemForm
                user={availableItem}
                pageMode={'available'}
                // companyID={getWarehouse[0]?.CompanyID}
              />
            ),
          },
          {
            label: `Assigned(${assignedItem?.length})`,
            content: (
              <AvailableStockItemForm
                user={assignedItem}
                pageMode={'assigned'}
                // companyID={getWarehouse[0]?.CompanyID}
              />
            ),
          },
        ]}
      />
    </>
  )
}
