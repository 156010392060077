import React, { lazy } from 'react'
import { DigitalReportsListing } from './DigitalReportsListing'

const ForexRevaluationListing = lazy(() =>
  import('./DigitalReportsListing').then(module => ({
    default: module.DigitalReportsListing,
  }))
)

// const ListingDetailTemplate = lazy(() =>
//   import('./ListingDetailTemplate').then(module => ({
//     default: module.ListingDetailTemplate,
//   }))
// )

// const FormTemplate = lazy(() =>
//   import('./FormTemplate').then(module => ({
//     default: module.FormTemplate,
//   }))
// )

const digitalReportsRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/submenu/:companyID/digital-reports',
    },
    component: <DigitalReportsListing />,
  },
  //   {
  //     props: { exact: true, path: '/listing-template' },
  //     component: <ListingDetailTemplate />,
  //   },
  //   {
  //     props: { exact: true, path: '/form-template' },
  //     component: <FormTemplate />,
  //   },
]

export default digitalReportsRoutes
