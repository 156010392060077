import React, { lazy } from 'react'

const UserListing = lazy(() =>
  import('./UserListing').then(module => ({
    default: module.UserListing,
  }))
)

const UserForm = lazy(() =>
  import('./UserForm').then(module => ({
    default: module.UserForm,
  }))
)

const UserDetail = lazy(() =>
  import('./UserDetail').then(module => ({
    default: module.UserDetail,
  }))
)
const UserCompanyAccess = lazy(() =>
  import('./UserCompanyAccess/UserCompanyAccessListing').then(module => ({
    default: module.UserCompanyAccess,
  }))
)

const userRoutes = [
  //USER LISTING W/O PAGE MODE
  {
    props: { exact: true, path: '/access-security/user' },
    component: <UserListing />,
  },
  {
    props: { exact: true, path: '/general-ledger/access-security/user' },
    component: <UserListing />,
  },

  // //USER LISTING WITH PAGE MODE
  // {
  //   props: {
  //     exact: true,
  //     path: '/:mode/access-security/user',
  //   },
  //   component: <UserListing />,
  // },
  {
    props: {
      exact: true,
      path: '/access-security/user/add',
    },
    component: <UserForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/access-security/user/edit/:userID',
    },
    component: <UserForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/access-security/user/detail/:ID',
    },
    component: <UserDetail />,
  },
  {
    props: {
      exact: true,
      path: '/access-security/user/company-access/:ID',
    },
    component: <UserCompanyAccess />,
  },
]

export default userRoutes
