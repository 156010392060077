import autoNumberingRoutes from './AutoNumbering/AutoNumberingRoutes'
import React, { lazy } from 'react'

const NumberingStructureSubmenu = lazy(() =>
  import('./NumberingStructureSubmenu').then(module => ({
    default: module.NumberingStructureSubmenu,
  }))
)
const GPNumberingStructureSubmenu = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/NumberStructureModule/NumberStructureSubmenu'
  ).then(module => ({
    default: module.NumberStructureSubmenu,
  }))
)

const numberingStructureRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/company-setting/numbering-structure',
    },
    component: <NumberingStructureSubmenu mode="cash-book" />,
  },
  //Inventory Control
  {
    props: {
      exact: true,
      path: '/inventory-control/:CompanyID/company-setting/numbering-structure',
    },
    component: <NumberingStructureSubmenu mode="inventory-control" />,
  },
  // GENERAL LEDGER
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/company-setting/numbering-structure',
    },
    component: <NumberingStructureSubmenu mode="general-ledger" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/company-setting/numbering-structure',
    },
    component: <NumberingStructureSubmenu mode="account-receivable" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/company-setting/numbering-structure',
    },
    component: <NumberingStructureSubmenu mode="account-payable" />,
  },

  //// GP Numbering Structure ////

  {
    props: {
      exact: true,
      path: '/supplier/:CompanyID/company-settings/numbering-structure',
    },
    component: <GPNumberingStructureSubmenu mode="supplier" />,
  },
  //Supplier
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/numbering-structure',
    },
    component: <GPNumberingStructureSubmenu mode="general-purchase" />,
  },
  // General Purchase
  {
    props: {
      exact: true,
      path: '/project-purchase/:CompanyID/company-settings/numbering-structure',
    },
    component: <GPNumberingStructureSubmenu mode="project-purchase" />,
  },
  //Project Purchase
  //////////////////////////////////////////
  ...autoNumberingRoutes,
]

export default numberingStructureRoutes
