import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { format } from 'date-fns'
import React from 'react'

export const Table2GL = (props: any) => {
  const { data, getUser } = props

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) fontSize = 6
  else fontSize = 9

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <table style={{ margin: '8px', width: '100%', fontSize }}>
          <tr>
            <th
              style={{ fontWeight: 'normal', textAlign: 'left', width: '33%' }}
            >
              <div
                style={{
                  border: '0.5px solid',
                  marginRight: '10px',
                  padding: '5px',
                }}
              >
                <span>
                  <b>Prepared By:</b>

                  <br />
                  <br />
                  {getUser
                    ?.filter(v => v?.ID === data?.createdBy)
                    .map(v => {
                      return v?.name
                    })}
                  <br />
                  <b>Date: </b>
                  {format(new Date(data?.createdTs), 'dd/MM/yyyy')}
                </span>
              </div>
            </th>
            {!!data?.submittedBy ? (
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '33%',
                }}
              >
                <div
                  style={{
                    border: '0.5px solid',
                    padding: '5px',
                  }}
                >
                  <span>
                    <b>Submitted By:</b>

                    <br />
                    <br />
                    {getUser
                      ?.filter(v => v?.ID === data?.submittedBy)
                      .map(v => {
                        return v?.name
                      })}
                    <br />
                    <b>Date: </b>
                    {format(new Date(data?.submittedTs), 'dd/MM/yyyy')}
                  </span>
                </div>
              </th>
            ) : (
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '33%',
                }}
              ></th>
            )}
            {!!data?.approvedBy ? (
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '33%',
                }}
              >
                <div
                  style={{
                    border: '0.5px solid',
                    marginLeft: '10px',
                    marginRight: '15px',
                    padding: '5px',
                  }}
                >
                  <span>
                    <b>Approved By:</b>

                    <br />
                    <br />
                    {getUser
                      ?.filter(v => v?.ID === data?.approvedBy)
                      .map(v => {
                        return v?.name
                      })}
                    <br />
                    <b>Date: </b>
                    {format(new Date(data?.approvedTs), 'dd/MM/yyyy')}
                  </span>
                </div>
              </th>
            ) : (
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '33%',
                  marginLeft: '15px',
                }}
              ></th>
            )}
          </tr>
        </table>
      </div>
    </>
  )
}

export default Table2GL
