import React, { lazy } from 'react'

const ForexRevaluationListing = lazy(() =>
  import('./ForexRevaluationListing').then(module => ({
    default: module.ForexRevaluationListing,
  }))
)

// const ListingDetailTemplate = lazy(() =>
//   import('./ListingDetailTemplate').then(module => ({
//     default: module.ListingDetailTemplate,
//   }))
// )

// const FormTemplate = lazy(() =>
//   import('./FormTemplate').then(module => ({
//     default: module.FormTemplate,
//   }))
// )

const forexRevaluationRoutes = [
  {
    props: { exact: true, path: '/cash-book/:CompanyID/submenu/:BankAccountID/forex-revaluation' },
    component: <ForexRevaluationListing />,
  },
//   {
//     props: { exact: true, path: '/listing-template' },
//     component: <ListingDetailTemplate />,
//   },
//   {
//     props: { exact: true, path: '/form-template' },
//     component: <FormTemplate />,
//   },
]

export default forexRevaluationRoutes
