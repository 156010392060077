import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import { SubmitterDialog } from 'components/Dialog/SubmitterDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  GetPaymentbyStatusDocument,
  UserSelection,
  useDocumentListingQuery,
  useGetBankAccountQuery,
  useGetPaymentQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostCbPaymentMutation,
  useUpdatePaymentMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { PaymentDetailContent } from './PaymentDetailContent'
import { PaymentDetailItemContent } from './PaymentDetailItemContent'

export const PaymentDetail = (props: any) => {
  let history = useHistory()
  const { CompanyID, PaymentID, BankAccountID }: any = useParams()
  const { mode } = props
  let location = useLocation()
  const editData = location?.state as any
  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [audioInput] = useState('')
  const [record, setRecord] = useState(false)
  const [, setText] = useState(mode === 'add' ? '' : editData?.remark)
  const [, setSingleRemark] = useState(null)
  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [openDialog, setOpenDialog] = useState(false)
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    loading: bankAccountLoading,
    error: bankAccountError,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: { BankAccountID: BankAccountID },
  })

  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    loading: PaymentLoading,
    error: PaymentError,
    data: { getPayment } = { getPayment: [] },
  } = useGetPaymentQuery({
    fetchPolicy: 'network-only',
    variables: { PaymentID: PaymentID },
  })

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: PaymentID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const [
    updatePayment,
    { loading: updateLoading, error: updateError },
  ] = useUpdatePaymentMutation({
    onError: error => {},

    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      mode === 'workdesk'
        ? setTimeout(() => {
            history.push({
              pathname: `/workdesk/cash-book`,
              state: { success: true, msgMode: 'update' },
            })
          }, 500)
        : setTimeout(() => {
            history.push({
              pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment`,
              state: { success: true, msgMode: 'update' },
            })
          }, 500)
    },
  })

  const RecordStatusText = pStatus => {
    switch (pStatus) {
      case ApprovalStatus.Submit:
        return 'Submitted'
      case ApprovalStatus.Active:
        return 'Draft'
      case ApprovalStatus.Approved:
        return 'Submitted'
      case ApprovalStatus.Completed:
        return 'Approved'
      case ApprovalStatus.Rejected:
        return 'Rejected'
      case ApprovalStatus.Cancelled:
        return 'Cancelled'
      default:
        return ''
    }
  }

  let color
  const handleStatusColor = type => {
    switch (type) {
      case 'SUBMIT':
        color = '#FF0000'
        break
      case 'COMPLETED':
        color = ''
        break
    }
    return color
  }

  const rejectFooterOption = {
    name: 'Reject',
    onClick: () => {
      {
        setOpenRejectDialog(true)
        // handleSubmit(data => onSubmit(ApprovalStatus.Rejected, 'reject'))()
      }
    },
    color: 'secondary',

    props: {
      type: 'submit',
    },
  }

  const approveFooterOption = {
    name: 'Approve',
    onClick: () => {
      handleSubmit(data => onSubmit(ApprovalStatus.Approved, 'APPROVED'))()
    },
    color: 'primary',

    props: {
      type: 'submit',
    },
  }
  const footerOptions: any[] = [rejectFooterOption, approveFooterOption]

  //To calculate Total Amount of Payment
  let totalTaxAmt = getPayment[0]?.PaymentItem?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  const [
    postCBPayment,
    {
      loading: postPaymentLoading,
      called: postPaymentCalled,
      error: postPaymentError,
    },
  ] = usePostCbPaymentMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment-posting`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const onSubmit = (data, status) => {
    postCBPayment({
      variables: {
        PaymentIDs: PaymentID,
        CompanyID,
        RefTable: 'CB_Payment',
      },
      refetchQueries: [
        {
          query: GetPaymentbyStatusDocument,
          variables: {
            CompanyID,
            BankAccountID,
            StatusArr: [ApprovalStatus.Completed, ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  return (
    <>
      {bankAccountLoading && <Loading />}
      {PaymentLoading && <Loading />}
      {updateLoading && <Loading />}
      {postPaymentLoading && <Loading />}
      {getUsersByAccountAndSoftwareLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          mode === 'workdesk'
            ? history.push(`/workdesk/cash-book`)
            : mode === 'workdesk-history'
            ? history.push(`/workdesk/general-ledger/workdesk-history`)
            : history.push(
                `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment`
              )
        }}
        smTitle={'Cash Book'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Cash Book' },
          { name: 'Payment' },
          { name: 'Payment', current: true },
        ]}
        rightRouteSegments={[{ name: `Approved`, current: true }]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle">
              {getBankAccount[0]?.BankProfile?.Name}
            </span>
          </>
        }
        infoLine={
          <>
            <span className="xxTitle">{'Account No.'}&nbsp;:&nbsp;</span>
            <span className="xxTitle">{getBankAccount[0]?.AccountNo}</span>
          </>
        }
      />
      <ContentWrapper multiDynamicInfo float footer>
        <CardContents>
          <PaymentDetailContent
            listEl={getPayment[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
            bankAccount={getBankAccount[0]}
          />
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Payment Detail',
            },
          }}
        >
          <PaymentDetailItemContent listItem={getPayment[0]?.PaymentItem} />
        </CardContents>
      </ContentWrapper>

      {mode === 'workdesk' ? (
        <AccountFooter options={[...footerOptions]} />
      ) : (
        ''
      )}

      <RejectDialog
        data={getPayment[0]}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        audioInput={audioInput}
        record={record}
        setRecord={setRecord}
        setValue={setValue}
        errors={errors}
        register={register}
        control={control}
        clearErrors={clearErrors}
        setText={setText}
        handleSingleRemark={handleSingleRemark}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />

      <SubmitterDialog
        data={getPayment[0]}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        moduleName={'Payment Posting'}
      />

      {getPayment[0]?.ApprovalStatus === 'SUBMIT' ? (
        <AccountFooter
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      ) : null}

      <TooltipAmountFooter
        data={editData}
        module={`CB-payment-approved`}
        detail={true}
      />
    </>
  )
}
