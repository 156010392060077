import React from 'react'
import { StandardLetterListing } from './StandardLetterListing'
import { LetterTemplateListing } from './LetterTemplateListing'
import { CompanyLetterTemplateListing } from './CompanyLetterTemplateListing'
import { LetterDesignForm } from './LetterDesignForm'

const eLetteringRoute = [
  {
    props: {
      exact: true,
      path: '/:pageMode/general-setting/standard-letter',
    },
    component: <StandardLetterListing mode="general-setting" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:pageMode/general-setting/standard-letter/:StandardLetterID/letter-template/',
    },
    component: <LetterTemplateListing />,
  },
  {
    props: {
      exact: true,
      path: '/:pageMode/:CompanyID/company-setting/company-standard-letter',
    },
    component: <StandardLetterListing mode="company-setting" />,
  },

  {
    props: {
      exact: true,
      path:
        '/:pageMode/:CompanyID/company-setting/company-standard-letter/:StandardLetterID/company-letter-template/',
    },
    component: <CompanyLetterTemplateListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:pageMode/general-setting/standard-letter/:StandardLetterID/letter-template/:LetterTemplateID/design',
    },
    component: <LetterDesignForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/:pageMode/:CompanyID/company-setting/company-standard-letter/:StandardLetterID/company-letter-template/:CompanyLetterTemplateID/design',
    },
    component: <LetterDesignForm />,
  },
]

export default eLetteringRoute
