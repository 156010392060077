import React, { lazy } from 'react'

// const StockAdjustmentReasonListing = lazy(() =>
//   import('./StockAdjustmentReasonListing').then(module => ({
//     default: module.StockAdjustmentReasonListing,
//   }))
// )

// const stockAdjustmentReason = [
//   {
//     props: {
//       exact: true,
//       path: '/inventory-control/general-setting/stock-adjustment-reason',
//     },
//     component: <StockAdjustmentReasonListing />,
//   },
// ]

const CreditorTypeSubmenu = lazy(() =>
  import('../StockAdjustmentReasonV2/CreditorTypeSubmenu').then(module => ({
    default: module.CreditorTypeSubmenu,
  }))
)

const CreditorTypeListing = lazy(() =>
  import('../StockAdjustmentReasonV2/CreditTypeListing').then(module => ({
    default: module.CreditTypeListing,
  }))
)

const ContractorListing = lazy(() =>
  import('../StockAdjustmentReasonV2/ContractorListing').then(module => ({
    default: module.ContractorListing,
  }))
)

const StockAdjustmentReasonListingMenu = lazy(() =>
  import('./StockAdjustmentReasonListing').then(module => ({
    default: module.StockAdjustmentReasonListing,
  }))
)

const stockAdjustmentReasonRoute = [
  {
    props: {
      exact: true,
      path: '/inventory-control/general-setting/stock-adjustment-reason',
    },
    component: <StockAdjustmentReasonListingMenu />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/inventory-control/general-setting/Credit-type-listing',
  //   },
  //   component: <CreditorTypeListing />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/inventory-control/general-setting/contract-listing',
  //   },
  //   component: <ContractorListing />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/inventory-control/general-setting/credit-type-submenu',
  //   },
  //   component: <CreditorTypeSubmenu />,
  // },
]

export default stockAdjustmentReasonRoute
