import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import Fuse from 'fuse.js'
import {
  AcctPermission,
  ApprovalStatus,
  useGetBudgetQuery,
  useGetBudgetbyStatusQuery,
} from 'generated/graphql'
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const GLOriginalBudgetListing = (props: any) => {
  useEffect(() => {
    console.log('---> GLOriginalBudgetListing')
  })
  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const { PageMode } = props
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { handleStatusColor, handleStatusRename } = useColorStatusList()
  const [openWorkFlow, setWorkFlow] = useState(false)
  const { menu: menu2, handleClick: handleClick2 }: any = useMenuOption()
  const [originalBudgetStatus, setOriginalBudgetStatus] = useState<string>(
    getSearch?.option
  )

  // console.log(getSearch?.option, 'getSearch?.option')
  // console.log(getSearch?.name, 'getSearch?.name')

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  const {
    loading: BudgetLoading,
    error: BudgetError,
    data: { getBudget: getBudget } = { getBudget: [] },
  } = useGetBudgetQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      Version: `Version 1`,
      orderByAsc: 'Fyear',
    },
  })

  useEffect(() => {
    if (getBudget && getBudget.length > 0) {
      let arr = []
      arr.push(
        ...getBudget?.filter(x => x.ApprovalStatus === ApprovalStatus.Active)
      )
      arr.push(
        ...getBudget?.filter(
          x =>
            x.ApprovalStatus === ApprovalStatus.Submit ||
            x.ApprovalStatus === ApprovalStatus.Approved
        )
      )
      arr.push(
        ...getBudget?.filter(x => x.ApprovalStatus === ApprovalStatus.Completed)
      )
      arr.push(
        ...getBudget?.filter(x => x.ApprovalStatus === ApprovalStatus.Rejected)
      )
      arr.push(
        ...getBudget?.filter(x => x.ApprovalStatus === ApprovalStatus.Cancelled)
      )

      setOriginalListing(arr)
    }
  }, [getBudget])

  const {
    loading: BudgetbyStatusLoading,
    error: BudgetbyStatusError,
    data: { getBudgetbyStatus } = { getBudgetbyStatus: [] },
  } = useGetBudgetbyStatusQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  //////////////////////////////////////////////////////////////////
  /////////////////////// ** USE EFFECT **//////////////////////////
  //////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (getBudgetbyStatus && getBudgetbyStatus?.length > 0) {
      setOriginalBudgetStatus(getSearch?.option ?? 'ACTIVE')
    }
  }, [getBudgetbyStatus])

  // console.log(getBudgetbyStatus, 'getBudgetbyStatus')

  // useEffect(() => {
  //   handleSearch(getSearch?.name ?? '', ['DocNo', 'Description'])
  // }, [originalList])

  useEffect(() => {
    if (getSearch?.name && !!originalList) {
      const keys = ['Fyear', 'Version']
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(`'"${getSearch?.name}"`)
      const val = result?.map(x => x.item)
      if (
        val.length !== 0 &&
        getSearch?.name !== `'` &&
        getSearch?.name !== `` &&
        getSearch?.name !== `'""` &&
        getSearch?.name !== undefined
      ) {
        handleSearch(getSearch?.name + '', keys)
      }
    }
  }, [getSearch?.name, originalList])

  // console.log(originalList, 'oriiiii')
  //////////////////////////////////////////////////////////////////
  ////// ** ORIGINALBUDGET DATA FILTERED BY STATUS ** //////
  //////////////////////////////////////////////////////////////////

  const approvalStatusEnum = [
    { name: 'All', ID: 'ALL' },
    { name: 'Pending', ID: 'ACTIVE' },
    { name: 'Verified', ID: 'COMPLETED' },
  ]

  let newFilteredList = filteredList?.filter(
    v =>
      v?.ApprovalStatus === originalBudgetStatus ||
      originalBudgetStatus === 'ALL'
  )

  // console.log(newFilteredList, 'newFilteredList')
  // console.log(originalBudgetStatus, 'originalBudgetStatus')

  //////////////////////////////////////////////////////////////////
  /////////////////////// ** TEXT COLOR ** /////////////////////////
  //////////////////////////////////////////////////////////////////

  let color
  const handleYearStatusColor = type => {
    switch (type) {
      case 'ACTIVE':
        color = '#FF0000'
        break
      case 'COMPLETED':
        color = ''
        break
    }
    return color
  }

  return (
    <>
      {BudgetbyStatusLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-ledger/${CompanyID}/`)
          localStorage.removeItem('searchFilter')
        }} //<- back button action
        smTitle="General Ledger"
        title={user?.companyName}
        currency={`MYR`}
        routeSegments={[
          { name: 'GL' },
          {
            name:
              PageMode === 'original' ? 'Original Budget' : 'Budget Revision',
            current: true,
          },
        ]}
        //rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />

      {/* <SearchHeader
        title={
          PageMode === 'original'
            ? 'Original Budget Listing'
            : 'Budget Revision Listing'
        }
        value={`${getBudget.length ?? 0}`}
        search
        fixed
        onChangeAction={e => {
          handleSearch(e?.target?.value, ['Fyear'])
          localStorage.setItem('searchFilter', e.target.value)
        }}
        defaultValue={getSearch ? getSearch : ''}
        isDefaultValue={!!getSearch}
        onCloseAction={() => handleSearch('', [])}
      /> */}

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['Fyear', 'Description'])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: originalBudgetStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
          }}
          isDefaultValue={!!getSearch}
        />

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setOriginalBudgetStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(originalBudgetStatus ?? 'ACTIVE')}
        />
      </div>

      <ContentWrapper float>
        <List className="core-list">
          {newFilteredList === undefined || newFilteredList?.length === 0
            ? !BudgetLoading && (
                <EmptyList
                  title="No Record found"
                  subtitle="Add a new record now."
                />
              )
            : !BudgetLoading &&
              newFilteredList?.map((el, index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="flex-space mdLabel"
                            // style={{
                            //   textDecoration:
                            //     el.ApprovalStatus === 'REJECTED'
                            //       ? 'line-through'
                            //       : null,
                            // }}
                            style={{
                              color: handleYearStatusColor(el?.ApprovalStatus),
                            }}
                          >
                            {el?.Fyear}
                          </span>
                          {el.ApprovalStatus === 'COMPLETED' &&
                          el.IsDefault === true ? (
                            <span>
                              {' '}
                              <CheckCircleIcon
                                style={{
                                  color: 'limegreen',
                                  fontSize: 'medium',
                                }}
                              />
                            </span>
                          ) : null}
                        </>
                      }
                      secondary={
                        <>
                          <span className="desc flex-space">
                            <span
                              className="fw-medium"
                              style={{
                                textDecoration:
                                  el.ApprovalStatus === 'REJECTED'
                                    ? 'line-through'
                                    : null,
                              }}
                            >
                              {`Latest updated ${formatDate(el.modTs)}.`}
                            </span>
                          </span>

                          {/* <span
                            className={`desc ${RecordStatusColor(
                              el.ApprovalStatus
                            )}`}
                            style={{
                              textDecoration: null,
                              color: RecordStatusColor(el.ApprovalStatus),
                            }}
                          >
                            {el.ApprovalStatus !== ApprovalStatus.Active ? (
                              <img
                                src={ApprovalLog}
                                style={{
                                  width: '12px',
                                  marginBottom: '-3px',
                                  marginRight: '4px',
                                }}
                                onClick={e => {
                                  setWorkFlow(true)
                                  handleClick2(
                                    e,
                                    el?.WorkFlow?.WorkFlowID,
                                    index,
                                    el
                                  )
                                }}
                              />
                            ) : null}
                            <span
                              className="desc"
                              style={{
                                color: RecordStatusColor(el.ApprovalStatus),
                                fontWeight: 'bold',
                              }}
                            >
                              {RecordStatusText(el.ApprovalStatus)}
                            </span>
                          </span> */}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        disabled={handlePermDisabled({
                          companyID: CompanyID,
                          permEnum:
                            AcctPermission.GeneralLedgerOriginalBudgetUpdate,
                        })}
                      >
                        <KeyboardArrowRight
                          onClick={() =>
                            history.push({
                              pathname:
                                el.ApprovalStatus === ApprovalStatus.Active
                                  ? `/general-ledger/${CompanyID}/original-budget/edit`
                                  : ApprovalStatus.Completed
                                  ? `/general-ledger/${CompanyID}/original-budget/detail`
                                  : `/general-ledger/${CompanyID}/original-budget/detail`,
                              state: el,
                            })
                          }
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
        </List>

        <FloatButton
          disabled={handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.GeneralLedgerOriginalBudgetCreate,
          })}
          copyright
          onClick={() =>
            history.push(`/general-ledger/${CompanyID}/original-budget/add`)
          }
        />
      </ContentWrapper>

      <ApprovalLogDialog
        data={menu2?.obj}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Budget'}
      />
    </>
  )
}
