// import {
//   Accordion,
//   AccordionDetails,
//   AccordionProps,
//   AccordionSummary,
//   List,
// } from '@material-ui/core'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import React from 'react'

// interface IExpansion extends AccordionProps {
//   children: any
//   summary?: any
//   expansion?: boolean
// }
// export default function CardExpansion({
//   summary,
//   children,
//   expansion,

//   ...props
// }: IExpansion) {
//   const [expanded, setExpanded] = React.useState<string | false>(false)

//   const handleChange = (panel: string) => (
//     event: React.ChangeEvent<{}>,
//     isExpanded: boolean
//   ) => {
//     setExpanded(isExpanded ? panel : false)
//   }

//   return (
//     <Accordion
//       {...props}
//       className="expand-card"
//       elevation={0}
//       onClick={e => e.stopPropagation()}
//       expanded={expanded === 'panel1' || expansion}
//       onChange={handleChange('panel1')}
//     >
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <List className="core-list">{summary}</List>
//       </AccordionSummary>
//       <AccordionDetails>{children}</AccordionDetails>
//     </Accordion>
//   )
// }

import { List } from '@material-ui/core'
import ExpansionPanel, {
  ExpansionPanelProps,
} from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'

interface IExpansion extends ExpansionPanelProps {
  children: any
  summary?: any
  expansion?: boolean
  customColor?: boolean
  className?: string
  backColor?: any
  headerColor?: any
}
export default function CardExpansion({
  summary,
  children,
  expansion,
  customColor,
  className,
  backColor,
  headerColor,

  ...props
}: IExpansion) {
  // const [expanded, setExpanded] = React.useState<string | false>(expansion)
  const [expanded, setExpanded] = React.useState<boolean>(expansion)

  const handleChange =
    (panel?: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      // setExpanded(isExpanded ? panel : false)
      setExpanded(isExpanded)
    }

  return (
    <ExpansionPanel
      {...props}
      className={'expand-card ' + className}
      elevation={0}
      onClick={e => e.stopPropagation()}
      style={{ backgroundColor: backColor ? backColor : null }}
      // expanded={expanded === 'panel1' || expansion}
      // onChange={handleChange('panel1')}
      expanded={expanded}
      onChange={handleChange()}
    >
      <ExpansionPanelSummary
        style={
          customColor
            ? { backgroundColor: '#d1d1d1' }
            : { backgroundColor: 'white' }
        }
        expandIcon={<ExpandMoreIcon />}
      >
        <List
          className={'core-list'}
          style={
            customColor
              ? { backgroundColor: '#d1d1d1' }
              : { backgroundColor: 'white' }
          }
        >
          {summary}
        </List>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
