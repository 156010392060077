import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs'
import {
  useGetAssignedUserByCompanyQuery,
  useGetWarehouseQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { AvailableUserForm } from './AvailableUserForm'

export const WarehouseUserAccessListing = (props: any) => {
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [value, setValue] = useState(0)
  const { WarehouseID }: any = useParams()
  const [availableUser, setAvailableUser] = useState([])
  const [assignedUser, setAssignedUser] = useState([])
  let location = useLocation()
  const CompanyID: any = location?.state
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  //API SECTION

  const {
    loading: WarehouseLoading,
    error: WarehouseError,
    data: { getWarehouse } = { getWarehouse: {} as any },
  } = useGetWarehouseQuery({
    fetchPolicy: 'network-only',
    variables: { WarehouseID: WarehouseID },
  })

  const {
    data: { getAssignedUserByCompany } = {
      getAssignedUserByCompany: [],
    },
    loading: companyAssignedLoading,
    error: companyAssignedError,
  } = useGetAssignedUserByCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },

    // onCompleted: ({ getAssignedUserByCompany }) => {
    //   setOriginalListing(getAssignedUserByCompany)
    // },
  })

  // const {
  //   loading: userLoading,
  //   error: userError,
  //   data: { getUser } = { getUser: {} as any },
  // } = useGetUserQuery({
  //   fetchPolicy: 'network-only',
  //   variables: { ID },
  //   onCompleted: data => {},
  // })

  // //API SECTION
  useEffect(() => {
    if (getWarehouse) {
      setAssignedUser(getWarehouse[0]?.userAssigned)
    }
    if (getAssignedUserByCompany && getAssignedUserByCompany?.length > 0) {
      const assignedEntity = getWarehouse[0]?.userAssigned?.map(v => v.UserID)

      const filteredUser = getAssignedUserByCompany?.filter(
        v => !assignedEntity?.includes(v?.UserID)
      )

      setAvailableUser(filteredUser)
    }
  }, [getWarehouse, getAssignedUserByCompany])

  return (
    <>
      {companyAssignedLoading && <Loading />}
      {WarehouseLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(`/inventory-control/general-setting/warehouse/`)
        }
        smTitle={'Inventory Control'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Settings' },
          { name: 'User' },
          { name: 'User Access', current: true },
        ]}
        rightRouteSegments={[{ name: 'Assign', current: true }]}
      />

      <StandardTabs
        value={value}
        onChange={handleChange}
        responsive
        fullWidth
        // dynamicInfo
        style={{ boxShadow: 'none' }}
        tabs={[
          {
            label: `Available(${availableUser?.length})`,
            content: (
              <AvailableUserForm
                user={availableUser}
                pageMode={'available'}
                companyID={getWarehouse[0]?.CompanyID}
              />
            ),
          },
          {
            label: `Assigned(${assignedUser?.length})`,
            content: (
              <AvailableUserForm
                user={assignedUser}
                pageMode={'assigned'}
                companyID={getWarehouse[0]?.CompanyID}
              />
            ),
          },
        ]}
      />
    </>
  )
}
