import React, { lazy } from 'react'

const PaymentMethodListing = lazy(() =>
  import('./PaymentMethodListing').then(module => ({
    default: module.PaymentMethodListing,
  }))
)

const paymentMethodRoutes = [
  {
    props: { exact: true, path: '/cash-book/general-setting/payment-method' },
    component: <PaymentMethodListing />,
  },
]

export default paymentMethodRoutes
