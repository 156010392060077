import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { ARDebitNoteDetail } from './ARDebitNoteDetail/ARDebitNoteDetail'
import { DebitNoteStateForm } from './ARDebitNoteForm/ARDebitNoteStateForm'
import { ARMain } from '../ARMain'
import { ARDetail } from '../ARDetail/ARDetail'
import { PDFPreviewV2 } from '../Hooks/PDFPreviewV2'

const debitNoteRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/debit-note/filter',
    },
    component: (
      <AdvancedFilter
        mode="debit-note"
        app="account-receivable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/debit-note',
    },
    component: (
      <ARMain
        arSubmenu="debit-note"
        routeSegments="Debit Note"
        accountType="account-receivable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/debit-note/:DocumentID/detail',
    },
    component: (
      <ARDetail
        arSubmenu="debit-note"
        routeSegments="Debit Note"
        accountType="account-receivable"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/debit-note/:DocumentID/detail`,
    },
    component: (
      <ARDetail
        arSubmenu="debit-note"
        routeSegments="Debit Note"
        accountType="account-receivable"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debit-note/:DebitNoteID/preview`,
    },
    component: (
      <PDFPreviewV2 accountType="account-receivable" docType="debit-note" />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/debit-note/:DebitNoteID/preview`,
    },
    component: (
      <PDFPreviewV2 accountType="account-receivable" docType="debit-note" />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debit-note/:formMode`,
    },
    component: (
      <DebitNoteStateForm
        accountType="account-receivable"
        docType="debit-note"
        routeSegments="Debit Note"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debit-note/:DebitNoteID/edit`,
    },
    component: <DebitNoteStateForm formMode="edit" />,
  },
]

export default debitNoteRoutes
