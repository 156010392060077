import { Fab, FabProps } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import CreateIcon from '@material-ui/icons/Create'
import React from 'react'
interface Props extends FabProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  footer?: boolean
  floatTemplate?: boolean
  floatTotalAmt?: boolean
  isCreate?: boolean
  copyright?: boolean
  noCopyright?: boolean
  saveFooter?: boolean
}
export default function FloatButton(props) {
  const {
    onClick,
    footer,
    floatTotalAmt,
    isCreate,
    copyright,
    noCopyright,
    saveFooter,
    style,
    disabled,
    copyRightWithFooter,
    floatTemplate,
    ...rest
  } = props
  return (
    <Fab
      {...rest}
      disabled={disabled}
      aria-label="add"
      style={{
        ...style,
        bottom: floatTotalAmt ? `100px` : '',
        marginBottom: copyright
          ? '25px'
          : saveFooter
          ? '48px'
          : copyRightWithFooter
          ? '70px'
          : noCopyright
          ? ''
          : '25px',
      }}
      className={`float-btn ${footer ? 'with-footer' : ''}`}
      onClick={onClick}
    >
      {isCreate ? <CreateIcon /> : <Add />}
    </Fab>
  )
}
