import React, { lazy } from 'react'

const PolicySettingsListing = lazy(() =>
  import('./PolicySettingsListing').then(module => ({
    default: module.PolicySettingsListing,
  }))
)

const policySettingsRoutes = [
  {
    props: { exact: true, path: '/cash-book/general-setting/policy-settings' },
    component: <PolicySettingsListing />,
  },
]

export default policySettingsRoutes
