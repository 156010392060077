/**
 *
 * @param phoneNo Mobile Number  for Malaysia
 * @author Abrar
 */
export const PhoneNoValidator = (phoneNo: string) => {
  return /^[+]6?01\d{8}(?:\d{1})?$/.test(phoneNo)
}
/**
 *
 * @param email Email Validation
 * @author Abrar
 */

export const EmailValidator = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

/**
 *
 * @param landlineNo  LandLine Number for Malaysia
 * @author Abrar
 */
export const LandLineValidator = (landlineNo: string) => {
  return /^[+]6?0\d{8}(?:\d{1})?$/.test(landlineNo)
}

/**
 *
 * @param regexvalidation return regex pattern
 * @param PhoneNo
 */

export const EmailValidatorPattern = () => {
  return /\S+@\S+\.\S+/
}
export const PhoneNoValidatorPattern = () => {
  return /^[+]6?01\d{8}(?:\d{1})?$/
}
export const LandLineValidatorPattern = () => {
  return /^[+]6?0\d{8}(?:\d{1})?$/
}
