import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import { FormAllocationTableContent } from 'components/Table/FormAllocationTableContent'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetAllocationDocumentDocument,
  RecordStatus,
  useCreateAllocationMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { useAPAllocationData } from '../Hooks/useAPAllocationData'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'

export const APAllocation = (props: any) => {
  let history = useHistory()
  const { CompanyID, AdvanceID, CreditNoteID, PaymentID, ID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const [val, setVal] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const { accountType, apSubmenu, routeSegments } = props

  //----------------USE FORM---------------------//
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    errors,
    setError,
    clearErrors,
  } = useForm<any>()

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'Allocation',
  })

  //-----------------QUERY------------------------//

  const {
    loading,
    allocLoading,
    primaryKey,
    allocPermissionCheck,
    allocatedObj,
    creditorID,
    documentAmt: docAmt,
    documentDetail,
    docRefTable,
    DocumentID,
  } = useAPAllocationData({
    apSubmenu,
    CompanyID,
    ID: ID,
    append,
  })

  //-------------------------MUTATION----------------------------------//
  const [
    createAllocation,
    {
      loading: CreateAllocationLoading,
      called: CreateAllocationCalled,
      error: CreateAllocationError,
    },
  ] = useCreateAllocationMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.allocationRecord())
      setTimeout(() => {
        history.goBack()
      }, 500)
    },
  })

  //-----------------------------ONSUBMIT-------------------------------//

  const onSubmit = (data, status) => {
    if (val == false) {
      setVal(true)
      createAllocation({
        variables: {
          entityID: DocumentID,
          input: watch('Allocation')
            ?.filter(
              alloc => Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
            )
            ?.map(alloc => {
              const mainCreditOrDebit =
                alloc?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
              return {
                ...(alloc?.AllocationID
                  ? { AllocationID: alloc?.AllocationID }
                  : null),
                AllocationAmt: Number(alloc?.AllocationAmt),
                CreditOrDebit: alloc?.CreditOrDebit,
                CompanyID: CompanyID,
                // for allocated table
                [`${alloc?.CreditOrDebit}RefTable`]: alloc?.[
                  `${alloc?.CreditOrDebit}RefTable`
                ],
                [`${alloc?.CreditOrDebit}ID`]: alloc?.[
                  `${alloc?.CreditOrDebit}ID`
                ],
                // main table
                [`${mainCreditOrDebit}RefTable`]: docRefTable,
              }
            }),
        },
        refetchQueries: [
          {
            query: GetAllocationDocumentDocument,
            variables: {
              entityID: DocumentID,
              companyID: CompanyID,
              refTable: docRefTable,
              creditorDebtorID: creditorID,
            },
          },
        ],
      })
      // }
    }
  }

  //----------------------------FOOTER---------------------------//

  const allocationTotal = watch('Allocation')?.reduce(
    (total, curVal) => (total = total + curVal.AllocationAmt),
    0
  )

  const minusamt = docAmt - allocationTotal

  const createUpdateCalled = CreateAllocationCalled

  const checkExceedAllocation = watch('Allocation')?.map((alloc, index) => {
    if (watch(`Allocation[${index}].AllocationAmt`) > alloc?.AllocationAmt)
      return true
    else return false
  })

  const submitFooterOption = {
    name: 'Save',
    onClick: e => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
    },
    color: 'primary',
    props: { type: 'submit' },
    disabled:
      parseFloat(allocationTotal) > docAmt
        ? true
        : !!checkExceedAllocation?.find(x => x === true)
        ? true
        : false,
  }

  const footerOptions: any[] = [submitFooterOption]

  //----------------------------USEEFFECT-------------------------------//
  useEffect(() => {
    if (editData?.Allocation) {
      let allocatedArr = editData?.Allocation
      allocatedArr?.map(allocated => {
        if (!(allocated[`${allocated?.CreditOrDebit}ID`] in allocatedObj)) {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] =
            allocated?.AllocationAmt
        } else {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] +=
            allocated?.AllocationAmt
        }
      })
    }
  }, [editData])

  return (
    <>
      {loading && <Loading />}
      {allocLoading && <Loading />}
      {CreateAllocationLoading && <Loading />}
      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="close"
        smTitle={smTitle.ACCOUNT_PAYABLE}
        title={user?.companyName}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Account Payable' },
          { name: routeSegments, current: true },
        ]}
        rightRouteSegments={[{ name: 'Allocation', current: true }]}
      />

      <DynamicSubHeader
        title={documentDetail?.DocNo}
        rightText={formatDate(documentDetail?.DocDate)}
        infoLine={documentDetail?.CreditorAccount?.CompanyName}
        rightInfoLine={
          <span className="desc flex-space">
            <span className="desc flex-space c-orange">
              {amtStr(documentDetail?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo footer float>
        <FormAllocationTableContent
          fields={fields}
          watch={watch}
          errors={errors}
          register={register}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          setError={setError}
          allocatedObj={allocatedObj}
          docAmt={docAmt}
          totalAmt={allocationTotal}
          headerStyle={{
            backgroundColor: '#faf2e8',
            color: '#ff9800',
            fontWeight: '700',
          }}
          headerRightTitle={'Allocate Amt'}
          hasCheckbox={true}
        />
      </ContentWrapper>

      <TotalAmountFooter
        balanceInfo={true}
        balanceAmt={minusamt}
        TotalAllocationAmt={allocationTotal}
      />

      {footerOptions?.length > 0 ? (
        <AccountFooter options={[...footerOptions]} />
      ) : null}
    </>
  )
}
