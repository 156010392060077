import React, { lazy } from 'react'

const WorkdeskMain = lazy(() =>
  import('./WorkdeskMain').then(module => ({
    default: module.WorkdeskMain,
  }))
)

const CompanyToDoListing = lazy(() =>
  import('./CompanyToDoListing').then(module => ({
    default: module.CompanyToDoListing,
  }))
)

const WorkdeskHistory = lazy(() =>
  import('./WorkdeskHistory').then(module => ({
    default: module.WorkdeskHistory,
  }))
)

const workdeskRoutes = [
  {
    props: { exact: true, path: '/workdesk' },
    component: <WorkdeskMain />,
  },
  {
    props: { exact: true, path: '/workdesk/:module' },
    component: <CompanyToDoListing />,
  },

  {
    props: { exact: true, path: '/workdesk/:module/workdesk-history' },
    component: <WorkdeskHistory />,
  },
]

export default workdeskRoutes
