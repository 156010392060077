import React, { lazy } from 'react'

const PDFPreviewIC = lazy(() =>
  import('../Hooks/PDFPreview/PDFPreviewIC').then(module => ({
    default: module.PDFPreviewIC,
  }))
)

export const icPdfRoutes = [
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/:icSubmenu/:ID/preview`,
    },
    component: <PDFPreviewIC />,
  },
  // after submit
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/:icSubmenu/:CompanyID/:ID/preview`,
    },
    component: <PDFPreviewIC />,
  },
]
