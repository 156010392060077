import React from 'react'
import { APDigitalReportSubmenu } from './APDigitalReportSubmenu'
import { APOverduePayableParamsForm } from './Parameters/APOverduePayableParamsForm'
import { APCreditorLedgerDetailbyDocDateParamsForm } from './Parameters/APCreditorDetailLedgerByDocDateParamsForm'
import { APCreditorAgingDetailbyDocDateParamsForm } from './Parameters/APCreditorAgingDetailbyDocDateParamsForm'
import { APOverduePayableReport } from './GeneratedReport/APOverduePayableReport'
import { APCreditorAgingByDocDateReport } from './GeneratedReport/APCreditorAgingByDocDateReport'
import { APCreditorLedgerDetailByDocDateReport } from './GeneratedReport/APCreditorLedgerDetailByDocDateReport'
import { APCreditorAgingSummarybyTrxDateParamsForm } from './Parameters/APCreditorAgingSummarybyTrxDateParamsForms'
import { APCreditorAgingSummaryByTrxDateReport } from './GeneratedReport/APCreditorAgingSummaryByTrxDateReport'
import { APCreditorControlSummaryForms } from './Parameters/APCreditorControlSummaryForms'
import { APCreditorControlSummaryReport } from './GeneratedReport/APCreditorControlSummaryReport'
import { APCreditorControlDetailForms } from './Parameters/APCreditorControlDetailForms '
import { APCreditorControlDetailReport } from './GeneratedReport/APCreditorControlDetailReport'
import { APCreditorAgingSummarybyDocDateParamsForm } from './Parameters/APCreditorAgingSummaryByDocDateParamsForm'
import { APCreditorAgingSummaryByDocDateReport } from './GeneratedReport/APCreditorAgingSummaryByDocDateReport'
import { APCreditorAgingByTrxDateReport } from './GeneratedReport/APCreditorAgingByTrxDateReport'
import { APCreditorAgingDetailbyTrxDateParamsForm } from './Parameters/APCreditorAgingDetailByTrxDateParamsForm'
import { APCreditorLedgerDetailByTrxDateReport } from './GeneratedReport/APCreditorLedgerDetailByTrxDateReport'
import { APCreditorLedgerDetailbyTrxDateParamsForm } from './Parameters/APCreditorDetailLedgerByTrxDateParamsForms'
import { APAdvanceLedgerParamsForm } from './Parameters/APAdvanceLedgerParamsForm'
import { APAdvanceLedgerReport } from './GeneratedReport/APAdvanceLedgerReport'
import { APEInvoiceListingParamsForm } from './Parameters/APEInvoiceListingParamsForm'
import { APEInvoiceListingReport } from './GeneratedReport/APEInvoiceListingReport'
const apDigitalReportRoutes = [
  {
    props: {
      exact: true,
      path: '/digital-reports/account-payable',
    },
    component: <APDigitalReportSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/account-payable/ap-overdue-payable/generated',
    },
    component: <APOverduePayableReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-aging-docdate/generated',
    },
    component: <APCreditorAgingByDocDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-aging-trxdate/generated',
    },
    component: <APCreditorAgingByTrxDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-ledger-detail-docdate/generated',
    },
    component: <APCreditorLedgerDetailByDocDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-ledger-detail-trx-date/generated',
    },
    component: <APCreditorLedgerDetailByTrxDateReport />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/account-payable/:CompanyID/ap-creditor-aging-summary-trx-date/generated',
  //   },
  //   component: <APCreditorAgingSummaryByTrxDateReport />,
  // },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-aging-summary-doc-date/generated',
    },
    component: <APCreditorAgingSummaryByDocDateReport />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/account-payable/ap-overdue-payable/parameters',
    },
    component: <APOverduePayableParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-ledger-detail-by-doc-date/parameters',
    },
    component: <APCreditorLedgerDetailbyDocDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-ledger-detail-by-trx-date/parameters',
    },
    component: <APCreditorLedgerDetailbyTrxDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-aging-detail-by-doc-date/parameters',
    },
    component: <APCreditorAgingDetailbyDocDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-aging-detail-by-trx-date/parameters',
    },
    component: <APCreditorAgingDetailbyTrxDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-aging-summary-trx-date/parameters',
    },
    component: <APCreditorAgingSummarybyTrxDateParamsForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-aging-summary-trx-date/generated',
    },
    component: <APCreditorAgingSummaryByTrxDateReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-aging-summary-doc-date/parameters',
    },
    component: <APCreditorAgingSummarybyDocDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-control-summary/parameters',
    },
    component: <APCreditorControlSummaryForms />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-control-summary/generated',
    },
    component: <APCreditorControlSummaryReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-control-detail/parameters',
    },
    component: <APCreditorControlDetailForms />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-payable/ap-creditor-control-detail/generated',
    },
    component: <APCreditorControlDetailReport />,
  },
  // APAdvanceLedgerParamsForm
  {
    props: {
      exact: true,
      path: '/digital-reports/account-payable/ap-advance-ledger/parameters',
    },
    component: <APAdvanceLedgerParamsForm />,
  },
  // APAdvance
  {
    props: {
      exact: true,
      path: '/digital-reports/account-payable/ap-advance-ledger/generated',
    },
    component: <APAdvanceLedgerReport />,
  },
  // e-InvoiceListingParamsForm
  {
    props: {
      exact: true,
      path: '/digital-reports/account-payable/e-invoice-listing/parameters',
    },
    component: <APEInvoiceListingParamsForm />,
  },
  // e-InvoiceListingreport
  {
    props: {
      exact: true,
      path: '/digital-reports/account-payable/e-invoice-listing/generated',
    },
    component: <APEInvoiceListingReport />,
  },
]

export default apDigitalReportRoutes
