import React, { lazy } from 'react'

const SupplierAccountListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/SupplierAccount/SupplierAccountListing'
  ).then(module => ({
    default: module.SupplierAccountListing,
  }))
)

const SupplierProfileDetail = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/SupplierAccount/SupplierProfileDetail'
  ).then(module => ({
    default: module.SupplierProfileDetail,
  }))
)

const SupplierLedgerDetail = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/SupplierAccount/SupplierLedgerDetail'
  ).then(module => ({
    default: module.SupplierLedgerDetail,
  }))
)

const supplierAccountRoutes = [
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/supplier-account`,
    },
    component: <SupplierAccountListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/supplier-account/supplier-profile/:CreditorAccountID/detail',
    },
    component: <SupplierProfileDetail />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/supplier-account/supplier-ledger/:CreditorAccountID/detail',
    },
    component: <SupplierLedgerDetail />,
  },
]

export default supplierAccountRoutes
