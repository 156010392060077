import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import OrangeDollarIcon from 'assets/icons/Money/orange-dollar.svg'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { DetailAllocationTableContent } from 'components/Table/DetailAllocationTableContent'
import {
  useDocumentListingQuery,
  useGetUsersByAccountAndSoftwareQuery,
  UserSelection,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { APRefundDetailContent } from './APRefundDetailContent'

export const APRefundDetail = props => {
  let history = useHistory()
  const { CompanyID, RefundID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const refundDetail: any = location?.state
  const [openWorkFlow, setWorkFlow] = useState(false)

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: RefundID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const allocationTotal = refundDetail?.Allocation?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.AllocationAmt,
    0
  )

  return (
    <>
      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="back"
        smTitle={'Account Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Account Payable' },
          { name: 'AP Submenu' },
          { name: 'Refund Posting', current: true },
        ]}
        currency={user?.companyCurrencyCode}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {refundDetail?.DocNo}
            </span>
          </>
        }
        rightText={
          <span className="c-orange">{formatDate(refundDetail?.DocDate)}</span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
            >
              {refundDetail?.Description}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <img
              src={OrangeDollarIcon}
              style={{
                width: '12px',
                marginBottom: '-2px',
                marginRight: '3px',
              }}
            />
            <span className="desc flex-space c-orange">
              {amtStr(refundDetail?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          <APRefundDetailContent
            listEl={refundDetail}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>

        {refundDetail?.Allocation?.length > 0 && (
          <>
            <CardContents
              section={{
                header: {
                  title: 'Allocation(s)',
                  rightTitle: amtStr(allocationTotal),
                  customFontSizeClass: 'xsTitle',
                  dollarIcon: (
                    <img
                      src={OrangeDollarIcon}
                      style={{
                        width: '15px',
                        height: '15px',
                        marginBottom: '-2px',
                        marginRight: '3px',
                        marginLeft: '3px',
                      }}
                    />
                  ),
                },
              }}
            >
              <DetailAllocationTableContent
                listItem={refundDetail?.Allocation}
              />
            </CardContents>
          </>
        )}
      </ContentWrapper>
      <ApprovalLogDialog
        data={refundDetail}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Refund'}
      />
    </>
  )
}
