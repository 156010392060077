import React from 'react'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import { withStyles, Theme, createStyles, Badge } from '@material-ui/core'
interface IIconProps {
  icon?: any
  image?: any
  imageType?: string
  children?: any
  badgeType?: string
  badgeNumber?: any
}
const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  })
)(Badge)
export default function IconItem(props: IIconProps) {
  const { image, imageType, badgeType, badgeNumber, icon } = props
  const handleAvatarType = imageType => {
    let style
    switch (imageType) {
      case 'svg':
        style = 'avatar-svg'
        break
      case 'img':
        style = 'avatar-img'
        break
      case 'project':
        style = 'avatarProj'
        break
      default:
        style = null
        break
    }
    return style
  }

  const handleBadgeType = badgeType => {
    let style
    switch (badgeType) {
      case 'ripple':
        style = (
          <StyledBadge
            overlap="circle"
            className="list-badge"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <Avatar className={handleAvatarType(imageType)}>
              <img src={image} alt="ifca" />
            </Avatar>
          </StyledBadge>
        )
        break
      case 'number':
        style = (
          <Badge
            className="list-badge list-badge-no"
            badgeContent={badgeNumber}
          >
            {!!icon ? (
              icon
            ) : (
              <Avatar className={handleAvatarType(imageType)}>
                <img src={image} alt="ifca" />
              </Avatar>
            )}
          </Badge>
        )
        break
      case 'default':
        style = (
          <Avatar
            className={handleAvatarType(imageType)}
            style={{
              boxShadow:
                imageType === 'img' || imageType === 'project'
                  ? '0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1)'
                  : null,
            }}
          >
            <img src={image} alt="ifca" />
          </Avatar>
        )
        break
      default:
        style = null
        break
    }
    return style
  }
  return (
    <ListItemAvatar
      style={
        icon && {
          marginRight: '-20px',
          marginLeft: '-20px',
          paddingBottom: '5px',
        }
      }
    >
      {handleBadgeType(badgeType)}
    </ListItemAvatar>
  )
}
