import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight, Tune } from '@material-ui/icons'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import Fuse from 'fuse.js'
import {
  AcctPermission,
  useDebtorAccountTotalDueQuery,
  // useGetDebtorAccountQuery,
  useGetDebtorAccountLazyQuery,
  useGetDebtorLatestUpdatedDateQuery,
  useGetHomeSummaryQuery,
  useGetOsDebtorDebitDocLazyQuery,
  useGetTaxSchemeQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/CustomHooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import {
  checkDueDate,
  formatDate,
} from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ExcelInput } from '@ifca-root/react-component/src/components/Input/ExcelUploadInput'
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2'
import DownloadIcon from '@material-ui/icons/GetApp'
import ExcelIcon from '@account-root/account-react/src/assets/icons/excel.svg'
import { DebtorAccountTemplateForm } from './DebtorAccountTemplateForm'
import InfiniteScroll from 'react-infinite-scroll-component'

export const DebtorAccountListing = (props: any) => {
  const getSearch = localStorage?.getItem('searchFilter')
  let history = useHistory()
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const { CompanyID, DebtorAccountID }: any = useParams()
  const [totalDocAmt, setTotalDocAmt] = useState(0)
  const [debtorName, setDebtorName] = useState('')
  const [osDialog, setOsDialog] = useState(false)
  const [TotalLength, setTotalLength] = useState(0)

  const {
    loading,
    called,
    data: {
      loggedInUserProfile,
      getAccountPermission,
      getCompany: curCompany,
      getRolePermission,
    } = {
      loggedInUserProfile: null,
      getAccountPermission: [],
      getCompany: [],
      getRolePermission: [],
    },
  } = useGetHomeSummaryQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })

  const [
    loadData,
    {
      fetchMore,
      loading: DebtorAccountLoading,
      error: DebtorAccountError,
      data: { getDebtorAccount, getDebtorAccountCount } = {
        getDebtorAccount: [],
        getDebtorAccountCount: null,
      },
    },
  ] = useGetDebtorAccountLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      DebtorAccountID: DebtorAccountID,
      CompanyID: CompanyID,
    },
    onCompleted: ({ getDebtorAccount, getDebtorAccountCount }) => {
      if (getDebtorAccount?.length > 0) {
        setOriginalListing([...filteredList, ...getDebtorAccount])
      }
      if (!!getDebtorAccountCount) {
        setTotalLength(getDebtorAccountCount)
      }
    },
  })

  useEffect(() => {
    loadData({
      variables: {
        CompanyID: CompanyID,
        take: 30,
        skip: 0,
      },
    })
  }, [])

  const {
    loading: DebtorLatestUpdatedDateLoading,
    called: DebtorLatestUpdatedDateCalled,
    data: { getDebtorLatestUpdatedDate } = { getDebtorLatestUpdatedDate: [] },
  } = useGetDebtorLatestUpdatedDateQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  const {
    loading: DebtorAccountTotalDueLoading,
    called: DebtorAccountTotalDueCalled,
    data: { DebtorAccountTotalDue } = { DebtorAccountTotalDue: [] },
  } = useDebtorAccountTotalDueQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  const [
    loadAPCreditDoc,
    {
      loading: arDocLoading,
      error: arDocError,
      data: {
        getARInvoice,
        getARDebitNote,
        getARCreditNote,
        getAROfficialReceipt,
        getARRefund,
      } = {
        getARInvoice: [],
        getARDebitNote: [],
        getARCreditNote: [],
        getAROfficialReceipt: [],
        getARRefund: [],
      },
    },
  ] = useGetOsDebtorDebitDocLazyQuery({
    fetchPolicy: 'network-only',
  })

  let outstandingCn: any[] = []
  let outstandingDn: any[] = []
  let outstandingInvoices: any[] = []
  let outstandingReceipt: any[] = []
  let outstandingRefund: any[] = []

  outstandingInvoices = getARInvoice.filter(inv => inv.BalanceAmt > 0)
  outstandingDn = getARDebitNote.filter(dn => dn.BalanceAmt > 0)

  outstandingCn = getARCreditNote
    ?.filter(ldg => ldg.BalanceAmt > 0)
    .map(ldg => ({ ...ldg, Negative: true }))

  outstandingReceipt = getAROfficialReceipt
    ?.filter(ldg => ldg.BalanceAmt > 0)
    .map(ldg => ({ ...ldg, Negative: true }))

  outstandingRefund = getARRefund.filter(rf => rf.BalanceAmt > 0)

  const outstandingArray: any[] = [
    ...outstandingInvoices,
    ...outstandingCn.map((cnItem: any) => ({
      ...cnItem,
      BalanceAmt: -cnItem.BalanceAmt,
    })),
    ...outstandingDn,
    ...outstandingReceipt.map((receiptItem: any) => ({
      ...receiptItem,
      BalanceAmt: -receiptItem.BalanceAmt,
    })),
    ...outstandingRefund,
  ]?.sort((a, b) => (a?.DocDate > b?.DocDate ? 1 : -1))

  const handleOSDialog = associateID => {
    setOsDialog(true)
    loadAPCreditDoc({
      variables: { CompanyID: CompanyID, DebtorAccountID: associateID },
    })
  }

  let newFilteredListWithTotalDocAmt = filteredList.map(subject => {
    let TotalDueAmount = DebtorAccountTotalDue.find(
      f => f?.DebtorAccountID === subject?.DebtorAccountID
    )
    let LatestUpdatedDate = getDebtorLatestUpdatedDate.find(
      e => e?.DebtorAccountID === subject?.DebtorAccountID
    )
    return { ...subject, TotalDueAmount, LatestUpdatedDate }
  })

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar: opensnackbar,
    setOpenSnackBar: setOpensnackbar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onDownloadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    setErrorDialog,
    resetFileUpload,
  } = useExcelUploadVersion2()

  const {
    loading: taxLoading,
    error: taxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: {
      AccTaxClass: 'INPUT',
      orderByAsc: 'Code',
    },
    onCompleted: () => {},
  })

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadData({
          variables: {
            CompanyID: CompanyID,
            skip: 0,
            take: 30,
            searchValue: change,
          },
        })
      }, 1000)
    )
  }
  /**ACL */

  const BalAmt = outstandingArray?.reduce(
    (total, curValue) => total + curValue?.BalanceAmt,
    0
  )

  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  return (
    <>
      {loading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {DebtorAccountTotalDueLoading && <Loading />}
      {DebtorLatestUpdatedDateLoading && <Loading />}
      {arDocLoading && <Loading />}
      {taxLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-receivable/${CompanyID}`)
          localStorage.removeItem('searchFilter')
        }} //<- back button action
        smTitle={'Account Receivable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'AR' },
          { name: 'Debtor Accounts', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchHeader
          title="Debtor Listing"
          value={`${TotalLength}`}
          search
          onChangeAction={e => {
            //handleSearch(e?.target?.value, ['DebtorName'])
            localStorage.setItem('searchFilter', e.target.value)
            changeDelay(e.target.value)
          }}
          defaultValue={getSearch ? getSearch : ''}
          isDefaultValue={!!getSearch}
          onCloseAction={() => {
            handleSearch('', [])
            localStorage.removeItem('searchFilter')
            setOriginalListing([])
            loadData({
              variables: {
                CompanyID: CompanyID,
                skip: 0,
                take: 30,
              },
            })
          }}
        />
      </div>

      <ContentWrapper footer overflow>
        <div className="message-alert text-noflow">
          <div className="flex-space">
            <IconText
              icon={
                <img
                  src={ExcelIcon}
                  style={{ width: '19px', paddingRight: '5px' }}
                />
              }
              font="mdDesc"
              children={
                <>
                  <span style={{ fontWeight: 'bold' }}>Upload </span>{' '}
                  <span>Excel Template</span>
                </>
              }
            ></IconText>
          </div>
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                minWidth: '0px',
                width: '32px',
                height: '24px',
                margin: '-2px 0px -2px 0px',
              }}
              onClick={() => {
                setOpenFileUpload(true)
              }}
            >
              <DownloadIcon
                style={{
                  width: '17px',
                  height: '17px',
                }}
              />
            </Button>
          </div>
        </div>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                loadData({
                  variables: {
                    CompanyID: CompanyID,
                    skip: currentLength,
                    take: 30,
                    searchValue: getSearch === '' ? undefined : getSearch,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                DebtorAccountLoading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {newFilteredListWithTotalDocAmt?.map((el, index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <span className="xsTitle flex-space">
                            {el.DebtorName}{' '}
                          </span>
                          <span
                            className="xsTitle c-orange click-text"
                            onClick={(e: any) => {
                              handleOSDialog(el?.DebtorAccountID)
                              setTotalDocAmt(el?.TotalAmt)
                              setDebtorName(el?.DebtorName)
                            }}
                          >
                            {el?.TotalOSAmt < 0
                              ? `(${amtStr(el?.TotalOSAmt * -1)})`
                              : amtStr(el?.TotalOSAmt)}
                          </span>
                        </>
                      }
                      secondary={
                        <span className="desc">
                          <span className="fw-medium">Total Due:</span>
                          &nbsp;
                          <span>
                            <span className="desc ">
                              {amtStr(el?.TotalDueAmount?.TotalDueAmount)}
                            </span>
                            <span> |</span>
                            &nbsp;
                            <span className="desc ">{`Last Payment Date: ${formatDate(
                              el?.LatestUpdatedDate?.LatestUpdatedDate
                            )} `}</span>
                          </span>
                        </span>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <KeyboardArrowRight
                          onClick={() =>
                            history.push({
                              pathname: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${el.DebtorAccountID}`,
                            })
                          }
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </InfiniteScroll>
          )}
        </List>
        <FloatButton
          onClick={() =>
            history.push(`/account-receivable/${CompanyID}/debtor-account/add`)
          }
          disabled={handlePermDisabled({
            permEnum: AcctPermission.AccReceivableDebtorAccCreate,
          })}
        />
      </ContentWrapper>
      <CommonDialog
        fullWidth={true}
        open={osDialog}
        onClose={() => setOsDialog(false)}
        sections={{
          header: {
            title: debtorName,
            infoLine: 'O/S Amount',
            rightInfoLine: (() => {
              return BalAmt < 0 ? `(${amtStr(BalAmt * -1)})` : amtStr(BalAmt)
            })(),
          },
          body: () => (
            <>
              <List className="core-list">
                {outstandingArray?.length === 0 ||
                outstandingArray === undefined ? (
                  <EmptyList title={'No Outstanding Document'} />
                ) : (
                  outstandingArray?.map((el, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <span className="desc date-width">
                              {formatDate(el?.DocDate)}
                            </span>
                            <span className="mdLabel flex-space">
                              {el?.DocNo}
                            </span>
                            <span className="xxTitle">
                              <IconText
                                font="desc xxTitle"
                                children={
                                  el?.Negative
                                    ? `-${amtStr(el?.DocAmt)}`
                                    : amtStr(el?.DocAmt)
                                }
                                childrenStyle={{
                                  color: el?.Negative ? 'red' : '',
                                }}
                              />
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            {!!el?.DueDate && (
                              <span
                                className="desc date-width"
                                style={{
                                  color: checkDueDate(el?.DueDate)
                                    ? 'red'
                                    : null,
                                }}
                              >
                                {formatDate(el?.DueDate)}
                              </span>
                            )}
                            <span className="desc flex-space text-overflow">
                              {el?.Description}
                            </span>
                            <span className="xxTitle">
                              <IconText
                                font=" desc xxTitle"
                                children={amtStr(el?.BalanceAmt)}
                              />
                            </span>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                )}
              </List>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setOsDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ExcelInput
        showFooter={false}
        showDupErrors={true}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          history.push({
            pathname: `/account-receivable/${CompanyID}/debtor-account/excel-validation`,
            state: {
              excelData: currentData?.map(x => {
                return {
                  DebtorType:
                    x?.DebtorType === null ? null : `${x?.DebtorType}`,
                  CompanyRegNo:
                    x?.CompanyRegNo === null ? null : `${x?.CompanyRegNo}`,
                  DebtorName:
                    x?.DebtorName === null ? null : `${x?.DebtorName}`,
                  MobileNo: x?.MobileNo === null ? null : `${x?.MobileNo}`,
                  OfficeNo: x?.OfficeNo === null ? null : `${x?.OfficeNo}`,
                  Email: x?.Email === null ? null : x?.Email?.text,
                  CreditTerm:
                    x?.CreditTerm === null ? null : `${x?.CreditTerm}`,
                  Tax: x?.Tax === null ? null : `${x?.Tax}`,
                  IsVIP: x?.IsVIP === null ? null : `${x?.IsVIP}`,
                  IsCompany: x?.IsCompany === null ? null : `${x?.IsCompany}`,
                  GSTRegNo: x?.GSTRegNo === null ? null : `${x?.GSTRegNo}`,
                  GSTStartDate:
                    x?.GSTStartDate === null ? null : `${x?.GSTStartDate}`,
                  Remark: x?.Remark === null ? null : `${x?.Remark}`,
                  Address: x?.Address === null ? null : `${x?.Address}`,
                  Country: x?.Country === null ? null : `${x?.Country}`,
                  State: x?.State === null ? null : `${x?.State}`,
                  City: x?.City === null ? null : `${x?.City}`,
                  PostCode: x?.PostCode === null ? null : `${x?.PostCode}`,
                  RowNo: x?.rowNo,
                }
              }),
              fileTitle: fileTitle,
            },
          })
          setOpenFileUpload(false)
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e })
        }}
        downloadButton={true}
        onDownloadTemplate={() => {
          onDownloadTemplate({
            name: `Debtor Account Spreadsheet`,
            workSheetName: `Debtor Account List`,
            headerSetup: DebtorAccountTemplateForm,
            isHeaderLocked: true,
            defaultHeaderFillStyle: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            },
            defaultHeaderBorderStyle: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            extraListData: [
              {
                data: ['Yes', 'No'],
                worksheetName: 'IsVIP',
                dataType: 'IsVIP',
              },
              {
                data: ['Yes', 'No'],
                worksheetName: 'IsCompany',
                dataType: 'IsCompany',
              },
              {
                data: getTaxScheme?.map(x => x?.Code),
                worksheetName: 'Tax',
                dataType: 'Tax',
              },
            ],
          })
        }}
        snackBarProps={{
          openSnackBar: opensnackbar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpensnackbar,
          onClickButton: () => {
            setOpensnackbar(false)
          },
        }}
      />
    </>
  )
}
