import React from 'react'

export const CardExpansionHeader = (props: any) => {
  const {
    title,
    rightTitle,
    rightIcon,
    style,
    // customTrigger,
  } = props

  return (
    <div
      style={
        style ?? {
          alignItems: 'center',
          height: '40px',
          marginBottom: '10px',
          marginLeft: '-5px',
        }
      }
      className="infoline-content"
    >
      <span
        style={{
          fontWeight: 600,
          fontSize: '12px',
          color: '#FF9800',
          backgroundColor: '#e9e9e9',
          paddingLeft: '6px',
          //marginLeft: '-12px',
          lineHeight: '30px',
        }}
        className="mdTitle flex-space"
      >
        {title}
      </span>
      <span
        style={{
          fontWeight: 600,
          fontSize: '12px',
          color: '#FF9800',
          backgroundColor: '#e9e9e9',
          paddingRight: '12px',
          marginRight: '-12px',
          lineHeight: '30px',

          position: 'relative',
        }}
        className="mdTitle "
      >
        {rightIcon}
        {rightTitle}
      </span>
    </div>
  )
}
