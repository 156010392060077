import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import Advance from 'assets/icons/ARAPModule/AccountPayable/advances-posting.svg'
import CreditNote from 'assets/icons/ARAPModule/AccountPayable/credit-note-posting.svg'
import DebitNote from 'assets/icons/ARAPModule/AccountPayable/Group 64855.svg'
import Invoices from 'assets/icons/ARAPModule/AccountPayable/invoices-posting.svg'
import Payment from 'assets/icons/ARAPModule/AccountPayable/payment-posting.svg'
import Refund from 'assets/icons/ARAPModule/AccountPayable/refund-posting.svg'
import Debtor from 'assets/icons/ARAPModule/debtor-creditor-acc.svg'
import DebtorProfile from 'assets/icons/ARAPModule/GeneralSetting/creditor-profile.svg'
import DigitalReports from 'assets/icons/digital-reporting.svg'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { SwitchDialog } from 'components/Switch/SwitchDialog'
import AppContext from 'containers/App/Store/AppContext'
import {
  AcctPermission,
  RecordStatus,
  useGetArSubmenuSummaryLazyQuery,
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
  useGetDebtorAccountQuery,
  useGetDebtorSubmenuSummaryLazyQuery,
  useGetDebtorTrxSummaryLazyQuery,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const ARSubmenu = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID, DebtorAccountID }: any = useParams()

  const [change, setSwitch] = useState(false)
  const [homeCompanyID, setHomeCompanyID] = useState<string>(CompanyID)

  if (globalState?.mode === '') {
    dispatch({
      type: 'mode',
      payload: 'general-ledger',
    })
  }

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }

  useEffect(() => {
    document.title = `AX - Acc Receivable`
  }, [])

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RecordStatus: RecordStatus.Active,
    },
  })

  //Debtor Account Company Assignment//

  const {
    loading: DebtorAccountLoading,
    error: DebtorAccountError,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      DebtorAccountID: DebtorAccountID,
      CompanyID: CompanyID,
    },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    loadARSubmenuSummary,
    {
      loading: ARSubmenuSummaryLoading,
      data: {
        arAdvanceCount,
        arInvoiceCount,
        arCreditNoteCount,
        arDebitNoteCount,
        arOfficialReceiptCount,
        arRefundCount,
      } = {
        arAdvanceCount: 0,
        arInvoiceCount: 0,
        arCreditNoteCount: 0,
        arDebitNoteCount: 0,
        arOfficialReceiptCount: 0,
        arRefundCount: 0,
      },
    },
  ] = useGetArSubmenuSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    loadDebtorSubmenuSummary,
    {
      loading: DebtorSubmenuSummaryLoading,
      data: {
        arAdvanceTotalAmt,
        arInvoiceTotalAmt,
        arCreditNoteTotalAmt,
        arDebitNoteTotalAmt,
        arOfficialReceiptTotalAmt,
        arRefundTotalAmt,
      } = {
        arAdvanceTotalAmt: 0,
        arInvoiceTotalAmt: 0,
        arCreditNoteTotalAmt: 0,
        arDebitNoteTotalAmt: 0,
        arOfficialReceiptTotalAmt: 0,
        arRefundTotalAmt: 0,
      },
    },
  ] = useGetDebtorSubmenuSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })

  const DebtorAccountTotalAmount =
    arInvoiceTotalAmt?.total +
    arDebitNoteTotalAmt?.total -
    arCreditNoteTotalAmt?.total -
    arOfficialReceiptTotalAmt?.total +
    arRefundTotalAmt?.total -
    arAdvanceTotalAmt?.total

  const [
    loadDebtorTrxSummary,
    {
      loading: DebtorTrxSummaryLoading,
      data: {
        arAdvanceLatestUpdate,
        arInvoiceLatestUpdate,
        arCreditNoteLatestUpdate,
        arDebitNoteLatestUpdate,
        arOfficialReceiptLatestUpdate,
        arRefundLatestUpdate,
      } = {
        arAdvanceLatestUpdate: undefined,
        arInvoiceLatestUpdate: undefined,
        arCreditNoteLatestUpdate: undefined,
        arDebitNoteLatestUpdate: undefined,
        arOfficialReceiptLatestUpdate: undefined,
        arRefundLatestUpdate: undefined,
      },
    },
  ] = useGetDebtorTrxSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (DebtorAccountID) {
      loadDebtorSubmenuSummary({
        variables: {
          CompanyID: CompanyID,
          DebtorAccountID: DebtorAccountID,
        },
      })
      loadDebtorTrxSummary({
        variables: {
          CompanyID: CompanyID,
          DebtorAccountID: DebtorAccountID,
        },
      })
    } else {
      loadARSubmenuSummary({
        variables: { CompanyID: CompanyID },
      })
    }
  }, [DebtorAccountID, CompanyID])

  const { handlePermHidden } = usePermissionChecker()

  /* -------------------------------------------- */
  /*                  AR SUBMENU                  */
  /* -------------------------------------------- */
  const debtorAcc = {
    path: `/account-receivable/${CompanyID}/debtor-account`,
    icon: Debtor,
    title: `Debtor Accounts`,
    label1: `${getDebtorAccount?.length} Debtor`,
    permission: AcctPermission.AccReceivableDebtorAccView,
    deployed: true,
  }

  const advance = {
    path: `/account-receivable/${CompanyID}/advance`,
    icon: Advance,
    title: `Advances`,
    draft: arAdvanceCount?.draft,
    submitted: arAdvanceCount?.submitted,
    permission: AcctPermission.AccReceivableAdvancesView,
    deployed: true,
  }

  const officialReceipt = {
    path: `/account-receivable/${CompanyID}/official-receipt`,
    icon: Advance,
    title: `Official Receipts`,
    draft: arOfficialReceiptCount?.draft,
    submitted: arOfficialReceiptCount?.submitted,
    permission: AcctPermission.AccReceivableReceiptsView,
    deployed: true,
  }

  const invoice = {
    path: `/account-receivable/${CompanyID}/invoice`,
    icon: Invoices,
    title: `Invoices`,
    draft: arInvoiceCount?.draft,
    submitted: arInvoiceCount?.submitted,
    permission: AcctPermission.AccReceivableInvoicesView,
    deployed: true,
  }

  const creditNote = {
    path: `/account-receivable/${CompanyID}/credit-note`,
    icon: CreditNote,
    title: `Credit Notes`,
    draft: arCreditNoteCount?.draft,
    submitted: arCreditNoteCount?.submitted,
    permission: AcctPermission.AccReceivableCreditNotesView,
    deployed: true,
    // disabled: true,
  }

  const debitNote = {
    path: `/account-receivable/${CompanyID}/debit-note`,
    icon: DebitNote,
    title: `Debit Notes`,
    draft: arDebitNoteCount?.draft,
    submitted: arDebitNoteCount?.submitted,
    permission: AcctPermission.AccReceivableDebitNotesView,
    deployed: true,
  }

  const refund = {
    path: `/account-receivable/${CompanyID}/refund`,
    icon: Refund,
    title: `Refunds`,
    draft: arRefundCount?.draft,
    submitted: arRefundCount?.submitted,
    permission: AcctPermission.AccReceivableRefundView,
    deployed: true,
  }

  const digitalReport = {
    path: `/account-receivable/${CompanyID}/digital-report`,
    icon: DigitalReports,
    title: `Digital Reports`,
    label1: ` `,
    data1: ' ', //hardcode
    permission: AcctPermission.AccReceivableDigitalReportsView,
    deployed: true,
  }

  const glExport = {
    path: `/account-receivable/${CompanyID}/gl-export`,
    icon: DigitalReports,
    title: `GL Export`,
    label1: ` `,
    data1: ' ', //hardcode
    deployed: true,
    disabled: true,
    // permission:
  }

  /* -------------------------------------------- */
  /*           DEBTOR ACCOUNT SUBMENU           */
  /* -------------------------------------------- */
  const debtorProfile = {
    path: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${DebtorAccountID}/detail`,
    icon: DebtorProfile,
    title: `Debtor Profile`,
    title2: ``,
    label1: `Last updated on `,
    deployed: true,
    data1: `${formatDate(
      getDebtorAccount[0]?.modTs ?? getDebtorAccount[0]?.createdTs
    )}.`,
    permission: AcctPermission.AccReceivableDebtorAccView,
  }

  const debtorInvoice = {
    path: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${DebtorAccountID}/invoice`,
    icon: Invoices,
    title: `Invoices`,
    title2: arInvoiceTotalAmt?.total,
    label1: `Last invoice date on `,
    deployed: true,
    data1: `${formatDate(arInvoiceLatestUpdate?.latestUpdate)}.`,
    permission: AcctPermission.AccReceivableInvoicesView,
  }

  const debtorCreditNote = {
    path: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${DebtorAccountID}/credit-note`,
    icon: CreditNote,
    title: `Credit Note`,
    title2: arCreditNoteTotalAmt?.total,
    label1: `Last credit note date on `,
    deployed: true,
    data1: `${formatDate(arCreditNoteLatestUpdate?.latestUpdate)}.`,
    permission: AcctPermission.AccReceivableCreditNotesView,
  }

  const debtorDebitNote = {
    path: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${DebtorAccountID}/debit-note`,
    icon: DebitNote,
    title: `Debit Note`,
    title2: arDebitNoteTotalAmt?.total,
    label1: `Last debit note date on `,
    deployed: true,
    data1: `${formatDate(arDebitNoteLatestUpdate?.latestUpdate)}.`,
    permission: AcctPermission.AccReceivableDebitNotesView,
  }

  const debtorAdvance = {
    path: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${DebtorAccountID}/advance`,
    icon: Advance,
    title: `Advances`,
    title2: arAdvanceTotalAmt?.total,
    label1: `Last advance date on `,
    deployed: true,
    data1: `${formatDate(arAdvanceLatestUpdate?.latestUpdate)}.`,
    permission: AcctPermission.AccReceivableAdvancesView,
  }

  const debtorReceipt = {
    path: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${DebtorAccountID}/official-receipt`,
    icon: Payment,
    title: `Official Receipts`,
    title2: arOfficialReceiptTotalAmt?.total,
    label1: `Last official receipt date on `,
    deployed: true,
    data1: `${formatDate(arOfficialReceiptLatestUpdate?.latestUpdate)}.`,
    permission: AcctPermission.AccReceivableReceiptsView,
  }

  const debtorRefund = {
    path: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${DebtorAccountID}/refund`,
    icon: Refund,
    title: `Refund`,
    title2: arRefundTotalAmt?.total,
    label1: `Last refund date on `,
    deployed: true,
    data1: `${formatDate(arRefundLatestUpdate?.latestUpdate)}.`,
    permission: AcctPermission.AccReceivableRefundView,
  }

  let mainMenuList = [],
    headerTitle,
    RETURN_ROUTES

  if (!DebtorAccountID) {
    mainMenuList = [
      debtorAcc,
      invoice,
      creditNote,
      debitNote,
      advance,
      officialReceipt,
      refund,
      digitalReport,
      // glExport,
    ]
    headerTitle = 'Account Receivable'
    RETURN_ROUTES = `/account-receivable/${CompanyID}`
  } else {
    mainMenuList = [
      debtorProfile,
      debtorInvoice,
      debtorCreditNote,
      debtorDebitNote,
      debtorAdvance,
      debtorReceipt,
      debtorRefund,
    ]
    headerTitle = 'Account Receivable'
    RETURN_ROUTES = `/account-receivable/${CompanyID}/debtor-account`
  }

  const totalDebtorDocAmt = getDebtorAccount?.reduce(
    (previousValue, currentValue) => previousValue + currentValue?.TotalAmt,
    0
  )

  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  let userAccDetail = {
    ...user,
    companyID: getCompany[0]?.CompanyID,
    companyName: getCompany[0]?.Name,
  }

  localStorage.setItem('loggedInUser', JSON.stringify(userAccDetail))

  console.log(
    'query => ',
    getCompany[0]?.Name,
    'localstorage => ',
    user?.companyName
  )

  return (
    <>
      {CompanyLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}
      {DebtorTrxSummaryLoading && <Loading />}
      {ARSubmenuSummaryLoading && <Loading />}
      {DebtorSubmenuSummaryLoading && <Loading />}
      <MainHeader
        mainBtn={'back'}
        onClick={
          DebtorAccountID
            ? () =>
                history.push(`/account-receivable/${CompanyID}/debtor-account`)
            : () => history.push(`/`)
        }
        smTitle={`${headerTitle}`}
        title={getCompany[0]?.Name}
        onClickAction={() => setSwitch(true)}
        sideBtn="company"
        rightCustomIcon={getCompany[0]?.Logo?.fileURL}
        routeSegments={
          DebtorAccountID
            ? [
                { name: 'AR' },
                { name: 'Main Menu' },
                { name: 'Debtor Account', current: true },
              ]
            : [{ name: 'Main Menu', current: true }]
        }
      />

      {DebtorAccountID ? (
        <DynamicSubHeader
          title={
            <span>
              <span style={{ fontWeight: 'bold' }}>
                {getDebtorAccount[0]?.DebtorName}
              </span>
            </span>
          }
          rightText={
            <>
              <span>
                <>
                  <span className="c-orange ">
                    {getDebtorAccount[0]?.TotalOSAmt < 0
                      ? `(${amtStr(getDebtorAccount[0]?.TotalOSAmt * -1)})`
                      : amtStr(getDebtorAccount[0]?.TotalOSAmt)}
                  </span>
                </>
              </span>
            </>
          }
        />
      ) : null}

      <ContentWrapper
        footer={DebtorAccountID ? true : false}
        singleDynamicInfo={DebtorAccountID ? true : false}
      >
        <List className="core-list">
          {mainMenuList.map((el, index) => {
            if (
              el?.deployed &&
              handlePermHidden({
                // companyID: CompanyID,
                permEnum: el?.permission,
              })
            )
              return (
                <ListItem
                  key={index}
                  onClick={() =>
                    !el?.disabled ? history.push(el?.path) : null
                  }
                  disabled={el?.disabled ? el?.disabled : null}
                >
                  <IconItem
                    image={el?.icon}
                    imageType="svg"
                    badgeType="default"
                  />
                  <ListItemText
                    style={{
                      textAlign: 'right',
                      marginLeft: '5px',
                    }}
                    primary={
                      DebtorAccountID && el?.title !== 'Debtor Profile' ? (
                        <>
                          <span className="icon-text xsTitle date-width flex-space">
                            {el?.title}
                          </span>

                          <span className="c-orange ">
                            <IconText
                              font="xsTitle flex-space"
                              children={amtStr(el?.title2)}
                            ></IconText>
                          </span>
                        </>
                      ) : (
                        <span className="icon-text xsTitle date-width flex-space">
                          {el?.title}
                        </span>
                      )
                    }
                    secondary={
                      <>
                        {el.label1 ? (
                          <span className="desc">
                            <span className="desc">{el.label1}</span>
                            &nbsp;
                            <span className="desc c-orange">{el.data1}</span>
                          </span>
                        ) : (
                          <>
                            <span className="desc">
                              <span className="text c-orange">
                                {`${el?.draft ?? 0}`}&nbsp;
                              </span>
                              <span className="text">
                                {`Draft`}&nbsp;&nbsp;{' '}
                              </span>
                              <span className="text c-orange">
                                {`${el?.submitted ?? 0}`}&nbsp;
                              </span>
                              <span className="text">{`Submitted`}</span>
                            </span>
                          </>
                        )}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          !el?.disabled
                            ? history.push({
                                pathname: el.path,
                                state: totalDebtorDocAmt,
                              })
                            : null
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          })}
        </List>
      </ContentWrapper>
      <SwitchDialog
        open={change}
        onClose={() => setSwitch(false)}
        data={getAssignedCompanyByUser}
        name={'Company'}
        nameID={'CompanyID'}
        currID={homeCompanyID}
        setCurrID={setHomeCompanyID}
        path={`/account-receivable`}
      />
      {/* FOOTER */}
    </>
  )
}
