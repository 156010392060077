import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { InputAdornment, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import BlackDollarIcon from 'assets/icons/Money/black-dollar.svg'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  useGetCostCentreQuery,
  useGetEntityCoaQuery,
  useGetMasterCoaQuery,
} from 'generated/graphql'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { uuid } from 'uuidv4'
import * as yup from 'yup'
import '../Journal.scss'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'

interface PaymentItemProps {
  MasterCOAID: string
  DocAmt: number
  CostCentreID: string
  Remark: string
}

export const JournalItemForm = (props: any) => {
  const {
    CompanyID,
    JournalProcessingID,
    JournalProcessingItemID,
    formMode,
    detailMode,
  }: any = useParams()

  let form, mode
  switch (detailMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Edit'
      mode = 'edit'
  }

  let history = useHistory()

  let location = useLocation()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const editData = location?.state as any

  const itemEditData: any = JSON.parse(
    localStorage.getItem('journalProcessingItem')
  )?.filter(
    item => item?.JournalProcessingItemID === JournalProcessingItemID
  )[0]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [docAmt, setDocAmt] = useState(
    amtNumStr(JournalProcessingItemID && itemEditData?.DocAmt) ?? 0
  )
  const [openExitConf, setOpenExitConf] = useState(null)
  const [text, setText] = useState(mode === 'add' ? '' : itemEditData?.remark)
  const [record, setRecord] = useState(false)
  const [audioData, setAudioData] = useState(null)
  const [audioInput, setAudioInput] = useState('')
  const [singleRemark, setSingleRemark] = useState(null)
  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */
  //Company
  useEffect(() => {
    if (JournalProcessingItemID && itemEditData?.Remark) {
      setText(itemEditData?.Remark)
      handleSingleRemark(JournalProcessingItemID && itemEditData?.Remark)
      setAudioInput(JournalProcessingItemID && itemEditData?.Remark)
      setValue('Remark', itemEditData?.Remark)
    }
  }, [JournalProcessingItemID])

  //EntityCOA
  const {
    loading: EntityCOALoading,
    error: EntityCOAErros,
    data: { getEntityCOA } = { getEntityCOA: [] },
  } = useGetEntityCoaQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
  })

  const masterCOAList =
    getEntityCOA?.length > 0
      ? getEntityCOA
          ?.map(coa => coa?.MasterCOA)
          ?.filter(coa => coa?.IsLastNode === true && coa?.IsControl === false)
      : getMasterCOA?.filter(
          coa => coa?.IsLastNode === true && coa?.IsControl === false
        )

  //CostCentre
  const {
    loading: CostCentreLoading,
    error: CostCentreErrors,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, IsLastNode: true },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  const handleAmtChange = event => {
    setDocAmt(event.value)
  }

  /* -------------------------------------------- */
  /*                   USE EFFECT                 */
  /* -------------------------------------------- */
  // useEffect(() => {
  //   if (JournalProcessingItemID && itemEditData?.Remark) {
  //     setValue('Remark', itemEditData?.Remark)
  //   }
  // }, [itemEditData])

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  const JournalItemSchema = yup.object().shape({
    MasterCOAID: CommonYupValidation.requireField(
      'Expense Account is required'
    ),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Remark: CommonYupValidation.requireField('Remark is required'),
    DocAmt: CommonYupValidation.requireField('DocAmt is required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
  } = useForm<PaymentItemProps>({
    defaultValues: {
      MasterCOAID:
        JournalProcessingItemID && itemEditData
          ? itemEditData?.MasterCOAID
          : '',
      DocAmt:
        JournalProcessingItemID && itemEditData ? itemEditData?.DocAmt : 0,
      CostCentreID:
        JournalProcessingItemID && itemEditData
          ? itemEditData?.CostCentreID
          : '',
      Remark:
        JournalProcessingItemID && itemEditData ? itemEditData?.Remark : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(JournalItemSchema),
  })

  //--- Submit Payment Item to LocalStorage
  const onSubmit = data => {
    const prvdetail =
      detailMode === 'add'
        ? JSON.parse(localStorage.getItem('journalProcessingItem')) || []
        : JSON.parse(localStorage.getItem('journalProcessingItem')).filter(
            x => x?.JournalProcessingItemID !== JournalProcessingItemID
          )
    let journalArray = [
      ...(prvdetail?.length === null || 0 ? null : prvdetail),

      {
        Sequence: itemEditData?.Sequence ?? prvdetail?.length + 1,
        JournalProcessingItemID:
          detailMode === 'add' ? uuid() : JournalProcessingItemID,
        MasterCOAID:
          formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
            ? data.MasterCOAID
            : itemEditData?.MasterCOAID,
        MasterCOACode:
          formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
            ? getMasterCOA.filter(
                coa => coa?.MasterCOAID === data.MasterCOAID
              )[0]?.Code
            : getMasterCOA.filter(
                coa => coa?.MasterCOAID === itemEditData?.MasterCOAID
              )[0]?.Code,
        MasterCOAName:
          formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
            ? getMasterCOA.filter(
                coa => coa?.MasterCOAID === data.MasterCOAID
              )[0]?.Name
            : getMasterCOA.filter(
                coa => coa?.MasterCOAID === itemEditData?.MasterCOAID
              )[0]?.Name,
        DocAmt:
          formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
            ? parseFloat(amtNumStr(data.DocAmt))
            : itemEditData?.DocAmt,
        CostCentreID: data.CostCentreID,
        CostCentreCode: getCostCentre.filter(
          ccc => ccc?.CostCentreID === data.CostCentreID
        )[0]?.Code,
        CostCentreName: getCostCentre.filter(
          ccc => ccc?.CostCentreID === data.CostCentreID
        )[0]?.Name,
        Remark: data.Remark,
      },
    ]
    localStorage.setItem('journalProcessingItem', JSON.stringify(journalArray))

    if (formMode === 'add') {
      history.push({
        pathname: `/general-ledger/${CompanyID}/journal-processing/${formMode}`,
        state: { ...editData },
      })
    } else if (formMode === 'edit' || formMode === 'approve-reject')
      history.push({
        pathname: `/general-ledger/${CompanyID}/journal-processing/${JournalProcessingID}/${formMode}`,
        state: { ...editData, detailMode: 'add' },
      })
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: itemEditData,
      formMode: detailMode,
    })

  return (
    <>
      {EntityCOALoading && <Loading />}
      {masterCOALoading && <Loading />}
      {CostCentreLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            if (formMode === 'add')
              history.push({
                pathname: `/general-ledger/${CompanyID}/journal-processing/${formMode}`,
                state: { ...editData },
              })
            else {
              history.push({
                pathname: `/general-ledger/${CompanyID}/journal-processing/${JournalProcessingID}/${formMode}`,
                state: { ...editData, detailMode: 'add' },
              })
            }
          }
        }}
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'General Ledger' },
          { name: 'Journal Entry', current: true },
        ]}
        rightRouteSegments={[
          { name: detailMode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />

      <ContentWrapper float>
        <CardContents
          section={{
            header: {
              title: 'Journal Detail',
            },
          }}
        >
          <Controller
            render={({ value, onChange }) => {
              const editCOA = masterCOAList?.filter(
                coa => coa?.MasterCOAID === watch('MasterCOAID')
              )[0]
              const selectedCOA = masterCOAList?.filter(
                coa => coa?.MasterCOAID === value
              )[0]
              return (
                <Autocomplete
                  options={
                    masterCOAList?.sort((a, b) => {
                      return a?.Code?.localeCompare(b?.Code)
                    }) || []
                  }
                  getOptionLabel={option => `${option?.Code} | ${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.MasterCOAID)
                  }}
                  disabled={mode === 'approve-reject'}
                  PopperComponent={AccCodeDropdownFullWidth}
                  renderOption={(props, option) => {
                    return (
                      <span>
                        {props?.Code}&nbsp;|&nbsp;{props?.Name}
                      </span>
                    )
                  }}
                  renderInput={(params: any) => {
                    if (detailMode === 'edit') {
                      params.inputProps.value = `${editCOA?.Code} | ${editCOA?.Name}`
                    }

                    return (
                      <div>
                        <TextField
                          {...params}
                          value={
                            selectedCOA
                              ? `${selectedCOA?.Code} | ${selectedCOA?.Name}`
                              : null
                          }
                          defaultValue={
                            editCOA
                              ? `${editCOA?.Code} | ${editCOA?.Name}`
                              : null
                          }
                          helperText={errors?.MasterCOAID?.message}
                          error={errors?.MasterCOAID ? true : false}
                          label="Account Code *"
                          style={{ width: '100%' }}
                          disabled={mode === 'approve-reject'}
                          InputLabelProps={
                            selectedCOA ? { shrink: true } : null
                          }
                          margin="normal"
                          variant="standard"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Account Code "
            name="MasterCOAID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.MasterCOAID?.message}
            error={errors?.MasterCOAID ? true : false}
            required
            disabled={formMode === 'approve-reject' ? true : false}
          />

          <Controller
            render={({ value, onChange }) => {
              const editCC = getCostCentre?.filter(
                cc => cc?.CostCentreID === watch('CostCentreID')
              )[0]
              const selectedCC = getCostCentre?.filter(
                cc => cc?.CostCentreID === value
              )[0]
              return (
                <Autocomplete
                  options={
                    getCostCentre?.sort((a, b) => {
                      return a?.Code.localeCompare(b?.Code)
                    }) || []
                  }
                  getOptionLabel={option => `${option?.Code} | ${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.CostCentreID)
                  }}
                  disabled={mode === 'approve-reject'}
                  PopperComponent={AccCodeDropdownFullWidth}
                  renderOption={(props, option) => {
                    return (
                      <span>
                        {props?.Code}&nbsp;|&nbsp;{props?.Name}
                      </span>
                    )
                  }}
                  renderInput={(params: any) => {
                    if (detailMode === 'edit') {
                      params.inputProps.value = `${editCC?.Code} | ${editCC?.Name}`
                    }

                    return (
                      <div>
                        <TextField
                          {...params}
                          value={
                            selectedCC
                              ? `${selectedCC?.Code} | ${selectedCC?.Name}`
                              : null
                          }
                          defaultValue={
                            editCC ? `${editCC?.Code} | ${editCC?.Name}` : null
                          }
                          helperText={errors?.CostCentreID?.message}
                          error={errors?.CostCentreID ? true : false}
                          label="Department *"
                          style={{ width: '100%' }}
                          disabled={mode === 'approve-reject'}
                          InputLabelProps={selectedCC ? { shrink: true } : null}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Department *"
            name="CostCentreID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.CostCentreID?.message}
            error={errors?.CostCentreID ? true : false}
            required
            disabled={formMode === 'approve-reject' ? true : false}
          />

          <Controller
            as={<NumberFormat />}
            thousandSeparator
            customInput={TextField}
            id="standard-basic"
            name="DocAmt"
            label="Amount"
            value={docAmt}
            autoComplete="off"
            control={control}
            onValueChange={e => {
              handleAmtChange(e)
            }}
            decimalScale={2}
            fixedDecimalScale
            margin="normal"
            required
            helperText={errors?.DocAmt?.message}
            error={errors?.DocAmt ? true : false}
            ref={register}
            // defaultValue={JournalProcessingItemID ? itemEditData?.DocAmt : 0}
            disabled={formMode === 'approve-reject' ? true : false}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconText
                    icon={
                      <img
                        src={BlackDollarIcon}
                        style={{ width: '17px', marginBottom: '3px' }}
                      />
                    }
                  />
                </InputAdornment>
              ),
            }}
          />

          <VoiceTextField
            name="Remark"
            mounted={true}
            label="Remark"
            required
            value={audioInput}
            record={record}
            setRecord={setRecord}
            setValue={setValue}
            helperTextProps={{
              helperText: errors?.Remark?.message,
              error: errors?.Remark ? true : false,
            }}
            customOnchange
            register={register}
            control={control}
            clearErrors={clearErrors}
            handleCustomOnChange={e => {
              setText(e.target.value)
              handleSingleRemark(e.target.value)
            }}
          />
        </CardContents>
      </ContentWrapper>

      <AccountFooter
        options={[
          {
            name: 'Save',
            color: 'primary',
            onClick: () => {
              handleSubmit(data => onSubmit(data))()
            },
            props: {
              type: 'submit',
            },
          },
        ]}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          if (formMode === 'add')
            history.push({
              pathname: `/general-ledger/${CompanyID}/journal-processing/${formMode}`,
              state: { ...editData },
            })
          else {
            history.push({
              pathname: `/general-ledger/${CompanyID}/journal-processing/${JournalProcessingID}/${formMode}`,
              state: { ...editData, detailMode: 'add' },
            })
          }
        }}
      />
    </>
  )
}
