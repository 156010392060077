import React, { lazy } from 'react'
import { StockEnquiryListing2 } from '../ICListing/StockEnquiry/StockEnquiryListing2'
import { StockEnquiryListing3 } from '../ICListing/StockEnquiry/StockEnquiryListing3'
import { StockItemDetail } from '../../GeneralSettingModule/StockItem/StockItemDetail'

//import { StockTransferForm } from './StockTransferForm'
//import { StockEnquiryListing1 } from './StockEnquiryListing1'

const StockEnquiryListing1 = lazy(() =>
  import('../ICListing/StockEnquiry/StockEnquiryListing1').then(module => ({
    default: module.StockEnquiryListing1,
  }))
)

// const StockEnquiryListing2 = lazy(() =>
//   import('./StockEnquiryListing2').then(module => ({
//     default: module.StockEnquiryListing2,
//   }))
// )

const stockEnquiryRoute = [
  {
    props: {
      exact: true,
      path: '/inventory-control/:WarehouseID/stock-enquiry',
    },
    component: <StockEnquiryListing1 />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:WarehouseID/stock-enquiry/:StockItemID/warehouse',
    },
    component: <StockEnquiryListing2 />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:WarehouseID/stock-enquiry/:StockItemID/general-ledger',
    },
    component: <StockEnquiryListing3 />,
  },
  {
    props: {
      exact: true,
      path: '/inventory-control/:WarehouseID/stock-enquiry/:StockItemID/detail',
    },
    component: <StockItemDetail mode="enquiry" />,
  },
  //   {
  //     props: {
  //       exact: true,
  //       path: '/inventory-control/:WarehouseID/stock-transfer/:StockTransferID/edit',
  //     },
  //     component: <StockTransferForm mode="edit" />,
  //   },
  //   {
  //     props: {
  //       exact: true,
  //       path: '/inventory-control/:WarehouseID/stock-transfer/:StockTransferID/detail',
  //     },
  //     component: <StockTransferDetail mode="edit"/>,
  //   },
]

export default stockEnquiryRoute
