import React from 'react'
import { GLBudgetFormV2 } from './GLBudgetFormV2'
import { GLBudgetRevisionFormV2 } from './GLBudgetRevisionFormV2'
import { GLBudgetRevisionListing } from './GLBudgetRevisionListing'
import { GLCostCentreBudget } from './GLCostCentreBudget'
import { GLOriginalBudgetListing } from './GLOriginalBudgetListing'
import { OriginalBudgetApprovalListing } from './GLOriginalBudgetApprovalListing'
import { GLPeriodBudget } from './GLPeriodBudget'

// const GLBudgetListing = lazy(() =>
//   import('./GLOriginalBudgetListing').then(module => ({
//     default: module.GLOriginalBudgetListing,
//   }))
// )

const GLBudgetRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/original-budget',
    },
    component: <GLOriginalBudgetListing PageMode="original" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/original-budget-approval',
    },
    component: <OriginalBudgetApprovalListing PageMode="original" />,
  },

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/budget-revision',
    },
    component: <GLBudgetRevisionListing PageMode="revision" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/original-budget/add',
    },
    component: <GLBudgetFormV2 mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/:budgetType/:MasterCOAID/add',
    },
    component: <GLCostCentreBudget mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/:budgetType/:MasterCOAID/:CostCentreDefinitionDetailID/add',
    },
    component: <GLPeriodBudget mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/original-budget/edit',
    },
    component: <GLBudgetFormV2 mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/:budgetType/:MasterCOAID/edit',
    },
    component: <GLCostCentreBudget mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/:budgetType/:MasterCOAID/:CostCentreDefinitionDetailID/edit',
    },
    component: <GLPeriodBudget mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/original-budget/approve-reject',
    },
    component: <GLBudgetFormV2 mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/original-budget/detail',
    },
    component: <GLBudgetFormV2 mode="detail" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/:budgetType/:MasterCOAID/approve-reject',
    },
    component: <GLCostCentreBudget mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/:budgetType/:MasterCOAID/detail',
    },
    component: <GLCostCentreBudget mode="detail" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/:budgetType/:MasterCOAID/:CostCentreDefinitionDetailID/approve-reject',
    },
    component: <GLPeriodBudget mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/:budgetType/:MasterCOAID/:CostCentreDefinitionDetailID/detail',
    },
    component: <GLPeriodBudget mode="detail" />,
  },

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/budget-revision/add',
    },
    component: <GLBudgetRevisionFormV2 mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/budget-revision/edit',
    },
    component: <GLBudgetRevisionFormV2 mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/budget-revision/approve-reject',
    },
    component: <GLBudgetRevisionFormV2 mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/budget-revision/detail',
    },
    component: <GLBudgetRevisionFormV2 mode="detail" />,
  },
]

export default GLBudgetRoutes
