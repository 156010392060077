import React, { lazy } from 'react'

const BankProfileListing = lazy(() =>
  import('./BankProfileListing').then(module => ({
    default: module.BankProfileListing,
  }))
)
const BankProfileExcelValidation = lazy(() =>
  import('./BankProfileExcelValidation').then(module => ({
    default: module.BankProfileExcelValidation,
  }))
)

const bankProfileRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/general-setting/bank-profile',
    },
    component: <BankProfileListing />,
  },
  {
    props: {
      exact: true,
      path: '/cash-book/general-setting/bank-profile/excel-validation',
    },
    component: <BankProfileExcelValidation />,
  },
]

export default bankProfileRoutes
