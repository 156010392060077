import React, { useEffect } from 'react'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import {
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  TextField,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useFieldArray } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { Delete } from '@material-ui/icons'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'

export const RoleListingComponent = (props: any) => {
  const {
    listIndex,
    control,
    register,
    errors,
    watch,
    setValue,
    theme,
    isDesktop,
    classesVaria,
    getApprovalList,
    getRole,
    key,
    checkPendingDocument,
    //getPending,
  } = props

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: `approvalPolicy.${listIndex}.approvalList`,
  })

  useEffect(() => {
    if (getApprovalList[listIndex]?.approvalList?.length > 0) {
      let arr = []

      getApprovalList[listIndex]?.approvalList?.map(list => {
        arr.push({
          stepNo: list?.stepNo,
          role: list?.role,
          roleID: list?.roleID,
        })
      })

      append(arr)
    }
  }, [])

  const checkRemainingRole = index => {
    let selectedRole = []
    if (
      watch(`approvalPolicy.${listIndex}.approvalList.${0}.roleID`) !==
      undefined
    ) {
      for (var i = index - 1; i >= 0; i--) {
        selectedRole.push(
          watch(`approvalPolicy.${listIndex}.approvalList.${i}.roleID`)
        )
      }
    }

    return getRole?.filter(x => !selectedRole.includes(x?.ID))
  }

  useEffect(() => {
    if (fields?.length > 0 && !!getRole) {
      fields?.map((a, b) => {
        register(`approvalPolicy.${listIndex}.approvalList.${b}.roleID`)

        setValue(
          `approvalPolicy.${listIndex}.approvalList.${b}.roleID`,
          a?.roleID
        )
      })
    }
  }, [])

  return (
    <Grid item xs={12} key={key}>
      <CardContents
        style={{
          marginTop: isDesktop ? '10px' : '10px',
          marginLeft: 'auto',
          marginRight: 'auto',
          boxShadow: 'none !important',
        }}
        section={{
          header: {
            title: 'Approval List',
            onClickAction: () => {
              //   checkPendingDocument(getPending)
              //   if (getPending?.document?.length === 0) {
              //     append({
              //       stepNo: Number(fields.length + 1),
              //       roleID: '',
              //       role: '',
              //     })
              //   }
              append({
                stepNo: Number(fields.length + 1),
                roleID: '',
                role: '',
              })
            },
            icon: (
              <AddIcon
                htmlColor="white"
                fontSize="default"
                style={{
                  width: '1.2rem',
                  height: '1.2rem',
                  margin: '10px 5px 0px 0px',
                  background: theme.palette.primary.main,
                  boxShadow: `0px 2px 5px 0px ${theme.palette.primary.main}`,
                  borderRadius: '3px',
                  color: 'rgba(224,234,254,100)',
                }}
              />
            ),
          },
        }}
      >
        {fields?.length == undefined || fields?.length == 0 ? (
          <>
            <Grid xs={12}>
              <EmptyList
                title="No Records"
                subtitle="Click the add button to create a format"
              />
            </Grid>
          </>
        ) : (
          <>
            <div className="rm-padding">
              <List
                style={{
                  width: '100%',
                  paddingBottom: '0px',
                }}
                className={classesVaria.list}
                key={key}
              >
                {fields?.map((x, index) => {
                  const approvalList = `approvalPolicy[${listIndex}].approvalList[${index}]`
                  return (
                    <>
                      <div key={x?.id}>
                        <ListItem
                          key={key}
                          role={undefined}
                          classes={{
                            root:
                              index !== fields?.length - 1
                                ? classesVaria.item
                                : classesVaria.lastItem,
                          }}
                          style={{ boxShadow: 'none', border: 'none' }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={1}
                              style={{
                                textAlign: 'center',
                                paddingTop: '15px',
                              }}
                            >{`${+index + 1}.`}</Grid>
                            <Grid container item xs={10}>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  paddingRight: '10px',
                                }}
                              >
                                <Controller
                                  ref={register}
                                  name={`approvalPolicy.${listIndex}.approvalList.${index}.roleID`}
                                  required
                                  control={control}
                                  defaultValue={x?.roleID}
                                  key={key}
                                  style={{
                                    marginTop: '5px',
                                  }}
                                  render={({ onChange, onBlur, value }) => (
                                    <TextField
                                      select
                                      variant="outlined"
                                      className="round-input"
                                      name={`approvalPolicy.${listIndex}.approvalList.${index}.roleID`}
                                      label={`Level ${index + 1} Approval`}
                                      required
                                      margin="dense"
                                      helperText={
                                        errors?.approvalPolicy?.[listIndex]
                                          ?.approvalList?.[index]?.roleID
                                          ?.message
                                      }
                                      error={
                                        errors?.approvalPolicy?.[listIndex]
                                          ?.approvalList?.[index]?.roleID
                                          ? true
                                          : false
                                      }
                                      onChange={e => {
                                        onChange(
                                          e.target.value,
                                          register(
                                            `approvalPolicy.${listIndex}.approvalList.${index}.roleID`
                                          ),
                                          setValue(
                                            `approvalPolicy.${listIndex}.approvalList.${index}.roleID`,
                                            e.target.value
                                          )
                                        )
                                      }}
                                      value={value}
                                      disabled={
                                        index !== 0 &&
                                        watch(
                                          `approvalPolicy.${listIndex}.approvalList.${index -
                                            1}.roleID`
                                        ) === ''
                                      }
                                    >
                                      {checkRemainingRole(index).map(role => (
                                        <MenuItem
                                          key={role?.ID}
                                          value={role?.ID}
                                        >
                                          {role?.name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={classesVaria.deleteIcon}
                              style={{
                                justifyContent: 'center',
                              }}
                            >
                              <IconButton
                                className="delete-icon"
                                onClick={() => {
                                  //   checkPendingDocument(getPending)
                                  //   if (getPending?.document?.length === 0) {
                                  //     remove(index)
                                  //   }
                                  remove(index)
                                }}
                                style={{ scale: 0.7 }}
                                question-uid={`${approvalList}.uid`}
                              >
                                <Delete
                                  htmlColor="white"
                                  fontSize="default"
                                  style={{
                                    width: '1.4rem',
                                    height: '1.4rem',
                                    margin: '0',
                                    background: theme.palette.error.main,
                                    boxShadow: `0px 2px 5px 0px ${theme.palette.error.main}`,
                                    borderRadius: '3px',
                                    color: 'white',
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </div>
                    </>
                  )
                })}
              </List>
            </div>
          </>
        )}
      </CardContents>
    </Grid>
  )
}
