import BigNumber from 'bignumber.js'

export const getTaxAmt = (tR, c) => {
  return new BigNumber(tR)
    .dividedBy(100)
    .times(c)
    .toNumber()
}

export const getTaxRate = tR => {
  return new BigNumber(tR).toNumber()
}

export const calculateDocAmt = (
  costAmt: number,
  taxAmt?: number,
  taxRate?: number
) => {
  if (taxRate > 0) {
    return new BigNumber(taxRate)
      .plus(100)
      .dividedBy(100)
      .times(costAmt)
      .toNumber()
  } else if (taxAmt > 0) {
    return new BigNumber(costAmt).plus(taxAmt).toNumber()
  } else {
    return new BigNumber(costAmt).toNumber()
  }
}

export const baseAmount = (qty: number, unitPriceAmount: number) => {
  if (!!qty && !!unitPriceAmount) {
    const baseAmt = new BigNumber(unitPriceAmount).multipliedBy(qty).toNumber()
    return baseAmt
  }
}

export const getTotalAmtWithQty = (
  qty: number,
  unitPrice: number,
  taxAmt: number
) => {
  return new BigNumber(unitPrice)
    .times(qty)
    .plus(taxAmt)
    .toNumber()
}

export const getTotalAmt = (tacn, c, taxA, taxR) => {
  if (tacn) {
    return new BigNumber(c).plus(taxA).toNumber()
  } else {
    return new BigNumber(taxR)
      .plus(100)
      .dividedBy(100)
      .times(c)
      .toNumber()
  }
}

export const taxAmtCalc = (tacn, taxA, taxR, baseA) => {
  if (tacn) {
    return new BigNumber(taxA).toNumber()
  } else {
    return new BigNumber(baseA)
      .times(taxR)
      .div(100)
      .toNumber()
  }
}

export const getTotalInvAmtCalc = (tacn, baseA, quantity) => {
  if (tacn) {
    if (quantity !== 0) {
      return new BigNumber(baseA).multipliedBy(quantity).toNumber()
    } else {
      return new BigNumber(baseA).toNumber()
    }
  } else {
    return new BigNumber(baseA).toNumber()
  }
}

export const getTotalInvAmt = (tacn, quantity, c, taxA) => {
  console.log(quantity, c, taxA, 'XXXX')
  if (tacn) {
    return new BigNumber(c).plus(taxA).toNumber()
  } else {
    return new BigNumber(c)
      .multipliedBy(quantity)
      .plus(taxA)
      .toNumber()
  }
}
