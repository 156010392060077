import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import {
  UserSelection,
  useDocumentListingQuery,
  useGetApBatchPaymentQuery,
  useGetApBatchPaymentbyStatusQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { AttachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'
import { useHistory, useParams } from 'react-router'

export const APBatchPaymentDetail = (props: any) => {
  let history = useHistory()
  const { CompanyID, BatchPaymentID }: any = useParams()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  /* -------------------------------------------- */
  /*                    QUERY                     */
  /* -------------------------------------------- */
  const {
    loading: PaymentbyStatusLoading,
    data: { getAPBatchPaymentbyStatus } = {
      getAPBatchPaymentbyStatus: [],
    },
  } = useGetApBatchPaymentbyStatusQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      StatusArr: 'COMPLETED',
      BatchPaymentID: BatchPaymentID,
    },
  })

  const {
    loading: APBatchPaymentLoading,
    data: { getAPBatchPayment } = {
      getAPBatchPayment: [],
    },
  } = useGetApBatchPaymentQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      BatchPaymentID: BatchPaymentID,
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: { superUserBool: UserSelection.All },
  })

  let array1: any = {}
  getAPBatchPayment[0]?.APPayment?.map(alloc => {
    array1[alloc?.CreditorAccount?.CompanyName] = [alloc]
  })

  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: BatchPaymentID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  return (
    <>
      {UserLoading && <Loading />}
      {APBatchPaymentLoading && <Loading />}
      {PaymentbyStatusLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-payable/${CompanyID}/batch-payment`)
        }}
        smTitle={'Account Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Supplier Accounts' },
          { name: 'Menu' },
          { name: 'Batch Payment', current: true },
        ]}
        rightRouteSegments={[{ name: 'Paid', current: true }]}
      />

      <DynamicSubHeader
        title={
          <span className="xsTitle" style={{ color: '#ff9800' }}>
            {getAPBatchPaymentbyStatus[0]?.RefNo}
          </span>
        }
        rightText={
          <span className="c-orange">
            {formatDate(getAPBatchPaymentbyStatus[0]?.DocDate)}
          </span>
        }
        infoLineStyle={{ width: '100px' }}
        infoLine={
          <div style={{ display: 'flex' }}>
            <Tooltip
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Avatar style={{ width: '20px', height: '20px' }}>
                        <PersonIcon style={{ fontSize: 'small' }} />
                      </Avatar>
                    </Grid>

                    <Grid item xs zeroMinWidth>
                      <div style={{ fontSize: '12px' }}>
                        {
                          getUsersByAccountAndSoftware?.find(
                            x =>
                              x?.ID ===
                              getAPBatchPaymentbyStatus[0]?.submittedBy
                          )?.name
                        }
                      </div>
                      <div style={{ fontSize: '10px' }}>
                        {formatDate(getAPBatchPaymentbyStatus[0]?.modTs) ??
                          formatDate(getAPBatchPaymentbyStatus[0]?.submittedTs)}
                        &nbsp;
                        {formatTime(getAPBatchPaymentbyStatus[0]?.modTs) ??
                          formatTime(getAPBatchPaymentbyStatus[0]?.submittedTs)}
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              arrow
              enterTouchDelay={0}
            >
              <div>
                <IconText
                  icon={
                    <img
                      src={SubmitterLog}
                      style={{
                        width: '11.5px',
                        marginRight: '5px',
                      }}
                    />
                  }
                  font="desc"
                ></IconText>
              </div>
            </Tooltip>
            <div className="desc">
              {getAPBatchPaymentbyStatus[0]?.Description}
            </div>{' '}
          </div>
        }
        rightInfoLine={
          <span className="desc">
            {amtStr(getAPBatchPaymentbyStatus[0]?.DocAmt)}
          </span>
        }
      />
      <ContentWrapper footer multiDynamicInfo>
        <CardContents>
          <div className="content-wrap left ">
            <div className="desc" style={{ color: 'grey' }}>
              Document Date
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {formatDate(getAPBatchPayment[0]?.DocDate)}
            </div>
          </div>

          <div className="content-wrap right ">
            <div className="desc" style={{ color: 'grey' }}>
              Reference No.
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {getAPBatchPayment[0]?.RefNo}
            </div>
          </div>

          <div className="content-wrap full ">
            <div className="desc" style={{ color: 'grey' }}>
              Bank Acc No
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {`${getAPBatchPayment[0]?.BankAccount?.BankProfile?.Name} - (${getAPBatchPayment[0]?.BankAccount?.AccountNo})`}{' '}
            </div>
          </div>

          <div className="content-wrap full ">
            <div className="desc" style={{ color: 'grey' }}>
              Description
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {getAPBatchPayment[0]?.Description}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Payment Method
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {getAPBatchPayment[0]?.PaymentMethod?.Name}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Amount
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {amtStr(getAPBatchPayment[0]?.DocAmt)}
            </div>
          </div>

          {getAPBatchPayment[0]?.PaymentMethod?.Name?.includes('Card') && (
            <div className="content-wrap full">
              <div className="desc" style={{ color: 'grey' }}>
                Card Type
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {getAPBatchPayment[0]?.CreditCardType?.Name}
              </div>
            </div>
          )}

          {getAPBatchPayment[0]?.PaymentMethod?.Name === 'Cheque' && (
            <>
              <div className="content-wrap full">
                <div className="desc" style={{ color: 'grey' }}>
                  Cheque No.
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {getAPBatchPayment[0]?.ChequeNo}
                </div>
              </div>
              <div className="content-wrap left">
                <div className="desc" style={{ color: 'grey' }}>
                  Cheque Date
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {formatDate(getAPBatchPayment[0]?.ChequeDate)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ color: 'grey' }}>
                  Cheque Expiry Date
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {formatDate(getAPBatchPayment[0]?.ChequeExpiryDate)}
                </div>
              </div>
            </>
          )}

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Attachment
            </div>
            {AttachmentDetailView(DocumentListing)}
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Transaction Date
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(getAPBatchPayment[0]?.TransactionDate)}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(getAPBatchPayment[0]?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                getUsersByAccountAndSoftware?.filter(
                  user => user.ID == getAPBatchPayment[0]?.approvedBy
                )[0]?.name
              }
            </div>
          </div>
        </CardContents>

        <List className="core-list">
          {array1 === undefined || array1?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            Object.keys(array1)?.map((el, index) => {
              return (
                <CardExpansion
                  summary={
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <span className="xsTitle flex-space">{el}</span>
                          </>
                        }
                        secondary={
                          <>
                            <span
                              className={`desc flex-space text-overflow click-text`}
                              onClick={() => {
                                history.push({
                                  pathname: `/account-payable/${CompanyID}/batch-payment/${BatchPaymentID}/${array1[el]?.[0]?.PaymentID}/preview`,
                                })
                              }}
                              style={{ marginBottom: '-5px' }}
                            >
                              {array1[el]?.[0]?.DocNo}
                            </span>
                            <span className="xxTitle">
                              <span className="c-orange highlight-text xsTitle">
                                {amtStr(
                                  array1[el]?.[0]?.Allocation?.reduce(
                                    (total, item) =>
                                      total + (item?.AllocationAmt ?? 0),
                                    0
                                  )
                                )}
                              </span>
                            </span>
                          </>
                        }
                      />
                    </ListItem>
                  }
                >
                  {array1[el]?.[0]?.Allocation?.map((detail, index) => {
                    return (
                      <>
                        <Grid spacing={1} container className="table-content">
                          <Grid
                            item
                            xs={1}
                            style={{ placeSelf: 'start', marginTop: '5px' }}
                          >
                            <span
                              className="Title"
                              style={{ fontWeight: 500, fontSize: 'small' }}
                            >{`${index + 1}.`}</span>
                          </Grid>

                          <Grid
                            item
                            xs={3}
                            style={{ placeSelf: 'start', marginTop: '8px' }}
                          >
                            <div className="p-l-5 desc date-width">
                              {formatDate(detail?.CreditDocDate)}
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            style={{
                              placeSelf: 'start',
                              marginTop: '8px',
                            }}
                          >
                            <div>
                              <div className="mdLabel flex-space">
                                {detail?.CreditDocNo}
                              </div>
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            style={{
                              placeSelf: 'start',
                              marginTop: '8px',
                              marginRight: 'auto',
                              textAlign: 'right',
                              flexWrap: 'wrap',
                              display: 'flex',
                              justifyContent: isDesktop ? 'end' : 'end',
                            }}
                          >
                            <div
                              className="c-black xxTitle"
                              style={{ color: 'primary' }}
                            >
                              {amtStr(detail?.AllocationAmt)}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid spacing={1} container className="table-content">
                          <Grid
                            item
                            xs={1}
                            style={{ placeSelf: 'start' }}
                          ></Grid>

                          <Grid
                            item
                            xs={11}
                            style={{ placeSelf: 'start', marginBottom: '10px' }}
                          >
                            <div className="p-l-5 desc text-noflow">
                              {detail?.Description}
                            </div>
                          </Grid>
                        </Grid>
                        <Divider
                          variant="fullWidth"
                          style={{ background: '#E4E4E4' }}
                        ></Divider>
                      </>
                    )
                  })}
                </CardExpansion>
              )
            })
          )}
        </List>
      </ContentWrapper>
    </>
  )
}
