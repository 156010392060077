import React, { lazy } from 'react'

const CompanyAuthorizationListing = lazy(() =>
  import('./CompanyAuthorisationListing').then(module => ({
    default: module.CompanyAuthorizationListing,
  }))
)

const CompanyAuthorizationAssignmentListing = lazy(() =>
  import('./CompanyAuthorisationAssignmentListing').then(module => ({
    default: module.CompanyAuthorizationAssignmentListing,
  }))
)

const CompanyAuthorizationUserListing = lazy(() =>
  import('./CompanyAuthorisationUserListing').then(module => ({
    default: module.CompanyAuthorizationUserListing,
  }))
)

const companyAuthorizationRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/company/submenu/:CompanyID/company-authorisation',
    },
    component: <CompanyAuthorizationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/company/submenu/:CompanyID/company-authorisation-assignment/:roleID/form',
    },
    component: <CompanyAuthorizationAssignmentListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-ledger/company/submenu/:CompanyID/company-authorisation/user/:ID',
    },
    component: <CompanyAuthorizationUserListing />,
  },
]

export default companyAuthorizationRoutes
