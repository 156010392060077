import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { TextField } from '@material-ui/core'
import { SubdirectoryArrowRightOutlined } from '@material-ui/icons'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import { CopyrightFooter } from 'components/Footer/Copyright'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  GetCoaStructureDocument,
  useCreateCoaStructureMutation,
  useGetCoaStructureQuery,
  useGetCompanyNameQuery,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'

interface RecurringJournalProps {
  StructureItem: any
}

export const AccCodeStructureForm = (props: any) => {
  const { mode } = props
  let location = useLocation()
  let history = useHistory()
  const { mode: pageMode }: any = useParams()
  const { CompanyID }: any = useParams()
  const [defaultStucture, setDefaultStucture] = useState<boolean>()
  const editData: any = location?.state
  let user = JSON.parse(localStorage.getItem('loggedInUser'))

  const AccountCodeSchema = yup.object().shape({})

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<RecurringJournalProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(AccountCodeSchema),
  })

  const { fields, append, remove, insert, prepend } = useFieldArray({
    control,
    name: 'StructureItem',
  })

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = {
      getCompany: [],
    },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: coaStructureLoading,
    error: coaStructureError,
    data: { getCOAStructure } = {
      getCOAStructure: [],
    },
  } = useGetCoaStructureQuery({
    fetchPolicy: 'network-only',
    variables: {
      AccountID: user?.accountID,
    },
  })

  const [
    createCoaStructure,
    {
      loading: createRecurringJournalsLoading,
      error: createRecurringJournalsError,
    },
  ] = useCreateCoaStructureMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      console.log('data', data)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/general-setting/acc-code-structure`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  useEffect(() => {
    if (getCOAStructure?.length !== 0) {
      history.push(`/general-ledger/general-setting/acc-code-structure`)
    } else if (mode === 'add') {
      prepend({
        numberLength: 0,
      })
    } else if (mode === 'edit') {
      let arr = []
      for (const v of editData) {
        arr.push({
          numberLength: v?.CodeLength,
        })
      }
      append(arr)
    }
  }, [mode])

  //   console.log('defaultStucture', defaultStucture)

  //   const defaultStructureFuc = () => {
  //     if (getCOAStructure !== undefined || getCOAStructure?.length !== 0)
  //       setDefaultStucture(true)
  //   }

  const onSubmit = data => {
    console.log(data)
    createCoaStructure({
      variables: {
        COAStructureInput: data?.StructureItem.map((x, index) => {
          return {
            Level: index + 1,
            CodeLength: x?.numberLength,
          }
        }),
      },
      refetchQueries: [
        {
          query: GetCoaStructureDocument,
        },
      ],
    })
  }

  console.log('This is fields', fields)

  return (
    <>
      {/* {companyLoading && <Loading />} */}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          mode === 'edit'
            ? history.push(`/general-ledger/general-setting/acc-code-structure`)
            : history.push(`/general-ledger/general-setting`)
        }
        smTitle={'General Ledger'}
        title={user?.accountName}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'Account Code Structure', current: true },
        ]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />
      {/* <form onSubmit={handleSubmit(onSubmit)} id="submit-form"> */}
      <ContentWrapper float footer>
        <div>
          <div className="message-alert text-noflow">
            <div>
              <span className={`icon-text`}>
                {
                  <InfoIcon
                    style={{
                      color: 'black',

                      fontSize: '13px',
                      zIndex: 5,
                      marginBottom: '2px',
                    }}
                    fontSize="small"
                  />
                }
                <span className="mdDesc">Click{'  '}</span>
                {
                  <AddBoxIcon
                    style={{
                      color: '#ff9800',

                      fontSize: '22px',
                      zIndex: 5,
                    }}
                    fontSize="small"
                  />
                }
                <span className="mdDesc">
                  {''}to add the{' '}
                  <span className="c-darkblack xsTitle">next level</span> of
                  Level
                </span>
              </span>

              <div
                className="extra-row"
                style={{ paddingLeft: '18px', paddingTop: '10px' }}
              >
                <span className={`icon-text`}>
                  <span className="mdDesc">Click{'  '}</span>
                  {
                    <div
                      style={{
                        backgroundColor: '#FF1B40',
                        borderRadius: '4px',
                        width: '18px',
                        height: '18px',
                        textAlign: 'center',
                        marginLeft: '3px',
                        marginRight: '4px',
                      }}
                    >
                      <div className="div-delete">
                        <DeleteIcon
                          style={{
                            color: 'white',
                            padding: 0,
                            fontSize: '15px',
                            zIndex: 5,
                            paddingBottom: '2px',
                            paddingLeft: '1.5px',
                          }}
                          fontSize="small"
                        />
                      </div>
                    </div>
                  }
                  <span className="mdDesc">
                    {''}to delete the{' '}
                    <span className="c-darkblack xsTitle">current level</span>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <CardContents
            section={{
              header: {
                title: 'Structure',
                onClickAction: () =>
                  append({
                    numberLength: 0,
                  }),
                icon: (
                  <AddBoxIcon
                    style={{
                      color: '#ff9800',
                    }}
                  />
                ),
              },
            }}
          >
            <div className="rm-padding table-wrap ">
              {fields?.map((v, index) => {
                const StructureItem = `StructureItem[${index}]`
                return (
                  <>
                    <div className="table-display">
                      <div
                        className="fw-medium xsTitle index-padding"
                        style={{
                          paddingLeft: `${
                            index === 0 ? 16 : (index + index) * 10
                          }px`,
                          paddingRight: `${10}px`,
                        }}
                      >
                        {index === 0 ? null : (
                          <span>
                            <SubdirectoryArrowRightOutlined
                              fontSize="inherit"
                              style={{
                                fontSize: '21px',
                                marginBottom: '-4px',
                                marginTop: '-5px',
                              }}
                            />
                          </span>
                        )}
                        <span
                          style={{
                            paddingLeft: `5px`,
                          }}
                        >
                          Level {index + 1}
                        </span>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          paddingLeft: `${
                            index === fields.length - 1
                              ? 0
                              : (fields.length - 1 - index) * 20
                          }px`,
                        }}
                      >
                        <Controller
                          ref={register()}
                          name={`${StructureItem}.numberLength`}
                          control={control}
                          defaultValue={v.numberLength || 0}
                          onChangeName="onValueChange"
                          onChange={([{ value, floatValue }]) => {
                            return value
                          }}
                          as={
                            <NumberFormat
                              key={v?.id}
                              allowNegative={true}
                              customInput={TextField}
                              className="qty-input-new"
                              label="Length of Number"
                              //decimalScale={2}
                              fixedDecimalScale
                              variant="outlined"
                              thousandSeparator
                              isNumericString
                              helperText={
                                errors?.StructureItem &&
                                errors?.StructureItem[index]?.numberLength
                                  ?.message
                              }
                              error={
                                errors?.StructureItem &&
                                errors?.StructureItem[index]?.numberLength
                              }
                            />
                          }
                        />
                      </div>
                      {index === fields.length - 1 ? (
                        <div
                          className="div-parent-delete"
                          style={{
                            paddingRight: '11px',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: '#FF1B40',
                              borderRadius: '4px',
                              width: '18px',
                              height: '18px',
                              textAlign: 'center',
                            }}
                          >
                            <div className="div-delete">
                              <DeleteIcon
                                style={{
                                  color: 'white',
                                  padding: 0,
                                  fontSize: '13px',
                                  zIndex: 5,
                                  paddingBottom: '2px',
                                }}
                                fontSize="small"
                                onClick={e => {
                                  remove(index)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="div-parent-delete"
                          style={{
                            paddingRight: '11px',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: 'white',
                              borderRadius: '4px',
                              width: '18px',
                              height: '18px',
                              textAlign: 'center',
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </>
                )
              })}
            </div>
          </CardContents>
        </div>
      </ContentWrapper>
      <Footer
        //value={amtStr(setTotal)}
        copyright
        options={[
          {
            name: 'Save',
            onClick: () => {
              handleSubmit(onSubmit)()
            },
            color: 'primary',
            props: {
              type: 'submit',
            },
          },
        ]}
      />
      {/* </form> */}
    </>
  )
}
