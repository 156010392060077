import BigNumber from 'bignumber.js'

export interface IValidatorOverload {
  (v: string): boolean
  (v: number): boolean
  (v: object): boolean
  (v: Array<any>): boolean
  (v: any): boolean
}

export const isString: IValidatorOverload = (v: any) =>
  typeof v === 'string' ? true : false

export const isNumber: IValidatorOverload = (v: any) => {
  switch (true) {
    case isString(v):
      return !/[\D]/g.test(v)
    case typeof v === 'number':
      return true
    default:
      return false
  }
}

export const isFloat: IValidatorOverload = (v: any) => {
  switch (true) {
    case isString(v):
      return !/[\D.]/g.test(v)
    case typeof v === 'number': // less overhead rather than calling `isNumber`
      return v === Math.trunc(v) ? false : true
    default:
      return false
  }
}

export const isPlainObject: IValidatorOverload = (v: any) =>
  !!v && v.constructor === Object

export const isBigNumber: IValidatorOverload & { (v: BigNumber): boolean } = (
  v: any
) => (v instanceof BigNumber ? true : false)

export const hasPeriod = (v: string) => /\./g.test(v)

export const isArray = (v: any): boolean => {
  return Array.isArray(v)
}
