export const toTitleCase = (str: any) => {
  return str?.replace(/\w\S*/g, function(txt: any) {
    return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase()
  })
}

// export const toProperCase = (str: any) => {
//   str
//     .split(' ')
//     .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
//     .join(' ')
// }

export const toProperCase = (str: any) => {
  str = str?.toLowerCase().split(' ')
  for (var i = 0; i < str?.length; i++) {
    str[i] = str[i]?.charAt(0)?.toUpperCase() + str[i]?.slice(1)
  }
  return str?.join(' ')
}

export const toTitleCaseNoUnderscore = str => {
  str = str?.toLowerCase()?.split('_')
  for (var i = 0; i < str?.length; i++) {
    str[i] = str[i]?.charAt(0)?.toUpperCase() + str[i]?.slice(1)
  }
  return str?.join(' ')
}

export function lowerCaseAllWordsExceptFirstLetters(str: string) {
  let results = str.split('_')
  let finalStr = ''
  results.forEach(string => {
    finalStr += ' '
    finalStr += string.replace(/\S*/g, function(word) {
      return word.charAt(0) + word.slice(1).toLowerCase()
    })
  })
  return finalStr.trim()
}
