import React, { lazy } from 'react'

const TaxTypeListing = lazy(() =>
  import('./TaxTypeListing').then(module => ({
    default: module.TaxTypeListing,
  }))
)

const TaxSchemeListing = lazy(() =>
  import('./TaxSchemeListing').then(module => ({
    default: module.TaxSchemeListing,
  }))
)

const TaxEffectiveDateForm = lazy(() =>
  import('./TaxEffectiveDateForm').then(module => ({
    default: module.TaxEffectiveDateForm,
  }))
)

const taxRoutes = [
  {
    props: { exact: true, path: '/common-setting/tax-type' },
    component: <TaxTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/common-setting/tax-type/:TaxTypeID/tax-scheme',
    },
    component: <TaxSchemeListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/common-setting/tax-type/:TaxTypeID/tax-scheme/tax-effective-date/add',
    },
    component: <TaxEffectiveDateForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/common-setting/tax-type/:TaxTypeID/tax-scheme/:TaxSchemeID/tax-effective-date/edit',
    },
    component: <TaxEffectiveDateForm mode="edit" />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/general-settings/tax-type/:taxTypeID/tax-scheme/:taxSchemeID/tax-effective-date/detail',
  //   },
  //   component: <TaxEffectiveDateSetupList />,
  // },
]

export default taxRoutes
