import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useEffect } from 'react'
import '../../style/CycleCount.scss'

interface DetailProps {
  listEl: any
  userList: any
}

export const CycleCountDetailContent = (props: DetailProps) => {
  useEffect(() => {
    console.log('StockIssueDetailContent')
  }, [])

  const { listEl, userList } = props

  return (
    <>
      <div className="content-wrap left">
        <div className="desc">Document Date</div>
        <div className="xsTitle">{formatDate(listEl?.DocDate)}</div>
      </div>
      <div className="content-wrap right">
        <div className="desc">Document Ref</div>
        <div className="xsTitle">{listEl?.RefNo}</div>
      </div>
      <div className="content-wrap full">
        <div className="desc">Warehouse</div>
        <div className="xsTitle">{listEl?.Warehouse?.Name}</div>
      </div>
      <div className="content-wrap left">
        <div className="desc">Description</div>
        <div className="xsTitle">{listEl?.Description}</div>
      </div>
    </>
  )
}
