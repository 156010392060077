import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  GetAuditAdjustmentbyStatusDocument,
  useDocumentListingQuery,
  useGetAuditAdjustmentQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostAuditAdjustmentMutation,
  UserSelection,
  useUpdateAuditAdjustmentMutation,
} from 'generated/graphql'
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { default as React, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { AuditAdjustmentDetailContent } from './AuditAdjustmentDetailContent'
import { AuditAdjustmentDetailItemContent } from './AuditAdjustmentDetailItemContent'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'

export const AuditAdjustmentDetail = (props: any) => {
  let history = useHistory()
  const { mode } = props
  const { CompanyID, AuditAdjustmentID }: any = useParams()
  const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openWorkFlow, setWorkFlow] = useState(false)
  const { handleStatusColor } = useColorStatusList()
  let location = useLocation()
  const editData = location?.state as any
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()
  const [audioInput, setAudioInput] = useState('')
  const [record, setRecord] = useState(false)
  const [text, setText] = useState(mode === 'add' ? '' : editData?.remark)
  const [singleRemark, setSingleRemark] = useState(null)
  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  let itemData = []
  itemData = editData?.AuditAdjustmentItem

  const docAmtReducer = (total, curVal) => total + curVal?.DocAmt

  const positiveAmt = itemData
    ?.filter(v => v?.DocAmt > 0)
    ?.reduce(docAmtReducer, 0)

  const negativeAmt = itemData
    ?.filter(v => v?.DocAmt < 0)
    ?.reduce(docAmtReducer, 0)

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    loading: AuditAdjustmentLoading,
    error: AuditAdjustmentError,
    data: { getAuditAdjustment } = { getAuditAdjustment: [] },
  } = useGetAuditAdjustmentQuery({
    variables: { AuditAdjustmentID: AuditAdjustmentID },
  })

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: AuditAdjustmentID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const [
    updateAuditAdjustment,
    {
      loading: updateAuditAdjustmentLoading,
      error: updateAuditAdjustmentError,
    },
  ] = useUpdateAuditAdjustmentMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      console.log('data', data)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      mode === 'workdesk'
        ? setTimeout(() => {
            history.push({
              pathname: `/workdesk/general-ledger`,
              state: { success: true, msgMode: 'update' },
            })
          }, 500)
        : setTimeout(() => {
            history.push({
              pathname: `/general-ledger/${CompanyID}/audit-adjustment`,
              state: { success: true, msgMode: 'update' },
            })
          }, 500)
    },
  })

  const RecordStatusText = pStatus => {
    switch (pStatus) {
      case ApprovalStatus.Submit:
        return 'Submitted'
      case ApprovalStatus.Active:
        return 'Draft'
      case ApprovalStatus.Approved:
        return 'Submitted'
      case ApprovalStatus.Completed:
        return 'Approved'
      case ApprovalStatus.Rejected:
        return 'Rejected'
      case ApprovalStatus.Cancelled:
        return 'Cancelled'
      default:
        return ''
    }
  }

  const rejectFooterOption = {
    name: 'Reject',
    onClick: () => {
      {
        setOpenRejectDialog(true)
      }
    },
    color: 'secondary',

    props: {
      type: 'submit',
    },
  }

  const approveFooterOption = {
    name: 'Approve',
    onClick: () => {
      handleSubmit(data => onSubmit(ApprovalStatus.Approved, 'APPROVED'))()
    },
    color: 'primary',

    props: {
      type: 'submit',
    },
  }
  const footerOptions: any[] = [rejectFooterOption, approveFooterOption]

  const [
    postAuditAdjustment,
    {
      loading: postAuditAdjustmentLoading,
      called: postAuditAdjustmentCalled,
      error: postAuditAdjustmentError,
    },
  ] = usePostAuditAdjustmentMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/${CompanyID}/audit-adjustment`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  // let listJournalIDs = Array.from(journalIDs || [])

  const onSubmit = (data, status) => {
    postAuditAdjustment({
      variables: {
        AuditAdjustmentIDs: AuditAdjustmentID,
        CompanyID,
      },
      refetchQueries: [
        {
          query: GetAuditAdjustmentbyStatusDocument,
          variables: { CompanyID },
        },
      ],
    })
  }

  return (
    <>
      {AuditAdjustmentLoading && <Loading />}
      {updateAuditAdjustmentLoading && <Loading />}
      {postAuditAdjustmentLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-ledger/${CompanyID}/audit-adjustment`)
        }}
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'Audit Adjustment' },
          { name: 'Audit Adjustment' },
          { name: 'Audit Adjustment', current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          <span className="c-orange">
            <span style={{ fontWeight: 'bold' }}>
              {getAuditAdjustment[0]?.DocNo ?? getAuditAdjustment[0]?.RefNo}
            </span>
          </span>
        }
        rightText={
          <span className="c-orange">
            {formatDate(getAuditAdjustment[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
            >
              {getAuditAdjustment[0]?.Description}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc ">
            <span className="desc c-orange">
              {amtStr(
                getAuditAdjustment[0]?.AuditAdjustmentItem?.filter(
                  item => item?.DocAmt > 0
                )?.reduce((x, y) => x + y?.DocAmt, 0)
              )}
            </span>
          </span>
        }
      />
      <ContentWrapper multiDynamicInfo float footer>
        <CardContents>
          <AuditAdjustmentDetailContent
            listEl={getAuditAdjustment[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Journal Detail',
            },
          }}
        >
          <AuditAdjustmentDetailItemContent
            listItem={getAuditAdjustment[0]?.AuditAdjustmentItem}
          />
        </CardContents>
      </ContentWrapper>
      {mode === 'workdesk' ? <Footer options={[...footerOptions]} /> : ''}
      <TotalAmountFooter
        detail={true}
        debitCreditInfo={true}
        debitAmt={amtStr(positiveAmt) ?? '0.00'}
        creditAmt={amtStr(Math.abs(negativeAmt)) ?? '0.00'}
      />

      {getAuditAdjustment[0]?.ApprovalStatus === 'SUBMIT' ? (
        <Footer
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              // props: { disabled: newFilteredList?.length === 0 },
            },
          ]}
        />
      ) : null}

      <RejectDialog
        data={getAuditAdjustment[0]}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        audioInput={audioInput}
        record={record}
        setRecord={setRecord}
        setValue={setValue}
        errors={errors}
        register={register}
        control={control}
        clearErrors={clearErrors}
        setText={setText}
        handleSingleRemark={handleSingleRemark}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />

      {/* <ApprovalLogDialog
        data={getJournalProcessing[0]}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Journal Processing'}
      /> */}
    </>
  )
}
