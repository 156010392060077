import React, { lazy } from 'react'
import { ExternalJournalDetail } from './ExternalJournalDetails'
import { ExternalJournalExcelValidation } from './ExternalJournalExcelValidation'
//import { ExternalJournalExcelValidation } from './ExternalJournalExcelValidations'

const ExternalJournalListing = lazy(() =>
  import('./ExtenalJournalListing').then(module => ({
    default: module.ExternalJournalListing,
  }))
)

const ExternalJournalFilter = lazy(() =>
  import('../../../components/Filter/ExternalJournalFilter').then(module => ({
    default: module.ExternalJournalFilter,
  }))
)

const externalJournalRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/external-journal/filter',
    },
    component: (
      <ExternalJournalFilter
        mode="external-journal"
        app="general-ledger"
        hasJournalType
        hasDocDate
        hasDateRange
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/external-journal',
    },
    component: <ExternalJournalListing />,
  },

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/external-journal/validation',
    },
    component: <ExternalJournalExcelValidation />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/external-journal/:ExternalJournalID/detail',
    },
    component: <ExternalJournalDetail />,
  },
]

export default externalJournalRoutes
