import GLAccountCodeJournalTypeMappingRoutes from './GLAccountCodeJournalTypeMapping/GLAccountCodeJournalTypeRoutes'
import leaseContractRoutes from './LeaseContract/LeaseContractRoutes'
import { MFRS16Submenu } from './MFRS16Submenu'
import React from 'react'
const mfrs16Route = [
  {
    props: { exact: true, path: '/mfrs16/:CompanyID' },
    component: <MFRS16Submenu accountType="mfrs16" />,
  },
  ...leaseContractRoutes,
  ...GLAccountCodeJournalTypeMappingRoutes,
]
export default mfrs16Route
