import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'

import theme from '@ifca-root/react-component/src/assets/theme'
import { Tune } from '@material-ui/icons'
import EventIcon from '@material-ui/icons/Event'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import {
  useGetApAdvancebyStatusLazyQuery,
  useGetApCreditNotebyStatusLazyQuery,
  useGetApCreditorCreditNoteByStatusLazyQuery,
  useGetApDebitNotebyStatusLazyQuery,
  useGetApInvoicebyStatusLazyQuery,
  useGetApPaymentbyStatusLazyQuery,
  useGetApRefundbyStatusLazyQuery,
  useGetCompanyNameQuery,
  useGetCreditorAccountCompanyAssignmentQuery,
  useGetCreditorTotalDocAmtQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'

/* -------------------------------------------- */
/*            For ACTIVE and APPROVED           */
/* -------------------------------------------- */
export const AuditLogListing = (props: any) => {
  const { docType } = props
  let history = useHistory()
  const { CompanyID, CreditorAccountID }: any = useParams()
  const getSearch = localStorage.getItem('searchFilter')
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const dummyList = [
    {
      ID: '12313',
      title: 'SAMPLE',
      oldValue: 'SAMPLE1',
      newValue: 'SAMPLE2',
      column: 'Right-of-use',
      dateToday: formatDate(new Date().toISOString()),
      timeStampz: formatTime(new Date().toISOString()),
    },
  ]

  return (
    <>
      {UserLoading && <Loading />}
      {CompanyLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(
            `/mfrs16/${CompanyID}/gl-account-code-journal-type-mapping`
          )
          localStorage.removeItem(`searchFilter`)
          localStorage.removeItem(`advancedFilter`)
        }} //<- back button action
        smTitle="MFRS 16"
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'GL Account Code & Journal Type Mapping' },
          { name: 'General Settings', current: true },
        ]}
        //rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />

      <DynamicSubHeader
        title={
          <span>
            <span style={{ fontWeight: 'bold' }}>{'Audit Log'}</span>
          </span>
        }
      />

      <div>
        <SearchHeader
          title={`Search here`}
          value={`${advancedFilterList(filteredList)?.length}`}
          search
          fixed
          dynamicInfo
          onChangeAction={e => {
            handleSearch(e?.target?.value, [
              'DocNo',
              'RefNo',
              'DocDate',
              'Description',
            ])
          }}
          onCloseAction={() => handleSearch('', [])}
          option={{
            icon: <Tune />,
            onClick: () =>
              history.push(`/account-payable/${CompanyID}/advance/filter`),
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch}
        />
      </div>
      <ContentWrapper style={{ marginTop: isDesktop ? '153px' : '90px' }} float>
        <List className="core-list">
          {advancedFilterList(dummyList) === undefined ||
          advancedFilterList(dummyList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(dummyList)
              //   ?.sort((a, b) => {
              //     return a.DocDate.localeCompare(b.DocDate)
              //   })
              ?.map((el, index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="flex-space mdLabel"
                            style={{ fontSize: '12px' }}
                          >
                            {el?.title}
                          </span>
                          <span
                            className="desc"
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            <span className="mdLabel">
                              <IconText
                                icon={<EventIcon />}
                                font="mdLabel"
                                children={`${el?.dateToday} ${el?.timeStampz}`}
                              ></IconText>
                            </span>
                          </span>
                        </>
                      }
                      secondary={
                        <>
                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={6}>
                              <span
                                style={{ fontSize: '10px' }}
                                className="flex-space mdLabel"
                              >
                                {el?.column}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                textAlign: 'end',
                              }}
                            >
                              <span className="desc">
                                <span
                                  style={{
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {'Modified'}
                                </span>
                              </span>
                            </Grid>
                          </Grid>

                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={6}>
                              <span style={{ fontSize: '10px' }}>
                                Old Value: {el?.oldValue}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                textAlign: 'end',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '10px',
                                  color: 'grey',
                                  fontWeight: 'bold',
                                }}
                              ></span>
                            </Grid>
                          </Grid>
                          <div>
                            <div
                              className="desc text-overflow"
                              style={{ width: '340px' }}
                            >
                              New Value:{el?.newValue}
                            </div>
                          </div>
                        </>
                      }
                    />
                  </ListItem>
                )
              })
          )}
        </List>
      </ContentWrapper>
    </>
  )
}
