import React, { lazy } from 'react'

const RetainEarningAccountListing = lazy(() =>
  import('./RetainEarningAccountListing').then(module => ({
    default: module.RetainEarningAccountListing,
  }))
)

const RetainEarningAccountForm = lazy(() =>
  import('./RetainEarningAccountForm').then(module => ({
    default: module.RetainEarningAccountForm,
  }))
)

// const FormTemplate = lazy(() =>
//   import('./FormTemplate').then(module => ({
//     default: module.FormTemplate,
//   }))
// )

const retainEarningAccountRoutes = [
  // {
  //   props: {
  //     exact: true,
  //     path: '/general-ledger/company-setting/:CompanyID/retain-earning-account',
  //   },
  //   component: <RetainEarningAccountListing />,
  // },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/company-setting/retain-earning-account',
    },
    component: <RetainEarningAccountForm />,
  },
  //   {
  //     props: { exact: true, path: '/form-template' },
  //     component: <FormTemplate />,
  //   },
]

export default retainEarningAccountRoutes
