import AppContext from '@account-root/account-react/src/containers/App/Store/AppContext';
import '@ifca-root/react-component/src/assets/styles/components/totalAmountFooter.scss';
import theme from '@ifca-root/react-component/src/assets/theme';
import {
  amtNumStr,
  amtStr,
} from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter';
import { Grid, Tooltip, useMediaQuery, withStyles } from '@material-ui/core';
import React, { useContext } from 'react';

export interface TooltipAmtFooterProps {
  data?: any;
  module?: string;
  docAmt?: any;
  detail?: boolean;
}

export const TooltipAmtFooter = (props: TooltipAmtFooterProps) => {
  const { data, module, detail } = props;
  const { globalState, dispatch }: any = useContext(AppContext as any);
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const AmountTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
    arrow: {
      '&.MuiTooltip-arrow': {
        right: 0,
        color: '#9e9e9e',
        width: '8rem',
      },
    },
  })(Tooltip);

  let itemData = [];
  let AmtData = [];
  let GRTNData = [];
  switch (module) {
    case 'schedule-draft':
      itemData = data;
      break;
    case 'schedule-prepare-po':
      itemData = data;
      break;
    case 'po-draft':
      itemData = data;
      break;
    case 'po-submit':
      itemData = data;
      break;
    case 'po-approved':
      itemData = data?.POItem;
      break;
    case 'grn-draft':
      AmtData = data;
      break;
    case 'grn-approved':
      AmtData = data;
      break;
    case 'grtn-draft':
      AmtData = data;
      break;
    case 'grtn-submit':
      GRTNData = data;
      break;
    case 'grtn-approved':
      AmtData = data;
      break;
    default:
      break;
  }

  const totalBaseAmt = itemData?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.BaseAmt,
    0,
  );
  const totalTaxAmt = itemData?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.TaxAmt,
    0,
  );
  const grandTotalAmt = itemData?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.TotalAmt,
    0,
  );

  const grandTotalDocAmt = GRTNData?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.ReturnedAmt,
    0,
  );

  return (
    <div
      className={detail ? 'totalAmountFooterDetail' : 'totalAmountFooter'}
      style={{
        width:
          isDesktop && globalState.drawerOpen
            ? 'calc(100% - 288px)'
            : !isDesktop
            ? '100%'
            : 'calc(100% - 48px)',
        marginLeft:
          isDesktop && globalState.drawerOpen
            ? '264px'
            : !isDesktop
            ? '0px'
            : '24px',
      }}
    >
      <AmountTooltip
        onClick={e => e.stopPropagation()}
        title={
          <React.Fragment>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs zeroMinWidth>
                <div>
                  {module === 'grn-draft' || module === 'grn-approved' ? (
                    <div style={{ fontSize: '12px' }}>
                      {`DO Amount: ${amtNumStr(Number(AmtData))}`}
                    </div>
                  ) : module === 'grtn-draft' || module === 'grtn-approved' ? (
                    <div style={{ fontSize: '12px' }}>
                      {`GRTN Amount: ${amtNumStr(Number(AmtData))}`}
                    </div>
                  ) : (
                    <div>
                      <div style={{ fontSize: '12px' }}>
                        {`Grand Total: ${amtStr(grandTotalAmt)}`}
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        {`Base Amount: ${amtStr(totalBaseAmt)}`}
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        {`Tax Amount: ${amtStr(totalTaxAmt)}`}
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </React.Fragment>
        }
        disableFocusListener
        placement="top-end"
        enterTouchDelay={0}
        arrow
      >
        <div>
          <span
            style={{
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
              fontSize: '10.5px',
              marginRight: '20px',
              textDecoration: 'underline',
            }}
          >
            Total:
            <span
              style={{
                marginLeft: '3px',
              }}
            />
            {module === 'grn-draft' ||
            module === 'grn-approved' ||
            module === 'grtn-draft' ||
            module === 'grtn-approved'
              ? new Intl.NumberFormat('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(Number(AmtData))
              : module === 'grtn-submit'
              ? amtStr(grandTotalDocAmt)
              : amtStr(grandTotalAmt)}
          </span>
        </div>
      </AmountTooltip>
    </div>
  );
};
