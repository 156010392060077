import * as ExcelJs from 'exceljs'
import FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import { country, nationality } from '../Const/Constants'

interface IOnUploadProps {
  columns?: string[]
  customDataProcessing?: (
    currData: any[],
    setCurrData: React.Dispatch<any>
  ) => void
  findExistingData?: any
}
interface IDownloadProps {
  name: string
  workSheetName: string
  headerSetup: ITemplateProps[]
  isHeaderLocked: boolean
  defaultHeaderFillStyle?: any
  defaultHeaderBorderStyle?: any
  headerStyle?: IHeaderStyle[]
  sheetPassword?: string
  protectWholeSheet?: boolean
  listType?: IListType
  extraListData?: IExtraListData[]
}

// get the data from user to create a dynamic selection listing
interface IExtraListData {
  data: string[]
  worksheetName: string
  dataType: string
}

// for now since worker profile hasCountry
// hasNationality
interface IListType {
  hasCountry?: boolean
  hasNationality?: boolean
}

interface IHeaderStyle {
  col: number
  fillStyle: ExcelJs.Fill
  borderStyle: Partial<ExcelJs.Borders>
}

interface ISelectedFileProps {
  event: any
}

interface ITemplateProps {
  header: Partial<ExcelJs.Column>
  // dataType?: keyof dataType
  // dataType?: 'TrueOrFalse' | 'country' | 'nationality'
  dataType?: string
}

// declare type dataType = {
//   TrueOrFalse: 'TrueOrFalse'
//   country: 'country'
//   nationality: 'nationality'
// }

export const useExcelUploadVersion2 = () => {
  const [fileTitle, setFileTitle] = useState<string>('') // value to display as placeholder
  const [openFileUpload, setOpenFileUpload] = useState<boolean>(false) // just Bool to open dialog
  const [currentData, setCurrentData] = useState(null) // keep track excel's data
  const [errorDialog, setErrorDialog] = useState<boolean>(false)
  const [onSave, setSave] = useState<boolean>(false)
  const [currFile, setCurrFile] = useState<any>()
  const [duplicateItems, setDuplicateItems] = useState<string[]>([])
  const [hasDuplicate, setHasDuplicate] = useState<number>(0)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)

  // SNACKBAR STUFF
  // SNACKBAR STUFF

  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')

  const snackFunc = (showText: string, ms: number) => {
    setSnackBarMessage(showText)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
    }, ms)
  }

  const options: Partial<ExcelJs.WorksheetProtection> = {
    selectLockedCells: true,
    selectUnlockedCells: true,
    formatColumns: true,
    formatCells: true,
    insertRows: true,
    autoFilter: true,
    deleteRows: true,
    scenarios: true,
    objects: true,
  }

  // GLOBAL VAR
  // GLOBAL VAR
  // GLOBAL VAR

  const wb = new ExcelJs.Workbook()

  // GETTING THE HEADER SETUP AND ANY EXTRA CONFIGURATIONS
  // GETTING THE HEADER SETUP AND ANY EXTRA CONFIGURATIONS
  // GETTING THE HEADER SETUP AND ANY EXTRA CONFIGURATIONS

  const downloadTemplateSetup = (template: ITemplateProps[]) => {
    let header = [],
      additionalCellSettings = []

    template?.map((val, index) => {
      header.push(val?.header)
      if (!!val?.dataType)
        additionalCellSettings.push({
          dataType: val?.dataType,
          colNo: index + 1,
        })
    })

    return {
      header,
      additionalCellSettings,
    }
  }

  // CREATE HIDDEN WS FOR DYNAMIC DATA SELECTION
  // CREATE HIDDEN WS FOR DYNAMIC DATA SELECTION
  // CREATE HIDDEN WS FOR DYNAMIC DATA SELECTION

  const createHiddenWorkSheet = (
    wb: ExcelJs.Workbook,
    worksheetName: string,
    columnData: string[]
  ) => {
    const hiddenWs: ExcelJs.Worksheet = wb.addWorksheet(worksheetName, {
      state: 'hidden',
    })

    hiddenWs.getColumn(1).values = columnData
  }

  // CREATING EXCEL TEMPLATE
  // CREATING EXCEL TEMPLATE
  // CREATING EXCEL TEMPLATE

  const onDownloadTemplate = async (props: IDownloadProps) => {
    try {
      const {
        workSheetName,
        isHeaderLocked,
        headerSetup,
        sheetPassword,
        defaultHeaderBorderStyle,
        defaultHeaderFillStyle,
        headerStyle,
        name,
        protectWholeSheet = false,
        listType,
        extraListData,
      } = props

      let data: any

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

      // initializing the workbook

      wb.properties.date1904 = true
      wb.calcProperties.fullCalcOnLoad = true
      const ws: ExcelJs.Worksheet = wb.addWorksheet(workSheetName)

      // since formula in excel has 255 words limit
      // needs another worksheet to initialize the selections
      if (listType) {
        if (listType?.hasCountry) {
          const conWs: ExcelJs.Worksheet = wb.addWorksheet('countries', {
            state: 'hidden',
          })

          conWs.getColumn(1).values = country
        }

        if (listType?.hasNationality) {
          const natWs: ExcelJs.Worksheet = wb.addWorksheet('nationalities', {
            state: 'hidden',
          })

          natWs.getColumn(1).values = nationality
        }
      }

      if (extraListData?.length > 0) {
        extraListData?.map(v => {
          createHiddenWorkSheet(wb, v.worksheetName, v.data)
        })
      }

      const { header, additionalCellSettings } = downloadTemplateSetup(
        headerSetup
      )

      // initiate header
      ws.columns = header

      // unprotect the column to be inserted
      if (!protectWholeSheet) {
        for (let i = 0; i < header.length; i++) {
          ws.getColumn(i + 1).protection = { locked: false }
        }
      }

      // console.log(additionalCellSettings, 'additional settings')

      if (!!additionalCellSettings) {
        additionalCellSettings.map((v, i) => {
          // capping the values in 3000 -- considerably large number
          // to speed up the process of creating template (increase if necessary)
          // initialize the cell with empty values just to do cell data validation
          // this allow the cell to be validate with our own data validation
          // for now only has boolean value
          let values = new Array(3000).fill('')
          ws.getColumn(v.colNo).values = values

          ws.getColumn(v.colNo).eachCell((cell, rowNo) => {
            // redefined the values of the header since it got overwrote by prev lines
            if (rowNo === 1) cell.value = header[v.colNo - 1]?.header

            // for now according to contract, there are column that only consists of true of false value
            if (v.dataType === 'TrueOrFalse') {
              ws.getCell(cell.address).dataValidation = BOOLEAN_TYPE_DATA
            }
            if (v.dataType === 'ActiveOrInactive') {
              ws.getCell(cell.address).dataValidation = ACTIVE_TYPE_DATA
            }

            if (v.dataType === 'nationality') {
              ws.getCell(cell.address).dataValidation = NATIONALITY_TYPE_DATA
            }
            if (v.dataType === 'country') {
              ws.getCell(cell.address).dataValidation = COUNTRY_TYPE_DATA
            }
            if (v.dataType === 'wageType') {
              ws.getCell(cell.address).dataValidation = WAGES_TYPE_DATA
            }
            if (v.dataType === 'date') {
              ws.getCell(cell.address).numFmt = 'dd/mm/yyyy'
              ws.getCell(cell.address).dataValidation = DATE_TYPE_DATA as any
            }
            if (v.dataType === 'parentCode') {
              ws.getCell(cell.address).dataValidation = PARENTCODE_TYPE_DATA(
                `=OFFSET($B$2, 0, 0, COUNTA($B:$B)-1, 1)`
              ) as any
            }
            if (v.dataType === 'code') {
              ws.getCell(cell.address).dataValidation = CODE_TYPE_DATA as any
            }
            if (v.dataType === 'accType') {
              ws.getCell(cell.address).dataValidation = ACCOUNT_TYPE_DATA as any
            }

            if (v.dataType === 'level' && rowNo !== 1) {
              ws.getCell(cell.address).dataValidation = NUMBER_TYPE_DATA(
                `=ISNUMBER(D${rowNo})`
              ) as any
            }
            if (v.dataType === 'amount' && rowNo !== 1) {
              ws.getCell(cell.address).dataValidation = NUMBER_TYPE_DATA(
                `=ISNUMBER(${cell.address})`
              ) as any
            }
            // if (v.dataType === 'baseAmount' && rowNo !== 1) {
            //   ws.getCell(cell.address).dataValidation = NUMBER_TYPE_DATA(
            //     `=ISNUMBER(F${rowNo})`
            //   ) as any
            // }
            // if (v.dataType === 'docAmount' && rowNo !== 1) {
            //   ws.getCell(cell.address).dataValidation = NUMBER_TYPE_DATA(
            //     `=ISNUMBER(G${rowNo})`
            //   ) as any
            // }
            if (v.dataType === 'bankReconAmount' && rowNo !== 1) {
              ws.getCell(cell.address).dataValidation = NUMBER_TYPE_DATA(
                `=ISNUMBER(${cell.address})`
              ) as any
            }
            if (v.dataType === 'journalDate') {
              ws.getCell(
                cell.address
              ).dataValidation = JOURNAL_DATE_TYPE_DATA as any

              ws.getCell(cell.address).numFmt = 'dd/MM/yyyy'
              if (v.dataType === 'custom') {
                ws.getCell(
                  cell.address
                ).dataValidation = MOBILE_TYPE_DATA as any
              }
            }

            if (v.dataType === 'number' && rowNo !== 1) {
              ws.getCell(cell.address).dataValidation = NUMBER_TYPE_DATA(
                `=ISNUMBER(${cell.address})`
              ) as any
            }
            if (v.dataType === 'text') {
              ws.getCell(cell.address).numFmt = '@'
            }
            if (v.dataType === 'abcClass') {
              ws.getCell(cell.address).dataValidation = ABC_CLASS_DATA
            }

            if (extraListData?.length > 0) {
              extraListData?.map(w => {
                if (v.dataType === w.dataType) {
                  ws.getCell(cell.address).dataValidation = CREATE_LIST_TYPE(
                    `=${w.worksheetName}!$A$1:$A$${w.data.length + 1}`
                  ) as any
                }
              })
            }
            // can add other datatypes as you want
            // will make it dynamic in the future
          })
        })
      }

      // header doesnt move in view
      ws.views = [{ state: 'frozen', ySplit: 1 }]

      // lock the header if user cannot change the header
      if (isHeaderLocked) ws.getRow(1).protection = { locked: true }

      // default styles (if provided) set to each one of the headers
      if (!!defaultHeaderFillStyle || !!defaultHeaderBorderStyle) {
        ws.getRow(1).eachCell(cell => {
          cell.fill = defaultHeaderFillStyle
          cell.border = defaultHeaderBorderStyle
        })
      }

      // custom styles (if provided) can be given to specific col by column
      // number as the position
      if (headerStyle?.length > 0) {
        headerStyle.forEach(val => {
          if (val.col > header.length)
            throw new Error('The column does not exists')
          else {
            ws.getCell(1, val.col).fill = val.fillStyle
            ws.getCell(1, val.col).border = val.borderStyle
          }
        })
      }

      // protect the whole excel sheet (with password provided
      // or default [THIS IS SET BY ME!])
      ws.protect(sheetPassword ? sheetPassword : 'contX@gen-Set', options)
      const buffer = await wb.xlsx.writeBuffer()
      // download part
      data = new Blob([buffer], { type: fileType })
      FileSaver.saveAs(data, name)
    } finally {
    }
  }

  // WHEN EXCEL FILE IS SELECTED
  // WHEN EXCEL FILE IS SELECTED
  // WHEN EXCEL FILE IS SELECTED

  const onSelectFile = async (props: ISelectedFileProps) => {
    const { event } = props
    let isValid: boolean = false

    const file: File = event.target.files[0]
    setCurrFile(file)

    if (!!file) {
      isValid =
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ? true
          : false
    }

    // initialize and read/load file buffer
    if (isValid === true) {
      // const wb = new ExcelJs.Workbook()
      await wb.xlsx.load(event.target.files[0])

      // get the worksheet (1 here because each excel has only one worksheet)
      // can be changed to use name of the specific worksheet in case there are multiple ws
      const ws = wb.getWorksheet(1)

      let dataCollected = [],
        columnNames

      setFileTitle(file.name)
      // populate the data for each row
      ws.eachRow({ includeEmpty: true }, (row, rowNo) => {
        let currRow = null
        if (rowNo !== 1) {
          currRow = row.values
          currRow.shift()
        } else {
          columnNames = row.values
          columnNames.shift()
        }

        if (rowNo > 1 && !isRowEmpty(currRow))
          dataCollected.push({
            ...basicDataProcessing(currRow, columnNames),
            rowNo: rowNo,
          })
      })

      // console.log(dataCollected, '>>>>>>>>>>>>>>')
      setCurrentData([...dataCollected])
    } else {
      snackFunc('Invalid file type!', 1000)
      return
    }
  }

  /// SOME PRE-DEFINED FUNCTION FOR PROCESSING DATA (BASIC PER LINE DATA)
  /// SOME PRE-DEFINED FUNCTION FOR PROCESSING DATA (BASIC PER LINE DATA)
  /// SOME PRE-DEFINED FUNCTION FOR PROCESSING DATA (BASIC PER LINE DATA)

  const basicDataProcessing = (data: any[], columns: any[]) => {
    let processedData = null

    if (data?.length > 0) {
      columns?.map((val, index) => {
        let temp = {
          [val]:
            data[index] === undefined || `${data[index]}`.trim() === ''
              ? null
              : data[index],
        }

        if (!!temp) processedData = { ...processedData, ...temp }
      })
    }
    return processedData
  }

  const isRowEmpty = (row: any) => {
    return row.filter(v => `${v}`.trim() !== '').length === 0
  }

  // UPLOADING OWN TEMPLATE
  // UPLOADING OWN TEMPLATE
  // UPLOADING OWN TEMPLATE

  const [hasCheckDup, setHasCheckDup] = useState<boolean>(false)

  const onUploadTemplate = async (props: IOnUploadProps) => {
    const { columns, customDataProcessing, findExistingData } = props
    let index_of_headers_map: any = {}

    if (currFile) await wb.xlsx.load(currFile)
    const ws = wb.getWorksheet(1)

    // initialize the header of the index in map
    ws.getRow(1).eachCell((cell, colNo) => {
      index_of_headers_map[cell.value as string] = colNo
    })

    // check if the column is empty
    if (!currentData || currentData?.length === 0) {
      snackFunc('Excel file is empty', 4000)
      setIsEmpty(true)
      return
    }
    // check if there are any duplicate items
    if (currentData) {
      columns.map(x => {
        let currColumn = ws.getColumn(index_of_headers_map[x])?.values as any
        let currColValues = currColumn.filter(x => x).slice(1) // slice to remove header value

        // this is just basic as it check all the values inside the column itself
        if (arrayHasDuplicates(currColValues)) {
          snackFunc(`Excel file contain duplicates`, 4000)
          setHasDuplicate(hasDuplicate + 1)
          return
        }
      })
    }

    if (!!customDataProcessing) {
      customDataProcessing(currentData, setCurrentData)
    }

    if (!!findExistingData) {
      await findExistingData()
    }

    // duplicate Items -- checking between excel and existing data
    // hasDuplicate -- check if there is any duplicates inside the excel itself
    // isEmpty -- check if the excel is empty
    // if (!isEmpty && hasDuplicate === 0) {
    //   setSave(true)
    // } else {
    //   setSave(false)
    //   // if (duplicateItems?.length > 0) {
    //   //   setErrorDialog(true)
    //   // }
    // }
  }

  // duplicate Items -- checking between excel and existing data
  // hasDuplicate -- check if there is any duplicates inside the excel itself
  // isEmpty -- check if the excel is empty
  // SINCE CONTRACT GENERAL SETTING HAS CHECKING FOR ALL OF THE MATERIALS
  // THEN IT'S LOGICAL TO PUT HAS CHECK DUP AS A TIE BREAKER TO SOLVE THE ENTIRE
  // THING SINCE THE DEFAULT VALUE OF ALL OF THEM CAUSE THIS USEEFFECT TO SET THE ONSAVE
  // VALUE TO BE TRUE IN THE FIRST PLACE WITHOUT ACTUALLY CHECKING THE DATA FROM THE EXCEL
  useEffect(() => {
    if (
      hasCheckDup &&
      duplicateItems.length === 0 &&
      !isEmpty &&
      hasDuplicate === 0
    ) {
      setSave(true)
    } else {
      setSave(false)
    }
  }, [hasCheckDup, isEmpty, hasDuplicate, duplicateItems])

  const resetFileUpload = () => {
    setIsEmpty(false)
    setHasDuplicate(0)
    setSave(false)
  }

  ///PREDEFINED CHECKING DUPLICATE FUNCTION
  ///PREDEFINED CHECKING DUPLICATE FUNCTION
  ///PREDEFINED CHECKING DUPLICATE FUNCTION

  // return true if arr has duplicates
  const arrayHasDuplicates = (arr: any) => {
    let arrayContainDuplicates = new Set(arr).size !== arr.length

    if (arrayContainDuplicates) {
      findDuplicateValues(arr)
    }

    return arrayContainDuplicates
  }

  /// DATA PROCESSING FOR BUIDLING MATERIAL CONTRACTX
  /// DATA PROCESSING FOR BUIDLING MATERIAL CONTRACTX
  /// DATA PROCESSING FOR BUIDLING MATERIAL CONTRACTX

  interface IBuildingMaterialCustomProps {
    name: string
    items: any
  }

  const buildingMaterialDataProcessing = (
    currData: any[],
    setCurrData: React.Dispatch<any>,
    data_from_user?: any[]
  ) => {
    let finalData: IBuildingMaterialCustomProps[] = []
    let name: string = ''
    let nameArr: string[] = []
    let nameIndex: number

    currData?.map((v, i) => {
      name = v?.name

      if (name) {
        nameArr.push(name)

        // check to see duplicates inside building materials
        if (!!finalData && finalData[nameIndex]) {
          if (arrayHasDuplicates(finalData[nameIndex]?.items)) {
            snackFunc(`Excel file contain duplicates`, 4000)
            return
          }
        }

        nameIndex = nameArr.length - 1
      }

      if (name && !!!finalData[nameIndex]) {
        finalData.push({ name: '', items: [] })
      }

      let tempObject = {}
      Object.entries(v).map(w => {
        if (w[0] != 'name') {
          tempObject[w[0]] = w[1]
        }
      })

      if (name && !!finalData[nameIndex]) {
        finalData[nameIndex].name = v.name
        finalData[nameIndex].items.push(tempObject)
      } else if (!name && !!finalData[nameIndex]) {
        finalData[nameIndex].items.push(tempObject)
      }
    })

    extractExistingValues(
      nameArr.map(v => v.toLowerCase()),
      data_from_user
    )

    setCurrData([...finalData])
  }

  interface IPNMProps {
    parent: string
    name: string
    pnmCostCat: PNMCostCategoryProps
    tax: string
  }

  interface PNMCostCategoryProps {
    roadTax: boolean
    insurance: boolean
    warranty: boolean
    quantity: boolean
  }

  const PNMDataProcessing = (
    currData: any[],
    setCurrData: React.Dispatch<any>,
    data_from_user?: string[]
  ) => {
    let finalData: IPNMProps[] = []
    let stringOfArr: string[] = []

    currData?.map((v, i) => {
      stringOfArr.push(v?.name?.toLowerCase())
      finalData.push({
        parent: v?.parent,
        name: v?.name,
        tax: v?.tax,
        pnmCostCat: {
          insurance: v?.insurance,
          quantity: v?.quantity,
          roadTax: v?.roadTax,
          warranty: v?.warranty,
        },
      })
    })

    extractExistingValues(stringOfArr, data_from_user)

    setCurrData([...finalData])
    // return existedValue
  }

  // PREDEFINED FINDING EXISTING VALUES FOR CONTRACT
  // PREDEFINED FINDING EXISTING VALUES FOR CONTRACT
  // PREDEFINED FINDING EXISTING VALUES FOR CONTRACT

  // compare name array from excel and name array from query
  const extractExistingValues = (
    data_from_excel: string[],
    provided_data: string[]
  ) => {
    let existedValue = []
    let excel_set = new Set(data_from_excel)
    let provided_data_set = new Set(provided_data)

    // console.log('does it come here?')

    excel_set.forEach(v => {
      if (provided_data_set.has(v)) existedValue.push(v)
    })

    setDuplicateItems([...existedValue])
    if (existedValue?.length > 0) setErrorDialog(true)
    else setHasCheckDup(true)
  }

  // PREDEFINED FINDING EXISTING VALUES FOR BUILDING MATERIAL CONTRACT
  // PREDEFINED FINDING EXISTING VALUES FOR BUILDING MATERIAL CONTRACT
  // PREDEFINED FINDING EXISTING VALUES FOR BUILDING MATERIAL CONTRACT

  return {
    openFileUpload,
    setOpenFileUpload,
    /////////////////////////
    fileTitle,
    setFileTitle,
    /////////////////////////
    openSnackBar,
    setOpenSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    snackFunc,
    /////////////////////////
    currentData,
    setCurrentData,
    /////////////////////////
    // itemError,
    onSave,
    duplicateItems,
    errorDialog,
    setErrorDialog,
    resetFileUpload,
    /////////////////////////
    onDownloadTemplate,
    onSelectFile,
    onUploadTemplate,
    ///////////////// SPECIFIC DATA PROCESSING
    buildingMaterialDataProcessing,
    PNMDataProcessing,
    extractExistingValues,
    //////////////////////////////
  }
}

const BOOLEAN_TYPE_DATA: ExcelJs.DataValidation = {
  type: 'list',
  allowBlank: true,
  formulae: ['"True, False"'],
  showErrorMessage: true,
  errorStyle: 'error',
  errorTitle: 'True or False',
  error: 'The value must be True or False only',
}

const ACTIVE_TYPE_DATA: ExcelJs.DataValidation = {
  type: 'list',
  allowBlank: true,
  formulae: ['"Active, Inactive"'],
  showErrorMessage: true,
  errorStyle: 'error',
  errorTitle: 'Active or Inactive',
  error: 'The value must be Active or Inactive only',
}

const COUNTRY_TYPE_DATA: ExcelJs.DataValidation = {
  type: 'list',
  allowBlank: true,
  formulae: ['=countries!$A$1:$A$253'],
  showErrorMessage: true,
  errorStyle: 'error',
  errorTitle: 'Error',
  error: 'The value must be from list',
}

const NATIONALITY_TYPE_DATA: ExcelJs.DataValidation = {
  type: 'list',
  allowBlank: true,
  formulae: ['=nationalities!$A$1:$A$113'],
  showErrorMessage: true,
  errorStyle: 'error',
  errorTitle: 'Error',
  error: 'The value must be from list',
}

const WAGES_TYPE_DATA: ExcelJs.DataValidation = {
  type: 'list',
  allowBlank: true,
  formulae: ['"Hourly, Daily, Monthly"'],
  showErrorMessage: true,
  errorStyle: 'error',
  errorTitle: 'Error',
  error: 'The value must be from list',
}

const DATE_TYPE_DATA: ExcelJs.DataValidation = {
  type: 'date',
  allowBlank: true,
  formulae: ['2000-01-01'],
  operator: 'greaterThan',
  showInputMessage: true,
  promptTitle: 'Date',
  prompt: 'Format (DD/MM/YYYY)',
  showErrorMessage: true,
  errorStyle: 'error',
  errorTitle: 'Error',
  error: 'Invalid Date/Date should be in DD/MM/YYYY format',
}
const MOBILE_TYPE_DATA: ExcelJs.DataValidation = {
  type: 'custom',
  allowBlank: true,
  formulae: [],
  showInputMessage: true,
  promptTitle: 'Mobile No',
  prompt: 'Please include country code',
}
const ABC_CLASS_DATA: ExcelJs.DataValidation = {
  type: 'list',
  allowBlank: true,
  formulae: ['"A, B, C"'],
  showErrorMessage: true,
  errorStyle: 'error',
  errorTitle: 'Error',
  error: 'The value must be from list',
}

const PARENTCODE_TYPE_DATA = (formula: string) => {
  return {
    type: 'list',
    allowBlank: true,
    formulae: [formula],
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Error',
    error: 'The value must be from list',
  }
}
const CODE_TYPE_DATA = {
  allowBlank: true,
  formulae: ['=OFFSET($B$2, 0, 0, COUNTA($B:$B), 1)'],
  showErrorMessage: true,
  errorStyle: 'error',
  errorTitle: 'Error',
  error: 'The value must be from list',
}

const ACCOUNT_TYPE_DATA: ExcelJs.DataValidation = {
  type: 'list',
  allowBlank: true,
  formulae: ['"ASSET, LIABILITY, EQUITY, REVENUE, EXPENSE"'],
  showErrorMessage: true,
  errorStyle: 'error',
  errorTitle: 'Error',
  error: 'The value must be from the list',
}

const NUMBER_TYPE_DATA = (formula: string) => {
  return {
    type: 'custom',
    allowBlank: true,
    formulae: [formula],
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Error',
    error: 'The value must be number only',
  }
}

const JOURNAL_DATE_TYPE_DATA = {
  allowBlank: true,
  //formulae: ['"=TODAY()"'],
  type: 'date',
  //operator: 'greaterThanOrEqual',
  showInputMessage: true,
  promptTitle: 'Journal Date',
  prompt: 'Format (DD/MM/YYYY)',
  showErrorMessage: true,
  errorStyle: 'error',
  errorTitle: 'Error',
  error: `Invalid Date Format or Date Doesn't Exist`,
}

// can change the properties to suit your needs
const CREATE_LIST_TYPE = (formula: string) => {
  return {
    type: 'list',
    allowBlank: true,
    formulae: [formula],
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Error',
    error: 'The value must be from the list',
  }
}

// FIND DUPLICATE VALUES IN ARRAY ITSELF
// FIND DUPLICATE VALUES IN ARRAY ITSELF
// FIND DUPLICATE VALUES IN ARRAY ITSELF

const findDuplicateValues = (arr: any[]) => {
  var set = new Set()
  var duplicates = new Set()
  for (let i = 0; i < arr.length; i++) {
    var size = set.size
    set.add(arr[i])
    if (set.size === size) {
      duplicates.add(arr[i])
    }
  }
  return duplicates
}
