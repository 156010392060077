import React, { lazy } from 'react'
import { RoutineJournalDetail } from './RoutineJournalDetail'

const RoutineJournalListing = lazy(() =>
  import('./RoutineJournalListing').then(module => ({
    default: module.RoutineJournalListing,
  }))
)
const RoutineJournalForm = lazy(() =>
  import('./RoutineJournalForm').then(module => ({
    default: module.RoutineJournalForm,
  }))
)

const routinejournalRoutes = [
  {
    props: { exact: true, path: '/general-ledger/:CompanyID/routine-journal' },
    component: <RoutineJournalListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/routine-journal/:RoutineJournalID/detail',
    },
    component: <RoutineJournalDetail />,
  },

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/routine-journal/add',
    },
    component: <RoutineJournalForm mode={'add'} />,
  },

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/routine-journal/:RoutineJournalID/edit',
    },
    component: <RoutineJournalForm mode={'edit'} />,
  },
]

export default routinejournalRoutes
