import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { ListItem, ListItemText } from '@material-ui/core'
import React from 'react'

export const GeneralDialog = (props: any) => {
  const {
    openDialog,
    setOpenDialog,
    onConfirm,
    loadData,
    title,
    body,
    docNo,
    CompanyID,
  } = props

  return (
    <CommonDialog
      fullWidth={true}
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      sections={{
        header: {
          title: title,
          infoLine: docNo,
        },
        body: () => (
          <div>
            <span className="mdDesc" style={{ whiteSpace: 'initial' }}>
              {body}
            </span>
          </div>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () => {
                  setOpenDialog(false)
                  loadData({
                    variables: { CompanyID: CompanyID, StatusArr: 'COMPLETED' },
                  })
                },
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: onConfirm,
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
