import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core'
import { AccountFooter } from 'components/Footer/AccountFooter'
import AppContext from 'containers/App/Store/AppContext'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AmountType,
  ColumnSource,
  DurationType,
  GetDataSourceSettingsDocument,
  useCreateDataSourceSettingsMutation,
  useGetDataSourceSettingsQuery,
  useUpdateDataSourceSettingsMutation,
  YearPeriodType,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import InfoIcon from '@material-ui/icons/Info'
import * as yup from 'yup'

interface DataSourceSettingsProps {
  name: string
  code: string
  columnSource: string
  columnValue: string
  duration: string
  yearType: string
  yearValue: number
  periodType: string
  periodValue: number
  hidden: any
}

const columnSourceList = [
  { name: 'Actual', ID: ColumnSource.Actual },
  { name: 'Budget', ID: ColumnSource.Budget },
]

const amountTypeList = [
  { name: 'Opening', ID: AmountType.Opening },
  { name: 'Closing', ID: AmountType.Closing },
  { name: 'Transaction', ID: AmountType.Transaction },
]

const yearPeriodTypeList = [
  { name: 'Relative', ID: YearPeriodType.Relative },
  { name: 'Fixed', ID: YearPeriodType.Fixed },
]

const durationTypeList = [
  { name: 'Year', ID: DurationType.Year },
  { name: 'Month', ID: DurationType.Month },
]

export const DataSourceSettingsForm = (props: any) => {
  const { mode } = props
  const { dataSourceSettingsID }: any = useParams()
  let formMode = mode === 'add' ? 'New' : 'Edit'
  let location = useLocation()
  const theme = useTheme()
  const editData: any = location?.state
  let history = useHistory()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { globalState } = useContext(AppContext as any)
  const [open1, setOpen1] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const [open3, setOpen3] = React.useState(false)

  const {
    loading: getDataSourceSettingsLoading,
    error: getDataSourceSettingsError,
    data: { getDataSourceSettings } = {
      getDataSourceSettings: [],
    },
  } = useGetDataSourceSettingsQuery({
    fetchPolicy: 'network-only',
  })

  // CREATE USER CONSTANT
  const [
    createDataSourceSettings,
    {
      loading: createDataSourceSettingsLoading,
      error: createDataSourceSettingsError,
    },
  ] = useCreateDataSourceSettingsMutation({
    onError: error => {},
    onCompleted: data => {
      // history.push(`/${pageMode}/general-settings/access-security/user`);
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      history.push({
        pathname: `/general-ledger/financial-reporting/data-source-settings`,
      })
    },
  })

  // UPDATE USER
  const [
    updateDataSourceSettings,
    {
      loading: updateDataSourceSettingsLoading,
      error: updateDataSourceSettingsError,
    },
  ] = useUpdateDataSourceSettingsMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      history.push({
        pathname: `/general-ledger/financial-reporting/data-source-settings`,
      })
    },
  })

  //GET ROLE

  const handleMutationType =
    mode === 'add' ? createDataSourceSettings : updateDataSourceSettings

  const DataSourceSettingsSchema = yup.object().shape({
    name: CommonYupValidation.requireField(SystemMsgs.name()),
    code: CommonYupValidation.requireField(SystemMsgs.code()),
    columnSource: CommonYupValidation.requireField(SystemMsgs.source()),
    columnValue: CommonYupValidation.requireField(SystemMsgs.amountType()),
    duration: yup.string().when('columnValue', {
      is: AmountType.Transaction,
      then: yup.string().required('Duration is required'),
      otherwise: null,
    }),
    yearType: CommonYupValidation.requireField('Year Type is Required'),
    yearValue: yup
      .number()
      .when('yearType', {
        is: YearPeriodType.Relative,
        then: yup
          .number()
          .transform(value => (isNaN(value) ? undefined : value))
          .required('Year Value is Required'),
      })
      .when('yearType', {
        is: YearPeriodType.Fixed,
        then: yup
          .number()
          .min(1000, 'Must be 4 digit year value')
          .max(9999, 'Must be 4 digit year value')
          .transform(value => (isNaN(value) ? undefined : value))
          .required('Year Value is Required'),
      })
      .transform(value => (isNaN(value) ? undefined : value))
      .required('Year Value is Required'),
    periodType: yup
      .string()
      .when('duration', {
        is: DurationType.Month,
        then: CommonYupValidation.requireField('Period Type is Required'),
      })
      .nullable(),

    periodValue: yup.number().when('duration', {
      is: DurationType.Month,
      then: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .required('Period Value is Required'),
    }),
  })

  const { handleSubmit, register, errors, control, getValues, watch } = useForm<
    DataSourceSettingsProps
  >({
    defaultValues: {
      name: editData?.Name,
      code: editData?.Code,
      columnSource: editData?.ColumnSource,
      columnValue: editData?.ColumnValue,
      yearType: editData?.YearType,
      yearValue: editData?.YearValue,
      periodType: editData?.PeriodType,
      periodValue: editData?.PeriodValue,
      duration: editData?.Duration,
    },
    mode: 'onSubmit',
    resolver: yupResolver(DataSourceSettingsSchema),
  })

  const onSubmit = data => {
    const input: any = {
      Name: data?.name,
      Code: data?.code,
      ColumnSource: data?.columnSource,
      ColumnValue: data?.columnValue,
      YearType: data?.yearType,
      YearValue: parseInt(data?.yearValue),
      PeriodType:
        data?.duration === DurationType.Month ? data?.periodType : null,
      PeriodValue:
        data?.duration === DurationType.Month
          ? parseInt(data?.periodValue)
          : null,
      Duration: data?.duration,
    }

    const inputFinal: any =
      mode === 'add'
        ? input
        : Object.assign({ DataSourceSettingsID: dataSourceSettingsID }, input)

    handleMutationType({
      variables: {
        input: inputFinal,
      },
      refetchQueries: [
        {
          query: GetDataSourceSettingsDocument,
          variables: {
            orderByDesc: 'createdTs',
          },
        },
      ],
    })
  }

  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const checkExistingCode = () => {
    if (
      (mode === 'add' &&
        getDataSourceSettings?.filter(x => x?.Code === watch(`code`))?.length >
          0) ||
      (mode === 'edit' &&
        editData?.Code !== watch(`code`) &&
        getDataSourceSettings?.filter(x => x?.Code === watch(`code`))?.length >
          0)
    ) {
      return true
    } else {
      return false
    }
  }

  const yearValInfo = () => {
    if (watch('yearValue') === undefined) {
      return
    }
  }

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  return (
    <>
      {createDataSourceSettingsLoading && <Loading />}
      {updateDataSourceSettingsLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(
            `/general-ledger/financial-reporting/data-source-settings`
          )
        }
        smTitle={'General Ledger'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Financial Reporting' },
          { name: 'Data Source Settings', current: true },
        ]}
        rightRouteSegments={[{ name: formMode, current: true }]}
      />

      <ContentWrapper footer float>
        <form id="submit-form" onSubmit={handleSubmit(onSubmit)}>
          <CardContents>
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  as={TextField}
                  name="name"
                  fullWidth
                  margin="normal"
                  label="Name "
                  required
                  helperText={errors?.name?.message}
                  error={errors?.name ? true : false}
                  control={control}
                  ref={register}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingRight: '10px' }}>
                <Controller
                  as={TextField}
                  name="code"
                  // className="left"
                  // fullWidth
                  margin="normal"
                  label="Code "
                  required
                  helperText={
                    checkExistingCode() === true
                      ? 'Code already exist'
                      : errors?.code?.message
                  }
                  error={errors?.code ? true : false}
                  control={control}
                  ref={register}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={
                    <TextField select>
                      {columnSourceList?.map((el, index) => (
                        <MenuItem
                          style={{ whiteSpace: 'normal' }}
                          value={el.ID}
                          key={index}
                        >
                          <span className="text-noflow">{el?.name}</span>
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  margin="normal"
                  required
                  select
                  name="columnSource"
                  // className="right"
                  // fullWidth
                  label="Source"
                  autoComplete="off"
                  control={control}
                  ref={register()}
                  helperText={errors?.columnSource?.message}
                  error={errors?.columnSource ? true : false}
                  defaultValue={editData?.columnSource}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingRight: '10px' }}>
                <Controller
                  as={
                    <TextField select>
                      {amountTypeList?.map((el, index) => (
                        <MenuItem
                          style={{ whiteSpace: 'normal' }}
                          value={el.ID}
                          key={index}
                        >
                          <span className="text-noflow">{el?.name}</span>
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  margin="normal"
                  required
                  select
                  name="columnValue"
                  // className="left"
                  // fullWidth
                  label="Amount Type"
                  autoComplete="off"
                  control={control}
                  ref={register()}
                  helperText={errors?.columnValue?.message}
                  error={errors?.columnValue ? true : false}
                  defaultValue={editData?.columnValue}
                  SelectProps={{ IconComponent: () => null }}
                  InputProps={{
                    endAdornment: (
                      <ClickAwayListener onClickAway={() => setOpen3(false)}>
                        <div>
                          <GreyTooltip
                            // placement="right"
                            onClick={() => setOpen3(true)}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => setOpen3(false)}
                            open={open3}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <React.Fragment>
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  Opening
                                </div>
                                <div
                                  style={{
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  -
                                </div>
                                &nbsp;
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  Year Opening
                                </div>
                                <div
                                  style={{
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  -
                                </div>
                                &nbsp;
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  Closing
                                </div>
                                <div
                                  style={{
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  -
                                </div>
                                &nbsp;
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  Year Closing
                                </div>
                                <div
                                  style={{
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  -
                                </div>
                                &nbsp;
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  Transaction
                                </div>
                                <div
                                  style={{
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  -
                                </div>
                              </React.Fragment>
                            }
                            arrow
                            enterTouchDelay={0}
                          >
                            <div>
                              <InfoIcon
                                style={{
                                  fontSize: 'medium',
                                  color: 'grey',
                                }}
                              />
                            </div>
                          </GreyTooltip>
                        </div>
                      </ClickAwayListener>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={
                    <TextField select>
                      {durationTypeList?.map((el, index) => (
                        <MenuItem
                          style={{ whiteSpace: 'normal' }}
                          value={el.ID}
                          key={index}
                        >
                          <span className="text-noflow">{el?.name}</span>
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  // className="left"
                  // fullWidth
                  margin="normal"
                  required
                  select
                  name="duration"
                  label="Duration"
                  autoComplete="off"
                  control={control}
                  ref={register()}
                  helperText={errors?.duration?.message}
                  error={errors?.duration ? true : false}
                  defaultValue={editData?.duration}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingRight: '10px' }}>
                {' '}
                <Controller
                  as={
                    <TextField select>
                      {yearPeriodTypeList?.map((el, index) => (
                        <MenuItem
                          style={{ whiteSpace: 'normal' }}
                          value={el.ID}
                          key={index}
                        >
                          <span className="text-noflow">{el?.name}</span>
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  // className="left"
                  // fullWidth
                  margin="normal"
                  required
                  select
                  name="yearType"
                  label="Year Type"
                  autoComplete="off"
                  control={control}
                  ref={register()}
                  helperText={errors?.yearType?.message}
                  error={errors?.yearType ? true : false}
                  defaultValue={editData?.yearType}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={
                    <NumberFormat
                      allowNegative={
                        watch('yearType') === YearPeriodType.Fixed
                          ? false
                          : true
                      }
                    />
                  }
                  decimalSeparator={false}
                  customInput={TextField}
                  // className="right"
                  // fullWidth
                  id="standard-basic"
                  name="yearValue"
                  label="Year Value"
                  autoComplete="off"
                  control={control}
                  margin="normal"
                  required
                  ref={register}
                  helperText={errors?.yearValue?.message}
                  error={errors?.yearValue ? true : false}
                  defaultValue={editData?.yearValue}
                  disabled={formMode === 'approve-reject'}
                  InputProps={{
                    endAdornment: (
                      <ClickAwayListener onClickAway={() => setOpen1(false)}>
                        <div>
                          <GreyTooltip
                            placement="top-end"
                            onClick={() => setOpen1(true)}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => setOpen1(false)}
                            open={open1}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <React.Fragment>
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  Relative
                                </div>
                                <div
                                  style={{
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  - can be +ve/-ve
                                </div>
                                <div
                                  style={{
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  - eg: (1 next year, 0 current year, -1 last
                                  year)
                                </div>
                                &nbsp;
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  Fixed
                                </div>
                                <div
                                  style={{
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  - must be year value
                                </div>
                                <div
                                  style={{
                                    fontSize: '12px',
                                  }}
                                  className="desc"
                                >
                                  - eg: (2002,2022,2023)
                                </div>
                              </React.Fragment>
                            }
                            arrow
                            enterTouchDelay={0}
                          >
                            <div>
                              <InfoIcon
                                style={{
                                  fontSize: 'medium',
                                  color: 'grey',
                                }}
                              />
                            </div>
                          </GreyTooltip>
                        </div>
                      </ClickAwayListener>
                    ),
                  }}
                />
              </Grid>

              {watch('duration') === DurationType.Month && (
                <Grid item xs={6} style={{ paddingRight: '10px' }}>
                  <Controller
                    as={
                      <TextField select>
                        {yearPeriodTypeList?.map((el, index) => (
                          <MenuItem
                            style={{ whiteSpace: 'normal' }}
                            value={el.ID}
                            key={index}
                          >
                            <span className="text-noflow">{el?.name}</span>
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                    // className="left"
                    // fullWidth
                    margin="normal"
                    required
                    select
                    name="periodType"
                    label="Period Type"
                    autoComplete="off"
                    control={control}
                    ref={register()}
                    helperText={errors?.periodType?.message}
                    error={errors?.periodType ? true : false}
                    defaultValue={editData?.periodType}
                  />
                </Grid>
              )}
              {watch('duration') === DurationType.Month && (
                <Grid item xs={6}>
                  <Controller
                    as={
                      <NumberFormat
                        allowNegative={
                          watch('periodType') === YearPeriodType.Fixed
                            ? false
                            : true
                        }
                      />
                    }
                    decimalSeparator={false}
                    customInput={TextField}
                    // className="right"
                    // fullWidth
                    id="standard-basic"
                    name="periodValue"
                    label="Period Value"
                    autoComplete="off"
                    control={control}
                    margin="normal"
                    required
                    ref={register}
                    helperText={errors?.periodValue?.message}
                    error={errors?.periodValue ? true : false}
                    defaultValue={editData?.periodValue}
                    disabled={formMode === 'approve-reject'}
                    InputProps={{
                      endAdornment: (
                        <ClickAwayListener onClickAway={() => setOpen2(false)}>
                          <div>
                            <GreyTooltip
                              placement="top-end"
                              onClick={() => setOpen2(true)}
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={() => setOpen2(false)}
                              open={open2}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <React.Fragment>
                                  <div
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: '12px',
                                    }}
                                    className="desc"
                                  >
                                    Relative
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '12px',
                                    }}
                                    className="desc"
                                  >
                                    - can be +ve/-ve
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '12px',
                                    }}
                                    className="desc"
                                  >
                                    - eg: (1 next period, 0 current period, -1
                                    last period)
                                  </div>
                                  &nbsp;
                                  <div
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: '12px',
                                    }}
                                    className="desc"
                                  >
                                    Fixed
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '12px',
                                    }}
                                    className="desc"
                                  >
                                    - must be period value
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '12px',
                                    }}
                                    className="desc"
                                  >
                                    - eg: (1,5,12)
                                  </div>
                                </React.Fragment>
                              }
                              arrow
                              enterTouchDelay={0}
                            >
                              <div>
                                <InfoIcon
                                  style={{
                                    fontSize: 'medium',
                                    color: 'grey',
                                  }}
                                />
                              </div>
                            </GreyTooltip>
                          </div>
                        </ClickAwayListener>
                      ),
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </CardContents>
        </form>
        <AccountFooter
          optionStyle={
            isDesktop && globalState.drawerOpen ? { marginLeft: '241px' } : null
          }
          // isDesktop
          // drawerOpen
          options={[
            {
              name: 'Save',
              onClick: () => handleSubmit(onSubmit)(),
              color: 'primary',
              props: {
                type: 'submit',
              },
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
