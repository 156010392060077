import {
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import Search from '@material-ui/icons/Search'
import React, { useState } from 'react'
interface ISearchBarProps {
  //Consultant request to have title and value
  title?: string
  value?: string
  borderRadius?: string
  onClick?: any
  input?: any
  option?: {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    icon?: any
  }
  search?: boolean
  onChangeAction?: any
  onCloseAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
  defaultValue?: string
  isDefaultValue?: boolean
}

export const SearchBar = (props: ISearchBarProps) => {
  const {
    title,
    value,
    borderRadius,
    onChangeAction,
    onCloseAction,
    defaultValue,
    isDefaultValue,
    option,
  } = props

  const [Empty, setEmpty] = useState(isDefaultValue ? false : true)
  const handleSearchToggle = () => {
    localStorage.setItem(
      'searchFilter',
      JSON.stringify({
        option: JSON.parse(localStorage.getItem('searchFilter')).option,
        name: '',
      })
    )
    setEmpty(true)
  }

  const setEmptyFunc = e => {
    if (!!e.target.value) {
      setEmpty(false)
    } else {
      setEmpty(true)
      localStorage.setItem(
        'searchFilter',
        JSON.stringify({
          option: JSON.parse(localStorage.getItem('searchFilter')).option,
          name: '',
        })
      )
    }
  }

  return (
    <List
      className={`search-header ${!Empty ? null : 'hide'}`}
      style={{ borderRadius: borderRadius, height: '100%' }}
    >
      <ListItem>
        <ListItemIcon className="search-indicator">
          <Search />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <>
              <InputBase
                placeholder={title ? `${title}(${value})` : 'Search here..'}
                inputProps={{ 'aria-label': 'search' }}
                onChange={e => {
                  onChangeAction(e)
                  setEmptyFunc(e)
                }}
                defaultValue={defaultValue}
                inputRef={input => {
                  // input && searchBar && input.focus()
                  input && Empty && (input.value = '')
                }}
              />
            </>
          }
        />

        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="close"
            style={{ paddingTop: '50px' }}
            onClick={e => {
              handleSearchToggle()
              onCloseAction(e)
            }}
            className={`close-search ${!Empty ? null : 'hide'} `}
          >
            <Close />
          </IconButton>
          {option && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={option?.onClick}
            >
              {option?.icon}
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}
