import { CopyRight } from '@ifca-root/react-component/src/components/AuthLayout/CopyRight'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { setAccessToken } from 'AccessToken'
import { AccountClient } from 'AccountClient'
import Logo from 'assets/images/Account-x.png'
import Background from 'assets/images/HR-Background.jpg'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import {
  LoggedInUserProfileDocument,
  LoggedInUserProfileQuery,
  useLoginMutation,
} from 'generated/graphql'
import { encryptMessage } from 'helpers/Crypto/JSEncrypt'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

interface LoginFormProps {
  email: string
  password: string
}
export const Login = () => {
  let history = useHistory()
  const [values, setValues] = useState(false)
  const [open, setOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [message, setMessage] = useState('')

  const rememberme: any = localStorage.getItem('rememberme')
  localStorage.clear()
  if (rememberme !== null) {
    localStorage.setItem('rememberme', rememberme)
  }
  const [rememberMe, setRememberMe] = useState(
    rememberme !== null ? true : false
  )

  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleCloseSnackBar = () => {
    setOpen(false)
    history.push('/profile')
  }
  const MESSAGE = 'Password Changed Successfully'

  const handleClickShowPassword = () => {
    setValues(!values)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleCheckRememberMe = (checked: boolean) => {
    setRememberMe(checked)
  }

  //useForm set DefaultValues, validationSchema
  const { handleSubmit, register, errors, control, reset, getValues } = useForm<
    LoginFormProps
  >({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  })

  /////////////////////////////////////////////////////////////////////
  ///////////////////////   QUERIES & MUTATIONS ///////////////////////
  /////////////////////////////////////////////////////////////////////

  const [login, { data, loading, error }] = useLoginMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message)
        setMessage(message)
        setOpenAlert(true)
      })
    },
    update: (store, { data }) => {
      if (!data) {
        return null
      }
      store.writeQuery<LoggedInUserProfileQuery>({
        query: LoggedInUserProfileDocument,
        data: {
          __typename: 'Query',
          loggedInUserProfile: data.login.user as any,
        },
      })
    },
    onCompleted: ({ login }) => {
      if (login && login?.accessToken) {
        setAccessToken(login.accessToken)
        sessionStorage.setItem('accessToken', login.accessToken)
      }
      if (login?.user?.lastestAccessedEntity) {
        localStorage.setItem(
          'latestCompany',
          login?.user?.lastestAccessedEntity
        )
      }
      if (rememberMe) {
        let rememberme = {
          username: getValues('email'),
          password: getValues('password'),
        }
        localStorage.setItem('rememberme', JSON.stringify(rememberme))
      } else {
        localStorage.removeItem('rememberme')
      }
      //localStorage.setItem('latestAccountType', 'ASSET')
      //localStorage.setItem('loggedInUser', JSON.stringify(login?.user))

      // localStorage.setItem(
      //   'latestCompany',
      //   data?.login?.user?.lastestAccessedEntity!
      // )

      setTimeout(() => {
        history.push({ pathname: `/`, state: login?.user })
        // if (getCompany?.length === 0) {
        //   history.push(`/company`)
        // } else {
        //   history.push(
        //     `home/${data?.login.user?.lastestAccessedEntity ||
        //       getCompany[0]?.CompanyID}`
        //   )
        // }
      }, 100)
    },
    client: AccountClient as any,
  })

  const onSubmit = data => {
    login({
      variables: {
        loginId: data?.email,
        password: encryptMessage(data?.password),
      },
    })
  }

  return (
    <>
      {loading && <Loading />}
      <AuthLayout className="margin-override" logo={Logo} image={Background}>
        <span className="page-title">Login</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.email?.message}
              error={errors?.email ? true : false}
              autoComplete="off"
              name="email"
              label="Email/Username"
              required
              control={control}
              ref={register}
            />
            <Grid container justify="flex-start">
              <Controller
                as={TextField}
                fullWidth
                helperText={errors?.password?.message}
                error={errors?.password ? true : false}
                autoComplete="off"
                name="password"
                label="Password"
                type={values ? 'text' : 'password'}
                required
                value={getValues('password')}
                //   onChange={handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                control={control}
                ref={register}
              />
            </Grid>

            <div className="other-auth">
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    checked={rememberMe}
                    onChange={e => handleCheckRememberMe(e.target.checked)}
                  />
                }
                label="Remember Me"
              />
              <span
                className="forget-link"
                onClick={() => history.push('/forgot-password')}
              >
                Forgot Password?
              </span>
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="login-btn"
            >
              Login
            </Button>
          </div>
        </form>
      </AuthLayout>
      <CommonDialog
        fullWidth={true}
        open={openAlert}
        onClose={handleCloseAlert}
        sections={{
          header: { title: 'Login Error' },
          body: () => <div>{message}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => handleCloseAlert(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <SnackBarMsg
        open={open}
        message={MESSAGE}
        onClose={handleCloseSnackBar}
      />
      <CopyRight />
    </>
  )
}
