import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

interface Props {
  space?: string
  font?: string
  label?: string
  children: any
  icon: any
  parentStyle?: any
  parentClassName?: any
  noNegative?: boolean
}

const IconText = props => {
  let {
    children,
    font,
    space,
    label,
    icon,
    onClick,
    labelStyle,
    childrenStyle,
    parentStyle,
    parentClassName,
    noNegative,
  } = props

  //Assing Space to Container
  const useStyles = makeStyles({
    containerSpace: {
      width: space,
    },
  })
  const classes = useStyles(props)

  const hasNumber = myString => {
    return /\d/.test(myString)
  }

  //Default Font
  if (font == null) font = 'xsTitle'

  let isNegative = false
  if (typeof children == 'string' && !noNegative && children?.includes('-'))
    isNegative = true

  return (
    <>
      <span
        className={`${classes.containerSpace} icon-text ${
          !!parentClassName ? parentClassName : ''
        }`}
        style={parentStyle}
      >
        {!!label ? (
          <span
            className={`${font} p-l-3`}
            onClick={onClick}
            style={labelStyle}
          >
            {label}
            {''}
          </span>
        ) : (
          ''
        )}
        {/* {isNegative ? '-' : ''} */}

        {icon}
        <span
          className={`${font} p-l-3`}
          onClick={onClick}
          style={childrenStyle}
        >
          {isNegative ? `(${children?.substring(1)})` : children}
        </span>
      </span>
    </>
  )
}

export default IconText
