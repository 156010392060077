import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { List, ListItem, ListItemText } from '@material-ui/core'
import {
  useDocumentListingQuery,
  useGetExpenseItemQuery,
  useGetItemQuery,
} from 'generated/graphql'
import { AttachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const ExpenseItemDetail = (props: any) => {
  const { mode }: any = props
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let history = useHistory()
  const { ExpenseItemID, ExpenseClassID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any

  // QUERY //

  const {
    loading: ExpenseItemLoading,
    error: ExpenseItemError,
    data: { getExpenseItem } = {
      getExpenseItem: [],
    },
  } = useGetItemQuery({
    fetchPolicy: 'network-only',
    variables: {
      ExpenseItemID: ExpenseItemID,
    },
  })

  return (
    <>
      {ExpenseItemLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(
            `/account-payable/general-setting/item-submenu/${ExpenseClassID}/item-listing`
          )
        }
        smTitle={smTitle.ACCOUNT_PAYABLE}
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Expense Item' },
          { name: 'Expense Item', current: true },
        ]}
      />

      <ContentWrapper>
        <CardContents
          section={{
            header: {
              title: 'Expense Item Details',
            },
          }}
        >
          <div className="content-wrap left">
            <div className="desc">Name</div>
            <div className="xsTitle">{getExpenseItem[0]?.ItemName}</div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Expense Class</div>
            <div className="xsTitle">
              {getExpenseItem[0]?.ExpenseClass?.Name}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Description</div>
            <div className="xsTitle text-noflow">
              {getExpenseItem[0]?.Description}
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc">UOM</div>
            <div className="xsTitle">{getExpenseItem[0]?.UOM?.Name}</div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Tax Code</div>
            <div className="xsTitle">{getExpenseItem[0]?.TaxScheme?.Code}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Item Account Code</div>
            <div className="xsTitle">
              {!!getExpenseItem[0]?.MasterCOA?.MasterCOAID
                ? `${getExpenseItem[0]?.MasterCOA?.Code} | ${getExpenseItem[0]?.MasterCOA?.Name}`
                : ``}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Remark</div>
            <div className="xsTitle text-noflow">
              {getExpenseItem[0]?.Remark}
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc">Category Account Code</div>
            <div className="xsTitle text-noflow">
              {!!getExpenseItem[0]?.ExpenseClass?.MasterCOAID
                ? ` ${getExpenseItem[0]?.ExpenseClass?.MasterCOA?.Code} | ${getExpenseItem[0]?.ExpenseClass?.MasterCOA?.Name}`
                : `${getExpenseItem[0]?.ExpenseClass?.ParentClassMCOA?.MasterCOACode} | ${getExpenseItem[0]?.ExpenseClass?.ParentClassMCOA?.MasterCOAName}`}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Status</div>
            <div
              className="xsTitle"
              style={{ display: 'flex', marginTop: '3px' }}
            >
              {getExpenseItem[0]?.RecordStatus === 'ACTIVE' && (
                <div
                  className="blue-status"
                  style={{
                    padding: '5px',
                    margin: '0px',
                    borderRadius: '2px',
                  }}
                >
                  Active
                </div>
              )}
              {getExpenseItem[0]?.RecordStatus === 'INACTIVE' && (
                <div
                  className="grey-status"
                  style={{
                    padding: '5px',
                    margin: '0px',
                    borderRadius: '2px',
                  }}
                >
                  Inactive
                </div>
              )}
            </div>
          </div>
        </CardContents>
      </ContentWrapper>
    </>
  )
}
