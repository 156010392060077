import theme from '@ifca-root/react-component/src/assets/theme'
import { Divider, Grid, useMediaQuery } from '@material-ui/core'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { default as React } from 'react'
import '../ReceiptForm/Receipt.scss'

export const ReceiptDetailItemContent = (props: any) => {
  const { listItem } = props
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  return (
    <>
      <div className="rm-padding table-wrap ">
        {listItem?.map((el, index) => {
          return (
            <>
              <Grid spacing={1} container className="table-content">
                <Grid
                  item
                  xs={1}
                  style={{
                    placeSelf: 'start',
                  }}
                >
                  <span className="xxTitle">{`${index + 1}.`}</span>
                </Grid>

                <Grid
                  item
                  xs={8}
                  style={{
                    placeSelf: 'start',
                    marginTop: '8px',
                  }}
                >
                  <div>
                    <div
                      className="xxTitle text-noflow"
                      style={{ lineBreak: 'anywhere' }}
                    >
                      {el?.MasterCOA?.Code} | {el?.MasterCOA?.Name}
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={3}
                  style={{
                    placeSelf: 'start',
                    marginTop: '8px',
                    marginRight: 'auto',
                    textAlign: 'right',
                    flexWrap: 'wrap',
                    display: 'flex',
                    justifyContent: isDesktop ? 'end' : 'end',
                  }}
                >
                  <div>
                    <div className="xxTitle">
                      {el.DocAmt < 0
                        ? `(${amtStr(el.DocAmt)})`
                        : amtStr(el.DocAmt)}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid spacing={1} container className="table-content">
                <Grid
                  item
                  xs={1}
                  style={{
                    placeSelf: 'start',
                  }}
                ></Grid>

                <Grid
                  item
                  xs={8}
                  style={{
                    placeSelf: 'start',
                    marginTop: '-18px',
                  }}
                >
                  <div>
                    <div className="desc text-noflow"></div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={3}
                  style={{
                    placeSelf: 'start',
                    marginTop: '-18px',
                    marginRight: 'auto',
                    textAlign: 'right',
                    flexWrap: 'wrap',
                    display: 'flex',
                    justifyContent: isDesktop ? 'end' : 'end',
                  }}
                >
                  <div>
                    <div className="desc text-noflow">{amtStr(el?.Amount)}</div>
                  </div>
                </Grid>
              </Grid>

              <Grid spacing={1} container className="table-content">
                <Grid
                  item
                  xs={1}
                  style={{
                    placeSelf: 'start',
                  }}
                ></Grid>

                <Grid
                  item
                  xs={4}
                  style={{
                    placeSelf: 'start',
                    marginTop: '-18px',
                  }}
                >
                  <div>
                    <div className="desc text-noflow">
                      {el?.CostCentre?.Code}
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{
                    placeSelf: 'start',
                    marginTop: '-18px',
                  }}
                >
                  <div>
                    <div className="desc text-noflow">
                      {`${el?.TaxScheme?.Code} ${el?.TaxRate}%`}
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={3}
                  style={{
                    placeSelf: 'start',
                    marginTop: '-18px',
                    marginRight: 'auto',
                    textAlign: 'right',
                    flexWrap: 'wrap',
                    display: 'flex',
                    justifyContent: isDesktop ? 'end' : 'end',
                  }}
                >
                  <div>
                    <div className="desc text-noflow">{amtStr(el?.TaxAmt)}</div>
                  </div>
                </Grid>
              </Grid>

              <Grid spacing={1} container className="table-content">
                <Grid
                  item
                  xs={1}
                  style={{
                    placeSelf: 'start',
                  }}
                ></Grid>

                <Grid
                  item
                  xs={11}
                  style={{
                    placeSelf: 'start',
                    marginTop: isDesktop
                      ? '-21px'
                      : el?.CostCentre?.Name.length > 20
                      ? '-16px'
                      : '-18px',
                  }}
                >
                  <div>
                    <div className="desc text-noflow">{el?.Remark}</div>
                  </div>
                </Grid>
              </Grid>

              <Divider
                variant="fullWidth"
                style={{ background: '#E4E4E4' }}
              ></Divider>
            </>
          )
        })}
      </div>
      <div
        className="footer-label"
        style={{
          background: '#bfd3ff',
        }}
      ></div>
    </>
  )
}
