import theme from '@ifca-root/react-component/src/assets/theme'
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import {
  amtNumStr,
  amtStr,
  amtStr4Dec,
} from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect } from 'react'
import '../../style/CycleCount.scss'

export const CycleCountDetailItemContent = (props: any) => {
  const { listItem } = props
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const sortList = arr => {
    if (arr?.length > 0) {
      const haveDifference = arr?.filter(x => x?.AdjustQty !== 0)
      const noDifference = arr?.filter(x => x?.AdjustQty === 0)

      return [...haveDifference, ...noDifference]
    } else {
      return []
    }
  }

  return (
    <>
      {/* <div className="rm-padding table-wrap ">
        <List className="core-list content-wrap full " disablePadding>
          {sortList(listItem)?.map((el, index) => (
            <ListItem
              //className="table-listItem "
              key={index}
              divider
              style={{ backgroundColor: 'white' }}
            >
              <ListItemText
                primary={
                  <>
                    <div className="content-wrap left">
                      <div className="desc">Item</div>
                      <div className="xsTitle">{el?.StockItem?.Name}</div>
                    </div>

                    <div className="content-wrap right">
                      <div className="desc">UOM</div>
                      <div className="xsTitle">{el?.UOM?.Name}</div>
                    </div>
                  </>
                }
                secondary={
                  <>
                    <Grid container>
                      <Grid item xs={4}>
                        <>
                          <div className="desc">On Hand</div>
                          <div className="xsTitle">
                            {amtStr4Dec(el?.CountQty)}
                          </div>
                        </>
                      </Grid>
                      <Grid item xs={4}>
                        <>
                          <div className="desc">Count</div>
                          <div className="xsTitle">
                            {amtStr4Dec(el?.OnHandQty)}
                          </div>
                        </>
                      </Grid>
                      <Grid item xs={4}>
                        <>
                          <div className="desc">Difference</div>
                          <div className="xsTitle">
                            {amtStr4Dec(el?.AdjustQty)}
                          </div>
                        </>
                      </Grid>
                    </Grid>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div
        className="footer-label"
        style={{
          background: '#bfd3ff',
        }}
      ></div> */}
      {
        <div className="rm-padding table-wrap">
          <List className="core-list content-wrap full " disablePadding>
            <ListItem className="table-header">
              <ListItemText
                primary={
                  <Grid container justify="flex-start">
                    <Grid item xs={1}>
                      <div className="xxTitle flex-space color-orange">No.</div>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="xxTitle flex-space color-orange">
                        Item
                      </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                      <div className="xxTitle color-orange">UOM</div>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                      <div className="xxTitle color-orange">On Hand</div>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                      <div className="xxTitle color-orange">Count</div>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                      <div className="xxTitle color-orange">Difference</div>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
            {sortList(listItem)?.map((v, i) => (
              <ListItem
                style={{ boxShadow: 'none', marginBottom: '0px' }}
                divider={true}
              >
                <Grid container justify="flex-start">
                  <Grid item xs={1}>
                    <div className="xxTitle">{i + 1}.</div>
                  </Grid>
                  <Grid item xs={11}>
                    <div className="xxTitle">{v?.StockItem?.Name}</div>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={2}>
                    <div className="desc">{v?.UOM?.Name}</div>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'right' }}>
                    <div className="desc">{amtStr4Dec(v.OnHandQty)}</div>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'right' }}>
                    <div className="desc">{amtStr4Dec(v.CountQty)}</div>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'right' }}>
                    <div className="desc">{amtStr4Dec(v.AdjustQty)}</div>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </div>
      }
    </>
  )
}
