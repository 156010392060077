import IconButton from '@material-ui/core/IconButton'

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import React from 'react'
import './AccountingPeriod.scss'

interface subHeaderProps {
  title?: any
  rightText?: any
  infoLine?: any
  dropDownOptions?: any
  handleClick?: any
  // fixResponsive?: boolean //new changes 5/6/2021 by syafiq ali
  isPicture?: any //new changes 5/6/2021 by syafiq ali
}
const DynamicSubHeaderPeriod = ({
  infoLine,
  rightText,
  title,
  dropDownOptions,
  handleClick,
  // fixResponsive, //new changes 5/6/2021 by syafiq ali
  isPicture,
}: subHeaderProps) => {
  return (
    //new changes 5/6/2021 by syafiq ali
    <div className={`fix-subheader`}>
      {/* //new changes 5/6/2021 by syafiq ali */}
      <div
        className={`dynamic-subheader fix-subheader-content 
         ${infoLine ? 'multiple' : 'single'} `}
      >
        {isPicture} {/* //new changes 5/6/2021 by syafiq ali */}
        <div className={`${isPicture && 'wraps-dynamic-content'}`}>
          <div className="dynamic-content" onClick={handleClick}>
            <div className="session">
              <div className="align-self flex-space c-orange">{title}</div>
              {rightText && <div className="rightText">{rightText}</div>}
              {dropDownOptions ? (
                <IconButton className="right-btn">
                  <ArrowDropDownOutlinedIcon />
                </IconButton>
              ) : null}
            </div>
          </div>
          {/* <div className="infoline-content">
            <div className="infoline">{infoLine}</div>
          </div> */}
        </div>
        {dropDownOptions}
      </div>
    </div>
  )
}

export default DynamicSubHeaderPeriod
