import Button from '@material-ui/core/Button'
import React from 'react'
import { BaseProps } from 'utils/models'

interface SearchHeaderProps {
  title: string
  action?: any
  actionTitle?: String
  noButton?: boolean
  rightTitle?: String
  style?: any
}

export const SubHeaderAction = (props: BaseProps<SearchHeaderProps>) => {
  const {
    title,
    action,
    actionTitle,
    noButton,
    rightTitle,
    style,
    ...other
  } = props

  return (
    <div className="action-header" style={style}>
      <span className="xsTitle c-darkblue flex-space">{title}</span>
      {!rightTitle ? null : <span className="xsTitle">{rightTitle}</span>}
      {noButton ? null : (
        <Button
          style={{
            width: '0px',
            height: '20px',
            paddingRight: 'initial',
          }}
          variant="contained"
          color="primary"
          onClick={action}
          form="submit-form"
          className="action-btn"
          type="submit"
          {...other}
        >
          {actionTitle ? actionTitle : 'Save'}
        </Button>
      )}
    </div>
  )
}
export default SubHeaderAction
