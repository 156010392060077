import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { AuditAdjustmentDetail } from './AuditAdjustmentDetail/AuditAdjustmentDetail'
import { AuditAdjustmentForm } from './AuditAdjustmentForm/AuditAdjustmentForm'
import { AuditAdjustmentItemForm } from './AuditAdjustmentForm/AuditAdjustmentItemForm'
import { AuditAdjustmentMain } from './AuditAdjustmentMain'
import { PDFPreviewGLV2 } from 'containers/GeneralLedgerModule/Hooks/PDFPreviewGLV2'

const auditAdjustmentRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED DILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/audit-adjustment/filter',
    },
    component: (
      <AdvancedFilter
        mode="audit-adjustment"
        app="general-ledger"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/audit-adjustment',
    },
    component: <AuditAdjustmentMain />,
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:AuditAdjustmentID/detail',
    },
    component: <AuditAdjustmentDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:AuditAdjustmentID/detail/workdesk',
    },
    component: <AuditAdjustmentDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:AuditAdjustmentID/detail/workdesk-history',
    },
    component: <AuditAdjustmentDetail mode="workdesk-history" />,
  },

  /* -------------------------------------------- */
  /*                  PREVIEW PDF                 */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:AuditAdjustmentID/preview',
    },
    component: (
      <PDFPreviewGLV2 accountType="general-ledger" docType="audit-adjustment" />
    ),
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/audit-adjustment/add',
    },
    component: <AuditAdjustmentForm formMode="add" type="AUDIT_ADJUSTMENT" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:AuditAdjustmentID/edit',
    },
    component: <AuditAdjustmentForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:AuditAdjustmentID/approve-reject',
    },
    component: <AuditAdjustmentForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:AuditAdjustmentID/resubmit',
    },
    component: <AuditAdjustmentForm formMode="resubmit" />,
  },

  /* -------------------------------------------- */
  /*                     ITEM                     */
  /* -------------------------------------------- */
  //payment = add , paymentitem = add
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:formMode/audit-adjustment-item/:detailMode',
    },
    component: <AuditAdjustmentItemForm />,
  },
  //payment = add , paymentitem = edit & add
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:AuditAdjustmentID/:formMode/audit-adjustment-item/:detailMode',
    },
    component: <AuditAdjustmentItemForm />,
  },
  //payment = add, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:formMode/audit-adjustment-item/:AuditAdjustmentItemID/:detailMode',
    },
    component: <AuditAdjustmentItemForm />,
  },
  //payment = edit, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/audit-adjustment/:AuditAdjustmentID/:formMode/audit-adjustment-item/:AuditAdjustmentItemID/:detailMode',
    },
    component: <AuditAdjustmentItemForm />,
  },
]

export default auditAdjustmentRoutes

// const auditadjustmentEntryRoutes = [
//   {
//     props: {
//       exact: true,
//       path: '/general-ledger/:CompanyID/audit-adjustment-entry',
//     },
//     component: <AuditAdjustmentEntriesListing />,
//   },

//   {
//     props: {
//       exact: true,
//       path:
//         '/general-ledger/:CompanyID/audit-adjustment-entry/:AuditAdjustmentID',
//     },
//     component: <AuditAdjustmentDetail />,
//   },
// ]

// export default auditadjustmentEntryRoutes
