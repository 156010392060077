import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import { amtStr4Dec } from '../../../helpers/StringNumberFunction/numFormatter';
import React from 'react';

export const customDateFormatter = date => {
  const toSplit = date?.split('-');
  const toReverse = toSplit?.reverse();
  const toJoin = toReverse?.join('/');
  return toJoin;
};
export const Table2RFQ = (props: any) => {
  const { data } = props;

  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) fontSize = 6;
  else fontSize = 9;

  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as const,
    className: 'click-text',
    backgroundColor: '#e7e6e6',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '8px',
  };

  const thname = {
    width: '20%',
    borderBottom: '0.5px  solid',
    textAlign: 'center' as const,
    className: 'click-text',
    backgroundColor: '#e7e6e6',
    paddingRight: '8px',
  };

  return (
    <div style={{ marginTop: '5px' }}>
      <table
        style={{
          width: '100%',
          fontSize,
          borderSpacing: '0 1em',
        }}
      >
        <tr>
          <th style={thleft}>Items</th>
          {data?.RFQSupplierInvitation?.map(x => (
            <th style={thname}>
              <span
                style={{ textDecoration: 'underline', paddingRight: '10px' }}
              >
                {x?.Supplier?.CompanyName}
              </span>
            </th>
          ))}
        </tr>

        {data?.RFQItem?.map((v, i) => (
          <tr>
            <td
              style={{
                textAlign: 'left',
                verticalAlign: 'top',
                padding: '0px 8px 0px 4px',
              }}
            >
              {v?.PRItem?.Item?.Name}
              <br />
              {v?.PRItem?.Remark ?? 'Not Applicable'}
              <br />
              Last Purchase
              <br />
              Date :{' '}
              {customDateFormatter(
                v?.PRItem?.Item?.LatestPurchase?.PurchaseDate,
              )}
              <br />
              Unit Price : {v?.PRItem?.Item?.LatestPurchase?.UnitPrice}
              <br />
              Supplier :
            </td>
            {data?.RFQSupplierInvitation?.map(x =>
              x?.LatestSubmittedQuotation?.RFQSubmissionItem?.filter(
                x => x?.RFQItemID === v?.RFQItemID,
              )?.map((el, index) => (
                <td
                  key={index}
                  style={{
                    textAlign: 'left',
                    verticalAlign: 'top',
                    paddingRight: '8px',
                    fontWeight: el?.Cheapest === true ? 'bold' : 'normal',
                  }}
                >
                  {amtStr4Dec(el?.UnitPrice)}/{v?.PRItem?.UOM?.Code}
                  <br />
                  {x?.LatestSubmittedQuotation?.Remarks ??
                    'No Quotation Remarks'}
                  <br />
                  Total : {el?.TotalPrice}
                  <br />
                  <br />
                  Quotation No. : {x?.LatestSubmittedQuotation?.RefNo}
                  <br />
                  {`Credit Term : ${x?.LatestSubmittedQuotation?.CreditTerm} days`}
                  <br />
                  Remark : {x?.LatestSubmittedQuotation?.Remarks}
                </td>
              )),
            )}
          </tr>
        ))}
      </table>
    </div>
  );
};

export default Table2RFQ;
