//import { BankAccountListing } from 'containers/CompanyModule/BankAccount/BankAccountListing'
import React from 'react'
import { ARGLExportForm } from './ARGLExportForm'
import { ARGLExportList } from './ARGLExportList'

const arGLExportRoutes = [
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/gl-export',
    },
    component: <ARGLExportList />,
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/gl-export/add',
    },
    component: <ARGLExportForm />,
  },
]

export default arGLExportRoutes
