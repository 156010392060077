import React, { lazy } from 'react'

const AutoNumberingListing = lazy(() =>
  import('./AutoNumberingListing').then(module => ({
    default: module.AutoNumberingListing,
  }))
)

const AutoNumberingForm = lazy(() =>
  import('./AutoNumberingForm').then(module => ({
    default: module.AutoNumberingForm,
  }))
)

const autoNumberingRoutes = [
  {
    props: {
      exact: true,
      path: '/general-setting/auto-numbering',
    },
    component: <AutoNumberingListing />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/auto-numbering/add',
    },
    component: <AutoNumberingForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/auto-numbering/edit',
    },
    component: <AutoNumberingForm mode="edit" />,
  },
]

export default autoNumberingRoutes
