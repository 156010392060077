import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { ARRefundDetail } from './ARRefundDetail/ARRefundDetail'
import { ARRefundForm } from './ARRefundForm/ARRefundForm'
import { ARMain } from '../ARMain'
import { ARDetail } from '../ARDetail/ARDetail'
import { PDFPreviewV2 } from '../Hooks/PDFPreviewV2'

const refundRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/refund/filter',
    },
    component: (
      <AdvancedFilter
        mode="refund"
        app="account-receivable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/refund',
    },
    component: (
      <ARMain
        arSubmenu="refund"
        routeSegments="Refund"
        accountType="account-receivable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/refund/:DocumentID/detail',
    },
    component: (
      <ARDetail
        arSubmenu="refund"
        routeSegments="Refund"
        accountType="account-receivable"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/refund/:DocumentID/detail`,
    },
    component: (
      <ARDetail
        arSubmenu="refund"
        routeSegments="Refund"
        accountType="account-receivable"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/refund/:RefundID/preview`,
    },
    component: (
      <PDFPreviewV2 accountType="account-receivable" docType="refund" />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/refund/:RefundID/preview`,
    },
    component: (
      <PDFPreviewV2 accountType="account-receivable" docType="refund" />
    ),
  },

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/refund/:RefundID/detail/workdesk',
    },
    component: <ARRefundDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/refund/:RefundID/detail/workdesk-history',
    },
    component: <ARRefundDetail mode="workdesk-history" />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/refund/add',
    },
    component: <ARRefundForm formMode="add" type="REFUND_AR" />,
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/refund/:RefundID/edit',
    },
    component: <ARRefundForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/refund/:RefundID/approve-reject',
    },
    component: <ARRefundForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/refund/:RefundID/resubmit',
    },
    component: <ARRefundForm formMode="resubmit" />,
  },
]

export default refundRoutes
