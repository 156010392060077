import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { List, ListItem, ListItemText, useMediaQuery } from '@material-ui/core'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import Empty from '@ifca-root/react-component/src/assets/hrIcons/no-records.svg'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import AddIcon from '@material-ui/icons/Add'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { uuid } from 'uuidv4'
import { ApprovalFormComponent } from './ApprovalFormComponent/ApprovalFormComponent'
// Get Pending AP Payment Query goes here
import {
  GetApprovalPolicyDocument,
  //useCreateUpdateDeleteApprovalPolicyPoMutation,
  useDeleteApprovalPolicyMutation,
  useGetApprovalPolicyLazyQuery,
  useGetRoleQuery,
} from 'generated/graphql'

// ==///////////////////////////////////////////==
// ======================================================= !!!!!!!!!!!
// ============== !! TO INCLUDE / REFINE ================= !!!!!!!!!!!
// ======================================================= !!!!!!!!!!!
// -- getPendingAPPaymentQuery
// -- GetApprovalPolicyDocument
// -- useCreateUpdateDeleteApprovalPolicyApMutation
// -- useDeleteApprovalPolicyMutation
// -- useGetApprovalPolicyLazyQuery
// -- useGetRoleQuery
// ==///////////////////////////////////////////==

export const ApprovalPolicyAPPaymentForm = () => {
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location: any = useLocation()
  let history = useHistory()
  const { CompanyID }: any = useParams()

  let refName = 'AP_Payment'

  const [getApprovalList, setApprovalList] = useState(null)
  const [removePolicy, setRemovePolicy] = useState([])
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const formMode = !!!getApprovalList ? 'new' : 'edit'
  //------------USEFORM------------//

  const formValidation = yup.object().shape({
    approvalPolicy: yup.array().of(
      yup.object().shape({
        upperLimit: yup.number().required('Upper Limit is required'),
        approvalList: yup.array().of(
          yup.object().shape({
            roleID: CommonYupValidation?.requireField(
              `Role is required`
            ).nullable(),
          })
        ),
      })
    ),
  })

  const { register, handleSubmit, errors, control, setValue, watch } = useForm({
    resolver: yupResolver(formValidation),
  })

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: `approvalPolicy`,
  })

  //   const {
  //     loading: getPendingGPDocumentLoading,
  //     error: getPendingGPDocumentDocumentErrors,
  //     data: { getPendingGP } = { getPendingGP: [] },
  //   } = useGetPendingGpQuery({
  //     fetchPolicy: 'network-only',
  //     variables: {
  //       RefTable: 'GP_POHeader',
  //     },
  //   });

  // const checkPendingDocument = pendingDoc => {
  //   if (pendingDoc?.document?.length > 0) {
  //     setErrorDia(true)
  //     setErrMsg(
  //       `All Unapproved AP must be approved before changing Approval Policy.`
  //     )
  //     return true
  //   } else false
  // }

  const onSubmit = (data, removePolicy) => {
    let submittedData = data?.approvalPolicy
    let inputData, deleteData

    if (Object.keys(data).length > 0) {
      inputData = submittedData?.map((v, i) => {
        return {
          ApprovalPolicyID: v?.approvalPolicyID,
          upperLimit: v?.upperLimit,
          lowerLimit: v?.lowerLimit ?? 0,
          Category: refName,
          ApprovalList: v?.approvalList?.map((x, index) => {
            return {
              Category: refName,
              StepNo: index + 1,
              RoleID: x?.roleID,
              RoleName: getRole?.filter(role => role?.ID === x?.roleID)[0]
                ?.name,
            }
          }),
        }
      })
    } else {
      inputData = getApprovalList?.map((v, i) => {
        return {
          ApprovalPolicyID: v?.approvalPolicyID,
          upperLimit: 0,
          lowerLimit: 0,
          Category: refName,
          ApprovalList: [],
        }
      })
    }

    deleteData =
      removePolicy?.length > 0
        ? removePolicy?.map(v => {
            return { ApprovalPolicyID: v }
          })
        : null

    // createUpdateDelete({
    //   variables: {
    //     input: inputData,
    //     deleteInput: deleteData,
    //   },
    // })
  }

  //---------------QUERIES AND MUTATION----------------//
  const {
    loading: getRoleLoading,
    error: getRoleError,
    data: { getRole } = { getRole: [] },
  } = useGetRoleQuery({
    fetchPolicy: 'network-only',
    variables: {
      ID: CompanyID,
    },
  })

  // Should cater for AP Payment Approval Policy
  const [
    loadApprovalPolicy,
    {
      loading: loadingApprovalPolicy,
      data: { getApprovalPolicy } = {
        getApprovalPolicy: [],
      },
    },
  ] = useGetApprovalPolicyLazyQuery({
    fetchPolicy: 'network-only',
    onError: err => {},
    onCompleted: ({ getApprovalPolicy }) => {
      let approvalArray = []
      if (getApprovalPolicy?.length > 0) {
        getApprovalPolicy
          ?.sort((a, b) => a?.lowerLimit - b?.lowerLimit)
          ?.map((v, i) => {
            approvalArray.push({
              approvalPolicyID: v?.ApprovalPolicyID,
              lowerLimit: v?.lowerLimit,
              upperLimit: v?.upperLimit,
              category: refName,
              approvalList: v?.ApprovalList?.map((b, y) => {
                return {
                  stepNo: b?.StepNo,
                  roleID: b?.RoleID,
                  roleName: b?.RoleName,
                }
              }),
            })

            return approvalArray
          })
      }
      setApprovalList(approvalArray)
      append(approvalArray)
      approvalArray.map((v, i) => {
        register(`approvalPolicy.${i}.approvalPolicyID`)
        register(`approvalPolicy.${i}.lowerLimit`)
        register(`approvalPolicy.${i}.upperLimit`)

        setValue(`approvalPolicy.${i}.approvalPolicyID`, v?.approvalPolicyID)
        setValue(`approvalPolicy.${i}.lowerLimit`, v?.lowerLimit)
        setValue(`approvalPolicy.${i}.upperLimit`, v?.upperLimit)
      })
    },
  })

  //========== createUpdateDeleteApprovalPolicyAPPaymentMutation =================
  // const [
  //   createUpdateDelete,
  //   { loading: loadingCreateUpdateDeleteApproval },
  // ] = useCreateUpdateDeleteApprovalPolicyPoMutation({
  //   onError: err => {
  //     setSnackBarMsg(SystemMsgs.updateRecordFail())
  //     setOpenSnackBar(true)
  //   },
  //   onCompleted: () => {
  //     setSnackBarMsg(SystemMsgs.updateRecord())
  //     setOpenSnackBar(true)
  //     history.push(`/general-purchase/general-setting/approval-policy/`)
  //   },
  // })

  //======= To cater delete mutation for Approval Policy AP Payment===========
  const [
    deleteApprovalPolicy,
    { loading: loadingDeleteApprovalPolicy },
  ] = useDeleteApprovalPolicyMutation({
    onError: err => {
      let errorMessage = err.message.substring(15)
      if (errorMessage) {
        setOpenSnackBar(true)
        setSnackBarMsg(errorMessage)
      }
    },
    onCompleted: data => {
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOpenSnackBar(true)
    },
  })

  const saveFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => onSubmit(data, removePolicy))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    //disabled: !!(getPendingGP?.document?.length > 0),
  }

  let footerOptions = [saveFooterOption]

  useEffect(() => {
    loadApprovalPolicy({
      variables: {
        Category: refName,
      },
    })
  }, [])

  return (
    <>
      {loadingApprovalPolicy && <Loading />}
      {getRoleLoading && <Loading />}
      {/* {loadingCreateUpdateDeleteApproval && <Loading />} */}
      {/* {getPendingGPDocumentLoading && <Loading />} */}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push({
            pathname: !!CompanyID
              ? `/account-payable/${CompanyID}/company-setting/approval-policy`
              : `/account-payable/general-setting/approval-policy`,
          })
        }}
        smTitle="General Settings"
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Approval Policy Flow', current: true },
        ]}
        rightRouteSegments={[{ name: 'New', current: true }]}
      />

      <DynamicSubHeader title={'Approval Policy'} infoLine="Payment" />

      {fields.length == undefined ||
      fields.length == 0 ||
      fields.length == 0 ? (
        <ContentWrapper footer>
          <CardContents
            style={{ marginTop: isDesktop ? '65px' : '65px' }}
            section={{
              header: {
                title: 'Approval List',
                onClickAction: () => {
                  //checkPendingDocument(getPendingGP);
                  //   if (getPendingGP?.document?.length === 0) {
                  //     append({
                  //       stepNo: fields?.length + 1,
                  //       role: '',
                  //       roleName: '',
                  //     });
                  //   }
                },
                icon: (
                  <AddIcon
                    htmlColor="white"
                    fontSize="default"
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      margin: '10px 5px 0px 0px',
                      background: theme.palette.primary.main,
                      boxShadow: `0px 2px 5px 0px ${theme.palette.primary.main}`,
                      borderRadius: '3px',
                      color: 'rgba(224,234,254,100)',
                    }}
                  />
                ),
              },
            }}
          >
            <List className="avatar-rounding">
              <ListItem>
                <IconItem
                  image={`${Empty}`}
                  imageType="svg"
                  badgeType="default"
                />

                <ListItemText
                  primary={
                    <>
                      <span className="desc  ">
                        <span className="xsTitle">No Records</span>
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      <span className="desc subheader-set wrap">
                        <span>{`Click add button to create a format.`} </span>
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
          </CardContents>
        </ContentWrapper>
      ) : (
        <ContentWrapper multiDynamicInfo float>
          <ApprovalFormComponent
            fields={fields}
            control={control}
            errors={errors}
            watch={watch}
            remove={remove}
            register={register}
            setValue={setValue}
            GetApprovalPolicyDocument={GetApprovalPolicyDocument}
            refName={refName}
            isDesktop={isDesktop}
            getApprovalList={getApprovalList}
            getRole={getRole}
            setRemovePolicy={setRemovePolicy}
            deleteApprovalPolicy={deleteApprovalPolicy}
            //checkPendingDocument={checkPendingDocument}
            //getPending={getPending}
          />
        </ContentWrapper>
      )}

      <FloatButton
        onClick={() => {
          //   checkPendingDocument(getPendingGP)
          //   if (getPendingGP?.document?.length === 0) {
          //     let newLowerLimit: any = watch(
          //       `approvalPolicy.${fields.length - 1}.upperLimit`
          //     )
          //       ? watch(`approvalPolicy.${fields.length - 1}.upperLimit`) + 0.01
          //       : 0
          //     let newUuid: any = uuid()
          //     append({
          //       approvalPolicyID: newUuid,
          //       lowerLimit: newLowerLimit,
          //       upperLimit: 0,
          //       category: refName,
          //       approvalList: [],
          //     })
          //     register(`approvalPolicy.${fields.length}.approvalPolicyID`)
          //     register(`approvalPolicy.${fields.length}.lowerLimit`)
          //     setValue(
          //       `approvalPolicy.${fields.length}.approvalPolicyID`,
          //       newUuid
          //     )
          //     setValue(
          //       `approvalPolicy.${fields.length}.lowerLimit`,
          //       newLowerLimit ?? 0
          //     )
          //   }
          let newLowerLimit: any = watch(
            `approvalPolicy.${fields.length - 1}.upperLimit`
          )
            ? watch(`approvalPolicy.${fields.length - 1}.upperLimit`) + 0.01
            : 0
          let newUuid: any = uuid()
          append({
            approvalPolicyID: newUuid,
            lowerLimit: newLowerLimit,
            upperLimit: 0,
            category: refName,
            approvalList: [],
          })
          register(`approvalPolicy.${fields.length}.approvalPolicyID`)
          register(`approvalPolicy.${fields.length}.lowerLimit`)
          setValue(`approvalPolicy.${fields.length}.approvalPolicyID`, newUuid)
          setValue(
            `approvalPolicy.${fields.length}.lowerLimit`,
            newLowerLimit ?? 0
          )
        }}
        saveFooter
      />

      {footerOptions?.length > 0 ? (
        <AccountFooter options={[...footerOptions]} />
      ) : null}

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
