import { ApolloProvider } from '@apollo/react-hooks'
import DateFnsUtils from '@date-io/date-fns'
import '@ifca-root/react-component/src/assets/styles/app.scss'
import theme from '@ifca-root/react-component/src/assets/theme'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers/'
import { setAccessToken } from 'AccessToken'
import { AccountClient, accountNodeRefreshUrl } from 'AccountClient'
import 'assets/styles/accountx.scss'
import { InstallBanner } from 'components/InstallBanner/InstallBanner'
import Layout from 'components/Layout/Layout'
import { useLogoutMutation } from 'generated/graphql'
import { useBroadcastChannel } from 'helpers/Hooks/useBroadcastChannel'
import { useInstallPrompt } from 'helpers/Hooks/useInstallPrompt'
import { useServiceWorker } from 'helpers/Hooks/useServiceWorker'
import { createBrowserHistory } from 'history'
import localForage from 'localforage'
import React, { Suspense, useEffect, useReducer, useState } from 'react'
import { Router } from 'react-router-dom'
import Routes from './Router/Routes'
import AppContext from './Store/AppContext'
import { GlobalInitialState, RootReducer } from './Store/RootReducer'
import SnackBarContext from './Store/SnackBarContext'

export const history = createBrowserHistory()

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState)
  const [loading, setLoading] = useState(true)
  const { userChannel } = useBroadcastChannel()
  const [logout, { client }] = useLogoutMutation({ client: AccountClient })
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const key = sessionStorage.getItem('tokenKey')
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false)
  const [snackBarMsg, setSnackBarMsg] = useState<string>('')
  const { isUpdateAvailable, updateAssets } = useServiceWorker()

  const routing: any = history?.location?.pathname

  const pathBeforeLogin = [
    '/authentication/404',
    '/reset-password/:token',
    '/user/activate/:token',
    '/user/create-password/:token',
    '/login',
    '/forgot-password',
  ]

  const isNotMainContent = () => {
    return pathBeforeLogin.filter(v => routing.includes(v)).length > 0
  }

  const userLogout = async (UserID?: string, entityID?: string) => {
    await logout({ variables: { ID: UserID, CompanyID: entityID } })
    setAccessToken('')
    await client!.resetStore()
  }

  const fetchRefresh = async () => {
    const data = await fetch(accountNodeRefreshUrl, {
      method: 'POST',
      credentials: 'include',
    })
    const { accessToken, user: userContext } = await data.json()
    sessionStorage.setItem('accessToken', accessToken)
    setAccessToken(accessToken)
    setLoading(false)

    if (!user && accessToken) {
      localStorage.setItem('latestCompany', userContext?.lastestAccessEntity)
      history.push('/')
    } else if (user == null && !accessToken && !isNotMainContent()) {
      history.push('/login')
    }
  }

  useEffect(() => {
    fetchRefresh()
    userChannel.onmessage = (data: any) => {
      if (data?.payload?.type === 'SIGN_OUT' && data?.userId === user?.ID) {
        userLogout(data?.userId, data?.entityID)
        localStorage.removeItem('loggedInUser')
        localForage.removeItem('permission')
        sessionStorage.removeItem('tokenKey')
        history.push('/login')
      }
    }
  }, [])

  // useEffect(() => {
  //   if (!!!key && !!!user && isNotMainContent()) {
  //     history.push('/login')
  //   }
  // }, [key, user])

  const { promptable, promptToInstall, isInstalled } = useInstallPrompt()
  const [isVisible, setVisible] = useState(false)

  const hide = () => setVisible(false)

  // if (loading) {
  //   return <Loading />
  // }

  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <SnackBarContext.Provider value={{ setOpenSnackBar, setSnackBarMsg }}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={AccountClient}>
            {/* ADD MORE THAN 1 CLIENTS IF NEEDED */}
            {/* <ApolloProvider client={client}> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={history}>
                <Layout>
                  {/* LOCATION TO PUT BANNER FOR FUTURE REFERENCE */}
                  <Suspense fallback={<Loading />}>
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                    />
                    <Routes />
                    <Backdrop open={isUpdateAvailable}>
                      <Dialog
                        open={isUpdateAvailable}
                        // onClose={() => setReloadDia(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {'New Update Available'}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Please update AccountX to the latest version to
                            continue.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={updateAssets} autoFocus>
                            Update
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Backdrop>
                    {/* <ServiceWorkerWrapper /> */}
                    {/* <Snackbar
                      open={isUpdateAvailable}
                      message="A new version is available!"
                      onClick={updateAssets}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      action={
                        <Button
                          color="inherit"
                          size="small"
                          onClick={updateAssets}
                        >
                          Reload
                        </Button>
                      }
                    /> */}
                    <SnackBarMsg
                      open={openSnackBar}
                      setOpen={setOpenSnackBar}
                      message={snackBarMsg}
                    />
                  </Suspense>
                </Layout>

                {promptable && !isInstalled ? (
                  <InstallBanner
                    ButtonOption={{
                      section: {
                        props: {
                          style: { display: !isVisible ? 'none' : null },
                        },
                      },
                      option1: {
                        props: {
                          onClick: () => hide(),
                        },
                      },
                      option2: {
                        props: {
                          onClick: () => promptToInstall(),
                        },
                      },
                    }}
                  />
                ) : null}
              </Router>
            </MuiPickersUtilsProvider>
          </ApolloProvider>
          {/* </ApolloProvider> */}
        </ThemeProvider>
      </SnackBarContext.Provider>
    </AppContext.Provider>
  )
}
export default App
