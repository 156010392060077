import React, { lazy } from 'react'

const PurchaseItemVariancePolicyForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/PurchaseItemVariancePolicy/PurchaseItemVariancePolicyForm'
  ).then(module => ({
    default: module.PurchaseItemVariancePolicyForm,
  }))
)

const purchaseVarianceRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/purchase-variance-policy',
    },
    component: <PurchaseItemVariancePolicyForm />,
  },
]

export default purchaseVarianceRoutes
