import React from 'react'
import { DataSourceSettingsForm } from './DataSourceSettingsForm'
import { DataSourceSettingsListing } from './DataSourceSettingsListing'

const dataSourceSettingsRoutes = [
  //GENERAL LEDGER
  {
    props: {
      exact: true,
      path: '/general-ledger/financial-reporting/data-source-settings',
    },
    component: <DataSourceSettingsListing module="general-ledger" />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/financial-reporting/data-source-settings/add',
    },
    component: <DataSourceSettingsForm module="general-ledger" mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/financial-reporting/data-source-settings/:dataSourceSettingsID/edit',
    },
    component: <DataSourceSettingsForm module="general-ledger" mode="edit" />,
  },
]

export default dataSourceSettingsRoutes
