import theme from '@ifca-root/react-component/src/assets/theme'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs'
import {
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { LockRounded } from '@material-ui/icons'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetAssignedEntityCoaDocument,
  RecordStatus,
  useAssignEntityCoaMutation,
  useDeleteAssignedEntityCoaMutation,
  useGetAssignedEntityCoaQuery,
  useGetCompanyQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'

interface EntityCOAProps {
  ParentMasterCOAID: String
  Code: string
  Name: string
  AccountType: string
  IsControl: boolean
  IsLastNode: boolean
}

export const AssignEntityCOAForm = (props: any) => {
  let history = useHistory()

  const { MasterCOAID, accType }: any = useParams()
  const [entityAssign, setEntityAssign] = useState<Set<any>>(new Set([]))
  const [entityUnassign, setEntityUnassign] = useState<Set<any>>(new Set([]))
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData: any = location?.state
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [tabVal, setTabVal] = useState(0)
  const [assigned, setAssigned] = useState([])
  const [available, setAvailable] = useState([])
  const [errorDia, setErrorDia] = useState(false)

  const handleChange = (event, newValue) => {
    setTabVal(newValue)
  }
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<EntityCOAProps>({
    defaultValues: {},
    mode: 'all',
  })

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name', RecordStatus: RecordStatus.Active },
  })

  const {
    loading: entityCoaLoading,
    error: entityCoaLoadingCoaError,
    data: { getEntityCOA } = { getEntityCOA: [] },
  } = useGetAssignedEntityCoaQuery({
    fetchPolicy: 'network-only',
    variables: { MasterCOAID },
    onCompleted: data => {},
  })

  const [
    assignEntityCOA,
    {
      loading: assignEntityCOALoading,
      error: createError,
      data: assignedEntityCOAData,
    },
  ] = useAssignEntityCoaMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      // setTimeout(() => {
      //   history.push({
      //     pathname: `/general-ledger/general-setting/master-coa/${accType}`,
      //     state: { success: true, msgMode: 'update' },
      //   })
      // }, 500)
      setEntityAssign(new Set([]))
    },
  })

  const [
    deleteAssignedEntityCOA,
    {
      loading: deleteAssignedEntityCOALoading,
      error: deleteAssignedEntityCOAError,
      data: deleteAssignedEntityCOAData,
    },
  ] = useDeleteAssignedEntityCoaMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      // setTimeout(() => {
      //   history.push({
      //     pathname: `/general-ledger/general-setting/master-coa/${accType}`,
      //     state: { success: true, msgMode: 'update' },
      //   })
      // }, 500)
      setEntityUnassign(new Set([]))
    },
  })

  useEffect(() => {
    if (getCompany && getCompany?.length > 0) {
      const assignedEnity = getEntityCOA?.map(v => {
        return v.CompanyID
      })

      const available = getCompany?.filter(
        v => !assignedEnity.includes(v?.CompanyID)
      )

      const assigned = getCompany
        ?.filter(v => assignedEnity.includes(v?.CompanyID))
        ?.map(el => {
          return {
            ...el,
            IsUsed:
              getEntityCOA?.find(x => x?.CompanyID === el?.CompanyID)?.IsUsed ??
              false,
          }
        })

      setAvailable(available)
      setAssigned(assigned)

      if (tabVal === 1) {
        const notuse = assigned?.filter(x => x?.IsUsed === false)
        const used = assigned?.filter(x => x?.IsUsed === true)
        setOriginalListing([...notuse, ...used])
      } else {
        setOriginalListing(available)
      }
    }
  }, [getCompany, getEntityCOA, tabVal])

  // handle checkbox for all employee
  const handleSelectAllAssign = e => {
    let entityTemp = entityAssign || new Set()
    if (e.target.checked) {
      filteredList.map(v => {
        entityTemp.add(v)
      })
    } else {
      entityTemp.clear()
    }
    setEntityAssign(new Set(entityTemp))
  }

  // handle checkbox for specific entityCoa
  const handleCheckBoxChangeAssign = (e: any, index: number) => {
    let entityTemp = entityAssign || new Set()
    if (!e.target.checked) {
      filteredList.find(v => {
        if (v.CompanyID === e.target.value) {
          entityTemp.delete(v)
        }
      })
    } else {
      filteredList.find(v => {
        if (v.CompanyID === e.target.value) {
          entityTemp.add(v)
        }
      })
    }
    setEntityAssign(new Set(entityTemp))
  }

  // handle checkbox for all employee
  const handleSelectAllUnassign = e => {
    let entityTemp = entityUnassign || new Set()
    if (e.target.checked) {
      filteredList
        ?.filter(x => x?.IsUsed === false)
        .map(v => {
          entityTemp.add(v)
        })
    } else {
      entityTemp.clear()
    }
    setEntityUnassign(new Set(entityTemp))
  }

  // handle checkbox for specific entityCoa
  const handleCheckBoxChangeUnassign = (e: any, index: number) => {
    let entityTemp = entityUnassign || new Set()
    if (!e.target.checked) {
      filteredList.find(v => {
        if (v.CompanyID === e.target.value) {
          entityTemp.delete(v)
        }
      })
    } else {
      filteredList.find(v => {
        if (v.CompanyID === e.target.value) {
          entityTemp.add(v)
        }
      })
    }
    setEntityUnassign(new Set(entityTemp))
  }

  let listCheckedEntityAssign = Array.from(entityAssign || [])
  const companyArrayAssign = listCheckedEntityAssign?.map(v => {
    return v.CompanyID
  })

  let listCheckedEntityUnassign = Array.from(entityUnassign || [])
  const companyArrayUnassign = listCheckedEntityUnassign?.map(v => {
    return v.CompanyID
  })

  const onSubmit = (ids, status) => {
    if (status === 'assign')
      assignEntityCOA({
        variables: {
          MasterCOAID: MasterCOAID,
          CompanyArr: ids,
        },
        refetchQueries: [
          {
            query: GetAssignedEntityCoaDocument,
            variables: { MasterCOAID },
          },
        ],
      })
    else {
      deleteAssignedEntityCOA({
        variables: {
          MasterCOAID: MasterCOAID,
          CompanyArr: ids,
        },
        refetchQueries: [
          {
            query: GetAssignedEntityCoaDocument,
            variables: { MasterCOAID },
          },
        ],
      })
    }
  }

  return (
    <>
      {assignEntityCOALoading && <Loading />}
      {entityCoaLoading && <Loading />}
      {assignEntityCOALoading && <Loading />}
      {deleteAssignedEntityCOALoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(`/general-ledger/general-setting/master-coa/${accType}`)
        }
        smTitle={'General Ledger'}
        title={user?.accountName}
        routeSegments={[
          { name: 'General Setting' },
          { name: 'General Setting' },
          { name: 'Master COA', current: true },
        ]}
        rightRouteSegments={[{ name: 'Assign', current: true }]}
      />
      <DynamicSubHeader
        title={
          <>
            <span>
              {editData?.code} | {editData?.name}
            </span>
          </>
        }

        //style={{paddingBottom: '100px'}}
      />

      <StandardTabs
        value={tabVal}
        onChange={handleChange}
        responsive
        fullWidth
        subHeader
        style={{
          boxShadow: 'none',
          ...(isDesktop
            ? {
                marginTop: '99px',
              }
            : {
                marginTop: '39px',
              }),
        }}
        tabs={[
          {
            label: `Available(${available?.length})`,
            content: (
              <>
                <SearchHeader
                  title={`Entity Listing`}
                  value={`${filteredList?.length ?? 0}`}
                  search
                  onChangeAction={e => {
                    handleSearch(e?.target?.value, ['Name'])
                  }}
                  onCloseAction={() => handleSearch('', [])}
                />
                <ContentWrapper>
                  {filteredList === undefined || filteredList?.length === 0 ? (
                    <EmptyList
                      title="No Record found"
                      subtitle="Add a new record now."
                    />
                  ) : (
                    <>
                      <ListItem style={{ padding: '0px 16px' }}>
                        <FormControlLabel
                          className="select-all-font"
                          control={
                            <Checkbox
                              color="primary"
                              onChange={e => {
                                handleSelectAllAssign(e)
                              }}
                              checked={
                                entityAssign?.size === filteredList?.length &&
                                filteredList?.length > 0
                              }
                            />
                          }
                          label={
                            <span className="xsTitle flex-space">
                              Select All
                            </span>
                          }
                          style={{ paddingLeft: '7px' }}
                        />
                      </ListItem>

                      <Card style={{ padding: '10px' }}>
                        <List disablePadding>
                          {filteredList.map((v, index) => {
                            return (
                              <ListItem
                                disableGutters
                                style={{ padding: '0px' }}
                              >
                                <Checkbox
                                  color="primary"
                                  value={v.CompanyID}
                                  onChange={e => {
                                    handleCheckBoxChangeAssign(e, index)
                                  }}
                                  checked={
                                    !!listCheckedEntityAssign.find(
                                      (el: any) => el.CompanyID === v.CompanyID
                                    ) ||
                                    entityAssign?.size === filteredList?.length
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <span className="xsTitle flex-space">
                                      {v.Name}
                                    </span>
                                  }
                                  secondary={
                                    <>
                                      {v.children?.length > 0 && (
                                        <span className="desc flex-space">
                                          {v?.selected ? v?.selected : 0}
                                          <span style={{ margin: '0px 4px' }}>
                                            /
                                          </span>
                                          {v.children?.length} Accessible
                                        </span>
                                      )}
                                    </>
                                  }
                                />
                              </ListItem>
                            )
                          })}
                        </List>
                      </Card>
                    </>
                  )}
                </ContentWrapper>
                <div style={{ margin: '100px' }}></div>
                <Footer
                  options={[
                    {
                      name: 'Assign',
                      onClick: () => {
                        if (companyArrayAssign?.length > 0)
                          onSubmit(companyArrayAssign, 'assign')
                        else {
                          setErrorDia(true)
                        }
                      },
                      color: 'primary',
                    },
                  ]}
                />
              </>
            ),
          },
          {
            label: `Assigned(${assigned?.length})`,
            content: (
              <>
                <SearchHeader
                  title={`Entity Listing`}
                  value={`${filteredList?.length ?? 0}`}
                  search
                  onChangeAction={e => {
                    handleSearch(e?.target?.value, ['Name'])
                  }}
                  onCloseAction={() => handleSearch('', [])}
                />
                <ContentWrapper>
                  {filteredList === undefined || filteredList?.length === 0 ? (
                    <EmptyList
                      title="No Record found"
                      subtitle="Add a new record now."
                    />
                  ) : (
                    <>
                      <ListItem style={{ padding: '0px 16px' }}>
                        <FormControlLabel
                          className="select-all-font"
                          control={
                            <Checkbox
                              color="primary"
                              onChange={e => {
                                handleSelectAllUnassign(e)
                              }}
                              checked={
                                entityUnassign?.size === filteredList?.length &&
                                filteredList?.length > 0
                              }
                            />
                          }
                          label={
                            <span className="xsTitle flex-space">
                              Select All
                            </span>
                          }
                          style={{ paddingLeft: '7px' }}
                        />
                      </ListItem>

                      <Card style={{ padding: '10px' }}>
                        <List disablePadding>
                          {filteredList.map((v, index) => {
                            return (
                              <ListItem
                                disableGutters
                                style={{ padding: '0px' }}
                              >
                                <Checkbox
                                  color="primary"
                                  value={v.CompanyID}
                                  onChange={e => {
                                    if (v?.IsUsed === false) {
                                      handleCheckBoxChangeUnassign(e, index)
                                    }
                                  }}
                                  checked={
                                    !!listCheckedEntityUnassign.find(
                                      (el: any) => el.CompanyID === v.CompanyID
                                    ) ||
                                    entityUnassign?.size ===
                                      filteredList?.length
                                  }
                                  style={{
                                    color:
                                      v?.IsUsed === true
                                        ? '#bdbdbd'
                                        : 'default',
                                  }}
                                />
                                <ListItemText
                                  primary={
                                    <>
                                      <span className="xsTitle ">{v.Name}</span>
                                    </>
                                  }
                                  secondary={
                                    <>
                                      {v.children?.length > 0 && (
                                        <span className="desc flex-space">
                                          {v?.selected ? v?.selected : 0}
                                          <span style={{ margin: '0px 4px' }}>
                                            /
                                          </span>
                                          {v.children?.length} Accessible
                                        </span>
                                      )}
                                    </>
                                  }
                                />
                                {v?.IsUsed === true ? (
                                  <span style={{ float: 'right' }}>
                                    <IconButton style={{ color: 'black' }}>
                                      <LockRounded fontSize="small" />
                                    </IconButton>
                                  </span>
                                ) : null}
                              </ListItem>
                            )
                          })}
                        </List>
                      </Card>
                    </>
                  )}
                </ContentWrapper>
                <div style={{ margin: '100px' }}></div>
                <Footer
                  options={[
                    {
                      name: 'Unassign',
                      onClick: () => {
                        if (companyArrayUnassign?.length > 0)
                          onSubmit(companyArrayUnassign, 'unassign')
                        else {
                          setErrorDia(true)
                        }
                      },
                      color: 'primary',
                    },
                  ]}
                />
              </>
            ),
          },
        ]}
      />
      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorHeaderMsg={'No company selected'}
        errorMsg={'Please select a company.'}
      />
    </>
  )
}
