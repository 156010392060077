import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Box,
  Card,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { CopyrightFooter } from 'components/Footer/Copyright'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  RecordStatus,
  GetTaxTypeDocument,
  //GetConTaxTypeDocument,
  TaxClass,
  useCreateTaxTypeMutation,
  useDeleteTaxTypeMutation,
  useGetCompanyNameQuery,
  useGetCostCentreTypeQuery,
  useGetTaxTypeQuery,
  //useCreateConTaxTypeMutation,
  //useDeleteConTaxTypeMutation,
  //useGetConTaxTypeQuery,
  useUpdateTaxTypeMutation,
  useCreateUpdateCostCentreDefinitionMutation,
  GetCostCentreDefinitionDetailDocument,
  useGetCostCentreDefinitionDetailQuery,
  useGetCostCentreQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import {
  commonList,
  deleteForm,
  handleRecordStatus,
} from '../../GeneralSettingModule/Common/CommonForm'
import AddBoxIcon from '@material-ui/icons/AddBox'
import theme from '@ifca-root/react-component/src/assets/theme'
import { Autocomplete } from '@material-ui/lab'
import DeleteIcon from '@material-ui/icons/Delete'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { uuid } from 'uuidv4'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'

export interface CostCentreDefinitionProps {
  CCDefItem: any[]
}

export const CostCentreDefinitionFormV3 = (props: any) => {
  useEffect(() => {
    console.log('CostCentreDefinitionFormV3')
  }, [])
  let history = useHistory()
  let location = useLocation()
  const editData: any = location?.state
  const { mode } = props
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: CostCentreTypeLoading,
    error: CostCentreTypeError,
    data: { getCostCentreType } = { getCostCentreType: [] },
  } = useGetCostCentreTypeQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getCostCentreType }) => {},
  })
  const {
    loading: CostCentreDefinitionDetailLoading,
    error: CostCentreDefinitionDetailError,
    data: { getCostCentreDefinitionDetail } = {
      getCostCentreDefinitionDetail: [],
    },
  } = useGetCostCentreDefinitionDetailQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: ({ getCostCentreDefinitionDetail }) => {
      if (getCostCentreDefinitionDetail?.length > 0) {
        const CCDefData = getCostCentreDefinitionDetail?.map(x => {
          return {
            CostCentreDefinitionDetailID: x?.CostCentreDefinitionDetailID,
            CostCentre: x?.Name,
            Level: x?.Level,
          }
        })

        append(CCDefData)

        // CCDefData?.map((x, index) => {
        //   setValue(`CCDefItem[${index}].CostCentre`, x?.CostCentre)
        //   setValue(`CCDefItem[${index}].Level`, x?.Level)
        // })
      }
      // else {
      //   append({
      //     CostCentreDefinitionDetailID: uuid(),
      //     Level: 1,
      //     CostCentre: undefined,
      //   })
      // }
    },
  })

  const {
    loading: CostCentreLoading,
    error: CostCentreErrors,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const [
    createUpdateCostCentreDefinition,
    {
      loading: createUpdateCostCentreTypeLoading,
      called: createUpdateCostCentreTypeCalled,
      error: createUpdateCostCentreTypeError,
    },
  ] = useCreateUpdateCostCentreDefinitionMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      console.log('data', data)
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/${CompanyID}/company-setting`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const CostCentreDefinitionSchema = yup.object().shape({
    CCDefItem: yup.array().of(
      yup.object().shape({
        Level: CommonYupValidation.requireField('Level is required'),
        CostCentre: CommonYupValidation.requireField('Department is required'),
      })
    ),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    getValues,
  } = useForm<CostCentreDefinitionProps>({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(CostCentreDefinitionSchema),
  })

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'CCDefItem',
  })

  console.log('FIELDS', fields)
  console.log(
    'WATCH',
    watch('CCDefItem')
      ?.map(v => v.Level)
      .sort((a, b) => b - a)[0],
    watch('CCDefItem')
  )

  let NumberData: any = (arr, index) => {
    const allWatchLevel = !arr ? [] : arr?.map(v => v.Level)
    const numData = [1, 2, 3, 4, 5, 6]

    const missNum = numData?.filter(x => !allWatchLevel.includes(x))

    let temp
    switch (missNum[0]) {
      case 1:
        temp = [1]
        break
      case 2:
        temp = [1, 2]
        break
      case 3:
        temp = [1, 2, 3]
        break
      case 4:
        temp = [1, 2, 3, 4]
        break
      case 5:
        temp = [1, 2, 3, 4, 5]
        break
      case 6:
        temp = [1, 2, 3, 4, 5, 6]
        break
    }
    return temp
  }

  // let NumberData: any = (arr, index) => {
  //   const maxlevel = arr?.map(v => v.Level).sort((a, b) => b - a)[0]
  //   console.log('maxlevel', maxlevel)
  //   let temp
  //   switch (maxlevel) {
  //     case undefined:
  //       temp = [1]
  //       break
  //     case 1:
  //       temp = [1, 2]
  //       break
  //     case 2:
  //       temp = [1, 2, 3]
  //       break
  //     case 3:
  //       temp = [1, 2, 3, 4]
  //       break
  //     case 4:
  //       temp = [1, 2, 3, 4, 5]
  //       break
  //     case 5:
  //       temp = [1, 2, 3, 4, 5, 6]
  //       break
  //     case 6:
  //       temp = [1, 2, 3, 4, 5, 6]
  //       break
  //   }
  //   return temp
  // }

  const missingNumber = () => {
    const numData = [1, 2, 3, 4, 5, 6]
    const allWatchLevel = watch('CCDefItem')?.map(x => x.Level)

    const missNum = numData?.filter(x => !allWatchLevel.includes(x))

    const MissNumIndex = watch('CCDefItem')
      ?.map((x, index) => {
        if (x?.Level >= missNum[0]) {
          return index
        }
      })
      ?.filter(x => x !== undefined)

    console.log('Miss Number', missNum[0])
    console.log('Miss Index', MissNumIndex)

    MissNumIndex?.map(v => {
      setValue(`CCDefItem[${v}].Level`, undefined)
      fields[v].Level = undefined
    })
  }

  useEffect(() => {
    if (fields?.length > 0) {
      missingNumber()
    }
  }, [fields])

  const CostCentreData = index => {
    const selectedCC = watch('CCDefItem')
      ?.filter((x, tindex) => tindex !== index)
      ?.map(x => x?.CostCentre)

    const unSelectedCC =
      watch('CCDefItem')?.length > 0
        ? getCostCentreType?.filter(x => !selectedCC.includes(x?.Name))
        : getCostCentreType

    return unSelectedCC
  }

  const onSubmit = data => {
    console.log('Passed Data', data)
    createUpdateCostCentreDefinition({
      variables: {
        CompanyID: CompanyID,
        CostCentreDefinitionDetailInput: data?.CCDefItem?.map(x => {
          return {
            CostCentreDefinitionDetailID: x?.CostCentreDefinitionDetailID,
            CompanyID: CompanyID,
            Name: x?.CostCentre,
            Level: Number(x?.Level),
            IsLastNode:
              data?.CCDefItem?.map(v => v.Level).sort((a, b) => b - a)[0] ===
              x?.Level
                ? true
                : false,
          }
        }),
      },
      refetchQueries: [
        {
          query: GetCostCentreDefinitionDetailDocument,
        },
      ],
    })
  }

  console.log('ERRORRRR', errors)

  return (
    <>
      {CostCentreLoading && <Loading />}
      {CostCentreDefinitionDetailLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/company-setting`)
        }
        smTitle={'General Ledger'}
        title={user?.accountName}
        //title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Company' },
          { name: 'Department Definition', current: true },
        ]}
        // rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />

      <ContentWrapper footer>
        <Box className="card-form">
          <Card>
            <CardHeader
              disableTypography
              title={<span className="smTitle ">{getCompany[0]?.Name} </span>}
              action={
                !CostCentreLoading && (
                  <IconButton
                    // disabled={section?.header?.disabled}
                    className="card-header-btn"
                    aria-label="edit"
                    //onClick={section?.header?.onClickAction}
                  >
                    {' '}
                    {fields?.length < getCostCentreType?.length ? (
                      <AddBoxIcon
                        style={{
                          width: '28px',
                          height: '28px',
                          marginTop: '10px',
                          float: 'right',
                          color: theme.palette.primary.main,
                        }}
                        onClick={() => {
                          if (fields?.length === 0) {
                            append({
                              CostCentreDefinitionDetailID: uuid(),
                              Level: 1,
                              CostCentre: undefined,
                            })
                          } else {
                            append({
                              CostCentreDefinitionDetailID: uuid(),
                              Level: undefined,
                              CostCentre: undefined,
                            })
                          }
                        }}
                      />
                    ) : null}
                  </IconButton>
                )
              }
            />
          </Card>
        </Box>

        {!CostCentreDefinitionDetailLoading &&
          //!CostCentreLoading &&
          (fields?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            fields?.map((el, index) => {
              const CCDefItem = `CCDefItem[${index}]`
              return (
                <div key={el?.id}>
                  <CardContents>
                    <Grid container>
                      <Grid item xs={2} style={{ paddingRight: '5px' }}>
                        <div hidden={true}>
                          <Controller
                            as={TextField}
                            name={`${CCDefItem}.CostCentreDefinitionDetailID`}
                            control={control}
                            ref={register}
                            value={el?.CostCentreDefinitionDetailID}
                            defaultValue={el?.CostCentreDefinitionDetailID}
                          />
                        </div>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <TextField
                              name={`${CCDefItem}.Level`}
                              label="Level"
                              margin="dense"
                              variant="outlined"
                              select
                              InputProps={{
                                style: { paddingLeft: '10px' },
                              }}
                              // disabled={
                              //   !!getCostCentre?.find(
                              //     x =>
                              //       x?.CostCentreDefinitionDetailID ===
                              //       el?.CostCentreDefinitionDetailID
                              //   )
                              // }
                              onChange={e => {
                                setValue(`${CCDefItem}.Level`, e?.target?.value)
                                fields[index].Level = e?.target?.value
                                missingNumber()
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={el?.Level}
                              className="qty-input-new-wid"
                              helperText={
                                errors.CCDefItem &&
                                errors.CCDefItem[index]?.Level?.message
                              }
                              error={
                                errors.CCDefItem &&
                                errors.CCDefItem[index]?.Level
                                  ? true
                                  : false
                              }
                            >
                              {NumberData(
                                watch('CCDefItem')?.filter(
                                  (x, tindex) => tindex !== index
                                ),
                                index
                              )?.map(v => (
                                <MenuItem value={v}>{v}</MenuItem>
                              ))}
                            </TextField>
                          )}
                          defaultValue={el?.Level}
                          required
                          select
                          name={`${CCDefItem}.Level`}
                          autoComplete="off"
                          control={control}
                          fullWidth
                          ref={register()}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        style={{ paddingRight: '5px', paddingLeft: '5px' }}
                      >
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <TextField
                              name={`${CCDefItem}.CostCentre`}
                              label="Department"
                              margin="dense"
                              variant="outlined"
                              select
                              className="qty-input-new-wid"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                style: { paddingLeft: '5px' },
                              }}
                              // disabled={
                              //   !!getCostCentre?.find(
                              //     x =>
                              //       x?.CostCentreDefinitionDetailID ===
                              //       el?.CostCentreDefinitionDetailID
                              //   )
                              // }
                              defaultValue={el?.CostCentre}
                              //defaultValue={menu?.obj?.SampleEnum}
                              onChange={e => {
                                setValue(
                                  `${CCDefItem}.CostCentre`,
                                  e?.target?.value
                                )
                              }}
                              helperText={
                                errors.CCDefItem &&
                                errors.CCDefItem[index]?.CostCentre?.message
                              }
                              error={
                                errors.CCDefItem &&
                                errors.CCDefItem[index]?.CostCentre
                                  ? true
                                  : false
                              }
                            >
                              {CostCentreData(index)?.map(v => (
                                <MenuItem value={v.Name}>{v.Name}</MenuItem>
                              ))}
                            </TextField>
                          )}
                          defaultValue={el?.CostCentre}
                          required
                          select
                          name={`${CCDefItem}.CostCentre`}
                          autoComplete="off"
                          control={control}
                          fullWidth
                          ref={register()}
                        />
                      </Grid>
                      <Grid item xs={1} className="delete-icon ">
                        {!CostCentreLoading && (
                          <div className="div-parent-delete">
                            {/* {!!getCostCentre?.find(
                              x =>
                                x?.CostCentreDefinitionDetailID ===
                                el?.CostCentreDefinitionDetailID
                            ) ? (
                              <div
                                style={{
                                  backgroundColor: 'grey',
                                  borderRadius: '4px',
                                  width: '22px',
                                  height: '22px',
                                  textAlign: 'center',
                                  marginTop: '15px',
                                  marginRight: '5px',
                                }}
                              >
                                <div className="div-delete">
                                  <DeleteIcon
                                    style={{
                                      color: 'white',
                                      padding: 0,
                                      fontSize: '18px',
                                      zIndex: 5,
                                      paddingTop: '2px',
                                    }}
                                    fontSize="small"
                                  />
                                </div>
                              </div>
                            ) : ( */}
                            <div
                              style={{
                                backgroundColor: '#FF1B40',
                                borderRadius: '4px',
                                width: '22px',
                                height: '22px',
                                textAlign: 'center',
                                marginTop: '15px',
                                marginRight: '5px',
                              }}
                            >
                              <div className="div-delete">
                                <DeleteIcon
                                  style={{
                                    color: 'white',
                                    padding: 0,
                                    fontSize: '18px',
                                    zIndex: 5,
                                    paddingTop: '2px',
                                  }}
                                  fontSize="small"
                                  onClick={e => {
                                    remove(index)
                                  }}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </CardContents>
                </div>
              )
            })
          ))}
      </ContentWrapper>
      {!CostCentreLoading && (
        <Footer
          copyright
          options={[
            {
              name: 'Save',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      )}
    </>
  )
}
