import React, { lazy } from 'react'
import { StockItemDetail } from './StockItemDetail'
import { StockItemListing } from './StockItemListing'
import { StockItemTreeListing } from './StockItemTreeListing'

const StockItemForm = lazy(() =>
  import('./StockItemForm').then(module => ({
    default: module.StockItemForm,
  }))
)

export const stockItemRoutes = [
  {
    props: {
      exact: true,
      path: `/inventory-control/general-setting/stock-item/:StockCategoryID/add`,
    },
    component: <StockItemForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/general-setting/stock-item/:StockCategoryID/:StockItemID/edit',
    },
    component: <StockItemForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/general-setting/stock-item/:StockCategoryID/:StockItemID/detail',
    },
    component: <StockItemDetail mode="view" />,
  },
  {
    props: {
      exact: true,
      path: `/inventory-control/general-setting/stock-item`,
    },
    component: <StockItemTreeListing />,
  },
  {
    props: {
      exact: true,
      path: `/inventory-control/general-setting/stock-item/:StockCategoryID`,
    },
    component: <StockItemListing />,
  },
]

export default stockItemRoutes
