export const calculateMonths = (startDate, endDate) => {
  // Calculate the difference in years between the end and start dates
  var yearsDifference = endDate.getFullYear() - startDate.getFullYear()

  // Calculate the fraction of a year for the remaining months
  var monthsDifference = endDate.getMonth() - startDate.getMonth()
  var fractionOfYear = yearsDifference + monthsDifference / 12

  // Multiply the fraction of a year by 12 to get the number of months
  var months = fractionOfYear * 12

  // Round the number of months to the nearest whole number
  var roundedMonths = Math.round(months)

  return roundedMonths
}
