import { differenceInDays, format, formatDistance, parseISO } from 'date-fns'

export const formatDate = (date: string) => {
  try {
    const result = parseISO(date)
    return date ? format(result, 'dd MMM yyyy') : 'N/A'
  } catch (error) {
    return date
  }
}

export const formatDashDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'yyyy-MM-dd') : 'N/A'
}

export const convertDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd MMM yyyy, hh:mm a') : 'N/A'
}

export const dateFormat = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd/MM/yyyy') : 'N/A'
}

export const formatTime = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'hh:mm a') : 'N/A'
}

export const convertTime = time => {
  if (time) {
    let hour = (time?.split(':'))[0]
    let min = (time?.split(':'))[1]
    let part = hour > 12 ? 'pm' : 'am'

    min = (min + '').length == 1 ? `0${min}` : min
    hour = hour > 12 ? hour - 12 : hour
    hour = (hour + '').length == 1 ? `0${hour}` : hour

    return `${hour}:${min} ${part}`
  } else {
    return 'N/A'
  }
}

export const dateDifferences = date => {
  var DateDifferences = formatDistance(new Date(), Date.parse(date), {
    includeSeconds: true,
  })
  return DateDifferences

  // var today = new Date();
  // console.log('today', today);

  // console.log('type:', today.getTime());
  // var TimeDifference = today.getTime() - Date.parse(date);
  // // One day/hour/min Time in ms (milliseconds)
  // var inDay = 1000 * 60 * 60 * 24;
  // var inHour = 1000 * 60 * 60;
  // var inMin = 1000 * 60;
  // var inSec = 1000;

  // var DaysDifference = TimeDifference / inDay;
  // var HoursDifference = TimeDifference / inHour;
  // var MinsDifference = TimeDifference / inMin;
  // console.log('day', DaysDifference);
  // let x, result;
  // if (DaysDifference >= 1) {
  //   x = DaysDifference.toFixed(0);
  //   console.log('day', x);
  //   result = x + ' Day(s) ago';
  //   return result;
  // } else if (DaysDifference < 1 && HoursDifference >= 1) {
  //   x = HoursDifference.toFixed(0);
  //   console.log('hour', x);
  //   result = x + ' Hr(s) ago';
  //   return result;
  // } else {
  //   x = MinsDifference.toFixed(0);
  //   console.log('min', x);
  //   result = x + ' Min(s) ago';
  //   return result;
  // }
}

export const daysDifferences = (date: string) => {
  var DateDifferences = differenceInDays(new Date(date), new Date())
  return DateDifferences
}

export const checkDateMoreThan = date => {
  const inputDate = new Date(date)
  const currDate = new Date()

  const yearEqual = inputDate.getFullYear() == currDate.getFullYear()
  const monthEqual = inputDate?.getMonth() == currDate?.getMonth()
  const dateEqual = inputDate?.getDate() == currDate?.getDate()
  const hourEqual = inputDate?.getHours() == currDate?.getHours()
  const minEqual = inputDate?.getMinutes() == currDate?.getMinutes()
  // const secEqual = inputDate?.getSeconds() == currDate?.getSeconds()

  if (inputDate.getFullYear() > currDate.getFullYear()) {
    return true
  } else if (yearEqual && inputDate?.getMonth() > currDate?.getMonth()) {
    return true
  } else if (
    yearEqual &&
    monthEqual &&
    inputDate?.getDate() > currDate?.getDate()
  ) {
    return true
  } else if (
    yearEqual &&
    monthEqual &&
    dateEqual &&
    inputDate?.getHours() > currDate?.getHours()
  ) {
    return true
  } else {
    return false
  }
}

export const getDatePlusDay = (date, addDay) => {
  let creditTermDay = !!addDay ? addDay : 30
  let newDate = new Date(date)
  newDate.setDate(newDate.getDate() + creditTermDay)
  return newDate
}

export const dateComparison = (
  startDate,
  endDate?,
  formula?: 'gt' | 'lt' | 'eq' | 'gte' | 'lte'
) => {
  let aLocale = new Date(startDate).toLocaleDateString()
  let bLocale = !!endDate
    ? new Date(endDate).toLocaleDateString()
    : new Date().toLocaleDateString()

  let a = new Date(aLocale)
  let b = new Date(bLocale)

  console.log(a, 'start', b, 'end')

  let date1 = new Date(a.getTime() + a.getTimezoneOffset() * -1 * 60 * 1000)
  let date2 = new Date(b.getTime() + b.getTimezoneOffset() * -1 * 60 * 1000)

  if (formula == 'gt') {
    return date1 > date2
  } else if (formula == 'gte') {
    return date1 >= date2
  } else if (formula == 'lt') {
    return date1 < date2
  } else if (formula == 'lte') {
    return date1 <= date2
  } else if (formula == 'eq') {
    return date1 == date2
  } else {
    return true
  }
}

export const dateConSubConComparison = (
  startDate,
  contractStartEnd?,
  formula?: 'between'
) => {
  let aLocale = new Date(startDate).toLocaleDateString()
  let bLocale = !!contractStartEnd?.conStart
    ? new Date(contractStartEnd?.conStart).toLocaleDateString()
    : new Date().toLocaleDateString()
  let cLocale = !!contractStartEnd?.conEnd
    ? new Date(contractStartEnd?.conEnd).toLocaleDateString()
    : new Date().toLocaleDateString()

  let val = new Date(aLocale)
  let start = new Date(bLocale)
  let end = new Date(cLocale)

  let subcontractDate = new Date(
    val.getTime() + val.getTimezoneOffset() * -1 * 60 * 1000
  )
  let conStart = new Date(
    start.getTime() + start.getTimezoneOffset() * -1 * 60 * 1000
  )
  let conEnd = new Date(
    end.getTime() + end.getTimezoneOffset() * -1 * 60 * 1000
  )

  console.log(
    subcontractDate,
    'subcontractDate',
    conStart,
    'conStart',
    conEnd,
    'conEnd',
    '>>>>>>>>>>>>',
    conStart < subcontractDate,
    subcontractDate < conEnd,
    conStart < subcontractDate && subcontractDate < conEnd
  )
  if (formula === 'between') {
    return conStart < subcontractDate && subcontractDate < conEnd
  } else {
    return true
  }
}
