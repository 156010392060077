import {
  Box,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Close, Search } from '@material-ui/icons'
import AppContext from 'containers/App/Store/AppContext'
import React, { useContext, useState } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import TuneIcon from '@material-ui/icons/Tune'
import { StandardDialog } from '../../../components/Dialog/StandardDialog'
import CardContents from '../../../components/CardList/CardContents'
import { DatePicker } from '@material-ui/pickers'
import { changeDateFormat } from '../../../helpers/Functions/dateFunction'
import { useDispatch, useSelector } from 'react-redux'
import { selectSearchBar } from '../../../components/AccountLedger/AccountLedgerListing/HeaderComponent/SearchBar/slice/selectors'
import { useFuseSearch } from '../../../components/AccountLedger/AccountLedgerListing/useFuseSearch/useFuseSearch'
import { useSearchBarSlice } from '../../../components/AccountLedger/AccountLedgerListing/HeaderComponent/SearchBar/slice'
import { useDropdownBarSlice } from '../../../components/AccountLedger/AccountLedgerListing/HeaderComponent/DropdownBar/slice'
import { selectDropdownBar } from '../../../components/AccountLedger/AccountLedgerListing/HeaderComponent/DropdownBar/slice/selectors'
import { format } from 'date-fns'

export const SearchFilterV2 = ({
  search,
  customMarginTop,
  searchBorderRadius,
  dropdownBorderRadius,
  searchInput,
  onChangeSearchInput,
  onCloseSearchInput,
  dropdownOptions,
  dropdownEl,
  selectedOption,
  handleOpenDropdownEl,
  handleCloseDropdownEl,
  handleOptionChange,
  filter,
  onClickFilter,
}: any) => {
  const { globalState }: any = useContext(AppContext)
  const marginTop = !!customMarginTop ? customMarginTop : ''

  return (
    <>
      <div
        className={`search-filter ${
          globalState.drawerOpen
            ? 'with-dropdown-filter-fw'
            : 'with-dropdown-filter'
        }`}
        style={{ marginTop: marginTop }}
      >
        {search && (
          <SearchItem
            borderRadius={searchBorderRadius}
            searchInput={searchInput}
            onChangeSearchInput={onChangeSearchInput}
            onCloseSearchInput={onCloseSearchInput}
            filter={filter}
            onClickFilter={onClickFilter}
          />
        )}
        {!!dropdownOptions && (
          <DropdownFilter
            borderRadius={dropdownBorderRadius}
            dropdownOptions={dropdownOptions}
            dropdownEl={dropdownEl}
            selectedOption={selectedOption}
            handleOpenDropdownEl={handleOpenDropdownEl}
            handleCloseDropdownEl={handleCloseDropdownEl}
            handleOptionChange={handleOptionChange}
          />
        )}
      </div>
    </>
  )
}

const SearchItem = ({
  borderRadius,
  searchInput,
  onChangeSearchInput,
  onCloseSearchInput,
  filter,
  onClickFilter,
}: any) => {
  return (
    <List
      className={`search-header ${!!searchInput ? null : 'hide'}`}
      style={{ borderRadius: borderRadius, height: '100%' }}
    >
      <ListItem>
        <ListItemIcon className="search-indicator">
          <Search />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <>
              <InputBase
                placeholder="Search here.."
                inputProps={{ 'aria-label': 'search' }}
                onChange={onChangeSearchInput}
                value={searchInput}
                inputRef={input => {
                  // input && searchBar && input.focus()
                  input && !searchInput && (input.value = '')
                }}
              />
            </>
          }
        />

        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="close"
            style={{ paddingTop: '50px' }}
            onClick={onCloseSearchInput}
            className={`close-search ${!!searchInput ? null : 'hide'} `}
          >
            <Close />
          </IconButton>
          {filter && (
            <IconButton
              edge="end"
              aria-label="close"
              style={{ paddingTop: '50px' }}
              onClick={onClickFilter}
              className={`close-search`}
            >
              <TuneIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}

const DropdownFilter = ({
  borderRadius,
  dropdownOptions,
  dropdownEl,
  selectedOption,
  handleOpenDropdownEl,
  handleCloseDropdownEl,
  handleOptionChange,
}: any) => {
  return (
    <div className="filter-dropdown">
      <List
        disablePadding
        className="search-header"
        style={{ borderRadius: borderRadius }}
      >
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleOpenDropdownEl}
        >
          <ListItemText
            disableTypography
            primary={
              <span className="xsTitle">
                {dropdownOptions?.find(e => e?.ID === selectedOption)?.name}
              </span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="arrow"
              onClick={handleOpenDropdownEl}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={dropdownEl}
        keepMounted
        open={Boolean(dropdownEl)}
        onClose={handleCloseDropdownEl}
      >
        {dropdownOptions?.map((option, index) => {
          return (
            <MenuItem
              key={option?.ID}
              selected={option?.ID === selectedOption}
              value={option?.ID}
              onClick={event => handleOptionChange(event, option)}
            >
              {option.name}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export const useSearchFilterV2Redux = () => {
  const dispatch = useDispatch()
  const { actions } = useSearchBarSlice()
  const { searchInput, searchFilter } = useSelector(selectSearchBar)
  const { handleSearch } = useFuseSearch()

  const onChangeSearchInput = event => {
    let searchFilterTemp = searchFilter
    if (searchFilterTemp?.length === 0) {
      // only setSearchFilter when its not yet set
      dispatch(actions.setSearchFilter(['docNo', 'title', 'docDate', 'docRef']))
    }
    let searchInput = event?.target?.value
    dispatch(actions.setSearchInput(searchInput))
    handleSearch(searchInput, searchFilter)
  }

  const onCloseSearchInput = event => {
    let searchFilter = []
    let searchInput = ''
    dispatch(actions.setSearchFilter(searchFilter))
    dispatch(actions.setSearchInput(searchInput))
    handleSearch(searchInput, searchFilter)
  }

  const { actions: actionsDropdownBar } = useDropdownBarSlice()
  const { anchorEl: dropdownEl, dropdownOptions, selectedOption } = useSelector(
    selectDropdownBar
  )

  //control dropdown action
  const handleOpenDropdownEl = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(actionsDropdownBar.setAnchorEL(event?.currentTarget))
  }
  const handleCloseDropdownEl = () => {
    dispatch(actionsDropdownBar.setAnchorEL(null))
  }

  //select dropdown
  const handleOptionChange = (event: any, option: any) => {
    dispatch(actionsDropdownBar.setSelectedOption(option?.ID))
    handleCloseDropdownEl()
  }

  return {
    searchInput,
    onChangeSearchInput,
    onCloseSearchInput,
    dropdownEl,
    dropdownOptions,
    selectedOption,
    handleOpenDropdownEl,
    handleCloseDropdownEl,
    handleOptionChange,
  }
}

export const useSearchFilterV2 = ({
  defaultValueSearch,
  handleSearch,
  searchKey,
  localStorageSearchKey,
  defaultValueDropdown,
  localStorageDropdownKey,
}: any) => {
  const [searchInput, setSearchInput] = useState(defaultValueSearch || '')
  const onChangeSearchInput = e => {
    handleSearch(e?.target?.value, searchKey)
    setSearchInput(e.target.value)
    localStorage.setItem(localStorageSearchKey, e.target.value)
    if (e?.target?.value === '') {
      onCloseSearchInput()
    }
  }
  const onCloseSearchInput = () => {
    handleSearch('', [])
    setSearchInput('')
    localStorage.removeItem(localStorageSearchKey)
  }

  const [dropdownEl, setDropdownEl] = useState<any>(null)
  const [selectedOption, setSelectedOption] = useState(defaultValueDropdown)
  //control dropdown action
  const handleOpenDropdownEl = (event: React.MouseEvent<HTMLElement>) => {
    setDropdownEl(event?.currentTarget)
  }
  const handleCloseDropdownEl = () => {
    setDropdownEl(null)
  }

  //select dropdown
  const handleOptionChange = (event: any, option: any) => {
    setSelectedOption(option?.ID)
    localStorage.setItem(localStorageDropdownKey, option?.ID)
    handleCloseDropdownEl()
  }
  return {
    searchInput,
    onChangeSearchInput,
    onCloseSearchInput,
    dropdownEl,
    selectedOption,
    setSelectedOption,
    handleOpenDropdownEl,
    handleCloseDropdownEl,
    handleOptionChange,
  }
}

export const useFilterDialog: any = (props?) => {
  const [filter, setFilter] = useState<boolean>(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [filterDropdown, setFilterDropdown] = useState<any>(null)

  const openFilterDialog = () => {
    setFilter(true)
  }
  const closeFilterDialog = () => {
    setFilter(false)
  }
  const onCloseFilter = () => {
    onClickReset()
    closeFilterDialog()
  }

  const onClickReset = () => {
    if (props?.dropdown) {
      setFilterDropdown(null)
    }
    setStartDate(null)
    setEndDate(null)
  }

  const handleFilterDate = data => {
    let date = format(new Date(data[props?.dateRange?.key]), 'yyyy-MM-dd')

    if (!!startDate && !!endDate) {
      return (
        new Date(date) >= new Date(startDate) &&
        new Date(date) <= new Date(endDate)
      )
    } else {
      return data
    }
  }

  const filterDialog = (
    <StandardDialog
      fullWidth
      open={filter}
      onClose={onCloseFilter}
      sections={{
        header: {
          title: 'Filter',
          actions: [
            {
              displayText: 'Reset',
              props: {
                onClick: onClickReset,
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
        body: () => {
          return (
            <>
              <CardContents>
                {props?.dropdown && (
                  <>
                    <TextField
                      fullWidth
                      id="standard-select-currency"
                      select
                      label={props?.dropdown?.title}
                      margin="normal"
                      onChange={e => {
                        setFilterDropdown(e?.target?.value)
                      }}
                      value={filterDropdown}
                      InputLabelProps={{
                        shrink: filterDropdown ? true : false,
                      }}
                    >
                      {props?.dropdown?.data?.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option[props?.dropdown?.key]}
                        >
                          {option[props?.dropdown?.display]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {props?.dateRange && (
                  <>
                    <div className="date-filter mdDesc">
                      {props?.dateRange?.title}
                    </div>
                    <DatePicker
                      autoOk
                      variant="inline"
                      disableToolbar
                      showTodayButton
                      label={`Start Date`}
                      select
                      required
                      fullWidth
                      className="date-filter left"
                      margin="normal"
                      format="dd MMM yyyy"
                      value={startDate}
                      maxDate={endDate}
                      SelectProps={{
                        renderValue: () =>
                          `${changeDateFormat(startDate, 'DD MMM YYYY')}`,
                      }}
                      onChange={date => {
                        setStartDate(changeDateFormat(date, 'YYYY-MM-DD'))
                      }}
                    />
                    <DatePicker
                      autoOk
                      variant="inline"
                      disableToolbar
                      showTodayButton
                      label={`End Date`}
                      select
                      required
                      fullWidth
                      className="date-filter right"
                      margin="normal"
                      format="dd MMM yyyy"
                      value={endDate}
                      minDate={startDate}
                      SelectProps={{
                        renderValue: () =>
                          `${changeDateFormat(endDate, 'DD MMM YYYY')}`,
                      }}
                      onChange={date => {
                        setEndDate(changeDateFormat(date, 'YYYY-MM-DD'))
                      }}
                    />
                  </>
                )}
              </CardContents>
            </>
          )
        },
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: onCloseFilter,
                variant: 'contained',
                color: 'secondary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: closeFilterDialog,
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )

  return {
    openFilterDialog,
    handleFilterDate,
    filterDialog,
    filterDropdown,
  }
}
