import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { Avatar, Grid, Tooltip } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import { FormAllocationTableContent } from 'components/Table/FormAllocationTableContent'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetAllocationDocumentDocument,
  useCreateAllocationMutation,
  useGetApAllocationDocumentLazyQuery,
  useGetArCreditNoteQuery,
  useGetUsersByAccountAndSoftwareQuery,
  UserSelection,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'

export const ARCreditNoteAllocation = props => {
  useEffect(() => {
    console.log('ARCreditNoteAllocation')
  }, [])
  let history = useHistory()
  const { CompanyID, CreditNoteID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const [allocatedObj, setAllocatedObj] = useState({})
  const [val, setVal] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  //----------------USE FORM---------------------//
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    errors,
    setError,
    clearErrors,
  } = useForm<any>()

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'Allocation',
  })

  //-----------------QUERY------------------------//

  const {
    loading: ARCreditNoteLoading,
    error: ARCreditNoteError,
    data: { getARCreditNote } = {
      getARCreditNote: [],
    },
  } = useGetArCreditNoteQuery({
    fetchPolicy: 'network-only',
    variables: {
      CreditNoteID: CreditNoteID,
    },
    onCompleted: ({ getARCreditNote }) => {
      if (getARCreditNote?.length > 0) {
        loadAllocationDocument({
          variables: {
            entityID: [CreditNoteID],
            companyID: CompanyID,
            refTable: 'AR_CreditNote',
            creditorDebtorID: getARCreditNote[0]?.DebtorAccountID,
          },
        })
      }
    },
  })

  const [
    loadAllocationDocument,
    {
      loading: getAllocationDocumentLoading,
      error: getAllocationDocumentError,
      data: { getAllocationDocument } = { getAllocationDocument: [] },
    },
  ] = useGetApAllocationDocumentLazyQuery({
    fetchPolicy: 'network-only',

    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: ({ getAllocationDocument }) => {
      let arr = []
      if (getAllocationDocument?.length > 0) {
        getAllocationDocument
          // ?.filter(
          //   alloc =>
          //     Number(alloc?.BalanceAmt) +
          //       Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0) >
          //       0 && (!alloc?.CanAllocate ? alloc?.AllocationID : true)
          // )
          // ?.sort((x, y) => {
          //   return y?.CanAllocate - x?.CanAllocate
          // })
          ?.map((alloc, index) => {
            arr.push({
              AllocationID: alloc?.AllocationID,
              CreditOrDebit: alloc?.CreditOrDebit,
              DocDate: alloc?.DocDate,
              DocNo: alloc?.DocNo,
              Description: alloc?.Description,
              // for previous allocated amt on the same doc
              AllocationAmt: Number(
                allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0
              ),
              // balance amount + previous allocated amt on the same doc
              BalanceAmt:
                Number(alloc?.BalanceAmt) +
                Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0),
              // credit or debit id
              [`${alloc?.CreditOrDebit}ID`]: alloc[`${alloc?.CreditOrDebit}ID`],
              // credit or debit ref table
              [`${alloc?.CreditOrDebit}RefTable`]: alloc[
                `${alloc?.CreditOrDebit}RefTable`
              ],
              // credit or debit doc no
              [`${alloc?.CreditOrDebit}DocNo`]: alloc[
                `${alloc?.CreditOrDebit}DocNo`
              ],
            })
            return alloc
          })
        append(arr)
      }
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  //-------------------------MUTATION----------------------------------//
  const [
    createAllocation,
    {
      loading: CreateAllocationLoading,
      called: CreateAllocationCalled,
      error: CreateAllocationError,
    },
  ] = useCreateAllocationMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      console.log('data', data)
      setTimeout(() => {
        // history.push({
        //   pathname: `/account-receivable/${CompanyID}/credit-note`,
        //   state: { success: true, msgMode: 'create' },
        // })
        history.goBack()
      }, 500)
    },
  })

  //-----------------------------ONSUBMIT-------------------------------//

  const onSubmit = (data, status) => {
    if (val == false) {
      setVal(true)
      createAllocation({
        variables: {
          input: watch('Allocation')
            ?.filter(
              alloc => Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
            )
            ?.map(alloc => {
              const mainCreditOrDebit =
                alloc?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
              return {
                ...(alloc?.AllocationID
                  ? { AllocationID: alloc?.AllocationID }
                  : null),
                AllocationAmt: Number(alloc?.AllocationAmt),
                CreditOrDebit: alloc?.CreditOrDebit,
                CompanyID: CompanyID,
                // for allocated table
                [`${alloc?.CreditOrDebit}RefTable`]: alloc?.[
                  `${alloc?.CreditOrDebit}RefTable`
                ],
                [`${alloc?.CreditOrDebit}ID`]: alloc?.[
                  `${alloc?.CreditOrDebit}ID`
                ],
                // main table
                [`${mainCreditOrDebit}RefTable`]: 'AR_CreditNote',
              }
            }),
          entityID: CreditNoteID,
        },
        refetchQueries: [
          {
            query: GetAllocationDocumentDocument,
            variables: {
              entityID: CreditNoteID,
              companyID: CompanyID,
              refTable: 'AR_CreditNote',
              creditorDebtorID: getARCreditNote[0]?.DebtorAccountID,
            },
          },
        ],
      })
      // }
    }
  }

  //----------------------------FOOTER---------------------------//

  const docAmt = getARCreditNote[0]?.DocAmt

  const allocationTotal = watch('Allocation')?.reduce(
    (total, currentValue) => (total = total + currentValue.AllocationAmt),
    0
  )

  const minusamt = docAmt - allocationTotal

  const createUpdateCalled = CreateAllocationCalled

  const submitFooterOption = {
    name: 'Save',
    onClick: e => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled: parseFloat(allocationTotal) > docAmt ? true : false,
  }

  const footerOptions: any[] = [submitFooterOption]

  //----------------------------USEEFFECT-------------------------------//
  useEffect(() => {
    if (editData?.Allocation) {
      let allocatedArr = editData?.Allocation
      allocatedArr?.map(allocated => {
        if (!(allocated[`${allocated?.CreditOrDebit}ID`] in allocatedObj)) {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] =
            allocated?.AllocationAmt
        } else {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] +=
            allocated?.AllocationAmt
        }
      })
    }
  }, [editData])

  return (
    <>
      {getAllocationDocumentLoading && <Loading />}
      {ARCreditNoteLoading && <Loading />}
      {CreateAllocationLoading && <Loading />}
      {UserLoading && <Loading />}

      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="back"
        smTitle={'Account Receivable'}
        title={`${user?.companyName}`}
        routeSegments={[
          { name: 'Account Receivable' },
          { name: 'AR Submenu' },
          { name: 'AR Submenu' },
          { name: 'Allocation', current: true },
        ]}
        currency={user?.companyCurrencyCode}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getARCreditNote[0]?.DocNo}
            </span>
          </>
        }
        rightText={
          <span
            className="desc"
            style={{ color: 'orange', fontWeight: 'bold' }}
          >
            {formatDate(getARCreditNote[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            {/* <div
              className="text-overflow"
              style={{ fontSize: '11px', width: '270px' }}
            >
              {getARCreditNote[0]?.DebtorAccount?.DebtorName}
            </div> */}
            <div className="icon-text">
              <Tooltip
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Avatar
                          style={{
                            width: '20px',
                            height: '20px',
                          }}
                        >
                          <PersonIcon style={{ fontSize: 'small' }} />
                        </Avatar>
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <div style={{ fontSize: '12px' }}>
                          {getUsersByAccountAndSoftware?.map(user => {
                            if (user.ID === getARCreditNote[0]?.approvedBy) {
                              return user?.name
                            }
                          })}
                        </div>
                        <div style={{ fontSize: '10px' }}>
                          {formatDate(getARCreditNote[0]?.approvedTs)}
                          &nbsp;
                          {formatTime(getARCreditNote[0]?.approvedTs)}
                        </div>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
                arrow
                enterTouchDelay={0}
              >
                <div>
                  <IconText
                    icon={
                      <img
                        src={SubmitterLog}
                        style={{
                          width: '11.5px',
                          marginRight: '5px',
                        }}
                      />
                    }
                    font="desc"
                  ></IconText>
                </div>
              </Tooltip>
              <span
                className="xsTitle content-wrap full text-overflow desc"
                style={{
                  marginBottom: '0px',
                }}
              >
                {getARCreditNote[0]?.Description}
              </span>
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <span className="desc flex-space">
              {amtStr(getARCreditNote[0]?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <FormAllocationTableContent
          fields={fields}
          watch={watch}
          errors={errors}
          register={register}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          setError={setError}
          allocatedObj={allocatedObj}
          docAmt={docAmt}
          totalAmt={allocationTotal}
          headerStyle={{
            backgroundColor: '#faf2e8',
            color: '#ff9800',
            fontWeight: '700',
          }}
          headerRightTitle={'Allocate Amt'}
          hasCheckbox={true}
        />
        <div style={{ marginBottom: '100px' }}></div>
      </ContentWrapper>

      <TotalAmountFooter
        // docAmt={calcTotal(creditNoteItems)}
        balanceInfo={true}
        balanceAmt={minusamt}
        TotalAllocationAmt={allocationTotal}
      />

      {footerOptions?.length > 0 ? (
        <AccountFooter options={[...footerOptions]} />
      ) : null}
    </>
  )
}
