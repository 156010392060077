import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { AddBox } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import { KeyboardDatePicker } from '@material-ui/pickers'
import BudgetPeriod from 'assets/icons/GeneralLedger/budget-period-cs-blue.svg'
import {
  RecordStatus,
  useGetBudgetQuery,
  useGetCompanyQuery,
} from 'generated/graphql'
import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useParams } from 'react-router'
import { uuid } from 'uuidv4'
import DynamicSubHeaderPeriod from '../AccountingPeriod/DynamicSubHeaderPeriod'

interface BudgetSetupProps {
  AccountID: string
  RecordStatus: string
  BudgetID: string
  CompanyID: string
  Fyear: number
  Version: string
  Description: string
  IsDefault: boolean
  BudgetItem: any
  BudgetDetails: any
}

export const BudgetSetupForm = (props: any) => {
  let history = useHistory()
  const { CompanyID, MasterCOAID }: any = useParams()
  const { mode } = props
  //const editData = location?.state as any
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const budgetItemCC = JSON.parse(localStorage.getItem('budgetCC'))

  // ACCOUNTX API CALLS

  const {
    loading: CompanyLoading,
    error,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RecordStatus: RecordStatus.Active },
  })

  const {
    loading: BudgetLoading,
    error: BudgetError,
    data: { getBudget: getBudget } = { getBudget: [] },
  } = useGetBudgetQuery({
    fetchPolicy: 'network-only',
    variables: {},
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<BudgetSetupProps>({
    defaultValues: {
      BudgetDetails: !!budgetItemCC
        ? budgetItemCC.map(v => {
            return {
              ID: v?.BudgetItemID,
              MasterCOAID: v?.MasterCOAID,
              Code: v?.MasterCOACode,
              BudgetAmt: v?.BudgetAmt,
            }
          })
        : [],
    },
    mode: 'all',
  })

  const { fields, append, remove, insert, prepend } = useFieldArray({
    control,
    name: 'BudgetDetails',
  })

  const onSubmit = (data, mode?) => {
    switch (mode) {
      case 'costCentre':
        localStorage.setItem(
          'budgetCC',
          JSON.stringify(
            data?.BudgetDetails?.map((x, index) => {
              return {
                BudgetItemID: x?.BudgetItemID,
                MasterCOAID: x?.MasterCOAID,
                Code: curCompany[0]?.EntityCOA.filter(
                  coa => coa?.MasterCOAID === x?.MasterCOAID
                )[0]?.MasterCOA?.Code,
                BudgetAmt: x?.BudgetAmt,
              }
            })
          )
        )

        break
    }
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {BudgetLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push(`/general-ledger/${CompanyID}/company-setting/budget`)
          localStorage.removeItem('budgetCC')
        }}
        smTitle={'Company Setting'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Company Setting' },
          { name: 'Company' },
          { name: 'Budget', current: true },
        ]}
        rightRouteSegments={[{ name: 'New', current: true }]}
      />

      <DynamicSubHeaderPeriod title={curCompany[0]?.Name} />

      <ContentWrapper singleDynamicInfo>
        <CardContents>
          <Controller
            as={KeyboardDatePicker}
            name="Fyear"
            label="Year"
            control={control}
            format="yyyy"
            value={new Date()}
            defaultValue={
              //mode === 'edit'
              //? editData?.Fyear
              //: new Date()
              new Date()
            }
            showTodayButton
            onChange={(date: Date | null) => {}}
            allowKeyboardControl
            fullWidth
            margin="normal"
            // className="left"
            inputRef={register}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            // helperText={errors?.JournalDate?.message}
            // error={errors?.JournalDate ? true : false}
            //disabled={}
            className="left"
            required
            disablePast={true}
          />

          <Controller
            as={
              <TextField select>
                {getBudget?.map((el, index) => (
                  <MenuItem
                    style={{ whiteSpace: 'normal' }}
                    value={el.Version}
                    key={index}
                  >
                    <span className="text-noflow">
                      {el.Fyear} | {el.Version}
                    </span>
                  </MenuItem>
                ))}
              </TextField>
            }
            name="Version"
            label="Version"
            autoComplete="off"
            control={control}
            format="yyyy"
            value={new Date()}
            margin="normal"
            inputRef={register}
            // helperText={errors?.JournalDate?.message}
            // error={errors?.JournalDate ? true : false}
            //disabled={}
            className="right"
            required
            select
          />
        </CardContents>
        <div className="message-alert text-noflow">
          <div>
            <span className="icon info-icon">
              {<InfoIcon style={{ color: 'orange' }} />}
            </span>
            <span className="desc">Click{'  '}</span>
            <span className="icon info-icon">
              {/* {<AttachMoney style={{ color: 'blue' }} />} */}
              <img
                src={BudgetPeriod}
                style={{
                  width: '23px',
                  marginBottom: '-2px',
                  font: 'xxTitle highlight-text',
                }}
              />
            </span>
            <span className="desc">
              {''}to add{' '}
              <span className="c-darkblack xxTitle">Department Budget</span>
            </span>
          </div>
        </div>
        <CardContents
          section={{
            header: {
              title: 'Budget Detail',
              // dropdown:
              //   <DropdownBar
              //     // options={getParentCompany.map(r => {
              //     //   return {
              //     //     name: r.Name,
              //     //     ID: r.CompanyID,
              //     //   }
              //     // })}
              //     // setOptionID={setParentCompany}
              //     borderRadius="4px"
              //   />,

              onClickAction: () =>
                append({
                  ID: uuid(),
                  MasterCOAID: '',
                  Code: '',
                  BudgetAmt: '',
                }),

              icon: <AddBox style={{ color: '#2f53e6' }} />,
            },
          }}
        >
          <div className="rm-padding table-wrap ">
            {fields?.map((v, index) => {
              return (
                <>
                  <Grid
                    spacing={1}
                    key={v.id}
                    container
                    justify="flex-start"
                    className="table-content"
                  >
                    <Grid
                      item
                      xs={1}
                      className="index-padding-wid"
                      style={{ placeSelf: 'start', marginTop: '8px' }}
                    >
                      <span className="fw-medium desc index- padding-wid">
                        {`${index + 1}.`}
                      </span>
                    </Grid>

                    <Grid container item xs={8}>
                      <Grid item xs={6} style={{ paddingRight: '10px' }}>
                        <div hidden={true}>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <TextField key={v?.id} hidden={true}></TextField>
                            )}
                            name={`BudgetDetails[${index}].BudgetItemID`}
                            control={control}
                            ref={register()}
                            defaultValue={v.ID}
                          />
                        </div>

                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <TextField
                              key={v?.id}
                              select
                              defaultValue={v.MasterCOAID}
                              margin="dense"
                              // helperText={errors?.UOM?.message}
                              // error={errors?.UOM ? true : false}
                              //label={'Budget Item'}
                              label="Acc Code"
                              InputLabelProps={{ shrink: true }}
                              required
                              variant="outlined"
                              onChange={e => {
                                register(`BudgetDetails[${index}].MasterCOAID`)
                                setValue(
                                  `BudgetDetails[${index}].MasterCOAID`,
                                  e?.target?.value
                                )
                                register(
                                  `BudgetDetails[${index}].MasterCOACode`
                                )
                                setValue(
                                  `BudgetDetails[${index}].MasterCOACode`,
                                  curCompany[0]?.EntityCOA.filter(
                                    coa => coa?.MasterCOAID === e?.target?.value
                                  )[0]?.MasterCOA?.Code
                                )
                              }}
                            >
                              {curCompany[0]?.EntityCOA.map((el, index) => (
                                <MenuItem value={el.MasterCOAID} key={index}>
                                  {el.MasterCOA?.Code}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                          // name="MasterCOACode"
                          name={`BudgetDetails[${index}].MasterCOAID`}
                          autoComplete="off"
                          control={control}
                          select
                          ref={register()}
                          defaultValue={v.MasterCOAID}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="mat-new"
                        style={{ paddingLeft: '10px' }}
                      >
                        <Controller
                          render={({ onChange }) => (
                            <NumberFormat
                              key={v?.id}
                              allowNegative={true}
                              customInput={TextField}
                              decimalScale={2}
                              fixedDecimalScale
                              variant="outlined"
                              autoComplete="off"
                              thousandSeparator
                              isNumericString
                              margin="dense"
                              onValueChange={values => {
                                let { floatValue } = values
                                onChange(
                                  values.floatValue,
                                  register(`BudgetDetails[${index}].BudgetAmt`),
                                  setValue(
                                    `BudgetDetails[${index}].BudgetAmt`,
                                    floatValue
                                  )
                                )
                              }}
                              label="Budget Amt * "
                              // InputLabelProps={{ shrink: true }}
                              defaultValue={v.BudgetAmt || 0}
                            />
                          )}
                          required
                          ref={register()}
                          // name={`BudgetAmt`}
                          name={`BudgetDetails[${index}].BudgetAmt`}
                          control={control}
                          onChangeName="onValueChange"
                          onChange={([{ value, floatValue }]) => {
                            return value
                          }}
                          disabled={mode === 'approve-reject'}
                          defaultValue={v.BudgetAmt || 0}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          handleSubmit(data => onSubmit(data, 'costCentre'))()
                          history.push(
                            `/general-ledger/${CompanyID}/company-setting/budget/add/${v.ID}/budgetcostcentre`
                            // /${getValues(`BudgetDetails[${index}].MasterCOAID
                            // )}`
                          )
                        }}
                      >
                        <img
                          src={BudgetPeriod}
                          style={{
                            width: '23px',
                            marginBottom: '-2px',
                            font: 'xxTitle highlight-text',
                          }}
                        />
                      </IconButton>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      style={{ placeSelf: 'center', textAlign: 'center' }}
                    >
                      <div className="rightText">
                        <DeleteIcon
                          style={{
                            backgroundColor: '#FF1B40',
                            borderRadius: '4px',
                            width: '18px',
                            height: '18px',
                            textAlign: 'center',
                            color: 'white',
                            padding: 0,
                            fontSize: '10px',
                            zIndex: 5,
                            paddingBottom: '2px',
                          }}
                          fontSize="small"
                          onClick={() => {
                            remove(index)
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Divider
                    variant="fullWidth"
                    style={{ background: '#7A7A7A' }}
                  ></Divider>
                </>
              )
            })}
          </div>
        </CardContents>
      </ContentWrapper>

      <Footer
        options={[
          {
            name: 'Save As Draft',
            color: 'primary',
            onClick: () => {
              //handleSubmit(onDraftSubmit)()
              // history.goBack()
            },
            props: {
              type: 'draft',
            },
          },
          {
            name: 'Submit',
            color: 'primary',
            onClick: () => {
              // handleSubmit(onSubmit)()
              // history.goBack()
            },
            props: {
              type: 'submit',
            },
          },
        ]}
      />
    </>
  )
}
