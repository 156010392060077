import React, { lazy } from 'react'
import { CycleCountView } from '../CycleCount/CycleCountDetail/CycleCountDetail'
import { StockRecon } from '../CycleCount/CycleCountForm/StockRecon'

const CycleCountListing = lazy(() =>
  import('../CycleCount/CycleCountMain').then(module => ({
    default: module.CycleCountMain,
  }))
)

const CycleCountForm = lazy(() =>
  import('../CycleCount/CycleCountForm/CycleCountForm').then(module => ({
    default: module.CycleCountForm,
  }))
)

const CycleCountDetail = lazy(() =>
  import('../CycleCount/CycleCountDetail/CycleCountDetail').then(module => ({
    default: module.CycleCountView,
  }))
)

const CycleCountRoute = [
  // {
  //   props: {
  //     exact: true,
  //     path: '/inventory-control/:WarehouseID/cycle-count',
  //   },
  //   component: <CycleCountListing />,
  // },
  // {
  //     props: {
  //         exact: true,
  //         path: '/inventory-control/:WarehouseID/cycle-count/add',
  //     },
  //     component: <CycleCountFormV2 formMode="add"/>
  // },
  {
    props: {
      exact: true,
      path: '/inventory-control/:WarehouseID/cycle-count/:CompanyID/add',
    },
    component: <CycleCountForm formMode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:WarehouseID/cycle-count/:CompanyID/:CycleCountID/edit',
    },
    component: <CycleCountForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/inventory-control/:WarehouseID/cycle-count/:CycleCountID/detail',
    },
    component: <CycleCountView />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:WarehouseID/cycle-count/:CompanyID/CycleCountID/view',
    },
    component: <CycleCountView />,
  },

  {
    props: {
      exact: true,
      path:
        '/inventory-control/:WarehouseID/cycle-count/:CycleCountID/stock-recon',
    },
    component: <StockRecon />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:WarehouseID/cycle-count/:CompanyID/:CycleCountID/stock-recon',
    },
    component: <StockRecon />,
  },
]

export default CycleCountRoute
