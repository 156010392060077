import React, { lazy } from 'react'

const InventoryControlListing = lazy(() =>
  import('./ICGeneralMain').then(module => ({
    default: module.InventoryControlListing,
  }))
)

export const icGeneralListingRoutes = [
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-issue`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-issue"
        routeSegments="Stock Issue"
        primaryKey="StockIssueID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-issue/:CompanyID`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-issue"
        routeSegments="Stock Issue"
        primaryKey="StockIssueID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-transfer`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-transfer"
        routeSegments="Stock Transfer"
        primaryKey="StockTransferID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-transfer/:CompanyID`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-transfer"
        routeSegments="Stock Transfer"
        primaryKey="StockTransferID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-adjustment`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-adjustment"
        routeSegments="Stock Qty Adjustment"
        primaryKey="StockAdjustmentID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-adjustment/:CompanyID`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-adjustment"
        routeSegments="Stock Qty Adjustment"
        primaryKey="StockAdjustmentID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-cost-adjustment`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-cost-adjustment"
        routeSegments="Stock Cost Adjustment"
        primaryKey="StockCostAdjustmentID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-cost-adjustment/:CompanyID`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-cost-adjustment"
        routeSegments="Stock Cost Adjustment"
        primaryKey="StockCostAdjustmentID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-receipt`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-receipt"
        routeSegments="Stock Receipt"
        primaryKey="StockReceiptID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-receipt/:CompanyID`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-receipt"
        routeSegments="Stock Receipt"
        primaryKey="StockReceiptID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/cycle-count`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="cycle-count"
        routeSegments="Cycle Count"
        primaryKey="CycleCountID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/cycle-count/:CompanyID`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="cycle-count"
        routeSegments="Cycle Count"
        primaryKey="CycleCountID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-requisition`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-requisition"
        routeSegments="Stock Requisition"
        primaryKey="StockRequisitionID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-transfer-requisition`,
    },
    component: (
      <InventoryControlListing
        icSubmenu="stock-transfer-requisition"
        routeSegments="Stock Transfer Requisition"
        primaryKey="StockTransferRequisitionID"
      />
    ),
  },
]

export default icGeneralListingRoutes
