import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { Tune } from '@material-ui/icons'
import Fuse from 'fuse.js'
import { useGetCompanyNameQuery } from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { LeaseContractEntryList } from './LeaseContractListing/LeaseContractEntryList'
// import { APEntryList } from './APListing/APEntryList'
// import { APPostingList } from './APListing/APPostingList'
// import { useAPListingData } from './Hooks/useAPListingData'

export const LeaseContractMain = (props: any) => {
  let history = useHistory()
  const { routeSegments } = props

  const { CompanyID }: any = useParams()
  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
    { name: 'Terminated', ID: 'TERMINATED' },
  ]
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  //   useEffect(() => {
  //     loadData()
  //   }, [CompanyID])

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = [
        'CreditorAccount.CompanyName',
        'DocAmt',
        'DocNo',
        'Description',
        'DocDateFormatted',
      ]
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }
      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch)
      const val = result?.map(x => x.item)
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys)
      }
    }
  }, [getSearch, originalList])

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  //   const {
  //     loading,
  //     primaryKey,
  //     createPermissionCheck,
  //     updatePermissionCheck,
  //     deletePermissionCheck,
  //     approveRejectPermissionCheck,
  //     userList,
  //     loadData,
  //     listData,
  //   } = useAPListingData({
  //     accountType,
  //     apSubmenu,
  //     CompanyID,
  //     setOriginalListing,
  //   })

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Doc Date',
      key: 'DocDate',
    },
  })

  return (
    <>
      {/* {loading && <Loading />} */}
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/mfrs16/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }}
        smTitle={'MFRS 16'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'MFRS 16' },
          { name: 'Lease Contract', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, [''])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
          }}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
        />
        {filterDialog}

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {/* {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && ( */}
      <LeaseContractEntryList
      // apSubmenu={apSubmenu}
      // filteredList={filteredList}
      // listLoading={loading}
      // listStatus={listStatus}
      // userList={userList}
      // primaryKey={primaryKey}
      // handleFilterDate={handleFilterDate}
      // createPermissionCheck={createPermissionCheck}
      // updatePermissionCheck={updatePermissionCheck}
      // deletePermissionCheck={deletePermissionCheck}
      // loadData={loadData}
      // CompanyID={CompanyID}
      />
      {/* )} */}

      {/* {listStatus === 'SUBMIT' && !loading && (
        <APPostingList
          list={filteredList}
          filteredList={filteredList}
          listLoading={loading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={userList}
          handleFilterDate={handleFilterDate}
          approveRejectPermissionCheck={approveRejectPermissionCheck}
          setOriginalListing={setOriginalListing}
          apSubmenu={apSubmenu}
          loadData={loadData}
          CompanyID={CompanyID}
        />
      )} */}
    </>
  )
}
