import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput as DocUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { changeDateFormat } from '@ifca-root/react-component/src/helpers/Functions/dateFunction'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { ListItem, ListItemText, MenuItem, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import { FormAllocationTableContent } from 'components/Table/FormAllocationTableContent'
import { DropdownTextfield } from 'containers/ARAPModule/Components/DropdownTextField'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  DocumentListingDocument,
  GetArOfficialReceiptDocument,
  RecordStatus,
  useCreateArOfficialReceiptMutation,
  useDocumentListingLazyQuery,
  useGetArAllocationDocumentLazyQuery,
  useGetBankAccountQuery,
  useGetCreditCardTypeQuery,
  useGetDebtorAccountQuery,
  useGetDocNumTitleQuery,
  useGetPaymentMethodQuery,
  useGetTaxSchemeQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateArOfficialReceiptMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  formatDashDate,
  getDatePlusDay,
} from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
import '../AROfficialReceipt.scss'

interface AROfficialReceiptProps {
  CompanyID: string
  DebtorAccountID: string
  ReceiptID: string
  DocDate: Date
  DocAmt: number
  RefNo: string
  Description: string
  Remark: string
  Attachment: null
  debitAmt: number
  approval: string
  user: string
  BankAccountID: string
  TrxDate: string
  BankName: string
  PaymentMethodID: string
  CreditCardID: string
  ChequeNo: string
  ChequeDate: Date
  Amount: number
  uploadAttach: string
  Allocation: any[]
}
interface FavAROfficialReceiptProps {
  Name: string
}

export const AROfficialReceiptForm = (props: any) => {
  const { formMode, type } = props

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  const JournalPItem = editData?.JournalProcessingItem
  const { CompanyID, ReceiptID }: any = useParams()
  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [favItems, setFavItems] = useState(null)
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [PaymentValidation, setPaymentValidation] = useState(false)
  const [valuepicked, setValuePicked] = useState<String>(null)
  const [voiceRemark, setVoiceRemark] = useState('')
  const [record, setRecord] = useState(false)

  const [cost, setCost] = useState(amtNumStr(editData?.Amount) ?? 0)
  const [totalAmt, setTotalAmt] = useState(0.0)
  const [amount, setAmount] = useState(0.0)
  const [selectedTaxAmount, setSelectedTaxAmount] = useState(null)
  const [allocatedObj, setAllocatedObj] = useState({})
  const [initDocs, setInitDocs] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)

  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])

  const {
    loading: DebtorAccountLoading,
    error: DebtorAccountError,
    data: { getDebtorAccount } = {
      getDebtorAccount: [],
    },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  //PaymentMethod
  const {
    loading: PaymentMethodLoading,
    error: PaymentMethodError,
    data: { getPaymentMethod } = { getPaymentMethod: [] },
  } = useGetPaymentMethodQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {},
  })

  const {
    called: bankAccountCalled,
    loading: bankAccountLoading,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RecordStatus: RecordStatus?.Active,
    },
  })

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'AR_OfficialReceipt',
    },
  })

  //CreditCardType
  const {
    loading: CreditCardTypeLoading,
    error: CreditCardTypeError,
    data: { getCreditCardType } = { getCreditCardType: [] },
  } = useGetCreditCardTypeQuery({
    fetchPolicy: 'network-only',
    variables: {},
  })

  // Tax
  const {
    loading: TaxLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    onCompleted: () => {},
    variables: {
      RecordStatus: RecordStatus.Active,
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const [
    loadAllocationDocument,
    {
      loading: getAllocationDocumentLoading,
      called: getAllocationDocumentCalled,
      error: getAllocationDocumentError,
      data: { getAllocationDocument } = { getAllocationDocument: [] },
    },
  ] = useGetArAllocationDocumentLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {},
    onCompleted: ({ getAllocationDocument }) => {
      let arr = []
      if (getAllocationDocument?.length > 0) {
        getAllocationDocument
          // ?.filter(
          //   alloc =>
          //     Number(alloc?.BalanceAmt) +
          //       Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0) >
          //       0 && (!alloc?.CanAllocate ? alloc?.AllocationID : true)
          // )
          // ?.sort((x, y) => {
          //   return y?.CanAllocate - x?.CanAllocate
          // })
          ?.map((alloc, index) => {
            arr.push({
              AllocationID: alloc?.AllocationID,
              CreditOrDebit: alloc?.CreditOrDebit,
              DocDate: alloc?.DocDate,
              DocNo: alloc?.DocNo,
              Description: alloc?.Description,
              // for previous allocated amt on the same doc
              AllocationAmt: Number(
                allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0
              ),
              // balance amount + previous allocated amt on the same doc
              BalanceAmt:
                Number(alloc?.BalanceAmt) +
                Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0),
              // credit or debit id
              [`${alloc?.CreditOrDebit}ID`]: alloc[`${alloc?.CreditOrDebit}ID`],
              // credit or debit ref table
              [`${alloc?.CreditOrDebit}RefTable`]: alloc[
                `${alloc?.CreditOrDebit}RefTable`
              ],
              // credit or debit doc no
              [`${alloc?.CreditOrDebit}DocNo`]: alloc[
                `${alloc?.CreditOrDebit}DocNo`
              ],
            })

            return alloc
          })
        append(arr)
      }
    },
  })

  const AROfficialReceiptFormSchema = yup.object().shape({
    DocDate: yup.string().required('Doc Date is Required'),
    TrxDate: yup.string().required('Transaction Date is Required'),
    RefNo: CommonYupValidation.requireField(SystemMsgs.referenceNo()),
    DebtorAccountID: yup.string().required('DebtorAccountID is Required'),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    PaymentMethodID: CommonYupValidation.requireField(
      'Payment Method is required'
    ),
    CreditCardID:
      PaymentValidation && valuepicked?.includes('Card')
        ? CommonYupValidation.requireField('Credit Card Type is required')
        : null,
    ChequeNo: yup.string().when('PaymentMethodID', {
      is: getPaymentMethod?.find(method => method?.Name === 'Cheque')
        ?.PaymentMethodID,
      then: yup.string().required('Cheque no. is required'),
      otherwise: null,
    }),
    ChequeDate: yup.date().when('PaymentMethodID', {
      is: getPaymentMethod?.find(method => method?.Name === 'Cheque')
        ?.PaymentMethodID,
      then: yup.date().required('Cheque date is required'),
      otherwise: null,
    }),

    BankAccountID: CommonYupValidation.requireField('Bank Account is required'),
    DocAmt: yup
      .number()
      .required('Amount is required')
      .moreThan(0)
      .typeError('Amount is required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    getValues,
    clearErrors,
    setError,
  } = useForm<AROfficialReceiptProps>({
    defaultValues: {
      CompanyID: editData ? editData?.CompanyID : '',
      DocDate: editData ? editData?.DocDate : new Date(),
      TrxDate: editData ? editData?.TransactionDate : new Date(),
      RefNo: editData ? editData?.RefNo : '',
      // TaxInvoiceNo: editData ? editData?.TaxInvoiceNo : '',
      DebtorAccountID: editData ? editData?.CreditAccountID : '',
      BankAccountID: editData ? editData?.BankAccountID : '',
      PaymentMethodID: editData ? editData?.PaymentMethodID : '',
      CreditCardID: editData ? editData?.CreditCardID : '',
      ChequeNo: editData ? editData?.ChequeNo : '',
      ChequeDate: editData ? editData?.ChequeDate : new Date(),
      DocAmt: editData ? editData?.DocAmt : 0,
      Amount: editData ? editData?.Amount : 0,
      Description: editData ? editData?.Description : '',
      Attachment: editData ? editData?.Attachment : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(AROfficialReceiptFormSchema),
  })

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'Allocation',
  })

  const [selectedBank, setSelectedBank] = useState(null)
  // select bank
  const handleBankChange = event => {
    setSelectedBank(
      getBankAccount.find(x => x?.BankAccountID === event?.target?.value)
    )
  }

  //Changing PaymentMethodID
  const [selectedPM, setSelectedPM] = useState(null)
  const paymentmethod: any = getPaymentMethod

  const handlePMChange = event => {
    if (
      getPaymentMethod?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name === 'Cheque' ||
      getPaymentMethod
        ?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name?.includes('Card')
    ) {
      setPaymentValidation(true)
    }
    if (
      getPaymentMethod?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name === 'Cheque'
    ) {
      setValuePicked('Cheque')
    } else if (
      getPaymentMethod
        ?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name?.includes('Card')
    ) {
      setValuePicked('Card')
    }
  }

  //Changing If PM chosed CreditCard
  const [selectedCC, setSelectedCC] = useState(null)
  const creditcard: any = getCreditCardType

  const handleCCChange = event => {
    setSelectedCC(
      creditcard.find(x => x?.CreditCardID === event?.target?.value)
    )
  }

  const handleCreditorDebtorChange = event => {
    remove()
    loadAllocationDocument({
      variables: {
        ...(editData && editData?.DebtorAccountID === event
          ? { entityID: [ReceiptID] }
          : null),
        creditorDebtorID: event,
        companyID: CompanyID,
        refTable: 'AR_OfficialReceipt',
      },
    })
  }

  const getAllocationTotal = () => {
    if (watch('DebtorAccountID') && watch('Allocation')?.length > 0) {
      return watch('Allocation').reduce((prevValue, currValue) => {
        return prevValue + currValue?.AllocationAmt
      }, 0)
    } else {
      return 0
    }
  }

  const taxScheme: any = getTaxScheme

  // Function//
  const handleAmtChange = event => {
    setCost(event.value)
  }

  const calculateTotalAmt = () => {
    setTotalAmt(amount)
  }

  useEffect(() => {
    if (editData?.Remark) {
      setValue('Remark', editData?.Remark)
    }
  }, [editData])

  useEffect(() => {
    calculateTotalAmt()
  }, [amount])

  // To get list of Tax Code that has been effective //
  const taxEffectiveDate: any = new Date().toISOString().slice(0, 10)

  const taxSchemeInput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'INPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  const taxSchemeOutput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'OUTPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  // Edit Bank Account
  useEffect(() => {
    if (!!editData && !!getBankAccount) {
      setSelectedBank(
        getBankAccount?.find(e => e?.BankAccountID === editData?.BankAccountID)
      )
    }
  }, [editData, getBankAccount])

  useEffect(() => {
    if (editData?.Allocation) {
      let allocatedArr = editData?.Allocation
      allocatedArr?.map(allocated => {
        if (!(allocated[`${allocated?.CreditOrDebit}ID`] in allocatedObj)) {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] =
            allocated?.AllocationAmt
        }
      })
    }
  }, [editData])

  useEffect(() => {
    if (editData?.DebtorAccountID) {
      loadAllocationDocument({
        variables: {
          entityID: ReceiptID,
          creditorDebtorID: editData?.DebtorAccountID,
          companyID: CompanyID,
          refTable: 'AR_OfficialReceipt',
        },
      })
    }
  }, [allocatedObj, editData])

  //////////////////////////////////////
  //...........ATTACHMENTS.............//
  ///////////////////////////////////////
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  useEffect(() => {
    if (!DocLoad && formMode !== 'add') {
      loadDoc({ variables: { refID: ReceiptID } })
    }
    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [formMode, ReceiptID, editData])

  const [
    createAROfficialReceipt,
    {
      loading: createAROfficialReceiptLoading,
      called: createAROfficialReceiptCalled,
      error: createAROfficialReceiptError,
    },
  ] = useCreateArOfficialReceiptMutation({
    onCompleted: ({ createAROfficialReceipt }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-receivable/${CompanyID}/official-receipt/${createAROfficialReceipt?.ReceiptID}/preview`,
          })
        } else {
          history.push({
            pathname: `/account-receivable/${CompanyID}/official-receipt`,
            state: { success: true, msgMode: 'create' },
          })
        }
      }, 500)
    },
  })

  const [
    updateAROfficialReceipt,
    {
      loading: updateAROfficialReceiptLoading,
      called: updateAROfficialReceiptCalled,
      error: updateAROfficialReceiptError,
    },
  ] = useUpdateArOfficialReceiptMutation({
    onError: error => {
      console.log(error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-receivable/${CompanyID}/official-receipt/${ReceiptID}/preview`,
          })
        } else {
          history.push({
            pathname: `/account-receivable/${CompanyID}/official-receipt`,
            state: { success: true, msgMode: 'update' },
          })
        }
      }, 500)
    },
  })

  const [val, setVal] = useState(false)
  const [errorDia, setErrorDia] = useState<boolean>(false)

  const onSubmit = (data, status) => {
    if (val == false) {
      setVal(true)
      if (formMode === 'add') {
        // if (
        //   getDocumentNumberHeader?.filter(x => x?.RefTable === 'AR_Advance')?.length === 0
        // ) {
        //   setOpenSnackBar(true)
        //   setSnackBarMsg(SystemMsgs.errorNumberingStructure())
        // } else {
        createAROfficialReceipt({
          variables: {
            input: {
              CompanyID: CompanyID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              RefNo: data?.RefNo,
              DebtorAccountID: data?.DebtorAccountID,
              PaymentMethodID: data?.PaymentMethodID,
              CreditCardID: data?.CreditCardID,
              BankAccountID: data?.BankAccountID,
              Description: data?.Description,
              Remark: data?.Remark,
              // TaxInvoiceNo: data.TaxInvoiceNo,
              Attachment: files,
              ChequeNo: data?.ChequeNo,
              ChequeDate: data?.ChequeDate,
              ChequeExpiryDate: !!data?.ChequeDate
                ? changeDateFormat(
                    getDatePlusDay(data?.ChequeDate, 180),
                    'DD MMM YYYY'
                  )
                : null,
              ApprovalStatus: statusInput(status),
              Amount: parseFloat(data?.DocAmt),
              DocAmt: parseFloat(data?.DocAmt),
              // BalanceAmt: getTotalAmt(true, cost, taxAmt, TaxRate),
            },
            allocationInput: watch('Allocation')
              ?.filter(
                alloc =>
                  Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
              )
              ?.map(alloc => {
                const mainCreditOrDebit =
                  alloc?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
                return {
                  ...(alloc?.AllocationID
                    ? { AllocationID: alloc?.AllocationID }
                    : null),
                  AllocationAmt: Number(alloc?.AllocationAmt),
                  CreditOrDebit: alloc?.CreditOrDebit,
                  CompanyID: CompanyID,
                  // for allocated table
                  [`${alloc?.CreditOrDebit}RefTable`]: alloc?.[
                    `${alloc?.CreditOrDebit}RefTable`
                  ],
                  [`${alloc?.CreditOrDebit}ID`]: alloc?.[
                    `${alloc?.CreditOrDebit}ID`
                  ],
                  // main table
                  [`${mainCreditOrDebit}RefTable`]: 'AR_OfficialReceipt',
                }
              }),
          },
          refetchQueries: [
            {
              query: GetArOfficialReceiptDocument,
            },
            {
              query: DocumentListingDocument,
            },
          ],
        })
        // }
      } else if (formMode !== 'add') {
        updateAROfficialReceipt({
          variables: {
            input: {
              CompanyID: CompanyID,
              ReceiptID: ReceiptID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              RefNo: data?.RefNo,
              DebtorAccountID: data?.DebtorAccountID,
              PaymentMethodID: data?.PaymentMethodID,
              CreditCardID: data?.CreditCardID,
              BankAccountID: data?.BankAccountID,
              Description: data?.Description,
              Remark: data?.Remark,
              // TaxInvoiceNo: data.TaxInvoiceNo,
              Attachment: files,
              ChequeNo: data?.ChequeNo,
              ChequeDate: data?.ChequeDate,
              ChequeExpiryDate: !!data?.ChequeDate
                ? changeDateFormat(
                    getDatePlusDay(data?.ChequeDate, 180),
                    'DD MMM YYYY'
                  )
                : null,
              ApprovalStatus: statusInput(status),
              Amount: parseFloat(data?.DocAmt),
              DocAmt: parseFloat(data?.DocAmt),
              // BalanceAmt: getTotalAmt(true, cost, taxAmt, TaxRate),
            },
            allocationInput: watch('Allocation')
              ?.filter(
                alloc =>
                  Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
              )
              ?.map(alloc => {
                const mainCreditOrDebit =
                  alloc?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
                return {
                  ...(alloc?.AllocationID
                    ? { AllocationID: alloc?.AllocationID }
                    : null),
                  AllocationAmt: Number(alloc?.AllocationAmt),
                  CreditOrDebit: alloc?.CreditOrDebit,
                  CompanyID: CompanyID,
                  // for allocated table
                  [`${alloc?.CreditOrDebit}RefTable`]: alloc?.[
                    `${alloc?.CreditOrDebit}RefTable`
                  ],
                  [`${alloc?.CreditOrDebit}ID`]: alloc?.[
                    `${alloc?.CreditOrDebit}ID`
                  ],
                  // main table
                  [`${mainCreditOrDebit}RefTable`]: 'AR_OfficialReceipt',
                }
              }),
          },
          refetchQueries: [
            {
              query: GetArOfficialReceiptDocument,
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: ReceiptID,
              },
            },
          ],
        })
      }
    }
  }

  let statusInput = mode => {
    let temp
    switch (mode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate <= latestOpenPeriodCheckingDate?.StartDate ||
    transferDate >= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? true : false
  const checkingYearClose2 = openPeriod2 ? false : true

  const createUpdateLoading = editData
    ? updateAROfficialReceiptLoading
    : createAROfficialReceiptLoading

  const createUpdateCalled = editData
    ? updateAROfficialReceiptCalled
    : createAROfficialReceiptCalled

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      checkingYearClose1 || checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableReceiptsDraft,
          }),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.DebtorAccountID ||
      !!errors?.PaymentMethodID ||
      !!errors?.BankAccountID ||
      !!errors?.Description ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableReceiptsDraft,
          }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: e => {
      handleSubmit(data =>
        getDocumentNumberHeader?.filter(
          x => x?.RefTable === 'AR_OfficialReceipt'
        )?.length === 0
          ? (setOpenSnackBar(true),
            setSnackBarMsg(SystemMsgs.errorNumberingStructure()))
          : !createUpdateCalled && onSubmit(data, 'submit')
      )()
      setIsSubmit(true)
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled: checkingYearClose1 || checkingYearClose2 ? true : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  const [openExitConf, setOpenExitConf] = useState(null)
  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,
      // for attachment
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
      // for allocation
      hasAllocation: true,
    })

  return (
    <>
      {createAROfficialReceiptLoading && <Loading />}
      {updateAROfficialReceiptLoading && <Loading />}
      {PaymentMethodLoading && <Loading />}
      {CreditCardTypeLoading && <Loading />}
      {bankAccountLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {TaxLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}
      {getAllocationDocumentLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          hasChanges() === true
            ? setOpenExitConf(true)
            : history.push(`/account-receivable/${CompanyID}/official-receipt`)
          localStorage.removeItem('DebitAmt')
        }}
        smTitle={'Account Receivable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Account Receivable' },
          { name: 'AP Submenu' },
          { name: 'Official Receipt Listing' },
          { name: 'Official Receipt', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              mode === 'add'
                ? 'New'
                : mode === 'approve-reject'
                ? 'Approve/Reject'
                : 'Edit',
            current: true,
          },
        ]}
      />

      <ContentWrapper float footer>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              className="left"
              as={KeyboardDatePicker}
              name="DocDate"
              label="Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              margin="dense"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.DocDate : new Date()}
              showTodayButton
              disabled={mode == 'approve-reject'}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose1
                  ? 'Financial Period already closed'
                  : errors?.DocDate?.message
              }
              error={errors?.DocDate || checkingYearClose1 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="dense"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose2
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose2 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Ref No"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            ref={register}
            defaultValue={
              formMode === 'edit' || mode === 'approve-reject'
                ? editData?.RefNo
                : ''
            }
            className="left"
            disabled={mode === 'approve-reject'}
          />

          <Controller
            render={({ onChange }) => (
              <NumberFormat
                allowNegative={true}
                customInput={TextField}
                decimalScale={2}
                fixedDecimalScale
                autoComplete="off"
                thousandSeparator
                isNumericString
                margin="dense"
                className="right"
                helperText={errors?.DocAmt && errors?.DocAmt.message}
                error={errors?.DocAmt ? true : false}
                onValueChange={values => {
                  let { floatValue } = values
                  onChange(
                    values.floatValue,
                    register(`DocAmt`),
                    setValue(`DocAmt`, floatValue)
                  )
                }}
                label="Amount"
                // InputLabelProps={{ shrink: true }}
                defaultValue={editData?.DocAmt ?? 0}
              />
            )}
            customInput={TextField}
            ref={register}
            control={control}
            margin="dense"
            className="right"
            name="DocAmt"
            label="Amount"
            disabled={formMode === 'approve-reject' ? true : false}
            required
            helperText={errors?.DocAmt?.message}
            error={errors?.DocAmt ? true : false}
            defaultValue={editData?.DocAmt ?? 0}
          />

          {!DebtorAccountLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = getDebtorAccount?.filter(
                  x => x?.DebtorAccountID === editData?.DebtorAccountID
                )[0]
                return (
                  <Autocomplete
                    options={
                      getDebtorAccount?.sort((a, b) => {
                        return a.DebtorName.localeCompare(b.DebtorName)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.DebtorName}`}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('DebtorAccountID', newValue?.DebtorAccountID)
                      handleCreditorDebtorChange(newValue?.DebtorAccountID)
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.DebtorName}</span>
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.DebtorAccountID?.message}
                            error={errors?.DebtorAccountID ? true : false}
                            label="Debtor Name"
                            style={{ width: '100%' }}
                            margin="dense"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Debtor Name"
              name="DebtorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.DebtorAccountID?.message}
              error={errors?.DebtorAccountID ? true : false}
              defaultValue={editData?.DebtorAccountID}
              required
              disabled={mode === 'approve-reject'}
            />
          )}

          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Payment Method"
                required
                select
                value={value}
                onChange={e => {
                  onChange()
                  handlePMChange(e)
                  setValue('PaymentMethodID', e?.target?.value)
                }}
                defaultValue={editData ? editData?.PaymentMethodID : ''}
                margin="dense"
                disabled={formMode === 'approve-reject'}
                helperText={errors?.PaymentMethodID?.message}
                error={errors?.PaymentMethodID ? true : false}
              >
                {getPaymentMethod
                  ?.sort((a, b) => {
                    return a.Name.localeCompare(b.Name)
                  })
                  .map((el, index) => (
                    <MenuItem key={index} value={el.PaymentMethodID}>
                      {el.Name}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            control={control}
            helperText={errors?.PaymentMethodID?.message}
            error={errors?.PaymentMethodID ? true : false}
            onChange={e => {
              //handlePMChange(e)
              setValue('PaymentMethodID', e?.target?.value)
            }}
            ref={register()}
            name="PaymentMethodID"
            autoComplete="off"
            value={editData?.PaymentMethodID}
            margin="dense"
            multiline={true}
            fullWidth
            required
          />

          {getPaymentMethod
            .find(el => el.PaymentMethodID === watch('PaymentMethodID'))
            ?.Name?.includes('Card') && (
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  select
                  required
                  name="CreditCardID"
                  label="Card Type"
                  onChange={e => {
                    onChange(e)
                    handleCCChange(e)
                    //setValue('CreditCardID', e?.target?.value)
                  }}
                  defaultValue={editData ? editData?.CreditCardID : ''}
                  disabled={mode == 'approve-reject'}
                  // helperText={errors?.CreditCardID?.message}
                  // error={errors?.CreditCardID ? true : false}
                >
                  {getCreditCardType
                    ?.sort((a, b) => {
                      return a.Name.localeCompare(b.Name)
                    })
                    .map((el, index) => (
                      <MenuItem key={index} value={el.CreditCardID}>
                        {`${el.Name}`}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              label="Card Type"
              name="CreditCardID"
              autoComplete="off"
              control={control}
              // helperText={errors?.CreditCardID?.message}
              // error={errors?.CreditCardID ? true : false}
              multiline={true}
              // defaultValue={getPayment[0]?.CreditCardID}
              fullWidth
              margin="dense"
              ref={register}
            />
          )}

          {getPaymentMethod.find(
            el => el.PaymentMethodID === watch('PaymentMethodID')
          )?.Name === 'Cheque' && (
            <>
              <Controller
                as={TextField}
                id="standard-basic"
                name="ChequeNo"
                label="Cheque No"
                className="left"
                autoComplete="off"
                control={control}
                margin="dense"
                required
                ref={register}
                // helperText={errors?.ChequeNo?.message}
                // error={errors?.ChequeNo ? true : false}
                defaultValue={editData ? editData?.ChequeNo : ''}
                disabled={mode == 'approve-reject'}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  required
                  as={KeyboardDatePicker}
                  name="ChequeDate"
                  label="Cheque Date"
                  control={control}
                  onChange={(date: Date | null) => {}}
                  format="dd MMM yyyy"
                  value={
                    // formMode === 'add' ? new Date() : editData?.ChequeDate
                    watch('ChequeDate')
                  }
                  margin="dense"
                  allowKeyboardControl
                  //inputRef={register({})}
                  ref={register}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  defaultValue={
                    formMode === 'add' ? new Date() : editData?.ChequeDate
                  }
                  showTodayButton
                  className="right"
                  disabled={mode == 'approve-reject'}
                  // helperText={errors?.ChequeDate?.message}
                  // error={errors?.ChequeDate ? true : false}
                />
              </MuiPickersUtilsProvider>
            </>
          )}

          <DropdownTextfield
            // mount={bankNameNeeded}
            disabled={formMode === 'approve-reject' ? true : false}
            helperText={errors?.BankAccountID?.message}
            error={errors?.BankAccountID ? true : false}
            label="Bank Account No"
            handleChangeEnabled
            handleChange={handleBankChange}
            selectProps={{
              renderValue: () =>
                `${selectedBank?.AccountNo} (${selectedBank?.BankProfile?.Name} | ${selectedBank?.Code})`,
            }}
            customDropdown={getBankAccount?.map((el, index) => (
              <MenuItem
                id="contract-select"
                key={index}
                value={el?.BankAccountID}
              >
                <div className="content-wrap full">
                  <div>
                    <span className="xsTitle">
                      {el.BankProfile?.Name} ({el.BankProfile?.Address?.city})
                    </span>
                  </div>
                  <div className="desc">
                    Account No: {`${el?.AccountNo} (${el?.Code})`}
                  </div>
                </div>
              </MenuItem>
            ))}
            name="BankAccountID"
            control={control}
            register={register}
            // defaultValue={watch(`BankAccountID`)}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            ref={register}
            defaultValue={
              formMode === 'edit' || mode === 'approve-reject'
                ? editData?.Description
                : ''
            }
            disabled={mode === 'approve-reject'}
          />

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />

          <DocUploadInput
            placeholder={previewFiles.length === 0 ? 'Attachment' : null}
            label={previewFiles.length > 0 ? 'Attachment' : null}
            files={files}
            onHandleUploadChange={handleUploadChange}
            multiple
            accept={
              '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            mapData={'application'}
            imagePreview={
              <>
                {previewFiles?.map((v, i) => (
                  <UploadPreview
                    remove
                    key={v}
                    src={v}
                    onClick={() => removeFile(i)}
                    mediaType={
                      // files[i]?.type
                      files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                    }
                  />
                ))}
              </>
            }
            disabled={mode === 'approve-reject'}
          />
        </CardContents>

        {watch('DebtorAccountID') && (
          <FormAllocationTableContent
            fields={fields}
            watch={watch}
            errors={errors}
            register={register}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            setError={setError}
            allocatedObj={allocatedObj}
            docAmt={watch('DocAmt')}
            totalAmt={getAllocationTotal()}
            headerStyle={{
              backgroundColor: '#faf2e8',
              color: '#ff9800',
              fontWeight: '700',
            }}
            hasCheckbox={true}
          />
        )}
        <div style={{ marginBottom: '50px' }} />
      </ContentWrapper>

      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        sections={{
          header: {
            title: 'Exit Confirmation',
          },
          body: () => (
            <>
              <div>
                Are you sure you want to exit? Your changes will not be saved.
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => history.goBack(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={errorDia}
        onClose={() => setErrorDia(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Document Numbering is empty'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>{'Please setup Document Numbering to Submit'}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <TotalAmountFooter docAmt={getAllocationTotal() ?? 0} />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-receivable/${CompanyID}/official-receipt`)
        }}
        // hasInfo={hasInfo}
      />
    </>
  )
}
