import * as ExcelJs from 'exceljs'
import FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'

interface IDownloadProps {
  name: string
  workSheetName: string
  headerSetup: ITemplateProps[]
  isHeaderLocked?: boolean
  defaultHeaderFillStyle?: any
  defaultHeaderBorderStyle?: any
  headerStyle?: IHeaderStyle[]
  sheetPassword?: string
  protectWholeSheet?: boolean
  isExport?: boolean
  BudgetYear?: number
}
interface IExportProps {
  name: string
  workSheetName: string
  headerSetup: ITemplateProps[]
  isHeaderLocked?: boolean
  defaultHeaderFillStyle?: any
  defaultHeaderBorderStyle?: any
  headerStyle?: IHeaderStyle[]
  sheetPassword?: string
  isExport?: boolean
  BudgetYear?: number
  BudgetData?: any
}

interface ITemplateProps {
  header: Partial<ExcelJs.Column>
  // dataType?: keyof dataType
  // dataType?: 'TrueOrFalse' | 'country' | 'nationality'
  dataType?: string
  period?: boolean
}

interface IHeaderStyle {
  col: number
  fillStyle: ExcelJs.Fill
  borderStyle: Partial<ExcelJs.Borders>
}

interface ISelectedFileProps {
  event: any
}

const options: Partial<ExcelJs.WorksheetProtection> = {
  selectLockedCells: true,
  selectUnlockedCells: true,
  formatColumns: true,
  formatCells: true,
  insertRows: true,
  autoFilter: true,
  deleteRows: true,
  scenarios: true,
  objects: true,
}

export const useBudgetExcelUpload = () => {
  const [fileTitle, setFileTitle] = useState<string>('') // value to display as placeholder
  const [openFileUpload, setOpenFileUpload] = useState<boolean>(false) // just Bool to open dialog
  const [currentData, setCurrentData] = useState(null) // keep track excel's data
  const [errorDialog, setErrorDialog] = useState<boolean>(false)
  const [onSave, setSave] = useState<boolean>(false)
  const [currFile, setCurrFile] = useState<any>()
  const [duplicateItems, setDuplicateItems] = useState<string[]>([])
  const [hasDuplicate, setHasDuplicate] = useState<number>(0)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [disableButton, setDisableButton] = useState<boolean>(false)

  const wb = new ExcelJs.Workbook()

  const downloadTemplateSetup = (template: ITemplateProps[]) => {
    let header = [],
      additionalCellSettings = []

    template?.map((val, index) => {
      header.push(val?.header)
      if (!!val?.dataType)
        additionalCellSettings.push({
          dataType: val?.dataType,
          colNo: index + 1,
        })
    })

    return {
      header,
      additionalCellSettings,
    }
  }

  const resetFileUpload = () => {
    setIsEmpty(false)
    setHasDuplicate(0)
    setSave(false)
    setCurrentData(null)
    setFileTitle('')
  }

  const onDownloadTemplateBudget = async (props: IDownloadProps) => {
    try {
      const {
        workSheetName,
        headerSetup,
        headerStyle,
        name,
        protectWholeSheet = false,
        isExport = false,
        BudgetYear,
      } = props

      let data: any

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

      // initializing the workbook

      wb.properties.date1904 = true
      wb.calcProperties.fullCalcOnLoad = true
      const ws: ExcelJs.Worksheet = wb.addWorksheet(workSheetName)

      const { header, additionalCellSettings } = downloadTemplateSetup(
        headerSetup
      )

      ws.columns = header
      ws.insertRow(1, [
        header[0]?.header,
        header[1]?.header,
        header[2]?.header,
        header[3]?.header,
        ...header
          ?.filter(x => x?.period === true)
          ?.map(el => {
            return `Financial Period (${BudgetYear})`
          }),
      ])
      ws.mergeCells('A1:A2')
      ws.mergeCells('B1:B2')
      ws.mergeCells('C1:C2')
      ws.mergeCells('D1:D2')
      if (header?.length > 4) {
        ws.mergeCells(1, 5, 1, header?.length)
      }

      // unprotect the column to be inserted
      if (!protectWholeSheet) {
        for (let i = 0; i < header.length; i++) {
          ws.getColumn(i + 1).protection = { locked: false }
        }
      }

      if (!!additionalCellSettings) {
        additionalCellSettings.map((v, i) => {
          // capping the values in 3000 -- considerably large number
          // to speed up the process of creating template (increase if necessary)
          // initialize the cell with empty values just to do cell data validation
          // this allow the cell to be validate with our own data validation
          // for now only has boolean value
          let values = new Array(3000).fill('')
          ws.getColumn(v.colNo).values = values
          ws.getCell('E1').value = `Financial Period (${BudgetYear})`

          ws.getColumn(v.colNo).eachCell((cell, rowNo) => {
            // redefined the values of the header since it got overwrote by prev lines
            if (rowNo === 2) cell.value = header[v.colNo - 1]?.header

            if (v.dataType === 'text' && rowNo !== 1 && rowNo !== 2) {
              ws.getCell(cell.address).numFmt = '@'
            }
            if (v.dataType === 'number' && rowNo !== 1 && rowNo !== 2) {
              ws.getCell(cell.address).dataValidation = NUMBER_TYPE_DATA(
                `=ISNUMBER(${cell.address})`
              ) as any
            }

            // for now according to contract, there are column that only consists of true of false value
          })
        })
      }

      // header doesnt move in view
      ws.views = [{ state: 'frozen', ySplit: 1 }]

      // lock the header if user cannot change the header
      ws.getRow(1).protection = { locked: true }
      // ws.getRow(2).protection = { locked: true }

      // default styles (if provided) set to each one of the headers

      ws.getRow(1).eachCell(cell => {
        cell.font = { bold: true }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        const cellVal = `${cell.value}`
        if (cellVal.includes('Financial Period')) {
          cell.alignment = {
            horizontal: 'center',
          }
        }
        // else {
        //   cell.alignment = {
        //     vertical: 'middle',
        //   }
        // }
      })
      ws.getRow(2).eachCell(cell => {
        cell.font = { bold: true }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }

        if (!!Number(cell.value)) {
          cell.alignment = {
            horizontal: 'center',
          }
        }
      })

      // custom styles (if provided) can be given to specific col by column
      // number as the position
      if (headerStyle?.length > 0) {
        headerStyle.forEach(val => {
          if (val.col > header.length)
            throw new Error('The column does not exists')
          else {
            ws.getCell(1, val.col).fill = val.fillStyle
            ws.getCell(1, val.col).border = val.borderStyle
          }
        })
      }

      // protect the whole excel sheet (with password provided
      // or default [THIS IS SET BY ME!])
      ws.protect('contX@gen-Set', options)
      const buffer = await wb.xlsx.writeBuffer()
      // download part
      data = new Blob([buffer], { type: fileType })

      if (isExport === true) {
        return data
      } else {
        FileSaver.saveAs(data, name)
      }
    } finally {
    }
  }

  const onSelectFile = async (props: ISelectedFileProps) => {
    const { event } = props
    let isValid: boolean = false

    const file: File = event.target.files[0]
    setCurrFile(file)

    if (!!file) {
      isValid =
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ? true
          : false
    }

    // initialize and read/load file buffer
    if (isValid === true) {
      // const wb = new ExcelJs.Workbook()
      await wb.xlsx.load(event.target.files[0])

      // get the worksheet (1 here because each excel has only one worksheet)
      // can be changed to use name of the specific worksheet in case there are multiple ws
      const ws = wb.getWorksheet(1)

      let dataCollected = [],
        columnNames

      setFileTitle(file.name)
      // populate the data for each row
      ws.eachRow({ includeEmpty: true }, (row, rowNo) => {
        let currRow = null
        if (rowNo === 2) {
          columnNames = row.values
          columnNames.shift()
        }
        if (rowNo > 2) {
          currRow = row.values
          currRow.shift()
        }

        if (rowNo > 2 && !isRowEmpty(currRow))
          dataCollected.push({
            ...basicDataProcessing(currRow, columnNames),
            rowNo: rowNo,
          })
      })

      // console.log(dataCollected, '>>>>>>>>>>>>>>')
      setCurrentData({
        BudgetYear: Number(`${ws.getCell('E1').value}`.replace(/[^0-9]/g, '')),
        ReportData: dataCollected?.filter(
          x =>
            x?.['1'] !== 0 ||
            x?.['2'] !== 0 ||
            x?.['3'] !== 0 ||
            x?.['4'] !== 0 ||
            x?.['5'] !== 0 ||
            x?.['6'] !== 0 ||
            x?.['7'] !== 0 ||
            x?.['8'] !== 0 ||
            x?.['9'] !== 0 ||
            x?.['10'] !== 0 ||
            x?.['11'] !== 0 ||
            x?.['12'] !== 0
        ),
      })
    }
    // else {
    //   snackFunc('Invalid file type!', 1000)
    //   return
    // }
  }
  const basicDataProcessing = (data: any[], columns: any[]) => {
    let processedData = null

    if (data?.length > 0) {
      columns?.map((val, index) => {
        let temp = {
          [val]:
            data[index] === undefined || `${data[index]}`.trim() === ''
              ? null
              : data[index],
        }

        if (!!temp) processedData = { ...processedData, ...temp }
      })
    }
    return processedData
  }

  const isRowEmpty = (row: any) => {
    return (
      row?.filter((el, index) => index > 3).filter(v => `${v}`.trim() !== '')
        .length === 0
    )
  }

  const onExportBudget = async (props: IExportProps) => {
    setDisableButton(true)
    try {
      const {
        workSheetName,
        headerSetup,
        headerStyle,
        name,
        isExport = false,
        BudgetYear,
        BudgetData,
      } = props

      let data: any

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

      // initializing the workbook

      wb.properties.date1904 = true
      wb.calcProperties.fullCalcOnLoad = true
      const ws: ExcelJs.Worksheet = wb.addWorksheet(workSheetName)

      const { header, additionalCellSettings } = downloadTemplateSetup(
        headerSetup
      )

      ws.columns = header
      ws.insertRow(1, [
        header[0]?.header,
        header[1]?.header,
        header[2]?.header,
        header[3]?.header,
        ...header
          ?.filter(x => x?.period === true)
          ?.map(el => {
            return `Financial Period (${BudgetYear})`
          }),
      ])
      ws.mergeCells('A1:A2')
      ws.mergeCells('B1:B2')
      ws.mergeCells('C1:C2')
      ws.mergeCells('D1:D2')
      if (header?.length > 4) {
        ws.mergeCells(1, 5, 1, header?.length)
      }

      // unprotect the column to be inserted
      for (let i = 0; i < header.length; i++) {
        ws.getColumn(i + 1).protection = { locked: false }
      }

      if (!!additionalCellSettings) {
        additionalCellSettings.map((v, i) => {
          // capping the values in 3000 -- considerably large number
          // to speed up the process of creating template (increase if necessary)
          // initialize the cell with empty values just to do cell data validation
          // this allow the cell to be validate with our own data validation
          // for now only has boolean value
          let values = new Array(3000).fill('')
          ws.getColumn(v.colNo).values = values
          ws.getCell('E1').value = `Financial Period (${BudgetYear})`

          ws.getColumn(v.colNo).eachCell((cell, rowNo) => {
            // redefined the values of the header since it got overwrote by prev lines
            if (rowNo === 2) cell.value = header[v.colNo - 1]?.header

            if (v.dataType === 'text' && rowNo !== 1 && rowNo !== 2) {
              cell.numFmt = '@'
            }
            if (v.dataType === 'number' && rowNo !== 1 && rowNo !== 2) {
              cell.numFmt = '#,##0.00'
              ws.getCell(cell.address).dataValidation = NUMBER_TYPE_DATA(
                `=ISNUMBER(${cell.address})`
              ) as any
            }
            // for now according to contract, there are column that only consists of true of false value
          })
        })
      }

      // header doesnt move in view
      ws.views = [{ state: 'frozen', ySplit: 1 }]
      ws.views = [{ state: 'frozen', ySplit: 2 }]

      // lock the header if user cannot change the header
      ws.getRow(1).protection = { locked: true }
      ws.getRow(2).protection = { locked: true }

      // lock whole column so user cannot change the column value
      ws.getColumn(1).protection = { locked: true }
      ws.getColumn(2).protection = { locked: true }
      ws.getColumn(3).protection = { locked: true }
      ws.getColumn(4).protection = { locked: true }
      ws.getRow(2).protection = { locked: false }

      // default styles (if provided) set to each one of the headers

      ws.getRow(1).eachCell(cell => {
        cell.font = { bold: true }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        const cellVal = `${cell.value}`
        if (cellVal.includes('Financial Period')) {
          cell.alignment = {
            horizontal: 'center',
          }
        }
        // else {
        //   cell.alignment = {
        //     vertical: 'middle',
        //   }
        // }
      })
      ws.getRow(2).eachCell(cell => {
        cell.font = { bold: true }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }

        if (!!Number(cell.value)) {
          cell.alignment = {
            horizontal: 'center',
          }
        }
      })

      // custom styles (if provided) can be given to specific col by column
      // number as the position
      if (headerStyle?.length > 0) {
        headerStyle.forEach(val => {
          if (val.col > header.length)
            throw new Error('The column does not exists')
          else {
            ws.getCell(1, val.col).fill = val.fillStyle
            ws.getCell(1, val.col).border = val.borderStyle
          }
        })
      }

      BudgetData?.map((x, row) => {
        const RowNo = row + 3
        const firstPeriodColumn = ws.getColumn(5).letter
        const lastPeriodColumn = ws.getColumn(additionalCellSettings?.length)
          .letter
        ws.getCell(`D${RowNo}`).value = {
          formula: `=SUM(${firstPeriodColumn}${RowNo}:${lastPeriodColumn}${RowNo})`,
          date1904: false,
        }
        header?.map((el, i) => {
          const index = i + 1
          const key = ws.getColumn(index).letter

          if (!!x[el?.header]) {
            ws.getCell(`${key}${RowNo}`).value = x[el?.header]
          }
        })
      })

      ws.protect('contX@gen-Set', options)
      const buffer = await wb.xlsx.writeBuffer()
      // download part
      data = new Blob([buffer], { type: fileType })

      if (isExport === true) {
        return data
      } else {
        FileSaver.saveAs(data, name)
      }

      wb.removeWorksheet(workSheetName)
      setDisableButton(false)
    } finally {
    }
  }

  return {
    onDownloadTemplateBudget,
    fileTitle,
    setFileTitle,
    currentData,
    setCurrentData,
    openFileUpload,
    setOpenFileUpload,
    resetFileUpload,
    onSelectFile,
    onExportBudget,
    disableButton,
    setDisableButton,
  }
}

const NUMBER_TYPE_DATA = (formula: string) => {
  return {
    type: 'custom',
    allowBlank: true,
    formulae: [formula],
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Error',
    error: 'The value must be number only',
  }
}
