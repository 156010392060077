import theme from '@ifca-root/react-component/src/assets/theme'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { default as React, useEffect } from 'react'
import '../AuditAdjustment.scss'

export const AuditAdjustmentDetailItemContent = (props: any) => {
  useEffect(() => {
    console.log('AuditAdjustmentDetailItemContent')
  }, [])
  const { listItem } = props
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <>
      <div className="rm-padding table-wrap">
        <List className="core-list content-wrap full " disablePadding>
          {listItem?.map((el, index) => (
            <ListItem
              className="table-listItem "
              key={index}
              divider
              style={{ backgroundColor: 'white' }}
            >
              <ListItemText
                primary={
                  <Grid container justify="flex-start" spacing={1}>
                    <Grid
                      item
                      xs={1}
                      style={{
                        placeSelf: 'start',
                      }}
                      className="desc"
                    >
                      {index + 1}.&nbsp;&nbsp;&nbsp;
                    </Grid>

                    <Grid
                      item
                      xs={8}
                      style={{
                        placeSelf: 'start',
                      }}
                    >
                      <div>
                        <div
                          className="xxTitle text-noflow"
                          style={{ lineBreak: 'anywhere' }}
                        >
                          {el?.MasterCOA?.Code} | {el?.MasterCOA?.Name}
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      style={{
                        placeSelf: 'start',
                        marginRight: 'auto',
                        textAlign: 'right',
                        flexWrap: 'wrap',
                        display: 'flex',
                        justifyContent: isDesktop ? 'end' : 'end',
                      }}
                      className="desc"
                    >
                      {el.DocAmt < 0
                        ? `(${amtStr(el.DocAmt * -1)})`
                        : amtStr(el.DocAmt)}
                    </Grid>
                  </Grid>
                }
                secondary={
                  <>
                    <Grid container justify="flex-start" spacing={1}>
                      <Grid
                        item
                        xs={1}
                        style={{
                          placeSelf: 'start',
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={11}
                        className="fw-medium desc "
                        style={{ color: '#858585', placeSelf: 'start' }}
                      >
                        {el.CostCentre.Name}
                      </Grid>
                    </Grid>
                    <Grid container justify="flex-start" spacing={1}>
                      <Grid
                        item
                        xs={1}
                        style={{
                          placeSelf: 'start',
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={11}
                        className="fw-medium desc "
                        style={{ color: '#858585' }}
                      >
                        {el.Remark}
                      </Grid>
                    </Grid>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div
        className="footer-label"
        style={{
          background: '#bfd3ff',
        }}
      ></div>
    </>
  )
}
