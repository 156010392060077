import AppBar from '@material-ui/core/AppBar'
import { useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
interface ITabsProps {
  value?: number
  onChange?: any
  tabs?: any
  fixed?: boolean
  fontSize?: boolean
  fullWidth?: boolean // syafiq 30/6/2021
  responsive?: boolean // syafiq 30/6/2021
  dynamicInfo?: boolean
  multiDynamicInfo?: boolean
  multiDynamicInfoSearch?: boolean
  subHeader?: boolean
  style?: Record<string, string>
  tabFont?: boolean
}

export default function StandardTabs(props: ITabsProps) {
  const {
    value,
    onChange,
    tabs,
    fixed,
    fontSize,
    fullWidth, //syafiq 30/6/2021
    responsive, //syafiq 30/6/2021
    dynamicInfo,
    multiDynamicInfo,
    multiDynamicInfoSearch,
    subHeader,
    style,
    tabFont,
  } = props
  const theme = useTheme()

  // const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
  //   defaultMatches: true,
  // })

  return (
    <div
      className={`core-tab 
      ${dynamicInfo && 'single-dynamicInfo'}
      ${multiDynamicInfo && 'multi-dynamicInfo'} 
      ${multiDynamicInfoSearch && `multi-dynamicinfo-search`}
      ${(responsive && !subHeader && 'adjust-padding') ||
        (responsive && subHeader && 'adjust-paddingV2')}`}
    >
      <AppBar
        position="static"
        className={`
          ${fixed && 'fixed-search-header'} 
          ${responsive && 'responsive-tab-header'}`}
        style={style}
      >
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          // variant={isDesktop ? null : 'fullWidth'}
          variant={fullWidth ? 'fullWidth' : 'scrollable'}
        >
          {tabs?.map((v, index) => (
            <Tab
              label={v.label}
              className={`${fontSize && 'tab-font'}`}
              style={{ ...(tabFont ? { fontSize: '12px' } : null) }}
              key={index}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs?.map((v, index) => (
        <TabPanel value={value} index={index}>
          {v.content}
        </TabPanel>
      ))}
    </div>
  )
}
