import { IconButton, ListItemText, ListSubheader } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteIcon from '@material-ui/icons/Delete'
import StarIcon from '@material-ui/icons/Star'
import React from 'react'

interface IFavoriteMenu {
  options: any
  funcLabel: string
  onClickFunc: any
  className?: React.CSSProperties
  borderRadius?: string
}

export default function FavoriteMenu(props) {
  const {
    options,
    funcLabel,
    addFavFunc,
    selectFavFunc,
    optionIDName,
    setOpenFavoriteDeleteDialog,
    anchorEl,
    setAnchorEl,
    handleClose,
    handleClickDelete,
  } = props
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (
    // event: React.MouseEvent<HTMLElement>,
    event: any,
    index: number
  ) => {
    // setOptionID(options[index][optionIDName])
    setSelectedIndex(index)
    setAnchorEl(null)
  }

  return (
    <div className="filter-dropdown">
      <IconButton edge="end" aria-label="arrow" onClick={handleClickListItem}>
        <StarIcon style={{ color: 'orange', paddingRight: '8px' }} />
      </IconButton>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <ListSubheader onClick={event => addFavFunc()}>
          {funcLabel}
        </ListSubheader>
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            selected={index === selectedIndex}
            value={option[optionIDName]}
            onClick={event => handleMenuItemClick(event, index)}
          >
            <ListItemText
              onClick={() => {
                selectFavFunc(option)
              }}
            >{`${index + 1}. ${option.Name}`}</ListItemText>
            <DeleteIcon
              onClick={e => {
                // iconClickFunc({
                //   variables: {
                //     [optionIDName]: option[optionIDName],
                //   },
                //   refetchQueries: delRefetchQuery,
                // })
                handleClickDelete(e, option[optionIDName], index, option)
                setOpenFavoriteDeleteDialog(true)
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
