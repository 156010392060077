import React, { lazy } from 'react'

const CurrencyListing = lazy(() =>
  import('./CurrencyListing').then(module => ({
    default: module.CurrencyListing,
  }))
)

const currencyRoutes = [
  {
    props: { exact: true, path: '/general-ledger/general-setting/currency' },
    component: <CurrencyListing />,
  },
]

export default currencyRoutes
