import React, { lazy } from 'react'

const GenerateRecurringJournalForm = lazy(() =>
  import('./GenerateRecurringForm/GenerateRecurringJournalForm').then(
    module => ({
      default: module.GenerateRecurringJournalForm,
    })
  )
)

const generateRecurringJournalRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/generate-recurring-journal',
    },
    component: <GenerateRecurringJournalForm />,
  },
]

export default generateRecurringJournalRoutes
