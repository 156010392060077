import React, { lazy } from 'react'
import { PDFPreviewGP } from '@account-root/procurement-react/src/components/PDFTemplate/PDFPreviewGP'

export const CENTRALISED_PO = 'centralised-po'
export const COMPANY_ID = ':CompanyID'
export const PURCHASE_ORDER_ID = ':dpoID'
export const FORM_MODE = ':formMode'
export const DETAIL = 'detail'
export const GENERAL_PURCHASE = 'general-purchase'
export const CENTRALISED_PO_PATH = `/${GENERAL_PURCHASE}/${COMPANY_ID}/${CENTRALISED_PO}`
export const CENTRALISED_PO_WITH_POID_PATH = `/${GENERAL_PURCHASE}/${COMPANY_ID}/${CENTRALISED_PO}/${PURCHASE_ORDER_ID}/${FORM_MODE}`

const PurchaseOrderListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule//GeneralPOModule/PurchaseOrderListing'
  ).then(module => ({
    default: module.PurchaseOrderListing,
  }))
)

const CentralisedPOForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/CentralisedPOModule/CentralisedPOForm'
  ).then(module => ({
    default: module.CentralisedPOForm,
  }))
)

const POForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule//DirectPOModule/POForm'
  ).then(module => ({
    default: module.PurchaseOrderForm,
  }))
)

const POItemForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule//DirectPOModule/POItemForm'
  ).then(module => ({
    default: module.PurchaseOrderItemForm,
  }))
)

const PurchaseOrderDetail = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule//GeneralPOModule/PurchaseOrderDetail'
  ).then(module => ({
    default: module.PurchaseOrderDetail,
  }))
)

const generalCentralisedPORoutes = [
  {
    props: {
      exact: true,
      path: `${CENTRALISED_PO_PATH}`,
    },
    component: <PurchaseOrderListing type="centralised-po" />,
  },
  {
    props: {
      exact: true,
      path: `${CENTRALISED_PO_PATH}/:POHeaderID/detail/preview`,
    },
    component: (
      <PDFPreviewGP
        accountType="/"
        docType="centralised-po"
        backPath="centralised-po"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `${CENTRALISED_PO_PATH}/add`,
    },
    component: (
      <CentralisedPOForm
        mode="add"
        type="centralised-po"
        title="Centralised PO"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `${CENTRALISED_PO_WITH_POID_PATH}`,
    },
    component: <POForm detailMode={'edit'} type="centralised-po" />,
  },
  {
    props: {
      exact: true,
      path: `${CENTRALISED_PO_WITH_POID_PATH}/po-item/:detailMode`,
    },
    component: <POItemForm type="centralised-po" />,
  },
  {
    props: {
      exact: true,
      path: `${CENTRALISED_PO_WITH_POID_PATH}/po-item/:detailMode`,
    },
    component: <POItemForm type="centralised-po" />,
  },
  {
    props: {
      exact: true,
      path: `${CENTRALISED_PO_WITH_POID_PATH}/po-item/:dpoItemID/:detailMode`,
    },
    component: <POItemForm type="centralised-po" />,
  },
  {
    props: {
      exact: true,
      path: `${CENTRALISED_PO_WITH_POID_PATH}/po-item/:dpoItemID/:detailMode`,
    },
    component: <POItemForm type="centralised-po" />,
  },
  {
    props: {
      exact: true,
      path: `${CENTRALISED_PO_PATH}/detail/:dpoID/view`,
    },
    component: <PurchaseOrderDetail type="centralised-po" />,
  },
]
export default generalCentralisedPORoutes
