import React, { lazy } from 'react'

// const ExpenseClassListing = lazy(() =>
//   import('./ExpenseClassListing').then(module => ({
//     default: module.ExpenseClassListing,
//   }))
// )

const ExpenseClassListingV2 = lazy(() =>
  import('./ExpenseClassListingV2').then(module => ({
    default: module.ExpenseClassListingV2,
  }))
)

const ExpenseClassAssignForm = lazy(() =>
  import('./ExpenseClassAssignment').then(module => ({
    default: module.ExpenseClassAssignment,
  }))
)

const ExpenseClassRoutes = [
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/expense-class',
    },
    component: <ExpenseClassListingV2 />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/expense-class/:ExpenseClassID/assign',
    },
    component: <ExpenseClassAssignForm />,
  },
]

export default ExpenseClassRoutes
