import React, { lazy } from 'react'
import { CostCentreTypeForm } from './CostCentreTypeForm'

const costCentrTypeRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/cost-centre-type',
    },
    component: <CostCentreTypeForm />,
  },
]

export default costCentrTypeRoutes
