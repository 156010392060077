import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import Logo from 'assets/images/Account-x.png'
import Background from 'assets/images/HR-Background.jpg'
import { useActivateUserMutation } from 'generated/graphql'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'

export const ActivatedUser = () => {
  console.log('USERactivate')
  const { token }: any = useParams()
  console.log(token, 'TOKEN')
  let history = useHistory()
  const [
    ActivatedUser,
    { loading: mutationUpdateLoading, error: mutationUpdateError, data },
  ] = useActivateUserMutation({
    onError: error => {
      console.log('error', error)
    },
    onCompleted: data => {
      if (data?.activateUser && token) {
        history.push(`/user/create-password/${token}`)
      } else {
        console.log('mutation return false')
      }
    },
  })

  useEffect(() => {
    if (token) {
      ActivatedUser({
        variables: {
          token: token,
        },
      })

      // history.push(`/user/create-password/${token}`)
    }
  }, [token])

  return (
    <>
      {mutationUpdateLoading && <Loading />}
      {!mutationUpdateLoading && (
        <AuthLayout className="margin-override" logo={Logo} image={Background}>
          {data?.activateUser && token ? (
            <div className="form-box">
              <span className="page-title">You're almost there!</span>
              <br />
              <span className="page-title">Redirecting...</span>
              {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          className="login-btn"
          onClick={() => {
            history.push(`/user/create-password/${token}`);
          }}
          >
          Create password
        </Button> */}
            </div>
          ) : (
            <div className="form-box">
              <span className="page-title">Something went wrong!</span>
              {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          className="login-btn"
          onClick={() => {
            history.push(`/user/create-password/${token}`);
          }}
          >
          Create password
        </Button> */}
            </div>
          )}
        </AuthLayout>
      )}
    </>
  )
}
