export const smTitle = {
  HOME: 'AccountX',
  GENERAL_LEDGER: 'General Ledger',
  CASH_BOOK: 'Cash Book',
  ACCOUNT_RECEIVABLE: 'Account Receivable',
  ACCOUNT_PAYABLE: 'Account Payable',
  INVENTORY_CONTROL: 'Inventory Control',
  GENERAL_PURCHASES: 'General Purchases',
  SYSTEM_ADMIN: 'System Admin',
  COMPANY_SETTINGS: 'Company Settings',
  GENERAL_SETTINGS: 'General Settings',
}
