import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput as DocUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { ListItem, ListItemText, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { calculateMonths } from 'containers/MFRS16Module/Helper/Calculation'
import {
  ApprovalStatus,
  useDocumentListingLazyQuery,
  useGetCompanySummaryQuery,
  useLatestOpenPeriodCheckingDateQuery,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
// import '../APAdvance.scss'

interface LeaseContractProps {
  CompanyID: string
  LeaseContractID: string
  StartJournalDate: string
  StartDate: string
  EndDate: string
  AccountROU: string
  LeaseMonth: number
  ContractNo: string
  MonthlyLeasePayment: number
  TotalLeasePayment: number
  AnnualDiscountRate: number
  DiscountedTotalCashFlow: number
  Description: string
  Remark: string
  Attachment: null
  Amount: number
  uploadAttach: string
}

export const LeaseContractForm = (props: any) => {
  const { formMode, type } = props

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  const { CompanyID, AdvanceID }: any = useParams()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openExitDialog, setopenExitDialog] = useState(false)

  const [initDocs, setInitDocs] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  const [voiceRemark, setVoiceRemark] = useState('')
  const [record, setRecord] = useState(false)

  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanySummaryQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: ({ getCompany }) => {},
  })

  const LeaseContractFormSchema = yup.object().shape({
    StartDate: yup.string().required('Doc Date is Required'),
    EndDate: yup.string().required('Transaction Date is Required'),
    ContractNo: CommonYupValidation.requireField(SystemMsgs.referenceNo()),
    CreditorAccountID: yup.string().required('Creditor Name is Required'),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),

    BankAccountID: CommonYupValidation.requireField('Bank Account is required'),
    LeaseMonth: yup
      .number()
      .required('Amount is required')
      .moreThan(0)
      .typeError('Amount is required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    getValues,
    clearErrors,
    formState,
  } = useForm<LeaseContractProps>({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(LeaseContractFormSchema),
  })

  useEffect(() => {
    if (editData?.Remark) {
      setValue('Remark', editData?.Remark)
    }
  }, [editData])

  //////////////////////////////////////
  //...........ATTACHMENTS.............//
  ///////////////////////////////////////
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  useEffect(() => {
    if (!DocLoad && formMode !== 'add') {
      loadDoc({ variables: { refID: AdvanceID } })
    }
    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [formMode, AdvanceID, editData])

  const [errorDia, setErrorDia] = useState<boolean>(false)

  const onSubmit = (data, status) => {}

  let statusInput = mode => {
    let temp
    switch (mode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      //   handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },

    // disabled: handlePermDisabled({
    //   companyID: CompanyID,
    //   permEnum: AcctPermission.AccPayableAdvancesDraft,
    // }),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      //   handleSubmit(data => !createUpdateCalled && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: e => {},
    color: 'primary',
    props: {
      type: 'submit',
    },
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  const [openExitConf, setOpenExitConf] = useState(null)

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
      formMode: formMode,
    })

  return (
    <>
      {CompanyLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          hasChanges() === true
            ? setOpenExitConf(true)
            : history.push(`/mfrs16/${CompanyID}/lease-contract`)
        }}
        smTitle={'MFRS 16'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'MFRS 16' },
          { name: 'Lease Contract' },
          { name: 'Lease Contract', current: true },
        ]}
        rightRouteSegments={[
          {
            name: 'New',
            //   mode === 'add'
            //     ? 'New'
            //     : mode === 'approve-reject'
            //     ? 'Approve/Reject'
            //     : 'Edit',
            current: true,
          },
        ]}
      />
      <ContentWrapper float footer>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Date"
              control={control}
              onChange={(date: Date | null) => {
                //date
              }}
              // onAccept={(date: Date | null) => {
              //   updateNewPaymentItem(date, taxEffectiveList)
              // }}
              format="dd/MM/yyyy"
              value={watch(
                formMode === 'add' ? new Date() : editData?.StartDate
              )}
              margin="dense"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.StartDate : new Date()}
              showTodayButton
              className="left"
              disabled={mode == 'approve-reject'}
              style={{
                paddingTop: '10px',
              }}
              // minDate={
              //   //   StartDateValidationBefore(
              //   // getDocumentDateValidation[0]?.MonthsBefore
              //   //)
              //   formMode === 'add'
              //     ? StartDateValidationBefore(
              //         getDocumentDateValidation[0]?.MonthsBefore
              //       )
              //     : editData?.StartDate
              // }
              // maxDate={StartDateValidationAfter(
              //   getDocumentDateValidation[0]?.MonthsAfter
              // )}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="EndDate"
              required
              label="End Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              // minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              // maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="ContractNo"
            label="Contract No."
            className="left"
            required
            autoComplete="off"
            control={control}
            margin="dense"
            ref={register}
            helperText={errors?.ContractNo?.message}
            error={errors?.ContractNo ? true : false}
            defaultValue={editData ? editData?.ContractNo : ''}
            disabled={mode == 'approve-reject'}
          />

          <Controller
            render={({ onChange }) => (
              <NumberFormat
                disabled
                allowNegative={true}
                customInput={TextField}
                autoComplete="off"
                thousandSeparator
                isNumericString
                className="right"
                margin="dense"
                value={calculateMonths(
                  new Date(watch('StartDate')),
                  new Date(watch('EndDate'))
                )}
                onValueChange={values => {
                  let { floatValue } = values
                  onChange(
                    values.floatValue,
                    register(`LeaseMonth`),
                    setValue(`LeaseMonth`, floatValue),
                    calculateMonths(
                      new Date(watch('StartDate')),
                      new Date(watch('EndDate'))
                    )
                  )
                }}
                label="Lease Month"
                // InputLabelProps={{ shrink: true }}
                defaultValue={editData?.LeaseMonth ?? 0}
              />
            )}
            customInput={TextField}
            ref={register}
            control={control}
            disabled
            margin="dense"
            className="right"
            name="LeaseMonth"
            label="Lease Month"
            helperText={errors?.LeaseMonth?.message}
            error={errors?.LeaseMonth ? true : false}
            defaultValue={editData?.LeaseMonth ?? 0}
          />
          <Controller
            as={
              <NumberFormat
                allowNegative={false}
                // value={cost}
                className="left"
                required
                customInput={TextField}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                label="Monthly Lease Payment"
                onValueChange={e => {
                  // setCost(e?.floatValue)
                  // setValueItem('UnitPrice', e?.floatValue)
                  // handleAmtChange(e)
                }}
              />
            }
            onChange={(value, newValue: any) => {
              // handleAmtChange(newValue)
            }}
            name="MonthlyLeasePayment"
            control={control}
            margin="dense"
            required
            className="left"
            // helperText={errors?.MonthlyLeasePayment?.message}
            // error={errors?.MonthlyLeasePayment ? true : false}
            ref={register}
            defaultValue={0}
          />
          <Controller
            as={
              <NumberFormat
                allowNegative={false}
                // value={cost}
                className="left"
                required
                customInput={TextField}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                label="Total Lease Payment"
                onValueChange={e => {
                  // setCost(e?.floatValue)
                  // setValueItem('UnitPrice', e?.floatValue)
                  // handleAmtChange(e)
                }}
              />
            }
            onChange={(value, newValue: any) => {
              // handleAmtChange(newValue)
            }}
            name="TotalLeasePayment"
            control={control}
            margin="dense"
            required
            className="left"
            // helperText={errors?.TotalLeasePayment?.message}
            // error={errors?.TotalLeasePayment ? true : false}
            ref={register}
            defaultValue={0}
          />
          <Controller
            as={
              <NumberFormat
                allowNegative={false}
                // value={cost}
                className="left"
                required
                customInput={TextField}
                thousandSeparator
                // decimalScale={2}
                fixedDecimalScale
                isNumericString
                label="Annual Discount Rate"
                onValueChange={e => {
                  // setCost(e?.floatValue)
                  // setValueItem('UnitPrice', e?.floatValue)
                  // handleAmtChange(e)
                }}
              />
            }
            onChange={(value, newValue: any) => {
              // handleAmtChange(newValue)
            }}
            name="AnnualDiscountRate"
            control={control}
            margin="dense"
            required
            className="left"
            // helperText={errors?.MonthlyLeasePayment?.message}
            // error={errors?.MonthlyLeasePayment ? true : false}
            ref={register}
            defaultValue={0}
          />
          <Controller
            as={
              <NumberFormat
                allowNegative={false}
                disabled
                // value={cost}
                className="left"
                required
                customInput={TextField}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                label="Discounted Total Cash Flow"
                onValueChange={e => {
                  // setCost(e?.floatValue)
                  // setValueItem('UnitPrice', e?.floatValue)
                  // handleAmtChange(e)
                }}
              />
            }
            onChange={(value, newValue: any) => {
              // handleAmtChange(newValue)
            }}
            disabled
            name="DiscountedTotalCashFlow"
            control={control}
            margin="dense"
            required
            className="left"
            // helperText={errors?.TotalLeasePayment?.message}
            // error={errors?.TotalLeasePayment ? true : false}
            ref={register}
            defaultValue={0}
          />
          <Controller
            required
            as={KeyboardDatePicker}
            name="StartJournalDate"
            label="Start Journal Date"
            control={control}
            onChange={(date: Date | null) => {
              //date
            }}
            // onAccept={(date: Date | null) => {
            //   updateNewPaymentItem(date, taxEffectiveList)
            // }}
            format="dd/MM/yyyy"
            value={watch(
              formMode === 'add' ? new Date() : editData?.StartJournalDate
            )}
            margin="dense"
            allowKeyboardControl
            ref={register}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            defaultValue={editData ? editData?.StartJournalDate : new Date()}
            showTodayButton
            className="left"
            disabled={mode == 'approve-reject'}

            // minDate={
            //   //   StartJournalDateValidationBefore(
            //   // getDocumentDateValidation[0]?.MonthsBefore
            //   //)
            //   formMode === 'add'
            //     ? StartDateValidationBefore(
            //         getDocumentDateValidation[0]?.MonthsBefore
            //       )
            //     : editData?.StartDate
            // }
            // maxDate={StartDateValidationAfter(
            //   getDocumentDateValidation[0]?.MonthsAfter
            // )}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="AccountROU"
            label="Account ROU"
            className="left"
            required
            autoComplete="off"
            control={control}
            margin="dense"
            ref={register}
            helperText={errors?.AccountROU?.message}
            error={errors?.AccountROU ? true : false}
            defaultValue={editData ? editData?.AccountROU : 'Yes'}
            disabled
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            ref={register}
            defaultValue={
              formMode === 'edit' || mode === 'approve-reject'
                ? editData?.Description
                : ''
            }
            disabled={mode === 'approve-reject'}
          />

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />

          <DocUploadInput
            placeholder={previewFiles.length === 0 ? 'Attachment' : null}
            label={previewFiles.length > 0 ? 'Attachment' : null}
            files={files}
            onHandleUploadChange={handleUploadChange}
            multiple
            accept={
              'application/msword, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            mapData={'application'}
            imagePreview={
              <>
                {previewFiles?.map((v, i) => (
                  <UploadPreview
                    remove
                    key={v}
                    src={v}
                    onClick={() => removeFile(i)}
                    mediaType={
                      // files[i]?.type
                      files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                    }
                  />
                ))}
              </>
            }
            disabled={mode === 'approve-reject'}
          />
        </CardContents>
      </ContentWrapper>

      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        sections={{
          header: {
            title: 'Exit Confirmation',
          },
          body: () => (
            <>
              <div>
                Are you sure you want to exit? Your changes will not be saved.
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => history.goBack(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={errorDia}
        onClose={() => setErrorDia(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Document Numbering is empty'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>{'Please setup Document Numbering to Submit'}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/mfrs16/${CompanyID}/lease-contract`)
        }}
      />
    </>
  )
}
