import React, { lazy } from 'react'
import { BudgetSetupCostCentreForm } from './BudgetSetupCostCentreForm'
import { BudgetSetupForm } from './BudgetSetupForm'
import { BudgetSetupPeriodForm } from './BudgetSetupPeriodForm'

const BudgetSetupListing = lazy(() =>
  import('./BudgetSetupListing').then(module => ({
    default: module.BudgetSetupListing,
  }))
)

const budgetSetupRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/company-setting/budget',
    },
    component: <BudgetSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/company-setting/budget/add',
    },
    component: <BudgetSetupForm mode={'add'} />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/budget/add/:BudgetItemID/budgetcostcentre',
    },
    component: <BudgetSetupCostCentreForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/budget/add/:BudgetItemID/budgetcostcentre/:BudgetItemCostCentreID/budgetperiod',
    },
    component: <BudgetSetupPeriodForm />,
  },
]

export default budgetSetupRoutes
