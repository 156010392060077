import BigNumber from 'bignumber.js'

export const getTaxAmt = (tR, c) => {
  return new BigNumber(tR)
    .dividedBy(100)
    .times(c)
    .toNumber()
}

export const getTaxRate = tR => {
  return new BigNumber(tR).toNumber()
}

export const getTotalAmt = (tacn, c, taxA, taxR) => {
  if (tacn) {
    return new BigNumber(c).plus(taxA).toNumber()
  } else {
    return new BigNumber(taxR)
      .plus(100)
      .dividedBy(100)
      .times(c)
      .toNumber()
  }
}

export const taxAmtCalc = (tacn, taxA, taxR, baseA) => {
  if (tacn) {
    return new BigNumber(taxA).toNumber()
  } else {
    return new BigNumber(baseA)
      .times(taxR)
      .div(100)
      .toNumber()
  }
}

export const getTotalInvAmtCalc = (tacn, taxA, taxR, baseA) => {
  if (tacn) {
    return new BigNumber(taxA).toNumber()
  } else {
    return new BigNumber(baseA)
      .times(taxR)
      .div(100)
      .toNumber()
  }
}
