import React from 'react';
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';

export const customDateFormatter = date => {
  const toSplit = date?.split('-');

  const toReverse = toSplit?.reverse();

  const toJoin = toReverse?.join('/');

  return toJoin;
};

export const TableRFQ = (props: any) => {
  const { data, page, pageLength } = props;
  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) fontSize = 7;
  else fontSize = 10;

  {
    /* ================RFQ================ */
  }
  return (
    <>
      <div style={{ fontSize }}>
        <div style={{ display: 'flex' }}>
          <span style={{ width: '50%', padding: '8px' }}>
            <table style={{ borderCollapse: 'collapse' }}>
              <tr>
                <td style={{ paddingRight: '20px' }}>Document No. </td>
                <td>:</td>
                <td>
                  <b>{data?.DocNo}</b>
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: '20px' }}>Document Date</td>
                <td>:</td>
                <td>{customDateFormatter(data?.DocDate)}</td>
              </tr>

              <tr>
                <td style={{ paddingRight: '20px' }}>Page </td>
                <td>:</td>
                <td>
                  {page + 1} / {pageLength}
                </td>
              </tr>
            </table>
          </span>
        </div>
      </div>
    </>
  );
};

export default TableRFQ;
