//import { BankAccountListing } from 'containers/CompanyModule/BankAccount/BankAccountListing'
import React from 'react'
import { CBGLExportForm } from './CBGLExportForm'
import { CBGLExportList } from './CBGLExportList'

const cbGLExportRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/gl-export',
    },
    component: <CBGLExportList />,
  },
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/gl-export/add',
    },
    component: <CBGLExportForm />,
  },
]

export default cbGLExportRoutes
