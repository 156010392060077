import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Mic } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'

export const ItemVoiceTextField = ({
  mounted,
  label,
  name,
  required,
  setValue,
  controllerProps,
  customOnchange,
  handleCustomOnChange,
  record,
  setRecord,
  control,
}: any) => {
  const { transcript, listening, resetTranscript } = useSpeechRecognition()

  useEffect(() => {
    if (!!transcript || transcript !== '') {
      setValue(name, transcript)
    }
  }, [transcript])

  useEffect(() => {
    if (record) {
      resetTranscript()
      SpeechRecognition.startListening({ continuous: true })
    } else {
      SpeechRecognition.stopListening()
    }
  }, [record])

  if (mounted) {
    return (
      <Controller
        render={({ onChange, value: renderValue }) => {
          return (
            <TextField
              margin="normal"
              id="stardard-basic"
              label={label}
              name={name}
              control={control}
              {...controllerProps}
              fullWidth
              multiline
              required={required}
              value={renderValue}
              onChange={
                customOnchange
                  ? handleCustomOnChange
                  : e => {
                      onChange(e)
                    }
              }
              InputLabelProps={{
                shrink: !!renderValue,
              }}
              InputProps={{
                endAdornment: listening ? (
                  <InputAdornment position="start">
                    <IconButton
                      style={{
                        paddingRight: '0px',
                      }}
                      onClick={e => {
                        if (listening) {
                          setRecord(false)
                        } else {
                          setRecord(true)
                        }
                      }}
                    >
                      <div style={{ color: 'red' }}>
                        <Mic />
                      </div>
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <Mic
                    onClick={() => {
                      setRecord(true)
                    }}
                  />
                ),
              }}
            />
          )
        }}
        name={name}
        control={control}
        {...controllerProps}
      />
    )
  } else return null
}
