import {
  Collapse,
  createStyles,
  makeStyles,
  SvgIcon,
  SvgIconProps,
  Theme,
  withStyles,
} from '@material-ui/core'
import { fade } from '@material-ui/core/styles'
import { TransitionProps } from '@material-ui/core/transitions'
import { TreeItem, TreeItemProps } from '@material-ui/lab'
import React, { useCallback, useContext, useEffect, useState } from 'react'
// import { useSpring, animated } from '@react-spring/web'
import './MasterCOA.scss'

const TreeViewContext = require('@material-ui/lab/esm/TreeView/TreeViewContext')

export function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  )
}

export function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  )
}

export function DotIcon(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 7, height: 7 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M9.875,0.625C4.697,0.625,0.5,4.822,0.5,10s4.197,9.375,9.375,9.375S19.25,15.178,19.25,10S15.053,0.625,9.875,0.625" />
    </SvgIcon>
  )
}

export function LockIcon(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.308,7.564h-1.993c0-2.929-2.385-5.314-5.314-5.314S4.686,4.635,4.686,7.564H2.693c-0.244,0-0.443,0.2-0.443,0.443v9.3c0,0.243,0.199,0.442,0.443,0.442h14.615c0.243,0,0.442-0.199,0.442-0.442v-9.3C17.75,7.764,17.551,7.564,17.308,7.564 M10,3.136c2.442,0,4.43,1.986,4.43,4.428H5.571C5.571,5.122,7.558,3.136,10,3.136 M16.865,16.864H3.136V8.45h13.729V16.864z M10,10.664c-0.854,0-1.55,0.696-1.55,1.551c0,0.699,0.467,1.292,1.107,1.485v0.95c0,0.243,0.2,0.442,0.443,0.442s0.443-0.199,0.443-0.442V13.7c0.64-0.193,1.106-0.786,1.106-1.485C11.55,11.36,10.854,10.664,10,10.664 M10,12.878c-0.366,0-0.664-0.298-0.664-0.663c0-0.366,0.298-0.665,0.664-0.665c0.365,0,0.664,0.299,0.664,0.665C10.664,12.58,10.365,12.878,10,12.878" />
    </SvgIcon>
  )
}

export function TransitionComponent(props: TransitionProps) {
  // const style = useSpring({
  //   from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
  //   to: {
  //     opacity: props.in ? 1 : 0,
  //     transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
  //   },
  // })

  return (
    // <animated.div style={style}>
    <Collapse {...props} />
    // </animated.div>
  )
}

interface ExtraTreeItemProps extends TreeItemProps {
  unCollapse?: any
}

export const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  })
)((props: ExtraTreeItemProps) => {
  const { label, unCollapse, ...other } = props
  const context = useContext(TreeViewContext.default) as any

  const focused = context.isFocused ? context.isFocused(props.nodeId) : false
  const labelClicked = useCallback((event: any) => {
    // if (!focused) {
    //   context.focus(props.nodeId)
    // }

    const multiple =
      context.multiSelect && (event.shiftKey || event.ctrlKey || event.metaKey)

    if (!context.selectionDisabled) {
      if (multiple) {
        if (event.shiftKey) {
          context.selectRange(event, { end: props.nodeId })
        } else {
          context.selectNode(event, props.nodeId, true)
        }
      } else {
        context.selectNode(event, props.nodeId)
      }
    }
    event.stopPropagation()
    if (props.onClick) {
      props.onClick(event)
    }
  }, [])

  return (
    <TreeItem
      {...other}
      className="noColor"
      onIconClick={
        unCollapse && !!unCollapse?.find(x => x === props.nodeId)
          ? labelClicked
          : null
      }
      label={<span onClick={labelClicked}>{label}</span>}
      TransitionComponent={TransitionComponent}
    />
  )
})

export const CustomTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      //marginLeft: 7,
      //paddingLeft: 18,
      //borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  })
)((props: ExtraTreeItemProps) => {
  const { label, unCollapse, ...other } = props
  const context = useContext(TreeViewContext.default) as any

  const focused = context.isFocused ? context.isFocused(props.nodeId) : false
  const labelClicked = useCallback((event: any) => {
    // if (!focused) {
    //   context.focus(props.nodeId)
    // }

    const multiple =
      context.multiSelect && (event.shiftKey || event.ctrlKey || event.metaKey)

    if (!context.selectionDisabled) {
      if (multiple) {
        if (event.shiftKey) {
          context.selectRange(event, { end: props.nodeId })
        } else {
          context.selectNode(event, props.nodeId, true)
        }
      } else {
        context.selectNode(event, props.nodeId)
      }
    }
    event.stopPropagation()
    if (props.onClick) {
      props.onClick(event)
    }
  }, [])

  return (
    <TreeItem
      {...other}
      className="noColor"
      onIconClick={
        unCollapse && !!unCollapse?.find(x => x === props.nodeId)
          ? labelClicked
          : null
      }
      label={<span onClick={labelClicked}>{label}</span>}
      TransitionComponent={TransitionComponent}
    />
  )
})

// export function ExpandIconOnlyTreeItem(props: TreeItemProps) {
//   const { label, ...other } = props
//   const context = useContext(TreeViewContext.default) as any

//   const focused = context.isFocused ? context.isFocused(props.nodeId) : false
//   const labelClicked = useCallback((event: any) => {
//     if (!focused) {
//       context.focus(props.nodeId)
//     }

//     const multiple =
//       context.multiSelect && (event.shiftKey || event.ctrlKey || event.metaKey)

//     if (!context.selectionDisabled) {
//       if (multiple) {
//         if (event.shiftKey) {
//           context.selectRange(event, { end: props.nodeId })
//         } else {
//           context.selectNode(event, props.nodeId, true)
//         }
//       } else {
//         context.selectNode(event, props.nodeId)
//       }
//     }
//     event.stopPropagation()
//     if (props.onClick) {
//       props.onClick(event)
//     }
//   }, [])

//   return (
//     <TreeItem
//       {...other}
//       label={<span onClick={labelClicked}>{label}</span>}
//       TransitionComponent={TransitionComponent}
//     />
//   )
// }

// export const useStyles = makeStyles({
//   root: {
//     height: 110,
//     flexGrow: 1,
//     maxWidth: 400,
//   },
// })
