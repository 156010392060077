import React, { lazy } from 'react'
import generalPORoutes from './GeneralPOModule/PurchaseOrderRoutes'
import generalRFQRoutes from './GeneralRFQModule/GeneralRFQRoutes'
import generalDORoutes from './GeneralDOModule/GeneralDORoutes'
import generalGRTNRoutes from './GeneralGRTNModule/GeneralGRTNRoutes'
import generalDirectPORoutes from './DirectPOModule/DirectPORoutes'
import scheduledPurchasesRoutes from './ScheduledPurchases/ScheduledPurchasesRoutes'
import generalPRRoutes from './GeneralPRModule/GeneralPRRoutes'
import generalCentralisedPORoutes from './CentralisedPOModule/CentralisedPORoutes'
import gpDigitalReportRoutes from './GPDigitalReport/GPDigitalReportRoutes'
import supplierAccountRoutes from './SupplierAccount/SupplierAccountRoutes'

const PurchaseHomeMenu = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/HomeModule/PurchaseHomeMenu'
  ).then(module => ({ default: module.PurchaseHomeMenu }))
)

const generalPurchasesRoutes = [
  {
    props: { exact: true, path: '/general-purchase/:CompanyID' },
    component: <PurchaseHomeMenu />,
  },
  ...generalPORoutes,
  ...generalDirectPORoutes,
  ...generalPRRoutes,
  ...generalDORoutes,
  ...generalRFQRoutes,
  ...generalGRTNRoutes,
  ...scheduledPurchasesRoutes,
  ...generalCentralisedPORoutes,
  ...gpDigitalReportRoutes,
  ...supplierAccountRoutes,
]

export default generalPurchasesRoutes
