import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '../../../../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../../../../utils/redux-injectors'
import { DropdownBarState } from './types'

export const initialState: DropdownBarState = {
  anchorEl: null,
  selectedOption: 'APPROVED',
  dropdownOptions: [
    {
      name: 'Draft',
      ID: 'ACTIVE',
    },
    {
      name: 'Submitted',
      ID: 'SUBMITTED',
    },
    {
      name: 'Approved',
      ID: 'APPROVED',
    },
    {
      name: 'Rejected',
      ID: 'REJECTED',
    },
    {
      name: 'Cancelled',
      ID: 'CANCELLED',
    },
    {
      name: 'All',
      ID: 'ALL',
    },
  ],
}

const slice = createSlice({
  name: 'dropdownBar',
  initialState,
  reducers: {
    setAnchorEL(state, action: PayloadAction<any>) {
      state.anchorEl = action.payload
    },
    setSelectedOption(state, action: PayloadAction<any>) {
      state.selectedOption = action.payload
    },
  },
})

export const { actions: dropdownBarActions } = slice

export const useDropdownBarSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useDropdownBarSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
