import React from 'react'
import { ReportSettingsListing } from './ReportSettingsListing'

const reportSettingsRoutes = [
  //GENERAL LEDGER
  {
    props: {
      exact: true,
      path: '/general-ledger/financial-reporting/report-settings',
    },
    component: <ReportSettingsListing module="general-ledger" />,
  },
]

export default reportSettingsRoutes
