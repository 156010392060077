import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import BigNumber from 'bignumber.js';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { formatDate } from '../../../../helpers/StringNumberFunction/formatDate';
import {
  amtStr,
  amtStr4Dec,
} from '../../../../helpers/StringNumberFunction/numFormatter';

interface GRTNProps {
  type?: string;
  data?: any;
  dataDetail?: any;
  register?: any;
  contractTitle?: string;
  control?: any;
  editMode?: any;
  mode?: string;
  setValue?: any;
  setError?: any;
  errors?: any;
  setNoError?: any;
  clearErrors?: any;
  setState?: any;
  watch?: any;
  dummyChecker?: any;
  checked?: any;
  setChecked?: any;
  selectedDate?: Date;
  editData?: any;
  doHeaderLoading?: any;
}

export const GRTNContent = (props: GRTNProps) => {
  const {
    type,
    data = null,
    dataDetail = null,
    register,
    contractTitle,
    control,
    editMode,
    mode,
    setValue,
    setError,
    watch,
    errors,
    setNoError,
    clearErrors,
    setState,
    dummyChecker,
    checked,
    setChecked,
    editData,
    selectedDate,
  } = props;

  const [anyChanges, setChanges] = useState([]);
  const funcDummy = () => {
    const checkExisting = editMode?.DOItem?.some(s => s?.ReturnedQty);
    const checkCond = Object?.values(watch(`getDO`) || {})?.map((v: any) =>
      v?.doItem?.map(s =>
        s?.returnedQty
          ? parseFloat(s?.returnedQty)
          : editMode?.DOItem?.find(a => a?.POItemID === s?.DOItemID)
              ?.ReturnedQty,
      ),
    );

    if (!checkExisting) {
      dummyChecker(checkCond?.map(v => v?.some(s => s > 0)));
    } else {
      dummyChecker(checkCond?.map(v => v?.some(s => s > 0)));
    }
  };

  useEffect(() => {
    if ((anyChanges || editMode) && !dataDetail) {
      funcDummy();
    }
  }, [anyChanges, editMode, dataDetail]);

  const [returnExist, setThisState] = useState([]);
  const [arrayDOItemID, setStateHere] = useState([]);

  useEffect(() => {
    let checkthis = dataDetail?.DOItem?.filter(a => a?.AcceptedQty > 0)?.map(
      s => s?.DOItemID,
    );
    let asd = dataDetail?.DOItem?.filter(a => a?.AcceptedQty > 0)?.map(s => s);

    setStateHere(checkthis);

    setThisState(asd);
  }, []);

  const cond = (errors, doitemid, inindex) => {
    if (errors) {
      if (errors?.getDO) {
        if (errors.getDO[doitemid]?.doItem) {
          return !!errors?.getDO[doitemid]?.doItem[inindex];
        }
      }
    }
    return false;
  };

  const sortingFormatDate = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formattedDate = sortingFormatDate(selectedDate);

  const addEditData = (data1, num?) => {
    return data1
      ?.filter(filtered => filtered?.UpToDateAmt > 0)
      ?.filter(dateFil => dateFil?.DocDate <= formattedDate)
      ?.map((el, index) => {
        const defaultDOHeaderIDs = editData?.GRTNHeaderItem?.map(
          x => x?.DOItem?.DOHeaderID,
        );
        return (
          <>
            <CardExpansion
              key={el?.DOHeaderID}
              expansion={
                mode === 'edit'
                  ? !!defaultDOHeaderIDs.includes(el?.DOHeaderID)
                  : false
              }
              summary={
                <Grid item xs={12}>
                  <ListItemText
                    primary={
                      <>
                        <Grid container className="fw-medium desc">
                          <input
                            name={`getDO[${el?.DOHeaderID}].DOHeaderID`}
                            ref={register}
                            defaultValue={el?.DOHeaderID}
                            style={{ display: 'none' }}
                          />
                          <Grid item xs={9} className="mdLabel">
                            <span>{`${el?.DocNo}  | ${el?.DoNo}`}</span>
                          </Grid>
                          <Grid item xs={3} className="right-text">
                            <span>{formatDate(el?.DocDate)}</span>
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </Grid>
              }
            >
              <div className="rm-padding table-wrap">
                <List
                  className="core-list content-wrap"
                  style={{ display: 'contents' }}
                >
                  {el?.DOItem?.filter(v => v?.UpToDateQty > 0)?.map(
                    (inel, inindex) => {
                      return (
                        <ListItem
                          key={inindex}
                          className="table-listItem"
                          divider={inindex > 1 ? true : false}
                          style={{ padding: '0px' }}
                        >
                          <ListItemText
                            primary={
                              <Grid container className="desc">
                                <Grid
                                  item
                                  xs={1}
                                  component="span"
                                  className="desc"
                                  style={{ paddingTop: '10px' }}
                                >
                                  <span className="xxTitle">{`${inindex +
                                    1}.`}</span>
                                </Grid>
                                <Grid
                                  item
                                  xs={11}
                                  component="span"
                                  className="desc fw-medium"
                                  style={{ paddingTop: '10px' }}
                                >
                                  <div
                                    className="xxTitle text-noflow"
                                    style={{
                                      width: '100%',
                                      wordWrap: 'break-word',
                                    }}
                                  >
                                    {inel?.POItem?.Item?.Name}
                                  </div>
                                </Grid>
                              </Grid>
                            }
                            secondary={
                              <>
                                <Grid container className="desc">
                                  <Grid item xs={1}></Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    component="span"
                                    className="desc"
                                  >
                                    <td style={{ paddingTop: '10px' }}>
                                      {' '}
                                      <div
                                        className="desc"
                                        style={{ color: 'lightslategrey' }}
                                      >
                                        {' '}
                                        <span className="desc fw-medium">
                                          {inel?.POItem?.UOM?.Code}
                                        </span>
                                      </div>
                                    </td>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={3}
                                    component="span"
                                    className="desc"
                                  >
                                    <td
                                      style={{
                                        paddingTop: '10px',
                                        width: '100px',
                                      }}
                                    >
                                      <input
                                        name={`getDO[${el?.DOHeaderID}].doItem[${inindex}].doItemID`}
                                        ref={register}
                                        defaultValue={inel?.DOItemID}
                                        style={{ display: 'none' }}
                                      />
                                      <Controller
                                        as={<NumberFormat />}
                                        thousandSeparator
                                        disabled
                                        variant="outlined"
                                        label="DO Qty"
                                        className="disabled-input"
                                        autoComplete="off"
                                        customInput={TextField}
                                        allowNegative={false}
                                        fixedDecimalScale
                                        name={`getDO[${el?.DOHeaderID}].doItem[${inindex}].upToDateQty`}
                                        ref={register}
                                        control={control}
                                        decimalScale={4}
                                        defaultValue={inel?.UpToDateQty}
                                      />
                                    </td>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    component="span"
                                    className="desc"
                                  >
                                    <td
                                      style={{
                                        paddingTop: '10px',
                                        width: '100px',
                                      }}
                                    >
                                      <Controller
                                        control={control}
                                        ref={register}
                                        defaultValue={
                                          mode !== 'add'
                                            ? editMode?.filter(
                                                v =>
                                                  v?.DOItemID ===
                                                  inel?.DOItemID,
                                              )?.length > 0
                                              ? editMode
                                                  ?.filter(
                                                    v =>
                                                      v?.DOItemID ===
                                                      inel?.DOItemID,
                                                  )
                                                  ?.map(x => x?.ReturnedQty)[0]
                                              : '.0000'
                                            : '.0000'
                                        }
                                        error={
                                          cond(errors, index + num, inindex)
                                            ? true
                                            : false
                                        }
                                        name={`getDO[${el?.DOHeaderID}].doItem[${inindex}].returnedQty`}
                                        render={({ onChange, value }) => {
                                          return (
                                            <NumberFormat
                                              thousandSeparator
                                              label="Rtn Qty"
                                              allowNegative={false}
                                              customInput={TextField}
                                              className="disabled-input"
                                              name={`getDO[${el?.DOHeaderID}].doItem[${inindex}].returnedQty`}
                                              variant="outlined"
                                              decimalScale={4}
                                              fixedDecimalScale
                                              defaultValue={
                                                mode !== 'add'
                                                  ? editMode
                                                      ?.filter(
                                                        v =>
                                                          v?.DOItemID ===
                                                          inel?.DOItemID,
                                                      )
                                                      ?.map(
                                                        x => x?.ReturnedQty,
                                                      )[0] ?? 0
                                                  : '0.00'
                                              }
                                              onValueChange={values => {
                                                const {
                                                  value,
                                                  floatValue,
                                                }: any = values;

                                                const val = floatValue;
                                                let displayValue;
                                                if (
                                                  val === 0 ||
                                                  val === null ||
                                                  val === undefined
                                                ) {
                                                  displayValue = '.0000';
                                                } else {
                                                  displayValue = val.toString();
                                                }

                                                const unitPrice =
                                                  inel?.POItem?.UnitPrice || 0;

                                                const calculatedAmt =
                                                  (val ?? 0) * unitPrice;

                                                setValue(
                                                  `getDO[${el?.DOHeaderID}].doItem[${inindex}].returnedAmt`,
                                                  calculatedAmt,
                                                );

                                                let temp = anyChanges;

                                                temp.push({
                                                  doItemID: inel?.DOItemID,
                                                  value: value,
                                                });

                                                if (
                                                  temp.find(
                                                    s =>
                                                      s?.doItemID ===
                                                      inel?.DOItemID,
                                                  ) !== false
                                                ) {
                                                  temp = temp?.filter(
                                                    d =>
                                                      d?.doItemID !==
                                                      inel?.DOItemID,
                                                  );
                                                  temp?.push({
                                                    doItemID: inel?.DOItemID,
                                                    value: value,
                                                  });
                                                }
                                                setChanges(temp);

                                                if (
                                                  value !== '0.00' &&
                                                  value !== ''
                                                ) {
                                                  if (
                                                    new BigNumber(value).gt(
                                                      inel?.UpToDateQty,
                                                    )
                                                  ) {
                                                    setNoError(false);
                                                    setError(
                                                      `getDO[${el?.DOHeaderID}].doItem[${inindex}]`,
                                                      {
                                                        type: 'manual',

                                                        message:
                                                          'Quantity exceeded ordered',
                                                      },
                                                    );
                                                  } else if (
                                                    new BigNumber(value).lt(0)
                                                  ) {
                                                    setNoError(false);
                                                    setError(
                                                      `getDO[${el?.DOHeaderID}].doItem[${inindex}]`,
                                                      {
                                                        type: 'manual',
                                                        message:
                                                          'Quantity must be positive',
                                                      },
                                                    );
                                                  } else {
                                                    setNoError(true);
                                                    clearErrors(
                                                      `getDO[${el?.DOHeaderID}].doItem[${inindex}]`,
                                                    );
                                                  }
                                                  setState(el?.DOHeaderID);
                                                } else if (
                                                  value === '0.00' ||
                                                  value === ''
                                                ) {
                                                }
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    </td>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    component="span"
                                    className="desc"
                                  >
                                    <td
                                      style={{
                                        paddingTop: '10px',
                                        width: '100px',
                                      }}
                                    >
                                      <Controller
                                        control={control}
                                        ref={register}
                                        label="Rtn Amt"
                                        defaultValue={
                                          mode !== 'add'
                                            ? editMode
                                                ?.filter(
                                                  v =>
                                                    v?.DOItemID ===
                                                    inel?.DOItemID,
                                                )
                                                ?.map(x => x?.ReturnedAmt)[0] ??
                                              0
                                            : '0.00'
                                        }
                                        name={`getDO[${el?.DOHeaderID}].doItem[${inindex}].returnedAmt`}
                                        render={({ onChange, value }) => {
                                          return (
                                            <NumberFormat
                                              thousandSeparator
                                              label="Rtn Amt"
                                              allowNegative={false}
                                              customInput={TextField}
                                              className="disabled-input"
                                              name={`getDO[${el?.DOHeaderID}].doItem[${inindex}].returnedAmt`}
                                              variant="outlined"
                                              value={value}
                                              decimalScale={2}
                                              fixedDecimalScale
                                              onValueChange={values => {
                                                const {
                                                  value,
                                                  floatValue,
                                                }: any = values;

                                                const val = floatValue;
                                                let displayValue;
                                                if (
                                                  val === 0 ||
                                                  val === null ||
                                                  val === undefined
                                                ) {
                                                  displayValue = '.00';
                                                } else {
                                                  displayValue = val.toString();
                                                }
                                                const unitPrice =
                                                  inel?.POItem?.UnitPrice || 0;

                                                const calculatedQty =
                                                  (val ?? 0) / unitPrice;

                                                setValue(
                                                  `getDO[${el?.DOHeaderID}].doItem[${inindex}].returnedQty`,
                                                  calculatedQty,
                                                );

                                                let temp = anyChanges;

                                                temp.push({
                                                  doItemID: inel?.DOItemID,
                                                  value: value,
                                                });

                                                if (
                                                  temp.find(
                                                    s =>
                                                      s?.doItemID ===
                                                      inel?.DOItemID,
                                                  ) !== false
                                                ) {
                                                  temp = temp?.filter(
                                                    d =>
                                                      d?.doItemID !==
                                                      inel?.DOItemID,
                                                  );
                                                  temp?.push({
                                                    doItemID: inel?.DOItemID,
                                                    value: value,
                                                  });
                                                }
                                                setChanges(temp);
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    </td>
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid item xs={1} component="span" />
                                  <Grid item xs={11} component="div">
                                    <div className="desc text-noflow fw-medium">
                                      {!!inel?.POItem?.Remarks
                                        ? inel?.POItem?.POHeader?.DocNo +
                                          ` | ` +
                                          inel?.POItem?.Remarks
                                        : inel?.POItem?.POHeader?.DocNo +
                                          ` | ` +
                                          'No Remarks'}
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid container className="desc">
                                  <Grid
                                    item
                                    style={{
                                      marginLeft: '30px',
                                      marginTop: '10px',
                                    }}
                                  >
                                    <Controller
                                      render={({ onChange, value }) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              style={{ marginLeft: '12px' }}
                                              color="primary"
                                              onChange={e => {
                                                onChange(e.target.checked);
                                              }}
                                              checked={value}
                                            />
                                          }
                                          label={
                                            <div
                                              className="desc"
                                              style={{
                                                color: 'lightslategray',
                                              }}
                                            >
                                              Replacement
                                            </div>
                                          }
                                          ref={register}
                                        />
                                      )}
                                      name={`getDO[${el?.DOHeaderID}].Item[${inindex}].replacement`}
                                      control={control}
                                      defaultValue={
                                        mode !== 'add'
                                          ? editMode
                                              ?.filter(
                                                v =>
                                                  v?.DOItemID ===
                                                  inel?.DOItemID,
                                              )
                                              ?.map(x => x?.Replacement)[0]
                                          : true
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            }
                          />
                        </ListItem>
                      );
                    },
                  )}
                </List>
              </div>
            </CardExpansion>
            <h1
              style={{
                borderBottom: 'inset',
                maxWidth: '100%',
                margin: '5px',
              }}
            />
          </>
        );
      });
  };

  const detailData = (detailData, num) => {
    let restructure = Object.values(
      _.chain(detailData?.GRTNHeaderItem)
        .groupBy('DOItem.DOHeader.DocNo')
        .value(),
    );

    return restructure.map((v, i, arr) => {
      return (
        <CardExpansion
          key={i + num}
          summary={
            <Grid item xs={12}>
              <ListItemText
                primary={
                  <>
                    <Grid container className="fw-medium desc">
                      <Grid item xs={4} className="mdLabel">
                        <span>{v[0]?.DOItem?.DOHeader?.DoNo}</span>
                      </Grid>
                      <Grid item xs={5} className="mdLabel">
                        <span>{v[0]?.DOItem?.DOHeader?.DocNo}</span>
                      </Grid>
                      <Grid item xs={3} className="right-text">
                        <span>{formatDate(v[0]?.GRTNHeader?.DocDate)}</span>
                      </Grid>
                    </Grid>
                  </>
                }
              />
            </Grid>
          }
        >
          <div className="rm-padding table-wrap">
            <List className="core-list content-wrap full">
              {v?.map((el, index) => {
                const acceptedVal = dataDetail?.GRTNHeaderItem?.filter(
                  x => x?.DOItemID === el?.DOItemID,
                )?.map(i => i?.AcceptedQty);

                return (
                  <ListItem
                    className="table-listItem "
                    key={index}
                    divider
                    style={{ backgroundColor: 'white' }}
                  >
                    <ListItemText
                      primary={
                        <Grid container className="desc">
                          <Grid
                            item
                            xs={1}
                            component="span"
                            className="desc m-t-10"
                          >
                            {index + 1}
                          </Grid>
                          <Grid
                            item
                            xs={11}
                            component="span"
                            className="desc m-t-10"
                          >
                            <div className="text-noflow">
                              {el?.DOItem?.POItem?.Item?.Name ??
                                el?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem
                                  ?.Item?.Name}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            component="span"
                            className="desc"
                          ></Grid>
                          <Grid item xs={8} component="span" className="desc">
                            <div className="text-noflow">
                              {(el?.DOItem?.POItem?.CostCentre?.Code ??
                                el?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem
                                  ?.CostCentre?.Code) +
                                ` | ` +
                                (el?.DOItem?.POItem?.CostCentre?.Name ??
                                  el?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem
                                    ?.CostCentre?.Name)}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            component="span"
                            className="desc"
                            style={{ textAlign: 'end' }}
                          >
                            <div className="desc c-lightgrey">
                              {`Unit Price`}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            component="span"
                            className="desc"
                          ></Grid>
                          <Grid item xs={8} component="span" className="desc">
                            <div className="text-noflow">
                              {`${el?.DOItem?.POItem?.POHeader?.DocNo ??
                                el?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem
                                  ?.POHeader?.DocNo} | ${el?.DOItem?.POItem
                                ?.Remarks ??
                                el?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem
                                  ?.Remarks}`}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            component="span"
                            className="desc"
                            style={{ textAlign: 'end' }}
                          >
                            <div className="fw-medium">
                              {amtStr4Dec(
                                el?.DOItem?.UnitPrice ??
                                  el?.DOItem?.ReplacedGRTNItem?.DOItem
                                    ?.UnitPrice,
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      }
                      secondary={
                        <>
                          <Grid container className="m-t-8">
                            <Grid item xs={1} component="span" />
                            <Grid
                              item
                              xs={3}
                              component="span"
                              className="desc left-text"
                            >
                              <div className="desc c-lightgrey">PO Qty</div>
                              <div className="fw-medium">
                                {amtStr4Dec(
                                  el?.DOItem?.POItem?.OrderedQty ??
                                    el?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem
                                      ?.OrderedQty,
                                )}{' '}
                                {el?.DOItem?.POItem?.Item?.UOM?.Code ??
                                  el?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem
                                    ?.Item?.UOM?.Code}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              component="span"
                              className="desc center-text"
                            >
                              <div className="desc c-lightgrey">DO Qty</div>
                              <div className="fw-medium">
                                {amtStr4Dec(
                                  el?.DOItem?.UpToDateQty + el?.ReturnedQty,
                                )}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              component="span"
                              className="desc right-text"
                            >
                              <div className="desc c-lightgrey">Return Qty</div>
                              <div className="fw-medium">
                                {amtStr4Dec(el?.ReturnedQty)}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              component="span"
                              className="desc right-text"
                            >
                              <div className="desc c-lightgrey">Return Amt</div>
                              <div className="fw-medium">
                                {amtStr(el?.ReturnedAmt)}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container justify="flex-start">
                            <Grid item xs={1} component="span" />
                            <Grid
                              item
                              xs={11}
                              className="fw-medium desc "
                              style={{ color: '#858585' }}
                            >
                              {el?.Replacement === true ? (
                                <div className="xxTitle">
                                  <VerifiedUserIcon
                                    style={{
                                      fontSize: '13px',
                                      color: 'green',
                                      verticalAlign: 'sub',
                                    }}
                                  />
                                  {` Replacement`}
                                </div>
                              ) : null}
                            </Grid>
                          </Grid>
                        </>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </CardExpansion>
      );
    });
  };

  const SubmitData = (detailData, num) => {
    let restructure = Object.values(
      _.chain(detailData?.GRTNHeaderItem)
        .groupBy('DOItem.DOHeader.DocNo')
        .value(),
    );

    return restructure.map((v, i, arr) => {
      return (
        <div className="rm-padding table-wrap">
          <List className="core-list content-wrap full">
            {v?.map((el, index) => {
              const acceptedVal = dataDetail?.GRTNHeaderItem?.filter(
                x => x?.DOItemID === el?.DOItemID,
              )?.map(i => i?.AcceptedQty);

              return (
                <ListItem className="table-listItem " key={index} divider>
                  <ListItemText
                    primary={
                      <>
                        <Grid container className="desc">
                          <Grid
                            item
                            xs={1}
                            component="span"
                            className="desc m-t-10"
                          >
                            {index + 1}.
                          </Grid>
                          <Grid
                            item
                            xs={11}
                            component="span"
                            className="desc m-t-10"
                          >
                            <div className="fw-medium text-noflow">
                              {el?.DOItem?.POItem?.Item?.Name}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            component="span"
                            className="desc m-t-10"
                          ></Grid>
                          <Grid
                            item
                            xs={11}
                            component="span"
                            className="desc m-t-10"
                          >
                            <div className="fw-medium text-noflow">
                              {el?.DOItem?.POItem?.CostCentre?.Name}
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    }
                    secondary={
                      <>
                        <Grid container className="desc">
                          <Grid
                            item
                            xs={1}
                            component="span"
                            className="desc m-t-10"
                          />
                          <Grid
                            item
                            xs={4}
                            component="span"
                            className="desc m-t-10"
                          >
                            <div className="desc c-lightgrey">
                              UOM:
                              <span className="p-l-3 desc fw-medium">
                                {el?.DOItem?.POItem?.Item?.UOM?.Code}
                              </span>
                            </div>

                            {el?.Replacement && (
                              <div className="xxTitle">
                                <VerifiedUserIcon
                                  style={{ fontSize: '13px', color: 'green' }}
                                />
                                {` Replacement`}
                              </div>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            component="span"
                            style={{
                              textAlign: 'center',
                              marginTop: '10px',
                            }}
                          >
                            <div className="desc c-lightgrey">DO Qty</div>
                            <div>{amtStr4Dec(el?.DOItem?.AcceptedQty)}</div>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            component="span"
                            style={{
                              textAlign: 'center',
                              marginTop: '10px',
                            }}
                          >
                            <div className="desc c-lightgrey">Return Qty</div>
                            <div>{amtStr4Dec(el?.ReturnedQty)}</div>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={1} component="span"></Grid>
                          <Grid item xs={11} component="div">
                            <div className="desc c-lightgrey">Remarks</div>
                            <span className="desc text-noflow">
                              {!!el?.DOItem?.POItem?.Remarks
                                ? el?.DOItem?.POItem?.Remarks
                                : 'No Remarks'}
                            </span>
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      );
    });
  };

  return (
    <>
      {mode != 'submit' ? (
        <CardContents
          contentStyle={{ display: 'block' }}
          section={{ header: { title: 'GRTN Item' } }}
        >
          {mode === 'view'
            ? detailData(dataDetail, 0)
            : addEditData(data, data?.length - 1)}
        </CardContents>
      ) : (
        SubmitData(dataDetail, 0)
      )}
    </>
  );
};
