import React, { lazy } from 'react'

const CreditCardTypeListing = lazy(() =>
  import('./CreditCardTypeListing').then(module => ({
    default: module.CreditCardTypeListing,
  }))
)

const creditCardTypeRoutes = [
  {
    props: { exact: true, path: '/cash-book/general-setting/credit-card-type' },
    component: <CreditCardTypeListing />,
  },
]

export default creditCardTypeRoutes
