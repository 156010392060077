import React, { lazy } from 'react'
import { APDetail } from '../APDetail/APDetail'
import { APMain } from '../APMain'
import { APInvoiceStateForm } from './APInvoiceForm/APInvoiceStateForm'

export const invoiceRoutes = [
  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */

  {
    props: { exact: true, path: '/account-payable/:CompanyID/invoice' },
    // component: (
    //   <APInvoiceMain accountType="account-payable" docType="invoice" />
    // ),
    component: (
      <APMain
        apSubmenu="invoice"
        routeSegments="Invoice"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/invoice/:DocumentID/detail`,
    },
    component: (
      <APDetail
        apSubmenu="invoice"
        routeSegments="Invoice"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/invoice/add`,
    },
    component: (
      <APInvoiceStateForm
        accountType="account-payable"
        docType="invoice"
        routeSegments="Invoice"
        formMode="add"
        // detailMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/invoice/:InvoiceID/edit`,
    },
    component: <APInvoiceStateForm formMode="edit" />,
  },
]

export default invoiceRoutes
