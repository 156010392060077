import StandardCardContent from '@ifca-root/react-component/src/components/CardList/StandardCardContent'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Beenhere } from '@material-ui/icons'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TreeItem from '@material-ui/lab/TreeItem'
import TreeView from '@material-ui/lab/TreeView'
import DisableBadge from 'assets/icons/disable-badge.svg'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { useGetRoleQuery } from 'generated/graphql'
import { IAction } from 'models'
import React, { Reducer, useEffect, useReducer } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  accessSecurityPerm,
  accPayablePerm,
  accReceivablePerm,
  cashBookPerm,
  commonSettingPerm,
  companySetupPerm,
  generalLedgerPerm,
  generalPurchasePerm,
  inventoryControlPerm,
  RenderPermissionTree,
} from './RoleTree'
interface Props {
  // roleName: string;
  selected: any
  companySetupTree: RenderPermissionTree
  commonSettingTree: RenderPermissionTree
  accessSecurityTree: RenderPermissionTree
  generalLedgerTree: RenderPermissionTree
  cashBookTree: RenderPermissionTree
  accPayableTree: RenderPermissionTree
  accReceivableTree: RenderPermissionTree
  inventoryControlTree: RenderPermissionTree
  generalPurchaseTree: RenderPermissionTree
}

interface RoleDetail {
  roleName: string
}

export const RoleDetail = () => {
  let history = useHistory()
  let location = useLocation()

  const { ID, mode: pageMode }: any = useParams()
  const {
    data: { getRole } = { getRole: [] },

    loading,
    error,
  } = useGetRoleQuery({
    variables: {
      ID: ID,
    },
  })

  const initialState: Props = {
    companySetupTree: companySetupPerm,
    commonSettingTree: commonSettingPerm,
    accessSecurityTree: accessSecurityPerm,
    generalLedgerTree: generalLedgerPerm,
    cashBookTree: cashBookPerm,
    accPayableTree: accPayablePerm,
    accReceivableTree: accReceivablePerm,
    inventoryControlTree: inventoryControlPerm,
    generalPurchaseTree: generalPurchasePerm,
    selected: [],
  }
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const selectedCount = (children, selected) => {
    let count = 0
    children?.map(x => {
      if (selected?.includes(x?.id)) count++
    })
    return count
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (getRole) {
      dispatch({
        type: 'selected',
        payload: getRole[0]?.rolePerm?.map(x => x.permName),
      })
    }
  }, [getRole])

  const [expanded, setExpanded] = React.useState<string[]>([])
  const renderTree = (nodes: RenderPermissionTree, modules: string) => (
    <TreeItem
      key={nodes.id}
      nodeId={`${nodes.id}`}
      label={
        <List className="" disablePadding>
          <ListItem>
            <ListItemIcon style={{ minWidth: '46px' }}>
              {state?.selected?.some(item => item === nodes.id) ? (
                <Beenhere style={{ color: '#FF9800' }} />
              ) : (
                <img
                  src={DisableBadge}
                  alt="disable"
                  style={{ width: '1em', height: '1em', fontSize: '1.5rem' }}
                  loading="lazy"
                />
              )}
            </ListItemIcon>

            <ListItemText
              primary={
                <span
                  className={`xsTitle ${
                    state.selected?.some(item => item === nodes.id)
                      ? 'color-orange'
                      : 'color-grey'
                  } flex-space`}
                >
                  {nodes.label}
                </span>
              }
              secondary={
                <>
                  {nodes.children?.length > 0 && (
                    <span className="desc flex-space">
                      {selectedCount(nodes.children, state.selected)}
                      <span>/</span>
                      {nodes.children?.length} Accessible
                    </span>
                  )}
                </>
              }
            />
          </ListItem>
        </List>
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map(node => renderTree(node, modules))
        : null}
    </TreeItem>
  )
  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  return (
    <>
      {loading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(
            location?.pathname === `/access-security/role/detail/${ID}`
              ? '/access-security/role'
              : '/general-ledger/access-security/role'
          )
        }
        smTitle="General Ledger"
        title={user?.accountName}
        routeSegments={[
          { name: 'Access Security' },
          { name: 'Access Security' },
          { name: 'Role', current: true },
        ]}
        rightRouteSegments={[{ name: 'Details', current: true }]}
      />
      <ContentWrapper float>
        <StandardCardContent
          section={{
            header: {
              title: (
                <ListItemText
                  primary={
                    <>
                      <span style={{ display: 'flex', width: '100%' }}>
                        <span className="smTitle  flex-space">
                          {getRole[0]?.name}
                        </span>
                      </span>
                    </>
                  }
                />
              ),
            },
          }}
        >
          <TreeView
            expanded={expanded}
            onNodeToggle={handleToggle}
            className="tree-view-list"
            defaultExpandIcon={<ExpandMoreIcon />}
            defaultCollapseIcon={<ExpandLessIcon />}
            multiSelect
          >
            {renderTree(state.companySetupTree, 'company-setup')}
            {renderTree(state.accessSecurityTree, 'access-security')}
            {renderTree(state.commonSettingTree, 'common-setting')}
            {renderTree(state.cashBookTree, 'cash-book')}
            {renderTree(state.accPayableTree, 'account-payable')}
            {renderTree(state.accReceivableTree, 'account-receivable')}
            {renderTree(state.inventoryControlTree, 'inventory-control')}
            {renderTree(state.generalPurchaseTree, 'general-purchase')}
          </TreeView>
        </StandardCardContent>
      </ContentWrapper>
    </>
  )
}
