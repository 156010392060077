import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { ListItem, ListItemText } from '@material-ui/core'
import React from 'react'

export const ExitConfirmationDialog = (props: any) => {
  const { openExitConf, setOpenExitConf, onConfirm, hasChanges } = props

  return (
    <CommonDialog
      fullWidth={true}
      open={openExitConf}
      onClose={() => setOpenExitConf(false)}
      sections={{
        header: {
          children: (
            <ListItem className="remove-padding">
              <ListItemText
                primary={
                  <>
                    <span
                      className="smTitle flex-space"
                      style={{ color: '#ffb11f' }}
                    >
                      {'Exit Confirmation'}
                    </span>
                  </>
                }
              />
            </ListItem>
          ),
        },
        body: () => (
          <div>
            <span className="mdDesc" style={{ whiteSpace: 'initial' }}>
              {'Are you sure you want to exit? Your changes will not be saved.'}
            </span>
          </div>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () => setOpenExitConf(false),
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: onConfirm,
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
