import React, { lazy } from 'react'
import { CompanyWarehouseListing } from './WarehouseCompanyListing'
import { WarehouseUserAccessListing } from './WarehouseUserAssignment/WarehouseUserAccessListing'
import { WarehouseItemAccessListing } from './WarehouseItemAssignment/WarehouseItemAccessListing'

const WarehouseListing = lazy(() =>
  import('./WarehouseListing').then(module => ({
    default: module.WarehouseListing,
  }))
)

const WarehouseForm = lazy(() =>
  import('./WarehouseForm').then(module => ({
    default: module.WarehouseForm,
  }))
)

const WarehouseDetail = lazy(() =>
  import('./WarehouseDetail').then(module => ({
    default: module.WarehouseDetail,
  }))
)

const warehouseRoutes = [
  // {
  //   props: {
  //     exact: true,
  //     path: '/inventory-control/general-setting/warehouse',
  //   },
  //   component: <CompanyWarehouseListing />,
  // },
  {
    props: {
      exact: true,
      path: '/inventory-control/general-setting/warehouse',
    },
    component: <WarehouseListing />,
  },
  {
    props: {
      exact: true,
      path: '/inventory-control/general-setting/warehouse/add',
    },
    component: <WarehouseForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/inventory-control/general-setting/warehouse/:WarehouseID/edit',
    },
    component: <WarehouseForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/inventory-control/general-setting/warehouse/:WarehouseID/assign',
    },
    component: <WarehouseUserAccessListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/general-setting/warehouse/:WarehouseID/assign-stock-item',
    },
    component: <WarehouseItemAccessListing />,
  },
  {
    props: {
      exact: true,
      path: '/inventory-control/general-setting/warehouse/:WarehouseID/detail',
    },
    component: <WarehouseDetail />,
  },
]

export default warehouseRoutes
