import { List, ListItem, ListItemText } from '@material-ui/core'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'

export const DetailAllocationTableContent = (props: any) => {
  const { listItem, hasSequence } = props

  return (
    <>
      <div className="rm-padding table-wrap">
        <List className="core-list content-wrap full " disablePadding>
          {listItem === undefined || !listItem || listItem?.length === 0 ? (
            <EmptyList
              title="No Allocation found"
              subtitle="Add allocation from listing menu."
            />
          ) : (
            listItem?.map((el, index) => (
              <ListItem
                className="table-listItem "
                key={index}
                divider
                style={{ backgroundColor: 'white' }}
              >
                <ListItemText
                  primary={
                    <>
                      {hasSequence === true && (
                        <span className="desc" style={{ width: '12px' }}>
                          {`${index + 1}.`}
                        </span>
                      )}

                      <span
                        className="desc date-width"
                        style={{ fontSize: '11px' }}
                      >
                        {formatDate(el?.[`${el?.CreditOrDebit}DocDate`])}
                      </span>
                      <span
                        className="desc flex-space"
                        style={{ fontSize: '11px', fontWeight: 700 }}
                      >
                        {el?.[`${el?.CreditOrDebit}DocNo`]}
                      </span>
                      <span className="desc">
                        <span className="xsTitle">
                          {amtStr(el?.AllocationAmt)}
                        </span>
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      <div>
                        <span
                          className="desc medium"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {el?.Description}
                        </span>
                      </div>
                    </>
                  }
                />
              </ListItem>
            ))
          )}
        </List>
      </div>
    </>
  )
}
