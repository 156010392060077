import React, { lazy } from 'react'
import { ExpenseItemDetail } from './ExpenseItemDetail'

const ExpenseItemSubmenu = lazy(() =>
  import('./ExpenseItemSubmenu').then(module => ({
    default: module.ExpenseItemSubmenu,
  }))
)
const ExpenseItemListing = lazy(() =>
  import('./ExpenseItemListing').then(module => ({
    default: module.ExpenseItemListing,
  }))
)

const ExpenseItemForm = lazy(() =>
  import('./ExpenseItemForm').then(module => ({
    default: module.ExpenseItemForm,
  }))
)

const expenseItemRoutes = [
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/item-submenu',
    },
    component: <ExpenseItemSubmenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/item-submenu/:ExpenseClassID/item-listing',
    },
    component: <ExpenseItemListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/item-submenu/:ExpenseClassID/item-listing/item-form/add',
    },
    component: <ExpenseItemForm formMode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/item-submenu/:ExpenseClassID/item-listing/item-form/:ExpenseItemID/edit',
    },
    component: <ExpenseItemForm formMode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/item-submenu/:ExpenseClassID/item-listing/:ExpenseItemID/detail',
    },
    component: <ExpenseItemDetail />,
  },
]

export default expenseItemRoutes
