import React, { lazy } from 'react'

const CreditorTypeSubmenu = lazy(() =>
  import('./CreditorTypeSubmenu').then(module => ({
    default: module.CreditorTypeSubmenu,
  }))
)

const CreditorTypeListing = lazy(() =>
  import('./CreditorTypeListing').then(module => ({
    default: module.CreditorTypeListing,
  }))
)

const ContractorListing = lazy(() =>
  import('./ContractorListing').then(module => ({
    default: module.ContractorListing,
  }))
)

const creditorTypeRoutes = [
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/creditor-type-submenu',
    },
    component: <CreditorTypeSubmenu />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/account-payable/general-setting/creditor-type-submenu/supplier-listing',
  //   },
  //   component: <CreditorTypeListing />,
  // },

  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/creditor-type-submenu/supplier',
    },
    component: <CreditorTypeListing type="Supplier" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/creditor-type-submenu/contractor',
    },
    component: <CreditorTypeListing type="Contractor" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/creditor-type-submenu/consultant',
    },
    component: <CreditorTypeListing type="Consultant" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/creditor-type-submenu/agent',
    },
    component: <CreditorTypeListing type="Agent" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/creditor-type-submenu/:CreditorTypeID/contractor-listing',
    },
    component: <ContractorListing />,
  },
]

export default creditorTypeRoutes
