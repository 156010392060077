import digitalReportRoutes from 'containers/DigitalReportModule/DigitalReportRoutes'
import React, { lazy } from 'react'
import auditAdjustmentRoutes from './AuditAdjustment/AuditAdjustmentEntriesRoutes'
import externalJournalRoutes from './ExternalJournalModule/ExternalJournalRoutes'
import GLBudgetRoutes from './GLBudget/GLBudgetRoutes'
import journalRoutes from './Journal/JournalRoutes'
import monthYearEndClosingRoutes from './MonthYearEndClosing/MonthYearEndClosingRoutes'
import recurringjournalRoutes from './RecurringJournal/RecurringJournalRoutes'
import routinejournalRoutes from './RoutineJournal/RoutineJournalRoutes'
import generateRecurringJournalRoutes from './GenerateRecurringJournal/GenerateRecurringRoutes'
import journalEnquiryRoutes from './JournalEnquiry/JournalEnquiryRoutes'

const GeneralLedgerSubmenu = lazy(() =>
  import('./GeneralLedgerSubmenu').then(module => ({
    default: module.GeneralLedgerSubmenu,
  }))
)

const generalLedgerRoutes = [
  {
    props: { exact: true, path: '/general-ledger/:CompanyID/' },
    component: <GeneralLedgerSubmenu />,
  },
  ...journalEnquiryRoutes,
  ...journalRoutes,
  ...auditAdjustmentRoutes,
  ...routinejournalRoutes,
  ...recurringjournalRoutes,
  ...generateRecurringJournalRoutes,
  ...externalJournalRoutes,
  ...GLBudgetRoutes,
  ...monthYearEndClosingRoutes,
  // ...digitalReportRoutes,
]

export default generalLedgerRoutes
