import React, { lazy } from 'react'

const SupplierProfileListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/SupplierProfile/SupplierProfileListing'
  ).then(module => ({
    default: module.SupplierProfileListing,
  }))
)

const SupplierProfileDetail = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/SupplierProfile/SupplierProfileDetail'
  ).then(module => ({
    default: module.SupplierProfileDetail,
  }))
)

const SupplierProfileForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/SupplierProfile/SupplierProfileForm'
  ).then(module => ({
    default: module.SupplierProfileForm,
  }))
)

const SupplierProfileAssignment = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/SupplierProfile/SupplierProfileAssignment'
  ).then(module => ({
    default: module.SupplierProfileAssignment,
  }))
)

const supplierProfileRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/supplier-profile',
    },
    component: <SupplierProfileListing />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/supplier-profile/add',
    },
    component: <SupplierProfileForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/general-setting/supplier-profile/:CreditorAccountID/edit',
    },
    component: <SupplierProfileForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/general-setting/supplier-profile/:CreditorAccountID/detail',
    },
    component: <SupplierProfileDetail mode="view" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/general-setting/supplier-profile/:CreditorAccountID/assign',
    },
    component: <SupplierProfileAssignment mode="assign" />,
  },
]

export default supplierProfileRoutes
