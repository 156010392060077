import React, { lazy } from 'react'
import { GeneralGRTNDetail } from '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralGRTNModule/GeneralGRTNDetail'
import { GeneralGRTNForm } from '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralGRTNModule/GeneralGRTNForm'
import { GeneralGRTNListing } from '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralGRTNModule/GeneralGRTNListing'
import { GeneralGRTNMain } from '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralGRTNModule/GeneralGRTNMain'

import { PDFPreviewGP } from '@account-root/procurement-react/src/components/PDFTemplate/PDFPreviewGP'

const generalGRTNRoutes = [
  {
    props: {
      exact: true,
      // path: '/general-purchase/:CompanyID/good-return-note',
      path: !'/general-purchase/:CompanyID/good-return-note'
        ? '/general-purchase/:CompanyID/good-return-note'
        : '/general-purchase/:CompanyID/good-return-note',
    },
    component: <GeneralGRTNMain />,
  },
  {
    props: {
      exact: true,
      // path: '/general-purchase/:CompanyID/good-return-note/add',
      path: '/general-purchase/:CompanyID/good-return-note/add',
    },
    component: <GeneralGRTNForm mode="add" />,
  },
  {
    props: {
      exact: true,
      // path: '/general-purchase/:CompanyID/good-return-note/add',
      path: '/general-purchase/:CompanyID/good-return-note/edit/:GRTNHeaderID',
    },
    component: <GeneralGRTNForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/good-return-note/:GRTNHeaderID/detail',
    },
    component: <GeneralGRTNDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/good-return-note/:GRTNHeaderID/detail/preview',
    },
    component: (
      <PDFPreviewGP
        accountType="/"
        docType="good-return-note"
        backPath="good-return-note"
      />
    ),
  },
]

export default generalGRTNRoutes
