export const addressFormat = (addr: any) => {
  if (
    addr?.country === 'Singapore' ||
    addr?.country === 'SINGAPORE' ||
    addr?.country === 'SG'
  ) {
    return (
      retAdd(addr?.address) +
      retAdd(addr?.country, true) +
      ' ' +
      retAdd(addr?.postCode, true)
    );
  } else {
    return (
      retAdd(addr?.address) +
      retAdd(addr?.city) +
      retAdd(addr?.postCode) +
      retAdd(addr?.state) +
      retAdd(addr?.country, true)
    );
  }
};

export const retAdd = (addLine, lastLine = false) => {
  if (addLine && lastLine) {
    return addLine;
  } else if (addLine) {
    return addLine + ', ';
  } else return '';
};
