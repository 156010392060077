import { Button, List, ListItem, ListItemText } from '@material-ui/core'
import React from 'react'
import { CommonDialog } from '../Dialog/CommonDialog'
import { StandardDialog } from '../Dialog/StandardDialog'
import { Footer } from '../Footer/Footer'
import SnackBarMsg from '../SnackBar/SnackBarMsg'
import { InputUpload } from './InputUpload'
import ExcelIcon from '@account-root/account-react/src/assets/icons/excel-white.svg'
import IconText from '../Typography/IconText'

interface ISnackBarProps {
  openSnackBar: boolean
  snackBarMessage: string
  setOpenSnackBar?: React.Dispatch<boolean>
  onClickButton?: any
  label?: string
}
interface IExcelInputProps {
  openUpload: boolean
  setOpenUpload: any
  upload: string
  template: any
  onDownloadTemplate: any
  onSelectFile: any
  setUpload: any
  setTemplate: any
  onUploadTemplate: any
  snackBarProps: ISnackBarProps
  showDupErrors?: boolean
  duplicateItems?: any
  errorDia?: boolean
  setErrorDia?: React.Dispatch<boolean>
  resetFileUpload?: any
  showFooter?: boolean
  downloadButton?: boolean
  num?: any
  setNum?: any
}

export const ExcelInput = (props: IExcelInputProps) => {
  const {
    openUpload,
    setOpenUpload,
    upload,
    onDownloadTemplate,
    onSelectFile,
    setUpload,
    setTemplate,
    onUploadTemplate,
    snackBarProps,
    duplicateItems,
    showDupErrors,
    errorDia,
    setErrorDia,
    resetFileUpload,
    showFooter = true,
    downloadButton = false,
    num,
    setNum,
  } = props

  const {
    openSnackBar,
    snackBarMessage,
    setOpenSnackBar,
    onClickButton,
    label,
  } = snackBarProps

  return (
    <div>
      {showFooter && (
        <Footer
          options={[
            {
              name: 'Download Template',
              color: 'primary',
              onClick: () => {
                num ? setNum(num + 1) : null
                onDownloadTemplate()
              },
            },
            {
              onClick: () => {
                setOpenUpload(true)
              },
              name: 'Upload Excel',
              color: 'primary',
            },
          ]}
        />
      )}

      {showDupErrors && errorDialog(errorDia, setErrorDia, duplicateItems)}

      <CommonDialog
        fullWidth={true}
        open={openUpload}
        onClose={() => {
          resetFileUpload()
          setUpload('')
          setTemplate([])
          setOpenUpload(false)
        }}
        sections={{
          header: {
            title: 'Import Excel Template',
            rightText: 'Upload',
          },
          body: () => (
            <>
              <InputUpload
                accept={'.xlsx, .xls, .cxv'}
                value={upload}
                label="File"
                required
                multiline={true}
                handleUpload={e => onSelectFile(e)}
                className="p-b-20"
              />
            </>
          ),

          leftFooterIcon: !!downloadButton && (
            <span style={{ width: '100%' }}>
              <Button
                variant="contained"
                size="small"
                className="btn-dialog btn-primary"
                onClick={() => {
                  onDownloadTemplate()
                }}
                style={{
                  float: 'left',
                }}
                color="primary"
              >
                <IconText
                  icon={
                    <img
                      src={ExcelIcon}
                      style={{ width: '19px', paddingRight: '5px' }}
                    />
                  }
                  font="mdDesc"
                  children={'Download'}
                />
              </Button>
            </span>
          ),

          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => [
                    setOpenUpload(false),
                    setUpload(''),
                    setTemplate([]),
                    resetFileUpload(),
                  ],
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Upload',
                props: {
                  onClick: () => [onUploadTemplate()],
                  variant: 'contained',
                  color: 'primary',
                  disabled: !!!upload,
                },
              },
            ],
          },
        }}
      />

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        setOpen={setOpenSnackBar}
        label={label}
        onClickButton={onClickButton}
      />
    </div>
  )
}

const errorDialog = (
  openErrDialog: boolean,
  setOpenErrDia: React.Dispatch<boolean>,
  duplicateItems: string[]
) => (
  <CommonDialog
    fullWidth={true}
    open={openErrDialog}
    onClose={() => {
      setOpenErrDia(false)
    }}
    sections={{
      header: {
        title: 'Item(s) exists',
      },
      body: () => (
        <>
          <div>
            <span className="mdDesc text-noflow">
              Unable to upload the following item(s).
              <List>
                {duplicateItems?.map((v, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      style={{ height: '10px' }}
                      primary={
                        <>
                          <span className="mdDesc flex-space">{`- ${v}`}</span>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </span>
            <span className="desc text-noflow">
              Please remove duplicated items and try again.
            </span>
          </div>
        </>
      ),
      footer: {
        actions: [
          {
            displayText: 'Close',
            props: {
              onClick: () => {
                setOpenErrDia(false)
              },
              variant: 'contained',
              color: 'primary',
            },
          },
        ],
      },
    }}
  />
)
