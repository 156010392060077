import React from 'react'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { format } from 'date-fns'

export const TableCBV = (props: any) => {
  const { data, page, pageLength } = props
  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 7
  } else {
    fontSize = 10
  }

  return (
    <>
      <div style={{ fontSize }}>
        <div style={{ display: 'flex' }}>
          {/* Left Column */}
          <span
            style={{ width: '60%', overflowWrap: 'anywhere', padding: '8px' }}
          >
            <table style={{ marginTop: '5px', borderCollapse: 'collapse' }}>
              <tr>
                <td>
                  <b>{data?.PayeeName ?? data?.ReceivedFrom}</b>
                </td>
              </tr>
              <tr></tr>
              <tr></tr>
              <tr>
                <td>{data?.Description}</td>
              </tr>
            </table>
          </span>

          {/* Right Column */}
          <span style={{ width: '40%', padding: '8px' }}>
            <table style={{ borderCollapse: 'collapse' }}>
              <>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Document No. </td>
                  <td>:</td>
                  <td>
                    <b>{data?.DocNo}</b>
                  </td>
                </tr>

                <tr>
                  <td style={{ paddingRight: '20px' }}>Document Date </td>
                  <td>:</td>
                  <td>{format(new Date(data?.DocDate), 'dd/MM/yyyy')}</td>
                </tr>

                <tr>
                  <td style={{ paddingRight: '20px' }}>Reference No. </td>
                  <td>:</td>
                  <td>{!data?.RefNo ? '' : data?.RefNo}</td>
                </tr>

                <tr>
                  <td>Payment Method </td>
                  <td>:</td>
                  <td>{data?.PaymentMethod?.Name ?? ''}</td>
                  <tr></tr>
                </tr>

                <tr>
                  <td>Bank Code </td>
                  <td>:</td>
                  <td>{data?.BankAccount?.Code ?? ''}</td>
                  <tr></tr>
                </tr>
                <tr>
                  <td>Bank A/C No. </td>
                  <td>:</td>
                  <td>{data?.BankAccount?.AccountNo ?? ''}</td>
                  <tr></tr>
                </tr>
                {/* <tr>
                  <td>Page No. </td>
                  <td>:</td>
                  <td>NA</td>
                </tr> */}

                {data?.DueDate && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Due Date </td>
                    <td>:</td>
                    <td>{format(new Date(data?.DueDate), 'dd/MM/yyyy')}</td>
                  </tr>
                )}

                <tr>
                  <td style={{ paddingRight: '20px' }}>Page </td>
                  <td>:</td>
                  <td>{`${page + 1} / ${pageLength}`}</td>
                </tr>
              </>
            </table>
          </span>
        </div>
      </div>
    </>
  )
}

export default TableCBV
