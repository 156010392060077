import React, { lazy } from 'react'

const MonthEndClosingForm = lazy(() =>
  import('./MonthEndClosingForm').then(module => ({
    default: module.MonthEndClosingForm,
  }))
)
const YearEndClosingForm = lazy(() =>
  import('./YearEndClosingForm').then(module => ({
    default: module.YearEndClosingForm,
  }))
)

const monthYearEndClosingRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/month-end-closing',
    },
    component: <MonthEndClosingForm />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/year-end-closing',
    },
    component: <YearEndClosingForm />,
  },
]

export default monthYearEndClosingRoutes
