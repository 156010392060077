import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { TextField } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'

export function DeleteFavoriteDialog(props) {
  const {
    openFavoriteDeleteDialog,
    setOpenFavoriteDeleteDialog,
    dialogTitle,
    menuFav,
    deleteMutation,
    favRefetchQuery,
    favID,
  } = props

  return (
    <CommonDialog
      fullWidth={true}
      open={openFavoriteDeleteDialog}
      onClose={() => setOpenFavoriteDeleteDialog(false)}
      sections={{
        header: {
          dynamic: (
            <div className="">
              <div className="dialog-dynamic-content">
                <div className="session">
                  <div className="flex session">
                    <div
                      className="dialog-title c-orange"
                      style={{ fontSize: '12px', fontWeight: 'bold' }}
                    >
                      {`${dialogTitle}`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="infoline-content">
                {' '}
                <div className="xsTitle">{menuFav?.obj?.Name}</div>
                <div className="flex-space"></div>
                <div className="xsTitle rightText">Delete</div>
              </div>
            </div>
          ),
        },
        body: () => (
          <div className="content-wrap full">
            <span className="mdDesc full">Confirm to delete?</span>
          </div>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => setOpenFavoriteDeleteDialog(false),
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: () =>
                  deleteMutation({
                    variables: {
                      favID: favID,
                    },
                    refetchQueries: favRefetchQuery,
                  }),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}

export function AddFavoriteDialog(props) {
  const {
    openFavoriteDialog,
    setOpenFavoriteDialog,
    favRegister,
    favControl,
    favNames,
    favErrors,
    favWatch,
    dialogTitle,
    handleFavSubmit,
    onSubmitFavorite,
  } = props

  return (
    <CommonDialog
      fullWidth={true}
      open={openFavoriteDialog}
      onClose={() => setOpenFavoriteDialog(false)}
      sections={{
        header: {
          dynamic: (
            <div className="">
              <div className="dialog-dynamic-content">
                <div className="session">
                  <div className="dialog-title c-orange title">
                    {`${dialogTitle}`}
                  </div>
                </div>
              </div>
              <div className="infoline-content">
                <>
                  <div className="xsTitle">Add Favorite</div>
                </>
              </div>
            </div>
          ),
        },
        body: () => (
          <>
            <div className="content-container">
              <Controller
                as={TextField}
                name="Name"
                label="Name"
                autoComplete="off"
                multiline={true}
                fullWidth
                ref={favRegister}
                control={favControl}
                helperText={
                  favNames?.includes(favWatch('Name'))
                    ? 'Favorite name already exist'
                    : favErrors?.Name?.message
                }
                error={
                  favErrors?.Name || favNames?.includes(favWatch('Name'))
                    ? true
                    : false
                }
              />
            </div>
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => setOpenFavoriteDialog(false),
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Save',
              props: {
                onClick: () => handleFavSubmit(onSubmitFavorite)(),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
