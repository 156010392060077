//import { BankAccountListing } from 'containers/CompanyModule/BankAccount/BankAccountListing'
import React from 'react'
import { APGLExportForm } from './APGLExportForm'
import { APGLExportList } from './APGLExportList'

const apGLExportRoutes = [
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/gl-export',
    },
    component: <APGLExportList />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/gl-export/add',
    },
    component: <APGLExportForm />,
  },
]

export default apGLExportRoutes
