import { getAccessToken } from 'AccessToken'
import React from 'react'
import { Redirect, Route, useRouteMatch } from 'react-router'

export const PrivateRoute = ({ children, ...rest }) => {
  const AccessToken = getAccessToken()
  const test = ''
  let settings = useRouteMatch({
    path: [
      '/authentication/404',
      '/forgot-password',
      '/user/activate/:token',
      '/reset-password',
    ],
    strict: true,
    sensitive: true,
  })
  return (
    <Route
      {...rest}
      render={
        ({ location }) =>
          // children
          // AccessToken ? (
          children
        // ) : (
        //   <Redirect
        //     to={{
        //       pathname: '/login',
        //     }}
        //   />
        // )
      }
    />
  )
}
