export const CreditorProfileTemplateForm = [
  {
    header: {
      header: 'CreditorCategory',
      width: 17,
    },
    dataType: 'CreditorCategory',
  },
  {
    header: {
      header: 'CompanyRegNo',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'TaxIdentificationNo',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'CompanyName',
      width: 40,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'CreditorType',
      width: 20,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'ContactNo',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'Email',
      width: 20,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'CreditTerm',
      width: 15,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'Tax',
      width: 15,
    },
    dataType: 'Tax',
  },
  {
    header: {
      header: 'Region',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'GSTRegNo',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'Remark',
      width: 30,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'BankAccountNo',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'BankName',
      width: 40,
    },
    dataType: 'BankName',
  },
  {
    header: {
      header: 'SWIFTCode',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'PayeeName',
      width: 25,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'Address',
      width: 40,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'Country',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'State',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'City',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'PostCode',
      width: 17,
    },
    dataType: 'text',
  },
]
