import { Divider, Grid, ListItem, ListItemText } from '@material-ui/core'
import { amtStr, amtStr4Dec } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'

export const APItemDetailContent = (props: any) => {
  const { listItem } = props

  return (
    <>
      <div className="rm-padding table-wrap ">
        <Grid container className="table-content">
          <Grid
            component="span"
            item
            xs={1}
            className="fw-thin desc"
            style={{ color: 'grey' }}
          >
            No.
          </Grid>
          <Grid
            component="span"
            item
            xs={5}
            className="fw-thin desc flex-space"
            style={{ color: 'grey' }}
          >
            Item
          </Grid>
          <Grid
            component="span"
            item
            xs={3}
            className="center-text fw-thin desc"
            style={{ color: 'grey' }}
          >
            Qty
          </Grid>

          <Grid
            component="span"
            item
            xs={3}
            className="right-text fw-thin desc"
            style={{ color: 'grey' }}
          >
            Amt
          </Grid>
        </Grid>
        {listItem
          ?.filter(x => x?.DocAmt !== 0)
          ?.map((el, index) => {
            return (
              <>
                <ListItem className="table-listItem" key={index} divider>
                  <Grid container>
                    <ListItemText
                      primary={
                        <>
                          <Grid container>
                            <Grid item xs={1} component="span">
                              <span className="desc text-noflow">
                                {index + 1}.
                              </span>
                            </Grid>
                            <Grid item xs={5} component="span">
                              <span className="xxTitle text-noflow">
                                {el?.ExpenseItem?.ItemName}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              component="span"
                              style={{ textAlign: 'center' }}
                            >
                              <span className="xxTitle">
                                {amtStr4Dec(el?.Quantity) + ` ` + el?.UOM?.Code}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              component="span"
                              style={{ textAlign: 'right' }}
                            >
                              <span className="xxTitle">
                                {amtStr(el?.DocAmt)}
                              </span>
                            </Grid>
                          </Grid>
                        </>
                      }
                      secondary={
                        <>
                          <Grid container component="span">
                            <Grid item xs={1} component="span"></Grid>
                            <Grid
                              item
                              xs={11}
                              component="span"
                              className="desc flex-space"
                            >
                              <div className="desc text-noflow">
                                Unit Price:
                                <span className="p-l-3 desc text-noflow">
                                  {amtStr4Dec(el?.UnitPrice)}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={1} component="span"></Grid>
                            <Grid item xs={11} component="div">
                              <span className="desc text-noflow">
                                Tax Amount:
                              </span>
                              <span className="p-l-3 desc text-noflow">
                                {amtStr(el?.TaxAmt) +
                                  ` | ` +
                                  el?.TaxScheme?.Code +
                                  ` ` +
                                  el?.TaxRate +
                                  '%'}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={1} component="span"></Grid>
                            <Grid item xs={11} component="div">
                              <span className="desc text-noflow">
                                {el?.CostCentre?.Code}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={1} component="span"></Grid>
                            <Grid item xs={11} component="div">
                              <div className="desc text-noflow">
                                {el?.Remark ?? ''}
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      }
                    />
                  </Grid>
                </ListItem>

                {index !== listItem?.length - 1 && (
                  <Divider
                    variant="fullWidth"
                    style={{ background: '#E4E4E4' }}
                  />
                )}
              </>
            )
          })}
      </div>
      <div className="footer-label" style={{ background: '#bfd3ff' }} />
    </>
  )
}
