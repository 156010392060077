import React, { lazy } from 'react'
import { AuditLogListing } from './Listing/AuditLog'
const GLAccountCodeJournalTypeMappingMain = lazy(() =>
  import('./GLAccountCodeJournalTypeMappingTab').then(module => ({
    default: module.GLAccountCodeJournalTypeMappingMain,
  }))
)

const GLAccountCodeJournalTypeMappingRoutes = [
  {
    props: {
      exact: true,
      path: `/mfrs16/:CompanyID/gl-account-code-journal-type-mapping`,
    },
    component: <GLAccountCodeJournalTypeMappingMain />,
  },
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/gl-account-code-journal-type-mapping/audit-log',
    },
    component: <AuditLogListing />,
  },
]

export default GLAccountCodeJournalTypeMappingRoutes
