/* -------------------------------------------- */
/*                   NEW FORM                   */
/* -------------------------------------------- */

// format date from form, since format is different from edit data
export const dateToString = (date?) => {
  const selectedDate = date ? new Date(date) : new Date()
  return selectedDate
    .toString()
    .replace(/\S+\s(\S+)\s(\d+)\s(\d+)\s.*/, '$2 $1 $3')
}

// use for new form, if any of the fields is not empty, or today date, or 0
export const checkHaveValues = (arr, itemArr?, allocArr?) => {
  if (itemArr || allocArr) {
    return (
      itemArr?.length > 0 ||
      allocArr?.length > 0 ||
      arr.some(
        el =>
          el !== '' &&
          el !== null &&
          el !== undefined &&
          el !== dateToString() &&
          el !== 0 &&
          el !== '0.00'
      )
    )
  } else {
    return arr.some(
      el =>
        el !== '' &&
        el !== null &&
        el !== undefined &&
        el !== dateToString() &&
        el !== 0 &&
        el !== '0.00'
    )
  }
}

/* -------------------------------------------- */
/*                   EDIT FORM                  */
/* -------------------------------------------- */

// map through form current form data, compare against existing data on each key
export const checkHaveChanges = (
  currData,
  prevData,
  currFiles?,
  prevFiles?
) => {
  // get current form data keys
  let currKeys = Object.keys(currData).sort()

  // compare form data against existing data, on form data keys
  let haveDiff = currKeys?.map(key => {
    const dateString = key?.includes('Date')
      ? dateToString(prevData[`${key}`])
      : null
    if ((dateString ?? prevData[`${key}`]) === currData[`${key}`]) {
      return false
    } else if (!(key in prevData)) {
      return false
    } else {
      return true
    }
  })
  if (currFiles?.length > 0 || prevFiles?.length > 0) {
    haveDiff.push(
      !prevFiles.every(file => currFiles.includes(file)) ||
        currFiles?.length !== prevFiles?.length
    )
  }

  return haveDiff?.includes(true)
}

/* -------------------------------------------- */
/*                MAIN COMPONENT                */
/* -------------------------------------------- */
interface CheckChangeProps {
  watch: any // current form data
  editData: any // existing data from db, this includes the items and allocations
  itemArr?: any // array of items in current form
  initFiles?: any // attachments to existing data
  currFiles?: any // attachments to current form data
  itemSuffixID?: string // primary key for item e.g. DebitNoteItemID
  itemTableName?: string // relation name for item e.g. DebitNoteItem
  formMode: any // e.g. add, edit
  hasAllocation?: Boolean
  hasItem?: Boolean
}
export const handleExitConfirmation = (props: CheckChangeProps) => {
  const {
    watch,
    editData,
    itemArr,
    initFiles,
    currFiles,
    itemSuffixID,
    itemTableName,
    formMode,
    hasAllocation,
    hasItem,
  } = props

  let currAllocArr
  currAllocArr = (watch('Allocation') as any)?.filter(
    alloc =>
      alloc?.AllocationAmt !== 0 ||
      (alloc?.AllocationID !== null && alloc?.AllocationID !== undefined)
  )
  let detailWatch = watch()
  let detailEditData = editData

  /* ------------------- TEST ------------------- */
  /* ------------ CHECK IF HAVE INFO ------------ */

  if (formMode === 'add') {
    if (hasAllocation) {
      const { Allocation: watchAllocation = [], ...watchWoAlloc } = watch()
      detailWatch = watchWoAlloc
    }

    const newFormValues = Object.values(detailWatch)

    const newPrevFiles =
      editData?.files?.length > 0 ? editData?.files?.map(x => x?.name) : []
    if (
      checkHaveValues(newFormValues, itemArr, currAllocArr) ||
      newPrevFiles?.length !== 0 ||
      (currFiles?.length !== 0 && undefined)
    )
      return true
    else return false
  }

  /* --------- CHECK FOR DETAIL CHANGES --------- */
  if (formMode === 'edit') {
    if (hasAllocation) {
      const { Allocation: editDataAllocation = [], ...editWoAlloc } = editData
      detailEditData = editWoAlloc
    }

    const editPrevFiles =
      initFiles && initFiles.length > 0 ? initFiles?.map(x => x?.name) : []
    let haveDetailChanges: Boolean
    if (detailEditData && Object?.keys(detailEditData)?.length > 1) {
      haveDetailChanges = checkHaveChanges(
        detailWatch,
        detailEditData,
        currFiles,
        editPrevFiles
      )
    }

    /* ---------- CHECK FOR ITEM CHANGES ---------- */
    let haveItemChanges: Boolean[] = []
    if (editData && itemArr?.length > 0) {
      haveItemChanges = itemArr?.map(item => {
        let prevData = editData?.[`${itemTableName}`]?.filter(
          prevItem =>
            prevItem?.[`${itemSuffixID}`] === item?.[`${itemSuffixID}`]
        )[0]
        return prevData ? checkHaveChanges(item, prevData) : true
      })
    }

    /* ---------- CHECK FOR ALLOC CHANGES --------- */
    let haveAllocChanges: Boolean[] = []
    if (editData && currAllocArr?.length > 0) {
      haveAllocChanges = currAllocArr?.map(item => {
        let prevData = editData?.Allocation?.filter(
          prevItem => prevItem?.AllocationID === item?.AllocationID
        )[0]
        return prevData ? checkHaveChanges(item, prevData) : true
      })
    }

    if (
      haveDetailChanges ||
      (haveItemChanges?.length > 0 && haveItemChanges.includes(true)) ||
      (haveAllocChanges?.length > 0 && haveAllocChanges.includes(true))
    )
      return true
    else return false
  }
}

export const handleObjExitConfirmation = (props: CheckChangeProps) => {
  const {
    watch,
    editData,
    itemArr,
    initFiles,
    currFiles,
    itemSuffixID,
    itemTableName,
    formMode,
    hasAllocation,
    hasItem,
  } = props

  let currAllocArr
  currAllocArr = (Object.values(watch('Allocation')) as any)?.filter(
    alloc =>
      alloc?.AllocationAmt !== 0 ||
      (alloc?.AllocationID !== null && alloc?.AllocationID !== undefined)
  )
  let detailWatch = watch()
  let detailEditData = editData

  /* ------------------- TEST ------------------- */
  /* ------------ CHECK IF HAVE INFO ------------ */

  if (formMode === 'add') {
    if (hasAllocation) {
      const { Allocation: watchAllocation = [], ...watchWoAlloc } = watch()
      detailWatch = watchWoAlloc
    }

    const newFormValues = Object.values(detailWatch)

    const newPrevFiles =
      editData?.files?.length > 0 ? editData?.files?.map(x => x?.name) : []
    if (
      checkHaveValues(newFormValues, itemArr, currAllocArr) ||
      newPrevFiles?.length !== 0 ||
      (currFiles?.length !== 0 && undefined)
    )
      return true
    else return false
  }

  /* --------- CHECK FOR DETAIL CHANGES --------- */
  if (formMode === 'edit') {
    if (hasAllocation) {
      const { Allocation: editDataAllocation = [], ...editWoAlloc } = editData
      detailEditData = editWoAlloc
    }

    const editPrevFiles =
      initFiles && initFiles.length > 0 ? initFiles?.map(x => x?.name) : []
    let haveDetailChanges: Boolean
    if (detailEditData && Object?.keys(detailEditData)?.length > 1) {
      haveDetailChanges = checkHaveChanges(
        detailWatch,
        detailEditData,
        currFiles,
        editPrevFiles
      )
    }

    /* ---------- CHECK FOR ITEM CHANGES ---------- */
    let haveItemChanges: Boolean[] = []
    if (editData && itemArr?.length > 0) {
      haveItemChanges = itemArr?.map(item => {
        let prevData = editData?.[`${itemTableName}`]?.filter(
          prevItem =>
            prevItem?.[`${itemSuffixID}`] === item?.[`${itemSuffixID}`]
        )[0]
        return prevData ? checkHaveChanges(item, prevData) : true
      })
    }

    /* ---------- CHECK FOR ALLOC CHANGES --------- */
    let haveAllocChanges: Boolean[] = []
    if (editData && currAllocArr?.length > 0) {
      haveAllocChanges = currAllocArr?.map(item => {
        let prevData = editData?.Allocation?.filter(
          prevItem => prevItem?.AllocationID === item?.AllocationID
        )[0]
        return prevData ? checkHaveChanges(item, prevData) : true
      })
    }

    if (
      haveDetailChanges ||
      (haveItemChanges?.length > 0 && haveItemChanges.includes(true)) ||
      (haveAllocChanges?.length > 0 && haveAllocChanges.includes(true))
    )
      return true
    else return false
  }
}
