import { AttachFile } from '@material-ui/icons'
import PrintIcon from '@material-ui/icons/Visibility'
import { AttachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { useHistory, useParams } from 'react-router'
import '../APPayment.scss'
import { default as React } from 'react'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
}

export const APPaymentDetailContent = (props: DetailProps) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { listEl, documentListing, listStatus, userList, mode } = props

  return (
    <>
      {mode === 'detail' && (
        <>
          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Creditor Name
            </div>
            <span
              style={{ float: 'right' }}
              onClick={() => {
                history.push({
                  pathname: `/account-payable/${CompanyID}/payment/${listEl?.PaymentID}/preview`,
                  state: {
                    ...listEl,
                    mode: 'detail',
                  },
                })
              }}
            >
              <PrintIcon
                style={{
                  fontSize: 'medium',
                  padding: '0px 10px 2px 0px',
                }}
              />
            </span>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.CreditorAccount?.CompanyName}
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Payment Method
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.PaymentMethod?.Name}
            </div>
          </div>

          <div
            className="content-wrap right"
            // style={{ width: mode === 'expansion' ? '40%' : '50%' }}
          >
            <div className="desc" style={{ fontWeight: 300 }}>
              Reference No.
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.RefNo}
            </div>
          </div>
        </>
      )}

      {mode === 'expansion' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Payment Method
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.PaymentMethod?.Name}
            </div>
          </div>

          <div
            className="content-wrap right flex-space"
            // style={{ width: mode === 'expansion' ? '40%' : '50%' }}
          >
            <div className="desc" style={{ fontWeight: 300 }}>
              Reference No.
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.RefNo}
            </div>
          </div>
        </>
      )}

      {mode === 'expansion' && documentListing?.length > 0 && (
        <div
          className="content-wrap right"
          style={{ width: '10%', textAlign: 'center', alignSelf: 'end' }}
        >
          {documentListing && (
            <>
              <span
                onClick={() =>
                  documentListing?.forEach(doc => {
                    window.open(doc?.fileURL, '_blank')
                  })
                }
                style={{
                  position: 'relative',
                  top: '1px',
                }}
              >
                <AttachFile
                  className="mdDesc"
                  style={{
                    fontSize: 12,
                    color: 'orange',
                  }}
                />
              </span>
              <span
                className="mdDesc"
                style={{ color: 'orange', textDecoration: 'underline' }}
              >
                {documentListing?.length}
              </span>
            </>
          )}
        </div>
      )}

      <div className="content-wrap full">
        <div className="desc" style={{ fontWeight: 300 }}>
          Bank Account No
        </div>
        <div className="xsTitle" style={{ fontWeight: 500 }}>
          {`${listEl?.BankAccount?.AccountNo} (${listEl?.BankAccount?.BankProfile?.Name})`}
        </div>
      </div>

      {listEl?.PaymentMethod?.Name?.includes('Card') && (
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Card Type
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {listEl?.CreditCardType?.Name}
          </div>
        </div>
      )}

      {listEl?.PaymentMethod?.Name === 'Cheque' && (
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Cheque No.
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {listEl?.ChequeNo}
          </div>
        </div>
      )}

      {listEl?.PaymentMethod?.Name === 'Cheque' && (
        <div className="content-wrap left">
          <div className="desc" style={{ fontWeight: 300 }}>
            Cheque Date
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {formatDate(listEl?.ChequeDate)}
          </div>
        </div>
      )}

      {listEl?.PaymentMethod?.Name === 'Cheque' && (
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Cheque Expiry Date
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {formatDate(listEl?.ChequeExpiryDate)}
          </div>
        </div>
      )}

      <div className="content-wrap full">
        <div className="desc" style={{ fontWeight: 300 }}>
          Description
        </div>
        <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
          {listEl?.Description ?? listEl?.Remark}
        </div>
      </div>

      {/* <div className="content-wrap left">
        <div className="desc" style={{ fontWeight: 300 }}>
          Amount
        </div>
        <div className="xsTitle" style={{ fontWeight: 500 }}>
          <img
            src={BlackDollarIcon}
            style={{
              width: '12px',
              marginBottom: '-2px',
              font: 'xxTitle highlight-text',
            }}
          />
          {amtStr(listEl?.Amount)}
        </div>
      </div> */}

      {mode === 'detail' && (
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Attachment
          </div>
          {AttachmentDetailView(documentListing)}
        </div>
      )}

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
    </>
  )
}
